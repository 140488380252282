import { Avatar, Box, Button, Divider, Stack } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BigPlayButton, ControlBar, ForwardControl, Player } from "video-react";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { baseUrl } from "../../../api/Api";
import ReadMoreComponent from "../../ReadMoreComponent/ReadMoreComponent";
import { UserContext } from "../../../utils/UserContext";
import { useNavigate } from "react-router-dom";
import { useStoreBroadcastItemMutation } from "../../../api/slices/braodcastApiSlice";
import BroadcastReaction from "./BroadcastReaction";
import BroadcastVideoPoll from "./BroadcastVideoPoll";


const BroadcastVideo = ({data}) => {
  const {userDetails} = useContext(UserContext)
  const navigate = useNavigate();
  const options = { year: "numeric", month: "short", day: "numeric" };
  const date = data?.created_at?new Date(data?.created_at).toLocaleDateString("en-US", options):''

  var hits = data?.meta?.hits?data.meta.hits:0;
  var username = data?.microsite_userdetails?.user_details? JSON.parse(data?.microsite_userdetails?.user_details)?.name?JSON.parse(data?.microsite_userdetails?.user_details)?.name:data?.user?.name:data?.user?.name


  const token = localStorage.getItem('token');
  const [storeBroadcastItem] = useStoreBroadcastItemMutation()

  // view count updation
  const [isPlaying, setIsPlaying] = useState(false);
  const [progressValue,setProgressValue] = useState(0)
  const playerRef = useRef(null);

  const handleStateChange = (state) => {
    if (state === 'playing' && !isPlaying) {
      setIsPlaying(true);
    } else if (state !== 'playing' && isPlaying) {
      setIsPlaying(false);
    }
  };


  const handlePlayButtonClick = (state, uuid) => {
    if(uuid && state==='play'){
      const formData = new FormData();
      formData.append("hits", hits+1);
      var url = `${baseUrl}/api/community/broadcastitem/${uuid}`
     
     storeBroadcastItem({token,formData,url,setProgressValue})
     .unwrap()
     .then((response) => {
      // console.log('response')
     })
    }
  };

  useEffect(() => {
    const playButton = document.querySelector('.video-react-big-play-button');
    if (playButton) {
      playButton.addEventListener('click',()=>{handlePlayButtonClick('play',data?.uuid)} );
    }

    return () => {
      if (playButton) {
        playButton.removeEventListener('click',()=>{handlePlayButtonClick('remove-play',data?.uuid)});
      }
    };
  }, [data]);

      
  
  return (
    <>
      <div className="broadcast-video-container">
      <div className="broadcast-video">
          <Player
            ref={playerRef}
            src={`${baseUrl}/${data?.video}`}
            onStateChange={handleStateChange}
          >
            <BigPlayButton position="center" />
            <ControlBar autoHide={false} className="my-class">
              <ForwardControl seconds={10} order={3.2} />
            </ControlBar>
          </Player>
        </div>
        
        <div className="broadcast-video-content">
          <h4>{data?.title}</h4>
          <div className="broadcast-user-info">
            <div className="broadcast-user-info-left cursorPointer" 
              onClick={(e)=> userDetails?.id===data?.user?.id
                ?navigate('/community-profile',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user,showInfo:true}})
                :navigate('/community-profile/other',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user}})
              }
            >
              <Avatar
                alt={data?.user?.name}
                src={`${baseUrl}/${data?.user?.avatar}`}
              />
              <span className="userName"> {username} </span>
            </div>
            <div className="broadcast-reactor-content">
              <Stack spacing={1.5} direction="row">
                <BroadcastReaction data={data} />
                {/* <Button variant="text" size="small" startIcon={<ThumbUpOutlinedIcon />} sx={{color:'#343232'}} >Like</Button> */}
                {/* <Button onClick={(e)=> setAutoFocus(true)} variant="text" size="small" startIcon={<ChatBubbleOutlineOutlinedIcon />} sx={{color:'#343232'}} >Comment</Button> */}
                {/* <Button variant="text" size="small" startIcon={<ShareOutlinedIcon />} sx={{color:'#343232'}} >Share</Button> */}
              </Stack>
            </div>
          </div>
          <Divider sx={{mt:0.5 , mb:0.5}}/>
          <div className="broadcast-video-details">
             <div className="video-use-info">
                 <span> <AccessTimeIcon sx={{fontSize:'16px',mr:0.5}} />  {date} </span>
                 <span> <PlayArrowIcon sx={{fontSize:'16px'}}/>  {data?.meta?.hits?`${data?.meta?.hits} views`:'No View'} </span>
             </div>
             <div className="video-details-text">
              {data?.details && <ReadMoreComponent text={data?.details} maxLength={150} />} 
             </div>

             {/* poll ui */}
             {/* <hr/>
             <BroadcastVideoPoll /> */}

          </div>
          {/* <Divider sx={{mt:2 , mb:2}}/> */}
        </div>
      </div>
    </>
  );
};

export default BroadcastVideo;
