import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ThemePurchaseBody from "../../../components/Matrimony/Theme/ThemePurchaseBody";
import { useParams } from "react-router";
import useGeolocation from "react-hook-geolocation";
import Geocode from 'react-geocode';
import { GoogleApiKey } from "../../../utils/GoogleApiKey";


const PurchaseThemePage = () => {
  const [currentLocation, setCurrentLocation] = useState("");
  // const [longitude, setLongitude] = useState(null);
  const params = useParams();
  //   const {
  //     data: countryData,
  //     isLoading,
  //     isError,
  //   } = useGetCountryQuery({latitude, longitude});

  // useEffect(() => {
  //   // var latitude = 37.0902;
  //   // var longitude = 95.7129;
  //   navigator.geolocation.getCurrentPosition((pos) => {
  //     const { latitude, longitude } = pos.coords;
  //     const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
  //     // const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
  //     fetch(url)
  //       .then((res) => res.json())
  //       .then((data) => {
  //         console.log("test--------", data);
  //         setCurrentLocation(data.address);
  //       });
  //   });
  // }, []);

  //   console.log("countryData------", countryData)


  Geocode.setApiKey(GoogleApiKey);
  const geolocation = useGeolocation();
  useEffect(()=>{
    setCurrentLocation('loading')
    if (geolocation.error) {
      alert(geolocation.error.message);
    } else {
      Geocode.fromLatLng(geolocation.latitude, geolocation.longitude).then(
        (response) => {
          response?.results[0]?.address_components.forEach(element => {
            if(element?.long_name ==="Bangladesh" || element?.short_name==="BD"){
              setCurrentLocation('BD')
            }
          });

        },
        (error) => {
          // console.error(error);
        }
      );
    }
  },[geolocation.latitude,geolocation.longitude,geolocation.error])
  
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="content_body">
            <ThemePurchaseBody
              params={params}
              currentLocation={currentLocation}
              // currentLocation={'sds'}
            />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PurchaseThemePage;
