import { Divider, Grid } from "@mui/material";
import BroadcastTopNavigate from "../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate";
import BroadcastCategory from "../../components/Broadcast/BroadcastCategory/BroadcastCategory";
import BroadcastItemSection from "../../components/Broadcast/BroadcastItem/BroadcastItemSection";
import { useGetBroadcastCategoryDataQuery, useGetBroadcastItemDataQuery } from "../../api/slices/braodcastApiSlice";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../utils/UserContext";

const BroadcastHome = () => {
  const {msDetails} = useContext(UserContext)
  const microsite_id = msDetails?.id;
  const user_id = ''

  const token = localStorage.getItem('token');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const {data: getBroadcastCategoryData, isFetching: getCategoryDataFetching, isError:getDataCategoryError} = useGetBroadcastCategoryDataQuery({token,microsite_id,currentPage,searchValue});
  const {data: getBroadcastItemData, isFetching: getItemDataFetching, isError:getDataItemError} = useGetBroadcastItemDataQuery({token,microsite_id,currentPage,searchValue,user_id});


  const [scrollTop, setScrollTop] = useState(true)
  useEffect(() => {
    if(scrollTop ===true){
      window.scrollTo(0, 0)
      setScrollTop(false)
    }
  }, [scrollTop])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <div className="broadcast-body">
              <BroadcastTopNavigate />
              <BroadcastCategory broadcastCategroy={getBroadcastCategoryData?.data} loading ={getCategoryDataFetching}  />
              {/* <Divider sx={{ mt:3 , mb:1}}/> */}
              <BroadcastItemSection broadcastItem ={getBroadcastItemData} loading={getItemDataFetching} />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default BroadcastHome;
