import React, { useEffect, useState } from 'react';
import shape1 from '../../../../asset/image/shape-1.webp';
import shape2 from '../../../../asset/image/shape-2.webp';
import midImg from '../../../../asset/image/mid.png';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../../../../api/Api';

import parser from 'html-react-parser'
import { Image } from 'antd';
import { useGetSingleMatrimonyQuery } from '../../../../api/slices/matrimonyApiSlice';

const DynamicBody = ({params}) => {
    const location = useLocation();
    const [details, setDetails] = useState(null)
    const token = localStorage.getItem("token");
    const [uuid, setUuid] = useState('')

    const {data: getSingleMatrimonyData,  isFetching: getDataFetching, isError:getDataError} = useGetSingleMatrimonyQuery({token,uuid});
    useEffect(()=>{
        if(location.state !==null){
            setUuid(location?.state?.data.uuid)            
        }

        if(getSingleMatrimonyData){
            setDetails(getSingleMatrimonyData)
        }
    },[location?.state,getSingleMatrimonyData])

  return (
     <>      
      {/* bridal-1  bridal-2 bridal-3 groom-1 groom-2 */}

      

      <div className={`metromonial_theme ${params.type}-${params.themenumber}`} > 
        <div className="theme_heading">
            <h4>{details?.name}'s Details</h4>
        </div>

        <div class="shape_one">
            <img src={shape1} alt=""/>
        </div>
        <div class="shape_two">
            <img src={shape2} alt=""/>
        </div>
        <div class="shape_three">
            <img src={midImg} alt=""/>
        </div>

       <div className="part_one">
                <div className="feature_img">
                    {details !==null && details?.featured_image !==null &&  <img src={`${baseUrl}/${details?.featured_image}`} alt={details?.name}/> }
                </div>
                <div className="user_personal_data">
                   <Grid container spacing={1.5}>
                      <Grid item lg={12}>
                      <div className="data_item">
                                <span> Name :</span>
                                {details?.name}
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Gender :</span>
                                {details?.meta?.gender}
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Religion :</span>
                                {details?.meta?.religion}
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Marital Status :</span>
                                {details?.meta?.marital_status}
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Date Of Birth :</span>
                                {details?.meta?.dob}                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Height :</span>
                                {details?.meta?.height}                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Weight :</span>
                                {details?.meta?.weight}                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Complexion :</span>
                                {details?.meta?.complexion}
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Blood Group :</span>
                                {details?.meta?.blood_group}
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Location :</span>
                                {details?.location}
                            </div>
                      </Grid>
                      <Grid item lg={12}>
                      <div className="data_item">
                                <span> Address :</span>
                                {details?.meta?.address}
                            </div>
                      </Grid>
                      <Grid item lg={12}>
                      <div className="data_item">
                                <span> About Me :</span>
                                {details?.meta?.about_me &&<>{parser(details?.meta?.about_me)}</> }
                            </div>
                      </Grid>
                   </Grid>
                   
                </div>
       </div>
       
       {details !==null && details?.files && details?.files.length>0 && 
       <div className="others_data">
        <div className="data_section">
            Gallery
         </div>
          <Grid container spacing={1}>
            <Image.PreviewGroup>
                    {details?.files.map((data, key)=>{
                        return(
                            <Grid item lg={3}>
                                 <div className="met_gallery_wrap">
                                    <Image key={data.uuid} src={`${data.url}`}/>
                               </div>
                            </Grid>
                        )
                    })}
               
            </Image.PreviewGroup>
          </Grid>
       </div>}
        {/* Grid item lg={} */}
       <div className="others_data">
        <div className="data_section">
            Family Information
         </div>
         <div className="m_data_wrapper">
         {details?.meta?.father_details &&
             <div className="m_data_item">
               <Grid container spacing={2}>
                   <Grid item lg={3}>
                       <div className="data_title">
                           Father's Details 
                        </div>
                   </Grid>
                   <Grid item lg={9}>
                    
                        <div className="data_text">
                        {parser(details?.meta?.father_details)}
                    </div>
                    
                      
                   </Grid>
                </Grid>
             </div>}
             {details?.meta?.mother_details &&
             <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Mother's Details 
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                            {parser(details?.meta?.mother_details)}
                         </div>
                    </Grid>
                 </Grid>
              </div>}
              {details?.meta?.siblings_details &&
              <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Sibling's Details 
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                        {parser(details?.meta?.siblings_details)} 
                         </div>
                    </Grid>
                 </Grid>
              </div>}
              {details?.meta?.paternal_family_details &&
              <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Paternal Family Details 
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                            {parser(details?.meta?.paternal_family_details)}
                         </div>
                    </Grid>
                 </Grid>
              </div>}
              {details?.meta?.maternal_family_details &&
              <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Maternal Family Details 
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                            {parser(details?.meta?.maternal_family_details)}
                         </div>
                    </Grid>
                 </Grid>
              </div>}
              {details !==null &&  details?.meta?.marital_status ==='married' && <div className="person_information">
                                <div className="information_table">
                                    <div className="section_title">
                                        Marital Details
                                    </div>
                                    <div className="info_item_list">
                                    {details?.meta?.marital_details && <p>{parser(details?.meta?.marital_details)}</p>}
                                    </div>
                                </div>
                </div>}
         </div>
       </div>

       <div className="others_data">
        <div className="data_section">
            Carrer Information
         </div>
         <div className="m_data_wrapper">
            {details?.meta?.education_details &&
             <div className="m_data_item">
               <Grid container spacing={2}>
                   <Grid item lg={3}>
                       <div className="data_title">
                           Educational Details 
                        </div>
                   </Grid>
                   <Grid item lg={9}>
                       <div className="data_text">
                            {parser(details?.meta?.education_details)}
                        </div>
                   </Grid>
                </Grid>
             </div>}
             {details?.meta?.work_details &&
             <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Work Details 
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                        {parser(details?.meta?.work_details)}
                         </div>
                    </Grid>
                 </Grid>
              </div>}

              {details !==null &&  details?.meta?.hobbies ==='married' && <div className="person_information">
                                <div className="information_table">
                                    <div className="section_title">
                                        Hobbies
                                    </div>
                                    <div className="info_item_list">
                                    {details?.meta?.hobbies && <p>{parser(details?.meta?.hobbies)}</p>}
                                    </div>
                                </div>
                            </div>}
         </div>
       </div>

       <div className="others_data">
        <div className="data_section">
            Note
         </div>
         <div className="m_data_wrapper">
            {details?.meta?.dislikes_from_partner &&
             <div className="m_data_item">
               <Grid container spacing={2}>
                   <Grid item lg={3}>
                       <div className="data_title">
                        Dislikes From Partner
                        </div>
                   </Grid>
                   <Grid item lg={9}>
                       <div className="data_text">
                        {parser(details?.meta?.dislikes_from_partner)}
                        </div>
                   </Grid>
                </Grid>
             </div>}
             {details?.meta?.dont_knock_if &&
             <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Dont Knock If
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                            {parser(details?.meta?.dont_knock_if)}
                         </div>
                    </Grid>
                 </Grid>
              </div>}
              {details?.meta?.special_note &&
              <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Special Note
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                            {parser(details?.meta?.special_note)} 
                         </div>
                    </Grid>
                 </Grid>
              </div>}
         </div>
       </div>
       {details !==null &&  details?.meta?.other_text &&
       <div className="others_data">
        <div className="data_section">
            Others
         </div>
         <div className="m_data_wrapper">
             <div className="m_data_item">
               <Grid container spacing={2}>
                   <Grid item lg={3}>
                       <div className="data_title">
                           Others Details 
                        </div>
                   </Grid>
                   <Grid item lg={9}>
                       <div className="data_text">
                            {parser(details?.meta?.other_text)}
                        </div>
                   </Grid>
                </Grid>
             </div>
         </div>
       </div>}
    </div>
     
     </>
  )
}

export default DynamicBody