import React, { useContext } from "react";
import { Avatar, Box } from "@mui/material";
import StorageProgress from "../../storageProgress/StorageProgress";
import ReadMoreComponent from "../../ReadMoreComponent/ReadMoreComponent";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../api/Api";
import { UserContext } from "../../../utils/UserContext";

const PollCard = ({data, active, handleSinglItem}) => {
  const {userDetails} = useContext(UserContext)
  const navigate = useNavigate()
  var username = data?.microsite_userdetails?.user_details? JSON.parse(data?.microsite_userdetails?.user_details)?.name?JSON.parse(data?.microsite_userdetails?.user_details)?.name:data?.user?.name:data?.user?.name
  return (
    <div className={active?'poll-card active':'poll-card'} onClick={(e)=> handleSinglItem(data)}>
       <div className="user-info">
           <Box className="user-details"
             onClick={(e)=> userDetails?.id===data?.user?.id
               ?navigate('/community-profile',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user,showInfo:true}})
               :navigate('/community-profile/other',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user}})
             }
             >
             <Box display={'flex'} alignItems={'center'}>
               <Avatar
                 alt={data?.title}
                 src={`${baseUrl}/${data?.user?.avatar}`}
                 sx={{ maxWidth: "32px", maxHeight: "32px" }}
               />
               <Box sx={{ml:1}} className="user-name">{username}</Box>
              </Box>  
           </Box>
       </div>
      <div className="details">
        {data?.name && data?.name.length>50 
          ?
          <small> {data?.name.slice(0,70)}...</small>
          :
          <small> {data?.name}</small>
        }
       
      </div>
      {data?.description && 
        <Box sx={{mt:1}}>
          <ReadMoreComponent text={data?.description} maxLength={120} parse={true}  />
        </Box>}
      {/* <div className="ratio-poll">
        <Box sx={{ width: "33.33%" }}>
          <StorageProgress completed={0} color={"#0AA936"} />
        </Box>
        <Box sx={{ width: "33.33%" }}>
          <StorageProgress completed={0} color={"#DC0000"} />
        </Box>
        <Box sx={{ width: "33.33%" }}>
          <StorageProgress completed={0} color={"#989898"} />
        </Box>
      </div> */}
      <hr />
    </div>
  );
};

export default PollCard;
