import React from "react";
import paymentCard from "../../asset/image/paymentCard.png";
import stripe from "../../asset/image/stripe.png";
import paypal from "../../asset/image/paypal.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import showcase1 from "../../asset/image/showcase-1.jpeg";
import showcase2 from "../../asset/image/showcase-2.jpeg";
import showcase3 from "../../asset/image/showcase-3.jpeg";

function BusinessPayment() {
  return (
    <>
      <div className="business-payment">
        <h2>Payment Details</h2>

        <div className="business-payment-wrap">
          <div className="pay-card mb-5">
            <div className="them-subscription-container">
              <div className="theme-img">
                <img src={showcase2} alt="" />
              </div>
              <div className="theme-info mt-5">
                <h4>Barlin Exprem Theme</h4>
                <h4> $5 </h4>
              </div>
              <div className="type-content">
                <h5>Premium Subscription Plan</h5>
                <form>
                  <label>
                    <input
                      type="radio"
                      name="business-type"
                      className="card-input-element"
                    />
                    <div className="panel panel-default card-input">
                      <h3>$10/ 1 month</h3>
                      <p>
                        Don't miss out—subscribe today !
                      </p>
                    </div>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="business-type"
                      className="card-input-element"
                    />
                    <div className="panel panel-default card-input">
                      <h3>$50/ 6 month</h3>
                      <p>
                        Don't miss out—subscribe today for only $50 
                      </p>
                    </div>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="business-type"
                      className="card-input-element"
                    />
                    <div className="panel panel-default card-input">
                      <h3>$90/ 12 month</h3>
                      <p>
                        Don't miss out—subscribe today for only $90 per month
                        and enjoy the full experience!
                      </p>
                    </div>
                  </label>
                </form>
                <Link to="/business-payment">
                  <Button
                    variant="contained"
                    fullWidth
                    size="small"
                    sx={{ mt: 2 }}
                  >
                    Pay Now
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="pay-other-line">
            <span> Pay With</span>

            <div className="other-method">
              <div className="method-item">
                <img src={paypal} alt="" />
              </div>
              <div className="method-item">
                <img src={stripe} alt="" />
              </div>
            </div>
          </div>

          <div className="pay-content">
            <div className="total-amount">
              <h6> Total Amount </h6>
              <h5>$ 10</h5>
            </div>
            <Link to="/payment-success">
              <Button variant="contained" fullWidth sx={{ mt: 5 }}>
                Pay Now
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessPayment;
