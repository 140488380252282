import React, { useEffect } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import AppRouter from "./router/AppRouter";
import "./asset/css/style.css";
import "./asset/css/responsive.css";
import { micrositeDetailsUrl } from "./api/Api";
import { Provider } from "react-redux";
import { store } from "./store/store";

// import axios from "axios";
// import "antd/dist/reset.css";
function App() {
  return (
    <div>
      <Provider store={store}>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
