import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Grid, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../utils/UserContext";
import { BoxLoadEffect} from "../../components/PageLoadEffects";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import GroupMainBody from "../../components/Groups/GroupMainBody";
import { useDeleteGroupMutation, useGetGroupDataQuery } from "../../api/slices/groupsApiSlice";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GroupMyPage = () => {
  const navigate = useNavigate();
  const {msDetails,userDetails, loggedInUser} = useContext(UserContext)
  const token = localStorage.getItem('token');

  const microsite_id = msDetails?.id
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [groupData, setGroupData] = useState(null)



  const {data: getGroupData, isFetching: getDataFetching, isError:getDataError} = useGetGroupDataQuery({token,microsite_id,currentPage,searchValue});
  const [deleteGroup, response] = useDeleteGroupMutation()


  useEffect(()=>{
    const storeGroup = [];
    if(getGroupData?.data){
      if(getGroupData?.data?.length>0){
        getGroupData?.data.forEach(element => {
          if(element?.user_id === userDetails.id){
            storeGroup.push(element)
          }
        });
      }
      setGroupData(storeGroup);
      setLastPage(getGroupData?.last_page);
      setTotalLength(getGroupData?.total)
    }
  },[getGroupData])

    //handle delete a resouce
    const handleDeleteGroup=(uuid)=>{
      Swal.fire({
          heightAuto: false,
          backdrop: false,
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            if (result.isConfirmed) {
              deleteGroup({token,uuid})
            }
          }
        });  
  }




  return (
    <Fragment>
       <Grid container spacing={2}>
           <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
           <Grid item lg={9} md={8} sm={12} xs={12}>
              <div className="content_body">
              <Tooltip title="Back">
                 <div className="backArrow" onClick={(e)=>{navigate(-1)}}>
                    <ArrowBackIcon/>
                 </div> 
               </Tooltip>
                 <div className="section_headear">
                    <Tooltip title="Groups" arrow>
                      <h4>Groups <span><InfoIcon /></span> </h4>
                    </Tooltip>

                    <div className="matromonial_search_form">  
                      <div className="searchBar">
                          <input type="text" placeholder="Search Here"
                            autocomplete="off"
                            className="form_control"
                            onChange={(e)=> setSearchValue(e.target.value)}
                            />
                            <i><Button type="submit">
                              <FontAwesomeIcon icon={faSearch}/></Button>
                            </i>
                      </div>
                    </div>
                    
                    <div className="btns_row">
                      {(loggedInUser?.user_type ==='admin' || userDetails?.id===msDetails.user_id) && 
                        <>
                            <Link to='/group-store'>
                              <div className="Btn_two">
                                Add Group
                              </div>
                            </Link>
                            <Link to='/my-groups'>
                              <div className="Btn_two">
                                My Groups
                              </div>
                            </Link>
                        </>
                      }
                    </div>
                 </div> 
                 
                 <div className="resource_wrapper">
                        {(groupData !==null && groupData.length ===0) &&
                         <div  className="placeholder_text">
                             No Groups Found    
                        </div>}
                    <Grid container spacing={2}>
                      {(groupData !==null && groupData.length>0 )&& groupData.map((data,key)=>{
                        return(
                           <Grid item lg={4} md={6} sm={12} xs={12}>
                             <GroupMainBody group={data} handleDeleteGroup={handleDeleteGroup} />
                           </Grid>
                        )
                      })}  
                       
                    </Grid>       
                    {groupData ===null && <>
                      <Grid container spacing={2}>
                         <Grid item lg={4} md={6} sm={12} xs={12}>{BoxLoadEffect()}</Grid>
                         <Grid item lg={4} md={6} sm={12} xs={12}>{BoxLoadEffect()}</Grid>
                         <Grid item lg={4} md={6} sm={12} xs={12}>{BoxLoadEffect()}</Grid>
                      </Grid>
                    </>}
                 </div>
              </div>
           </Grid>
        </Grid>
        <ToastContainer />
    </Fragment>
  );
};

export default GroupMyPage;
