import React, { Fragment, useEffect } from "react";
import { Grid, Tooltip } from "@mui/material";
import ResourceCreate from "../../components/resource/ResourceCreateBody";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalBusinessCreateBody from "../../components/localBusiness/LocalBusinessCreateBody";
import ChoosePlan from "../../components/postBusiness/ChoosePlan";
import ChooseCommuinity from "../../components/postBusiness/ChooseCommuinity";
import BusinessSetup from "../../components/postBusiness/BusinessSetup";
import BusinessPayment from "../../components/postBusiness/BusinessPayment";
import PaymentSuccessMsg from "../../components/postBusiness/PaymentSuccessMsg";
import PaymentFailedMsg from "../../components/postBusiness/PaymentFailedMsg";


const LocalBusinessCreatePage = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
       <Grid container spacing={2}>
           <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
           <Grid item lg={9} md={8} sm={12} xs={12}>
              <div className="content_body">
                    <Tooltip title="Back">
                       <div className="backArrow" onClick={(e)=>{navigate(-1)}}>
                          <ArrowBackIcon/>
                       </div> 
                   </Tooltip>
                   <div className="post-business-container">
                      {/* <ChoosePlan/> */}
                      {/* <ChooseCommuinity/> */}
                      {/* <BusinessSetup/> */}
                      {/* <BusinessPayment/> */}
                      {/* <PaymentSuccessMsg/> */}
                      {/* <PaymentFailedMsg/> */}
                   </div>
               </div>
           </Grid>
        </Grid>
    </Fragment>
  );
};

export default LocalBusinessCreatePage;
