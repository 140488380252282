import React, { useEffect, useState } from "react";
import BroadcasSubItem from "./BroadcasSubItem";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import SubItemEffect from "../../Skeleton/VideoBroadcast/SubItemEffect";

const BroadcastSubItemList = ({data,broadcastItem,singleUuid,setScrollTop,loading }) => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(true)
  const storeActiveItem = [];
  if(broadcastItem && broadcastItem?.data && broadcastItem?.data?.length>0){
    broadcastItem?.data.forEach(item => {
      if(data?.category_id ===item?.category_id && item?.status ===1){
        storeActiveItem.push(item)
      }
    });
  }

  useEffect(()=>{
    setTimeout(()=>{
      setTimer(false)
    },1500)
  },[timer])

    // handle details item
    const handleSinglItem = (uuid)=>{
      navigate(`/broadcast-details/${uuid}`)
      setScrollTop(true)
    }

  return (
    <>
    {loading && <SubItemEffect />} 
        {!loading && (storeActiveItem?.length ===0) &&
          <>
          {!timer
            ? <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}>
                <Button disabled>{'No Media Data Found...'}</Button>
              </Box>
            : <SubItemEffect />
          }
          </>
      }
      <div className="broadcast-sub-item-list">
      {storeActiveItem && storeActiveItem?.length>0 && storeActiveItem.map((item, i)=>{
        return(
          <BroadcasSubItem
            data={item}
            video={item?.video}
            img={item?.thumb}
            title={item?.title}
            userAvatar={item?.user?.avatar}
            userName={item?.user?.name}
            singleUuid={singleUuid}
            handleSinglItem={handleSinglItem}
          />
        )
      })}
       
      </div>
    </>
  );
};

export default BroadcastSubItemList;
