import React, { Fragment, useContext, useEffect, useState } from "react";
import lms from "../../asset/image/lms.png";
import whiteLogo from "../../asset/image/logo_white.png";
import blackLogo from "../../asset/image/logo_black.png";
import "../../asset/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import newsfeed from "../../asset/image/newsfeed.png";
import { UserContext } from "../../utils/UserContext";
import Avatar from "../../asset/image/avatar.jpg";
import { baseUrl, commonUserUrl, reportUrl } from "../../api/Api";
import { Box, Button, Container, Grid, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import StoreIcon from '@mui/icons-material/Store';
import LogoutIcon from '@mui/icons-material/Logout';
import Groups3Icon from '@mui/icons-material/Groups3';
import MenuIcon from '@mui/icons-material/Menu';

import mamberImg from "../../asset/image/noticeSideC.png";
import eventImg from "../../asset/image/noticeSideE.png";
import resources from "../../asset/image/noticeSideG.png";
import resourceImg from "../../asset/image/resourceSide.png";
import jobImage from "../../asset/image/jobSideH.png";
import representiveS from "../../asset/image/representiveSide.png";
import folder from "../../asset/image/folder.png";
import localRepresentetiveImg from "../../asset/image/representetive.png";
import coupleImg from "../../asset/image/couple.png";
import magazineImg from "../../asset/image/magazine.png";

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotificationsIcon from '@mui/icons-material/Notifications';
import BugReportIcon from '@mui/icons-material/BugReport';
import ReportIcon from '@mui/icons-material/Report';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';


import ChatIcon from '@mui/icons-material/Chat';

import peopleImg from "../../asset/image/people.png"
import ChatWhite from "../../asset/image/Chat-white.png";

import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteNotificationMutation, useGetNotificationQuery, useStoreNotificationMutation } from "../../api/slices/notificationApiSlice";

import parser from 'html-react-parser'
import Swal from "sweetalert2";
import { notifyError } from "../../utils/Toast";
import broadcastImage from "../../asset/image/videoBroadcast.png";
import pollImage from "../../asset/image/pollImage.png";


const TopNaviation = (props) => {
  const { userDetails, msDetails,loggedInUser } = useContext(UserContext);
  const [gender, setGender] = useState();
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const msID = msDetails?.id;
  // const msID = '';
  const userID = userDetails.id
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const [notifications, setNotifications] = useState([])

  const {data: getNotificationsData, isFetching: getDataFetching, isError:getDataError} = useGetNotificationQuery({token,msID,userID,currentPage,searchValue});
  const [storeNotification] = useStoreNotificationMutation()
  const [deleteNotification] = useDeleteNotificationMutation()

     //get details
     const getMicrositeDetails = ()=>{
      let config = {
          method: 'get',
          url: `${commonUserUrl}/${userDetails?.id}/${msDetails?.id}`,
        };
        axios.request(config)
        .then((res) => {
         const storeData = res?.data?.user_details
         const storeMeta = res?.data?.meta
         if(storeData){
          const info = JSON.parse(storeData)
          const meta = storeMeta
  
          if(info?.name){
            setName(info?.name);
          }else{
            setName(userDetails?.profile?.name);
          }
         
         
          if(info?.avater){
            setAvatar(info?.avater);
          }else{
            setAvatar(userDetails?.profile?.avatar);
          }
          // setCover(meta?.banner)
         }
        }).catch((e)=>  { })
  }
  useEffect(()=>{
    getMicrositeDetails()
  },[])

  // notification hook
  useEffect(()=>{
    var storeNotification = [];
    if(getNotificationsData && getNotificationsData?.data?.length>0){
      getNotificationsData?.data.forEach(element => {
        if(element?.status ===0){
          storeNotification.push(element)
        }
      });
      setNotifications(storeNotification)
    }
  },[getNotificationsData])


  // handle delete notification 
  const handleDeleteNotification =(uuid)=>{
    Swal.fire({
      heightAuto: false,
      backdrop: false,
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          // Show a loading indicator
          Swal.showLoading();
           deleteNotification({ token, uuid });
        } catch (error) {
          // Handle errors and display an error message
          Swal.fire('Error', 'An error occurred while deleting the profile.', 'error');
        }
      }
    });  
  }



  const [navElement, setNavElement] = useState({
    navBarTitle: "navTitle", //pass a className name
    navBarLogo: [whiteLogo], //pass an object
    navVariant: "dark",
    navBackgroundColor: "navBackground",
    navItemsColor: "navItems",
    pageTitle: props.title,
  });
  const onScroll = () => {
    if (window.scrollY > 100) {
      setNavElement({
        navBarTitle: "navTitleScroll",
        navBarLogo: [blackLogo],
        navVariant: "light",
        navBackgroundColor: "navBackgroundScroll",
        navItemsColor: "navItemsScroll",
      });
    } else if (window.scrollY < 100) {
      setNavElement({
        navBarTitle: "navTitle",
        navVariant: "dark",
        navBarLogo: [whiteLogo],
        navBackgroundColor: "navBackground",
        navItemsColor: "navItems",
      });
    }
  };

  window.addEventListener("scroll", onScroll);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("data");
    localStorage.removeItem("loggedInUserInfo");
    localStorage.removeItem("msDetails");
    
    window.location.href = "/home";
  };



  // Dropdown 
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);


  // Drawer 
  const [state, setState] = React.useState({ left: false });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleMenuDrawer = (anchor) => {
    setState({ ...state, [anchor]: false });
  }


  // handle profile
  const  handleProfile =(e,anchor)=>{
    handleMenuDrawer(anchor);
    navigate('/community-profile',{state:{showInfo:true}})
  }
  const  handleCreateCommunity =(e,anchor)=>{
    handleMenuDrawer(anchor);
    navigate('/community-packeges')
  }

  // dropdown menu for notification
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const openNotification = Boolean(anchorElNotification);
  const handleClickNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };


  // handle replay 
  const handleReplay = (row, status) =>{
    handleCloseNotification();
    let formData = new FormData();
    formData.append('user_id', userDetails.id);
    formData.append('microsite_id', msDetails.id);
    formData.append('name', userDetails?.profile?.name );
    formData.append('email', userDetails?.email);
    formData.append('status', status);
    formData.append('report_to', 'admin');
     

      var url =`${reportUrl}/${row.uuid}`

      storeNotification({token,formData,url})
      .unwrap()
      .then((response) => {
        // setLoaderVisible(false)
        navigate('/report/details', {state:{row:row}})
      })  
   
    .catch((error) => {
      // setLoaderVisible(false)
      notifyError('Something went wrong.')
    });
}


// Check if the user is accessing the page on a mobile device
var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

// if (isMobile) {
//   // User is accessing the page on a mobile device
//   console.log("Mobile device detected");
// } else {
//   // User is accessing the page on a desktop device
//   console.log("Desktop device detected");
// }


  return (
    <Fragment>
      <title>{navElement.pageTitle}</title>
      <header>
        <div className="header">
          <Container>
            <div className="header_content">
              <Grid container alignItems="center" className="mob_header_grid">
                <Grid item lg={4} md={3} sm={2} xs={2}>
                  <div className="header_item">
                    <Link className="logo" to="/community-info">
                      <img src={lms} alt="" />
                    </Link>

                    {JSON.stringify(msDetails?.name)?.length < 14 ? <>
                    <div onClick={(e)=> navigate('/community-info')} className="commuinity_name cursorPointer">{msDetails.name}</div></> 
                      : 
                      <>
                      <div onClick={(e)=> navigate('/community-info')} className="commuinity_name cursorPointer">{JSON.stringify(msDetails?.name)?.slice(0, 14)}...</div>
                      </>
                      }
                  </div>
                </Grid>
                <Grid item lg={4} md={5} sm={8} xs={8}>
                  <div className="header_item_wrapper">
                    <Tooltip title="Home">
                      <Link to="/" onClick={(e)=> window.scroll(0,0)}>
                        <div className="header_item_list">
                          <HomeIcon />
                        </div>
                      </Link>
                    </Tooltip>
                    {/* <Link >
                        <div className="header_item_list">
                          <Badge badgeContent={4} color="primary">
                            <NotificationsIcon color="action" />
                          </Badge>
                        </div>
                      </Link> */}
                    <Tooltip title="Jobs">
                      <Link to="/job">
                        <div className="header_item_list">
                          <WorkIcon color="action" />
                        </div>
                      </Link>
                    </Tooltip>

                    <Tooltip title="Classified">
                      <Link to="/classified">
                        <div className="header_item_list">
                          <StoreIcon color="action" />
                        </div>
                      </Link>
                    </Tooltip>

                    <Tooltip title="Community Chat">
                        <Link to="/chat">
                            <Box color='action' className="header_item_list">
                              <img src={ChatWhite} alt=""  />
                            </Box>
                      </Link>
                    </Tooltip>
                     
                    <Tooltip title="Community Report Notifications">
                        {/* <Link to="/chat"> */}
                            <Box onClick={handleClickNotification} color='action' className="header_item_list">
                             <Badge badgeContent={notifications?.length} max={5} color="primary">
                               <MailIcon color="action" />
                             </Badge>
                            </Box>
                      {/* </Link> */}
                    </Tooltip>
                    
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorElNotification}
                          open={openNotification}
                          onClose={handleCloseNotification}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          {notifications.length>0 && notifications.slice(0,5).map((notification, key)=>{
                            if(notification?.status ===0){
                              return(
                                <MenuItem >
                                  <Box onClick={(e)=> handleReplay(notification,2)}>{parser(notification?.message)}</Box>
                                 <span>
                                    <Box sx={{ml:2, mt:'-20px'}}>
                                      <DeleteIcon onClick={(e)=>handleDeleteNotification(notification.uuid)} style={{ color: 'red' }} />
                                    </Box>
                                  </span>
                                </MenuItem>
                              )
                            }
                          })}
                          {notifications.length ===0 &&
                              <MenuItem >
                                <Button disabled> No Notification Found</Button>
                              </MenuItem>
                          }

                          {notifications.length>5 &&
                            <>
                              <hr/>
                              <Box display={'flex'} justifyContent={'center'}>
                                <Button variant="contained" onClick={(e)=> {navigate('/report');handleCloseNotification(e)}} >Show More</Button>
                              </Box>
                            </>
                          }
                        </Menu>
                      {!isMobile && 
                          <Link >
                            <div className="header_item_list " onClick={(e)=>window.location.href='/home'}>
                              <SearchIcon color="action" />
                            </div>
                          </Link>
                          } 
                    
                   
                  </div>
                  
                </Grid>
                
                <Grid item lg={4} md={4} sm={2} xs={2}>
                  <div className="header_item_wrapper2">
                {/*             
                  <Tooltip title="Community Chat" className="mob_none">
                        <Link to="/chat">
                            <Box color='action' className="header_item_list">
                              <img src={ChatWhite} alt="" width={'10%'} />
                            </Box>
                      </Link>
                    </Tooltip> */}

                    {/* <Tooltip title="Chat Inbox" className="">
                      <Link to="/chat">
                        <div className="header_item_list">
                          <img src={ChatWhite} width={'65%'} alt="" />
                        </div>
                      </Link>
                    </Tooltip> */}
                      
                  {(loggedInUser?.user_type ==='admin' || userDetails.id === msDetails?.user_id) &&
                      <Tooltip title="Community Setup" className="mob_none">
                            <Link to="/community-setup">
                            <div className="header_item_list">
                                <SettingsIcon color="action" />
                            </div>
                          </Link>
                        </Tooltip>
                      }
                  <Tooltip title="Report to admin" className="mob_none">
                      <Link to="/report">
                        <div className="header_item_list">
                          <ReportIcon color="action" />
                        </div>
                      </Link>
                    </Tooltip>
                    {/* <Tooltip title="Report to developer">
                      <Link to="/report-developer">
                        <div className="header_item_list">
                          <BugReportIcon color="action" />
                        </div>
                      </Link>
                    </Tooltip> */}
                    <div className="profile_dashed">
                      <IconButton
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}>
                        {
                          //Avatar
                          avatar ? (
                            <div className="profile_img">
                              <img
                                src={`${baseUrl}/${avatar}`}
                                alt={name}
                              />
                            </div>
                          ) : (
                            <div className="profile_img">
                              <img
                                src={`${Avatar}`}
                                alt={name}
                              />
                            </div>
                          )
                        }
                      </IconButton>
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <div className="menu_wrapper"
                                  autoFocusItem={open}
                                  id="composition-menu"
                                  aria-labelledby="composition-button"
                                  onKeyDown={handleListKeyDown}>
                                  <Link >
                                    <div className="menu_profile" onClick={(e)=> {window.location.href=('/community-profile'); handleClose()}}>
                                      <div className="menu_profile_left">
                                        {avatar ? (
                                          <div className="profile_img">
                                            <img
                                              src={`${baseUrl}/${avatar}`}
                                              alt={name}
                                            />
                                          </div>
                                        ) : (
                                          <div className="profile_img">
                                            <img
                                              src={`${Avatar}`}
                                              alt={name}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="userName">
                                        {name}
                                      </div>
                                    </div>
                                  </Link>
                                 
                                  <div className="menu_wrapper_list" onClick={(e) => window.location.href = '/commuinityList'}>
                                    <i><Groups3Icon /></i>
                                    <span>My Communities</span>
                                  </div>
                                  {(loggedInUser?.user_type ==='admin' || userDetails.id === msDetails?.user_id) && <div className="menu_wrapper_list" onClick={(e) => {navigate('/complete-profile');handleToggle()}}>
                                    <i><SettingsIcon /></i>
                                    <span>Complete Profile</span>
                                  </div>
                                  }
                                  
                                  <div className="menu_wrapper_list" onClick={logout}>
                                    <i><LogoutIcon /></i>
                                    <span>Log Out</span>
                                  </div>
                                </div>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </div>
                    {['left'].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <div className="mobile_hamburger_icon" onClick={toggleDrawer(anchor, true)}>
                          <MenuIcon />
                        </div>
                        <SwipeableDrawer
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                        >
                          {/* {list(anchor)} */}
                          <div className="drawer_Wrapper">
                            <a>
                              <div className="profile_dashed_mob" onClick={(e)=> handleProfile(e, anchor)}>
                                {
                                  //Avatar
                                  avatar ? (
                                    <div className="profile_img">
                                      <img
                                        src={`${baseUrl}/${avatar}`}
                                        alt={name}
                                      />
                                    </div>
                                  ) : (
                                    <div className="profile_img">
                                      <img
                                        src={Avatar}
                                        alt={name}
                                      />
                                    </div>
                                  )
                                }
                                <div className="user_name">
                                  {name}
                                </div>
                              </div>
                            </a>
                            <a onClick={(e)=> handleProfile(e,anchor)} >
                                  <div className="item_center mb-10">
                                     <Button variant="contained" sx={{color:'#fff !important'}}>Community Profile</Button>
                                   </div>
                                </a>
                            <a onClick={(e)=> handleCreateCommunity(e,anchor)} >
                                  <div className="item_center mb-10">
                                     <Button variant="outlined" sx={{color:'#000 !important'}}>Create Community</Button>
                                   </div>
                                </a>
                             <Link to='/home' onClick={(e) => handleMenuDrawer(anchor)} >
                              <div className="my_commuinity" >
                                <i><SearchIcon /></i>
                                <span>Search Communities</span>
                              </div>
                            </Link>   
                            <Link to='/commuinityList' onClick={(e) => handleMenuDrawer(anchor)}>
                              <div className="my_commuinity">
                                <i><Groups3Icon /></i>
                                <span>My Communities</span>
                              </div>
                            </Link>

                            <Link to='/report' onClick={(e) => handleMenuDrawer(anchor)}>
                              <div className="my_commuinity">
                                <i><ReportIcon /></i>
                                <span>Report to admin</span>
                              </div>
                            </Link>
                           
                            {(loggedInUser?.user_type ==='admin' || userDetails.id === msDetails?.user_id) &&
                              <Link to='/community-setup' onClick={(e) => handleMenuDrawer(anchor)}>
                                <div className="my_commuinity">
                                  <i><SettingsIcon /></i>
                                  <span>Community Setup</span>
                                </div>
                              </Link>
                            }

                            <Link to='/chat' onClick={(e) => handleMenuDrawer(anchor)}>
                              <div className="my_commuinity">
                                <i><ChatIcon /></i>
                                {/* <i><img src={chatImg} alt="" width={'15%'}/></i> */}
                                <span>Inbox</span>
                              </div>
                            </Link>

                            {/* <Link to='/chat' onClick={(e) => handleMenuDrawer(anchor)}>
                              <div className="my_commuinity">
                                <img src={chatImage} alt="" width={'10%'} />
                                <span>Chat</span>
                              </div>
                            </Link> */}
                            
                            {/* <Link to='/classified' onClick={(e) => handleMenuDrawer(anchor)} >
                              <div className="my_commuinity" >
                                <i><StoreIcon /></i>
                                <span>Classified</span>
                              </div>
                            </Link>    */}

                            {msDetails?.meta?.community_settings ===undefined && (loggedInUser?.user_type ==='admin' || userDetails.id === msDetails?.user_id) &&
                                <div className="sidebar_list">
                                  <Box display='flex' justifyContent='center' justifyItems='center'>
                                    <Button variant="contained" onClick={(e) => navigate('/community-setup')}>Setup Menu</Button>
                                  </Box>
                                </div>
                              }
                            <div className="sidebar_list">
                              <Link to='/groups' onClick={(e) => handleMenuDrawer(anchor)}>
                                      <div className="sidebar_list_item">
                                        <div className="list_icon">
                                          <img src={peopleImg} alt="" />
                                        </div>
                                        <div className="list_name">
                                          Groups
                                        </div>
                                      </div>
                                </Link>
                              </div>
                           
                          <div className="sidebar_list">
                              <a target="_blank" href="https://icircles.app/app/panel/instant-meetings/start" onClick={(e) => handleMenuDrawer(anchor)}>
                                  <div className="sidebar_list_item">
                                      <div className="list_icon">
                                        <img src={'https://icircles.app/sideicons/home/ikhannect.png'} alt="iCircles" />
                                      </div>
                                      <div className="list_name">
                                          iKannect
                                      </div>
                                  </div>
                              </a>
                            </div>

                            

                            {msDetails?.meta?.community_settings && <div className="sidebar_list">
                              {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.member_enable==='1'  
                                && msDetails?.meta?.community_settings?.member_menu_name &&
                                  <Link to='/member' onClick={(e) => handleMenuDrawer(anchor)}>
                                    <div className="sidebar_list_item">
                                      <div className="list_icon">
                                        <img src={mamberImg} alt="" />
                                      </div>
                                      <div className="list_name">
                                        {msDetails?.meta?.community_settings?.member_menu_name}
                                      </div>
                                    </div>
                                  </Link>
                               }
                              {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.my_lounge_post_enable==='1'  
                                  && msDetails?.meta?.community_settings?.my_lounge_menu_name &&
                                    <Link to='/community-profile' onClick={(e) => handleMenuDrawer(anchor)}>
                                      <div className="sidebar_list_item">
                                        <div className="list_icon">
                                          <img src={newsfeed} alt="" />
                                        </div>
                                        <div className="list_name">
                                          {msDetails?.meta?.community_settings?.my_lounge_menu_name}
                                        </div>
                                      </div>
                                    </Link>
                                }
                                {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.my_job_enable==='1'  
                                  && msDetails?.meta?.community_settings?.my_job_menu_name &&
                                  <Link to='/my-job-list' onClick={(e) => handleMenuDrawer(anchor)}>
                                    <div className="sidebar_list_item">
                                      <div className="list_icon">
                                        <img src={jobImage} alt="" />
                                      </div>
                                      <div className="list_name">
                                        {msDetails?.meta?.community_settings?.my_job_menu_name}
                                      </div>
                                    </div>
                                  </Link>
                              }
                                {msDetails?.meta?.community_type ==='housing' &&
                                  <Link to='/address-list' onClick={(e) => handleMenuDrawer(anchor)}>
                                    <div className="sidebar_list_item">
                                      <div className="list_icon">
                                        <img src={jobImage} alt="" />
                                      </div>
                                      <div className="list_name">
                                        Address List
                                      </div>
                                    </div>
                                  </Link>
                              }
                              {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.event_enable==='1'  
                                && msDetails?.meta?.community_settings?.event_menu_name && 
                                <Link to='/event' onClick={(e) => handleMenuDrawer(anchor)}>
                                  <div className="sidebar_list_item">
                                    <div className="list_icon">
                                      <img src={eventImg} alt="" />
                                    </div>
                                    <div className="list_name">
                                      {msDetails?.meta?.community_settings?.event_menu_name}
                                    </div>
                                  </div>
                                </Link>
                               }
                                {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.resource_enable==='1'  
                                  && msDetails?.meta?.community_settings?.resource_menu_name &&  
                                  <Link to='/resource' onClick={(e) => handleMenuDrawer(anchor)}>
                                    <div className="sidebar_list_item">
                                      <div className="list_icon">
                                        <img src={resources} alt="" />
                                      </div>
                                      <div className="list_name">
                                        {msDetails?.meta?.community_settings?.resource_menu_name}
                                      </div>
                                    </div>
                                  </Link>
                              }
                               {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.media_enable==='1'  
                                  && msDetails?.meta?.community_settings?.media_menu_name &&
                                  <Link to='/media' onClick={(e) => handleMenuDrawer(anchor)}>
                                    <div className="sidebar_list_item">
                                      <div className="list_icon">
                                        <img src={folder} alt="" />
                                      </div>
                                      <div className="list_name">
                                        {msDetails?.meta?.community_settings?.media_menu_name}
                                      </div>
                                    </div>
                                  </Link>
                              }
                               {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.broadcast_enable==='1'  
                                  && msDetails?.meta?.community_settings?.broadcast_menu_name &&
                                  <Link to='/broadcast' onClick={(e) => handleMenuDrawer(anchor)}>
                                    <div className="sidebar_list_item">
                                      <div className="list_icon">
                                        <img src={broadcastImage} alt="" />
                                      </div>
                                      <div className="list_name">
                                        {msDetails?.meta?.community_settings?.broadcast_menu_name !=='undefined'?msDetails?.meta?.community_settings?.broadcast_menu_name:''}
                                      </div>
                                    </div>
                                  </Link>
                              }
                               {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.poll_enable==='1'  
                                  && msDetails?.meta?.community_settings?.poll_menu_name &&
                                  <Link to='/poll' onClick={(e) => handleMenuDrawer(anchor)}>
                                    <div className="sidebar_list_item">
                                      <div className="list_icon">
                                        <img src={pollImage} alt="" />
                                      </div>
                                      <div className="list_name">
                                        {msDetails?.meta?.community_settings?.poll_menu_name !=='undefined'?msDetails?.meta?.community_settings?.poll_menu_name:''}
                                      </div>
                                    </div>
                                  </Link>
                              }
                              {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.my_classified_enable==='1'  
                                && msDetails?.meta?.community_settings?.my_classified_menu_name &&
                                <Link to='/myClassified' onClick={(e) => handleMenuDrawer(anchor)}>
                                  <div className="sidebar_list_item">
                                    <div className="list_icon">
                                      <img src={resourceImg} alt="" />
                                    </div>
                                    <div className="list_name">
                                        {msDetails?.meta?.community_settings?.my_classified_menu_name}
                                    </div>
                                  </div>
                                </Link>
                              }
                              {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.business_enable==='1'  
                              && msDetails?.meta?.community_settings?.business_menu_name &&
                                <Link to='/localBusiness' onClick={(e) => handleMenuDrawer(anchor)}>
                                  <div className="sidebar_list_item">
                                    <div className="list_icon">
                                      <img src={representiveS} alt="" />
                                    </div>
                                    <div className="list_name">
                                      {msDetails?.meta?.community_settings?.business_menu_name}
                                    </div>
                                  </div>
                                </Link>
                              }
                               {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.representative_enable==='1'  
                                && msDetails?.meta?.community_settings?.representative_menu_name &&
                                  <Link to='/local-representatives' onClick={(e) => handleMenuDrawer(anchor)}>
                                    <div className="sidebar_list_item">
                                      <div className="list_icon">
                                        <img src={localRepresentetiveImg} alt="" />
                                      </div>
                                      <div className="list_name">
                                        {msDetails?.meta?.community_settings?.representative_menu_name}
                                      </div>
                                    </div>
                                  </Link>
                               }
                                {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.matrimony_enable==='1'  
                                  && msDetails?.meta?.community_settings?.matrimony_menu_name &&
                                  <Link to='/matrimonial' onClick={(e) => handleMenuDrawer(anchor)}>
                                    <div className="sidebar_list_item">
                                      <div className="list_icon">
                                        <img src={coupleImg} alt="" />
                                      </div>
                                      <div className="list_name">
                                        {msDetails?.meta?.community_settings?.matrimony_menu_name}
                                      </div>
                                    </div>
                                  </Link>
                                }
                               {msDetails?.meta?.community_settings && msDetails?.meta?.community_settings?.magazine_menu_enable==='1'  
                                && msDetails?.meta?.community_settings?.magazine_menu_name && 
                                  <Link to='/magazine' onClick={(e) => handleMenuDrawer(anchor)}>
                                    <div className="sidebar_list_item">
                                      <div className="list_icon">
                                        <img src={magazineImg} alt="" />
                                      </div>
                                      <div className="list_name">
                                        {msDetails?.meta?.community_settings?.magazine_menu_name}
                                      </div>
                                    </div>
                                  </Link>
                              }
                            </div>}

                            <div className="logout_btn"  onClick={logout}>
                              Logout
                            </div>
                            <Box display='flex' justifyContent='center' justifyItems='center'>
                                {/* <a href="https://icircles.app/" target="_blank" ><li>Powered By icircles.app</li></a> */}
                                <Box sx={{mb:5,ml:5}}><a href="https://icircles.app/" target="_blank"> Powered By <img src="https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png" width='20%'/></a></Box>
                            </Box>
                          </div>
                        </SwipeableDrawer>
                      </React.Fragment>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </header>
    </Fragment>
  );
};

export default TopNaviation;
