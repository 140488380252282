import { Grid } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import PayStationPaymentCheckOut from "../../../components/Matrimony/Theme/PayStationPaymentCheckOut";
import { useContext } from "react";
import { UserContext } from "../../../utils/UserContext";
import { useParams } from "react-router-dom";

const PayStationPaymentPage = () => {
    const { userDetails, msDetails } = useContext(UserContext);
    const params = useParams();
  return (
    <div>
      <Fragment>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
          <Grid item lg={9} md={8} sm={12} xs={12}>
            <div className="content_body">
              <PayStationPaymentCheckOut params={params} />
            </div>
          </Grid>
        </Grid>
      </Fragment>
    </div>
  );
};

export default PayStationPaymentPage;
