import { Grid } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { ShimmerCategoryItem, ShimmerContentBlock, ShimmerSimpleGallery } from "react-shimmer-effects";
import { ShimmerCircularImage } from "react-shimmer-effects";
import { ShimmerPostItem } from "react-shimmer-effects";

const SubItemEffect = () => {
  return (
    <Fragment>
        <ShimmerCategoryItem hasImage imageType="thumbnail" imageWidth={100} imageHeight={100} text />
        <ShimmerCategoryItem hasImage imageType="thumbnail" imageWidth={100} imageHeight={100} text />
        <ShimmerCategoryItem hasImage imageType="thumbnail" imageWidth={100} imageHeight={100} text />
        <ShimmerCategoryItem hasImage imageType="thumbnail" imageWidth={100} imageHeight={100} text />
        <ShimmerCategoryItem hasImage imageType="thumbnail" imageWidth={100} imageHeight={100} text />
        <ShimmerCategoryItem hasImage imageType="thumbnail" imageWidth={100} imageHeight={100} text />
    </Fragment>
  );
};

export default SubItemEffect;
