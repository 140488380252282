import React from 'react'
import sucessImg from "../../asset/image/paySuccess.png";
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';


function PaymentSuccessMsg() {
  return (
    <>
      <div className="payment-msg">
          <h2>Payment Confirmation</h2>

          <div className="msg-content">
              <img src={sucessImg} alt="" />
              <h3>Payment successfully completed</h3>

              <Link to='/localBusiness'>
                  <Button variant="outlined" size="small" sx={{mt:5}}> <ArrowBackIcon/> Return to community business </Button>
              </Link>
          </div>
      </div>
    </>
  )
}

export default PaymentSuccessMsg