import React, { memo, useContext, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { InboxOutlined } from "@ant-design/icons";
import { UploadProps } from "antd";
import { message, Upload } from "antd";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetBroadcastCategoryDataQuery, useStoreBroadcastItemMutation } from "../../api/slices/braodcastApiSlice";
import { baseUrl, createFeedsUrl } from "../../api/Api";

import CameraIcon from '@mui/icons-material/Camera';
import VideocamIcon from '@mui/icons-material/Videocam';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Webcam from "react-webcam";
import { UserContext } from "../../utils/UserContext";
import LoungeUploadProgress from "../LoungeUploadProgress/LoungeUploadProgress";
import UploadLoader from "../PageLoadEffects/UploadLoader";
import MainLoader from "../PageLoadEffects/MainLoader";
import { useStoreFeedMutation } from "../../api/slices/loungeApiSlice";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function FeedPinnedForm({setFileList, handleCloseRecord}) {
  const defaultValues = {
    title: "",
    subTitle: "",
    details: "",
    allCategory: [],
    selectedCategory: null,
    tags: "",
    keywords: "",
    media: null,
    thumb: null,
    status: "false",  
    visible:false,

    uploaderOptionShow:true,
    uloaderDetailsShow:false,
    videoRecordOptions:false
  };
  const methods = useForm({ defaultValues });
  const { watch, setValue } = methods;
  const values = watch();

  const navigate = useNavigate();
  const location = useLocation();
  const singleUuid = location?.state?.uuid;

  const { msDetails, userDetails, loggedInUser } = useContext(UserContext);
  const microsite_id = msDetails?.id;

  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [storeUuid, setStoreUuid] = useState(null);
  const [progressValue,setProgressValue] = useState(0)


  // handle handleRecordMobileMedia
  const handleRecordMobileMedia = (e)=>{
    const newFile = {
      uid: Date.now(),
      name: e.target.files[0]?.name,
      status: "done",
      type: e.target.files[0]?.type,
      originFileObj: e.target.files[0],
    };
    setValue('media', e.target.files[0])
    setFileList((prevFileList) => [...prevFileList, newFile]);
    handleCloseRecord()
  }



// media modal
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    return formattedTime;
  };


  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (reset) => {
    setOpen(false);
    if(reset ===true){
      setValue('media', null)
      setFileList((prevFileList) => [...prevFileList]);
    }
  };



  // video recored desktop and laptop
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [seconds, setSeconds] = useState(null); // Initial countdown time in seconds

  const startRecording = async () => {
    setSeconds(null)
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setIsRunning(true);
        videoRef.current.srcObject = stream;
  
        mediaRecorderRef.current = new MediaRecorder(stream);
        const chunks = [];
  
        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        };
  
        mediaRecorderRef.current.onstop = () => {
          const recordedBlob = new Blob(chunks, { type: "video/webm" }); // Specify the correct MIME type here
          
          // Create file name with timestamp and correct file extension
          const fileName = `rec-video-${Date.now()}.${recordedBlob.type.split("/")[1]}`
          
          const url = URL.createObjectURL(recordedBlob);
          // Create File object with the Blob and file name
          const file = new File(
              [recordedBlob],
              fileName,
              { type: "video/webm"}, // Specify the correct MIME type again here
          );

          const newFile = {
            uid: Date.now(),
            name: file.name,
            status: "done",
            url: url,
            type: recordedBlob.type,
            originFileObj: file,
          };
            
          setValue('media', file);
          setFileList((prevFileList) => [...prevFileList, newFile]);
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  };
  const startCountdown = () => {
    let countdown = 5; // Countdown timer value in seconds
    const countdownInterval =  setInterval(() => {
      if (countdown === 0) {
        setSeconds(null)
        clearInterval(countdownInterval); 
        startRecording()
      }else{
        setSeconds(countdown)
      }
      countdown--;
    }, 1000); // Interval of s1 second for countdown
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
    setIsRecording(false);
    setIsRunning(false);
    // setTimer(0);
    // setValue('videoRecordOptions', false)
  };

  const handleStartStopClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      // startRecording();
      startCountdown()
    }
  };

  // camera handleInputTrigger 
    const handleInputTrigger = (e)=>{
      if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/)) {
        document.getElementById("button-Camera").click();
      } else {
          setValue('videoRecordOptions', true)
      }
    } 

// handle cancle
const handleCancel = ()=>{
  setValue('media',null);
  setFileList((prevFileList) => [...prevFileList]);
  setValue('videoRecordOptions', false)
  setTimer(0)
  stopRecording();
  handleClose(false)
}

// handle re-take
const handleRetake = () =>{
  setValue('media', null)
  setFileList((prevFileList) => [...prevFileList]);
  setTimer(0)
  startCountdown()
}

// handle Next
const handleNext = () =>{
  setValue('uploaderOptionShow',false);
  setValue('videoRecordOptions', false)
  setValue('uloaderDetailsShow',true); 
  setTimer(0)
  handleClose(false)
  handleCloseRecord()
}

  return (
    <>

  {values?.videoRecordOptions &&  <Box className="deviceRecorder">
      {/* <video ref={videoRef} autoPlay muted playsInline /> */}
      <Box className="deviceRecorderBody">
        <Box className="video">
          <Box className='videoElements'>
            {(videoRef?.current ===null || seconds!==null ) &&
             <Box className ={seconds===1?'videoWebCam fade-out':''}>
                <Webcam />
             </Box>
             }
            <Box className ={seconds===0?'fade-in':''}>
              <video style={{display:videoRef?.current ===null || seconds!==null ?'none':'block'}} ref={videoRef} autoPlay muted  playsInline />
            </Box>
          </Box>
        </Box>
        <Box className="recorder-btn">
          <p>{formatTime(timer)}</p>
          {seconds !==null && <h1>{seconds}</h1>}
          <button  onClick={handleStartStopClick}>
            {/* {isRecording ? 'Stop ⏺' : 'Start ⏩'} */}
            {isRecording ? 'Stop' : 'Start'}
          </button>
        </Box>

        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
          <Button variant="outlined" sx={{color:'#A0153E !important',m:0.5}}  onClick={(e)=> handleCancel() }>
            Cancel
          </Button>
          <Button variant="outlined" sx={{color:'#337357 !important',m:0.5}} disabled={values?.media !==null?false:true}  onClick={(e)=>handleRetake()}>
            Retake
          </Button>
          <Button variant="outlined" sx={{color:'#7149C6 !important',m:0.5}} disabled={values?.media !==null?false:true}  onClick={(e)=> handleNext()}>
            Next
          </Button>
        </Box>
       
      </Box>
    </Box>
    }

    {(values?.uploaderOptionShow && !values?.videoRecordOptions)&&
      <Grid container spacing={4} sx={{mb:1 }}>
        <Grid item lg={12} xs={12}>
          <Box className={values?.videoRecordOptions?'media-form divDisabled':'media-form'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Box  className='camcoder-icon' onClick={(e)=> handleInputTrigger()} >
                  <VideocamIcon className="videoIcon" />
                    <input type="file" id="button-Camera" className="inputFile"  capture accept={"video/mp4,video/x-m4v,video/*;capture=camera"} onChange={(e)=> handleRecordMobileMedia(e)} />
                  {/* <VisuallyHiddenInput type="file" capture accept={"video/mp4,video/x-m4v,video/*"} onChange={(e)=> setValue('media', e.target.files[0])} /> */}
              </Box>
          </Box>
        </Grid>
      </Grid>
    }       
    </>
  );
}

export default memo(FeedPinnedForm)
