import { Avatar, Button, Menu, alpha, styled } from "@mui/material";
import React, { useContext, useState } from "react";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { baseUrl } from "../../../api/Api";
import LazyImage from "../../LazyImage";
import ReadMoreComponent from "../../ReadMoreComponent/ReadMoreComponent";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../utils/UserContext";
import { useDeleteBroadcastItemMutation, useStoreBroadcastCategoryMutation, useStoreBroadcastItemMutation } from "../../../api/slices/braodcastApiSlice";
import Swal from "sweetalert2";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-data": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      }
    },
  },
}));

const LookBackItem = ({data,segName}) => {
  const navigate = useNavigate() 
  const {userDetails,msDetails, loggedInUser} = useContext(UserContext)
  const [progressValue,setProgressValue] = useState(0)
  const [anchorElReport, setAnchorElReport] = useState(null);
  const openReport = Boolean(anchorElReport);
  const handleClickReport = (event) => {
    setAnchorElReport(event.currentTarget);
  };
  const handleCloseReport = () => {
    setAnchorElReport(null);
  };

  const options = { year: "numeric", month: "short", day: "numeric" };
  const date = data?.created_at?new Date(data?.created_at).toLocaleDateString("en-US", options):''

  const bg = data?.status ===2?'#9B4444':data?.status ===0?'#FFC374':'#124076'

  const token = localStorage.getItem('token');
  const [storeBroadcastItem] = useStoreBroadcastItemMutation()
  const [storeBroadcastCategory] = useStoreBroadcastCategoryMutation()
  const [deleteBroadcastItem, response] = useDeleteBroadcastItemMutation()
  const handleDeleteRow = (uuid) => {
   Swal.fire({
     title: "Are you sure?",
     text: "You won't be able to revert this!",
     icon: "warning",
     showCancelButton: true,
     confirmButtonColor: "#3085d6",
     cancelButtonColor: "#d33",
     confirmButtonText: "Yes, delete it!",
   }).then((result) => {
     if (result.isConfirmed) {
      deleteBroadcastItem({token,uuid})
     }
   });
 };


// handle details item
   const handleSinglItem = (uuid)=>{
      navigate(`/broadcast-details/${uuid}`)
   }

// handle edit item
  const handleEditItem = (uuid)=>{
    navigate(`/broadcast-upload/${uuid}`)
 }

// update broad cast item
  const updateBroadcastItem = (uuid,data,status)=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Change Status!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("status", status);
  
        var url = `${baseUrl}/api/community/broadcastitem/${uuid}`
       
      
       storeBroadcastItem({token,formData,url,setProgressValue})
       .unwrap()
       .then((response) => {
        if(response?.status==='success' && status ===1 && data?.categories?.status ===0){
          const formData = new FormData();
          formData.append("status", 1);
          var url = `${baseUrl}/api/community/broadcastcategory/${data?.categories?.uuid}`
          storeBroadcastCategory({token,formData,url,setProgressValue})
        }
       })

      }
    });
  }
  
  var username = data?.microsite_userdetails?.user_details? JSON.parse(data?.microsite_userdetails?.user_details)?.name?JSON.parse(data?.microsite_userdetails?.user_details)?.name:data?.user?.name:data?.user?.name


  return (
    <>
      <div className="lookBack-item cursorPointer" >
        <div className="lookBack-item-img" onClick={(e)=>handleSinglItem(data?.uuid, data)}>
          <LazyImage src={`${baseUrl}/${data?.thumb}`} alt={data?.title} />
        </div>
        <div className="lookBack-item-content">
          <h4 onClick={(e)=>handleSinglItem(data?.uuid, data)}>{data?.title}</h4>
          <div className="user-info">
            <Box className="user-details"
             onClick={(e)=> userDetails?.id===data?.user?.id
               ?navigate('/community-profile',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user,showInfo:true}})
               :navigate('/community-profile/other',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user}})
             }
            >
              <Avatar
                alt={data?.title}
                src={`${baseUrl}/${data?.user?.avatar}`}
                sx={{ maxWidth: "26px", maxHeight: "26px" }}
              />
              <div className="user-name">{username}</div>
            </Box>

            <Box sx={{ mt: 1 }}>
              <Button
                id="demo-customized-button-2"
                aria-controls={openReport ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openReport ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClickReport}
                endIcon={<KeyboardArrowDownIcon />}
                size="small"
                sx={{ color: "#fff !important", backgroundColor:bg}}
              >

                {data?.status ===1 && 'Approved'}
                {data?.status ===0 && 'Pending'}
                {data?.status ===2 && 'Rejected'}

              </Button>
              <StyledMenu
                id="demo-customized-menu-2"
                MenudataProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorElReport}
                open={openReport}
                onClose={handleCloseReport}
              >
               {(data?.status !==1 && segName !=='broadcast-library' && (loggedInUser?.user_type ==='admin' || userDetails.id === msDetails?.user_id)) && <MenuItem onClick={(e)=>{updateBroadcastItem(data?.uuid, data,1);handleClickReport(e)}} disableRipple>
                  Approve
                </MenuItem>}
                
                {(data?.status !==0 && segName !=='broadcast-library' && (loggedInUser?.user_type ==='admin' || userDetails.id === msDetails?.user_id)) && <MenuItem onClick={(e)=>{updateBroadcastItem(data?.uuid, data,0);handleClickReport(e)}} disableRipple>
                  Pending
                </MenuItem>}

                {(data?.status !==2 && segName !=='broadcast-library' && (loggedInUser?.user_type ==='admin' || userDetails.id === msDetails?.user_id)) && <MenuItem onClick={(e)=>{updateBroadcastItem(data?.uuid, data,2);handleClickReport(e)}} disableRipple>
                  Reject
                </MenuItem>}
                
                {data?.user_id === userDetails?.id &&  <MenuItem onClick={(e)=>{handleEditItem(data?.uuid, data);handleCloseReport(e)}} disableRipple>
                  Edit
                </MenuItem>}
                {data?.user_id === userDetails?.id &&  <MenuItem onClick={(e)=>{handleDeleteRow(data?.uuid, data);handleCloseReport(e)}} disableRipple>
                  Delete
                </MenuItem>}
               
              </StyledMenu>
            </Box>
          </div>
          {data?.details && <ReadMoreComponent text={data.details} maxLength={150} />} 
          <div className="overView">
            {/* <span> 7.2M views </span> */}
            <span> {date} </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LookBackItem;
