import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import pollRewardImge from '../../../asset/image/reward.png'
import PollRewardCard from './PollRewardCard'
import { Link } from 'react-router-dom'

const PollReward = () => {
  return (
    <div className="poll_reward">
        <Box className='reward-header'>
            <Box className='img' sx={{width:'120px', height:'auto'}}>
               <img src={pollRewardImge} alt='' width={100} height={100} />
            </Box>
            <Box className='text'>Congratulations! Claim your reward!</Box>
        </Box>
        <Box className='reward-card'>
            <Grid container spacing={2}>
                 <Grid item lg={3} md={3} sm={12} xs={12}>
                     <PollRewardCard />
                 </Grid>
            </Grid>
        </Box>

        <Box sx={{mt:5, textAlign:'center'}}>
            <Link to='/poll-reward-wallet'>
               <Button variant='contained' sx={{textTransform:'capitalize'}}>Reward Wallet</Button>
            </Link>
        </Box>
    </div>
  )
}

export default PollReward