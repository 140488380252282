import { Divider, Grid } from "@mui/material";
import BroadcastTopNavigate from "../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate";
import BroadcastCategory from "../../components/Broadcast/BroadcastCategory/BroadcastCategory";
import BroadcastItemSection from "../../components/Broadcast/BroadcastItem/BroadcastItemSection";
import { useGetBroadcastCategoryDataQuery, useGetBroadcastItemDataQuery, useGetSingleBroadcastCategoryDataQuery } from "../../api/slices/braodcastApiSlice";
import { useContext, useState } from "react";
import { UserContext } from "../../utils/UserContext";
import BroadcastSingleCategoryDetails from "../../components/Broadcast/BroadcastSingleCategoryDetails/BroadcastSingleCategoryDetails";
import { useParams } from "react-router-dom";
import BroadcastRelatedItemSection from "../../components/Broadcast/BroadcastItem/BroadcastRelatedItemSection";

const SingleCategory = () => {
  const params = useParams()
  const {msDetails} = useContext(UserContext)
  const microsite_id = msDetails?.id;
  const singleUuid = params?.uuid

  const token = localStorage.getItem('token');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const {data: getBroadcastCategoryData, isFetching: getCategoryDataFetching, isError:getDataError} = useGetBroadcastCategoryDataQuery({token,microsite_id,currentPage,searchValue});
  const {data: getSingleBroadcastCategoryData, isFetching:getSingleBroadcastCategoryDataFeting, isError:getSingleDataError} = useGetSingleBroadcastCategoryDataQuery({token,singleUuid,microsite_id})
  const {data: getBroadcastItemData, isFetching: getItemDataFetching, isError:getDataItemError} = useGetBroadcastItemDataQuery({token,microsite_id,currentPage,searchValue});

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <div className="broadcast-body">
              <BroadcastTopNavigate />
              <BroadcastSingleCategoryDetails details = {getSingleBroadcastCategoryData} loading={getSingleBroadcastCategoryDataFeting} />
              {/* <BroadcastCategory broadcastCategroy={getBroadcastCategoryData?.data} details = {getSingleBroadcastCategoryData} subcategory={true} loading ={getCategoryDataFetching}  /> */}
              <BroadcastCategory broadcastCategroy={getBroadcastCategoryData?.data} loading ={getCategoryDataFetching}  />
              <Divider sx={{ mt:3 , mb:1}}/>
              <BroadcastRelatedItemSection broadcastItem ={getBroadcastItemData} details = {getSingleBroadcastCategoryData} loading={getItemDataFetching} />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SingleCategory;
