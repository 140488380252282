import { Box, Button, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import boradcastLogo from "../../../asset/image/broadcastLgo.png";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuIcon from "@mui/icons-material/Menu";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { useContext, useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AssistantIcon from "@mui/icons-material/Assistant";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DifferenceIcon from '@mui/icons-material/Difference';
import HistoryIcon from "@mui/icons-material/History";
import test1 from "../../../asset/image/test1.png";
import test2 from "../../../asset/image/test2.jpg";
import test3 from "../../../asset/image/test3.webp";
import test4 from "../../../asset/image/test4.jpg";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { UserContext } from "../../../utils/UserContext";
import { useGetBroadcastCategoryDataQuery, useGetBroadcastItemDataQuery } from "../../../api/slices/braodcastApiSlice";
import { useGetPollDataQuery } from "../../../api/slices/pollApiSlice";

const PollTopNavigate = () => {
  const navigate = useNavigate();
  // Menu Bar Dropdown
  const [menuDropdown, setMenuDropdown] = useState(null);
  const menuDropdownOpen = Boolean(menuDropdown);
  const menuDropdownHandleClick = (event) => {
    setMenuDropdown(event.currentTarget);
  };
  const menuDropdownHandleClose = () => {
    setMenuDropdown(null);
  };

  // Video Menu Dropdown
  const [videoDropdown, setVideoDropdown] = useState(null);
  const videoDropdownOpen = Boolean(videoDropdown);
  const videoDropdownHandleClick = (event) => {
    setVideoDropdown(event.currentTarget);
  };



  // apis
  const {msDetails, userDetails, loggedInUser} = useContext(UserContext)
  const microsite_id = msDetails?.id
  const user_id = ''
  const active_pools ='yes'
  const status ='1'

  const token = localStorage.getItem('token');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const {data: getPollData, isFetching: getItemDataFetching, isError:getDataItemError} = useGetPollDataQuery({token,microsite_id,user_id,active_pools,status,currentPage,searchValue,user_id});

  useEffect(()=>{
    if(searchValue !=='' && getPollData?.data){
      setSearchResults(getPollData?.data)
    }
  },[searchValue])

    // handle details item
     const handleSinglItem = (data)=>{
       navigate(`/poll/${data?.uuid}`, {state:{pollDetails:data}})
     }

  return (
    <>
      <div className="broadcast-Top">
        <Tooltip title="Back">
          <div
            className="backArrow"
            onClick={(e) => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </div>
        </Tooltip>
        {/* <div className="broadTop-logo cursorPointer" onClick={(e)=> navigate('/broadcast')} >
          <img src={boradcastLogo} alt="" />
        </div> */}
        <div className="broadcast_search_form mob-broadcast-none">
          <div className="searchBar">
            <input
              type="text"
              placeholder="Search"
              autocomplete="off"
              className="form_control"
              onChange={(e)=> setSearchValue(e.target.value)}
            />
            <i>
              <Button type="submit" sx={{ minWidth: "50px" }}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </i>
          </div>
          {(searchValue !=='' && searchResults !==null) &&
            <div className="search-plate">
              <ul>
                {searchResults && searchResults.map((item,i)=>{
                  if(item?.status ===1){
                    return(
                      <li key={item?.uuid} onClick={(e)=> handleSinglItem(item)}>{item?.name}</li>
                    )
                  }
                })}
              </ul>
            </div>
          }
        </div>
        <div className="top-action">
          {/* <Tooltip title="Add Poll">
            <div
              className="top-action-btn"
              onClick={videoDropdownHandleClick}
              aria-controls={videoDropdownOpen ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={videoDropdownOpen ? "true" : undefined}
            >
              <Link to="/poll-create">
                <AddBoxIcon sx={{ color: "#fff", fontSize: "20px" }} />
              </Link>
            </div>
          </Tooltip> */}
          <Box>
            <Link to="/poll-create">
              <Button variant="contained" className="MuiBtn_create">Create Poll</Button>
            </Link>
          </Box>

          <Tooltip title="Menu List">
            <div
              className="top-action-btn"
              onClick={menuDropdownHandleClick}
              aria-controls={menuDropdownOpen ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuDropdownOpen ? "true" : undefined}>
              <MenuIcon sx={{ color: "#fff", fontSize: "20px" }} />
            </div>
          </Tooltip>
          {/* Menu List Menu */}
          <Menu
            anchorEl={menuDropdown}
            id="account-menu"
            open={menuDropdownOpen}
            onClose={menuDropdownHandleClose}
            onClick={menuDropdownHandleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1},
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0},
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={(e)=> {menuDropdownHandleClose(); navigate('/poll')}} sx={{fontSize:'13px'}}>
              <ListItemIcon>
                <HomeIcon fontSize="small" />
              </ListItemIcon>
              Home
            </MenuItem>
            {(loggedInUser?.user_type ==='admin' || userDetails.id === msDetails?.user_id) &&
            <Link to="/poll-manage">
              <MenuItem onClick={menuDropdownHandleClose} sx={{fontSize:'13px'}}>
                <ListItemIcon>
                  <DifferenceIcon fontSize="small" />
                </ListItemIcon>
                  Manage Polls
              </MenuItem>
            </Link>}
            <Link to="/poll-library">
              <MenuItem onClick={menuDropdownHandleClose} sx={{fontSize:'13px'}}>
                <ListItemIcon>
                  <LibraryBooksIcon fontSize="small" />
                </ListItemIcon>
                My Polls
              </MenuItem>
            </Link>
          </Menu>
      
        </div>
        
        <div className="broadcast_search_form web-broadcast-none">
          <div className="searchBar">
            <input
              type="text"
              placeholder="Search"
              autocomplete="off"
              className="form_control"
              onChange={(e)=> setSearchValue(e.target.value)}
            />
            <i>
              <Button type="submit" sx={{ minWidth: "50px" }}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </i>
          </div>
          {(searchValue !=='' && searchResults !==null) &&
            <div className="search-plate">
              <ul>
                {searchResults && searchResults.map((item,i)=>{
                  if(item?.status ===1){
                    return(
                      <li key={item?.uuid} onClick={(e)=> handleSinglItem(item)}>{item?.title}</li>
                    )
                  }
                })}
              </ul>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default PollTopNavigate;
