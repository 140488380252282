import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { matrimonyUrl, micrositeDetailsUrl } from "../../../api/Api";
import ms from "date-fns/esm/locale/ms/index.js";
import { Button, Grid, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "grey",
      // color: "black",
      padding: "20px",
      fontWeight: "000",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      // border: "1px solid black",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#000",
      },
      "::placeholder": {
        color: "#000",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
  },
};
const PaymentCheckOutForm = ({ userDetails, msDetails, params }) => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("token");

  const matrimonyProfile = localStorage.getItem('matrimony_profile')
  const parseProfile = matrimonyProfile?JSON.parse(matrimonyProfile):''

  const [loaderVisible, setLoaderVisible] = useState(false)
  const handleMatrimony = async (invoice_number) => {
    setLoaderVisible(true);

    let data = new FormData();
    data.append('microsite_id', msDetails.id);
    data.append('user_id', userDetails?.id);
    data.append('theme_id', invoice_number);   

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };

      var  url = `${matrimonyUrl}/${parseProfile?.uuid}`
  
      axios
        .post(url, data, config)
        .then((response) => {
          // handleGetMainMagazine();
          setLoaderVisible(false);
          localStorage.setItem('matrimony_profile',JSON.stringify(response?.data?.matrimony_profile))

          navigate('/matrimony-my-theme')
        })
        .catch((err) => {
        });
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: userDetails?.profile?.name,
        email: userDetails?.email,
        phone: userDetails?.meta?.phone,
      },
    });
    if (paymentMethod) {
      setIsLoading(true);
      Swal.fire({
        title: "Congratulation!",
        text: "You Payment Successfully Completed!",
        icon: "success",
      });
      paymentMethod["microsite_id"] = msDetails?.uuid;
      paymentMethod["microsite_name"] = msDetails?.name;
      paymentMethod["microsite_location"] = msDetails?.location;
      paymentMethod["uuid"] = userDetails?.uuid;
      paymentMethod["price"] = 10;
      paymentMethod["theme_details"] = params;
      // console.log("card Payment success-------", paymentMethod);
      const invoice_number = `${userDetails?.email}-${params?.type}-${params?.themeId}`
      handleMatrimony(invoice_number)
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Check Your Stripe Credentials Again!",
        // footer: '<a href="#">Why do I have this issue?</a>',
      });
    }
  };
  return (
    <div>
      <>
        {!success ? (
          <form onSubmit={handleSubmit}>
            <h4>Pay Your Bills</h4>
            <div className="paybill_form">
              <fieldset className="FormGroup">
                <div className="FormRow">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="outlined-read-only-input"
                        label="Email"
                        defaultValue={userDetails?.email}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className="card_content">
                        <CardElement />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="outlined-read-only-input"
                        label="Name"
                        defaultValue={userDetails?.profile?.name}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="outlined-read-only-input"
                        label="Phone Number"
                        defaultValue={userDetails?.meta?.phone}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </fieldset>
              {isLoading ? (
                <>
                  <button>Pay</button>
                </>
              ) : (
                <>
                  <button className="pay_button">Pay</button>
                </>
              )}

              {/* <button>Pay</button> */}
            </div>
          </form>
        ) : (
          //
          <div>
            <h2>
              <p>Payment success...</p>
            </h2>
          </div>
        )}
      </>
    </div>
  );
};

export default PaymentCheckOutForm;
