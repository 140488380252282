import React, { useState } from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SkipNextIcon from '@mui/icons-material/SkipNext';

function BussCurrencySetupPage() {
  const navigate = useNavigate();

  const [currency, setCurrency] = useState("");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="content_body">
            <Tooltip title="Back">
              <div
                className="backArrow"
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
              </div>
            </Tooltip>
            <div className="post-business-container">
            <Link to="/business-description">
                <div className="skip-btn">
                  <SkipNextIcon /> SKIP
                </div>
              </Link>

              <div className="choose-commuinity">
                <h2>Currency</h2>

                <div className="choose-commuinity-content">
                  <p> Please select currency for your business </p>
                  <Grid container spacing={2} sx={{ mt: 4 }}>
                    <Grid item lg={12} md={12} xs={12}>
                      <FormControl variant="filled" focused fullWidth>
                        <InputLabel id="currency-select-filled-label">
                          Currency
                        </InputLabel>
                        <Select
                          labelId="currency-select-filled-label"
                          id="demo-simple-select-filled"
                          value={currency}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>USD Dollar</MenuItem>
                          <MenuItem value={20}>BDT Taka</MenuItem>
                          <MenuItem value={30}>RS Rupe</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Link to="/business-description">
                    <Button variant="contained" fullWidth sx={{ mt: 2 }}>
                      Next
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default BussCurrencySetupPage;
