import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { Upload } from "antd";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { get, set, useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "../../utils/Toast";
import {
  businessTypeUrl,
  localBusinessUrl,
  localBusinessMediaUrl,
  userMicrositesUrl,
  commuinityUrl,
} from "../../api/Api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../utils/UserContext";
import { ToastContainer } from "react-toastify";
import jobImage from "../../asset/image/jobSideH.png";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { ThreeDotLoaderEffect } from "../PageLoadEffects";
import SunEditor from "suneditor-react";
import Autocomplete from "@mui/material/Autocomplete";
import UploadLoader from "../PageLoadEffects/UploadLoader";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { GoogleApiKey } from "../../utils/GoogleApiKey";
import Geocode from 'react-geocode';
import useGeolocation from "react-hook-geolocation";
import MainLoader from "../PageLoadEffects/MainLoader";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SyncIcon from '@mui/icons-material/Sync';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const LocalBusinessCreateBody = () => {
  const navigate = useNavigate();
  const useContentLocation = useLocation();
  const { msDetails, userDetails } = useContext(UserContext);
  const [fileList, setFileList] = useState([]);
  const [getFileList, setGetFileList] = useState(null);
  const [loaderEffectValue, setLoaderEffectValue] = useState(false);
  const token = localStorage.getItem("token");
  const [allBusinessType, setAllBusinessType] = useState([]);
  const [businessTypeValue, setBusinessTypeValue] = useState('');
  const [storeBusinessTypeValue, setStoreBusinessTypeValue] = useState('')

  const [typingBusinessType, setTypingBusinessType] = useState('')

  const [userMicrosites, setUserMicrosites] = useState([]);
  const [newMicrosite, setNewMicrosite] = useState('')

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // input default state value
  const defaultValues = {
    title: "",
    subtitle: "",
    phone: "",
    email: "",
    address: "",
    location: "",
    details: "",
    websiteUrl: "",
    googleUrl: "",
    facebookUrl: "",
    instagramUrl: "",
    youtubeUrl: "",
    twitterUrl: "",
  };
  const methods = useForm({ defaultValues });
  const { watch, setValue } = methods;
  const values = watch();

  const handleEditorChange = (content, type) => {
    setValue("details", content);
  };

  // get details item
  const getSingleResouceDetails = () => {
    let config = {
      method: "get",
      url: `${localBusinessUrl}/${useContentLocation.state.uuid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.request(config).then((response) => {
      if (response.data) {
        setValue("title", response?.data?.title !=='null'?response?.data?.title:'');
        setValue("subtitle", response?.data?.subtitle !=='null'?response?.data?.subtitle:'');
        setValue("details", response?.data?.details);
        setStoreBusinessTypeValue(response?.data?.type);

        setValue("websiteUrl", response?.data?.meta?.website_url);
        setValue("googleUrl", response?.data?.meta?.google_url);
        setValue("facebookUrl", response?.data?.meta?.facebook_url);
        setValue("instagramUrl", response?.data?.meta?.instagram_url);
        setValue("youtubeUrl", response?.data?.meta?.youtube_url);
        setValue("twitterUrl", response?.data?.meta?.twitter_url);

        setValue("phone", response?.data?.meta?.phone);
        setValue("email", response?.data?.meta?.email);
        setValue("address", response?.data?.meta?.address);
        setValue("location", response?.data?.meta?.location);

        if(response?.data?.meta?.microsite_business_id){
          setNewMicrosite(JSON.parse(response?.data?.meta?.microsite_business_id))
        }

        if (response?.data?.files.length > 0) {
          setGetFileList(response?.data?.files);
        }
      }
    });
  };

  useEffect(() => {
    if (useContentLocation.state !== null) {
      getSingleResouceDetails();
    }
  }, []);

  //Get all  BusinessType from api
  const getAllBusinessType = () => {
    let config = {
      method: "get",
      url: businessTypeUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.request(config).then((res) => {
      setAllBusinessType(res.data);

      //  setBusinessTypeValue(res.data[0].type)
    });
  };
  useEffect(() => {
    getAllBusinessType();
  }, []);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const getAllUserMicrosites = () => {
    let config = {
      method: "get",
      // url: `${userMicrositesUrl}?type_id=''`,
      url: `${userMicrositesUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(config)
      .then((response) => {
        const storeBusiness = [];
        if(response?.data?.data && response.data.data.length>0){
          response.data.data.forEach(element => {
            if(element?.microsites !==null ){
              storeBusiness.push(element)
            }
            
          });
        }
        setUserMicrosites(storeBusiness);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getAllUserMicrosites();
  }, []);

  // handle create new resource
  const handleLocalBusiness = () => {
    setLoaderEffectValue(true);
    let data = new FormData();
    data.append("microsite_id", msDetails.id);
    if(newMicrosite?.id){
      data.append("microsite_business_id",JSON.stringify(newMicrosite))
    }
    data.append("user_id", userDetails.id);
    data.append("title", values.title);
    data.append("subtitle", values.subtitle);
    data.append("phone", values.phone);
    data.append("email", values.email);
    data.append("address", values.address);
    data.append("location", values.location);

    data.append("details", values.details);
    data.append("type", typingBusinessType);

    data.append("website_url", values.websiteUrl);
    data.append("google_url", values.googleUrl);
    data.append("facebook_url", values.facebookUrl);
    data.append("instagram_url", values.instagramUrl);
    data.append("youtube_url", values.youtubeUrl);
    data.append("twitter_url", values.twitterUrl);

    data.append("status", 0);
    

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        data.append("files[]", file.originFileObj);
      });
    }
    var config;
    if (useContentLocation.state !== null) {
      config = {
        method: "post",
        url: `${localBusinessUrl}/${useContentLocation.state.uuid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
    } else {
      config = {
        method: "post",
        url: `${localBusinessUrl}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
    }

    axios
      .request(config)
      .then((response) => {
        setLoaderEffectValue(false);
        //notifySuccess();
        // /local-business-subscriptions
        if (useContentLocation.state !== null) {
          navigate("/localBusiness");
        } else {
          navigate('/local-business-subscriptions', {state:{business:response?.data?.entity}});
        }
      })
      .catch((err) => {
        setLoaderEffectValue(false);
        if (err?.response) {
          notifyError(err?.response?.data?.message);
        } else {
          notifyError("Something went wrong!.");
        }
      });
  };

  //handle delete a resouce media
  const handleDeleteResourceMedia = (uuid) => {
    Swal.fire({
      heightAuto: false,
      backdrop: false,
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoaderEffectValue(true);
        let config = {
          method: "delete",
          url: `${localBusinessMediaUrl}/${uuid}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        axios
          .request(config)
          .then((response) => {
            setLoaderEffectValue(false);
            //notifySuccess();
            window.location.reload();
          })
          .catch((err) => {
            setLoaderEffectValue(false);
            if (err?.response) {
              notifyError(err?.response?.data?.message);
            } else {
              notifyError("Something went wrong!.");
            }
          });
      }
    });
  };


  // handle create new community microsite
  Geocode.setApiKey(GoogleApiKey);
  const [communityTypeValue, setCommunityTypeValue] = useState('')
  const geolocation = useGeolocation();  
  const [communityName,setCommunityName] = useState("");
  const [tagline,setTagLine] = useState("");
  const [website,setWebsite] = useState("");
  const [address,setAddress] = useState("");
  const [email,setEmail] =useState("");
  const [phone,setPhone] =useState("");
  const [details, setDetails] = useState("")
  const [location, setLocation] = useState("");
  const [logo,setLogo] = useState("");
  const [logoPreview,setLogoPreview] = useState("");
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')

  const [actionEffectValue,setActionEffectValue] = useState(false);
  const [checked, setChecked] = useState(false);

  const communityTypes = [
    { label: "Apple", value: "apple" },
  { label: "Banana", value: "banana" },
  { label: "Cherry", value: "cherry" },
  { label: "Date", value: "date" },
  ]

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  
    // get address and location
    const getLocation = () => {
      if (geolocation.error) {
        alert(geolocation.error.message);
      } else {
        Geocode.fromLatLng(geolocation.latitude, geolocation.longitude).then(
          (response) => {
            setLatitude(geolocation.latitude)
            setLongitude(geolocation.longitude)
            const address = response.results[9].formatted_address;
            setLocation(address);
          },
          (error) => {
            // console.error(error);
          }
        );
      }
    };

    // preview logo
    const handleLogo = (e)=>{
      setLogoPreview(URL.createObjectURL(e.target.files[0]))
      setLogo(e.target.files[0]);
    }

  //Create new microsite
    const handleCreate = (e) =>{
      e.preventDefault();
      setActionEffectValue(true)
      let data = new FormData();
        data.append('name', communityName);
        // data.append('subtype_id', 9);
        data.append('tagline', tagline);
        data.append('location', location);
        data.append('entity_email', email);
        data.append('entity_phone', phone);
        data.append('entity_logo', logo);
        data.append('entity_details', details);
        data.append('website', website);
        data.append('address', address);
        data.append('status', '1');
        data.append('latitude', latitude);
        data.append('longitude', longitude);

        // data.append('community_type', '');


        let config = {
          method: 'post',
          url: commuinityUrl,
          headers: { 
            'Authorization': `Bearer ${token}`, 
          },
          data : data
        };

        if(checked ===true && communityName.length>=3){
          axios.request(config)
          .then((response) => {
            //notifySuccess();
            setCommunityName('');
            setTagLine('');
            setLocation('');
            setAddress('');
            setDetails('');
            setEmail('');
            setPhone('');
            setLogo('');
            setLogoPreview('');
            setDetails('');

            getAllUserMicrosites()
            setActionEffectValue(false)
            // window.location.href = '/login';            
          })
          .catch((err)=>{
            setActionEffectValue(false)
            if (err?.response) {
              notifyError(err?.response?.data?.message)
            }else{
              notifyError('Something went wrong!.')
            }
          })
        }else{
          setActionEffectValue(false)
          if(communityName.length<3){
            notifyError('Name must be greater than 3 characters')
          }else{
            notifyError('Agree first with our T&C')
          }
        }
    }  

    useEffect(()=>{
      if(storeBusinessTypeValue && allBusinessType && allBusinessType.length>0){
        allBusinessType.forEach(element => {
          if(element?.type === storeBusinessTypeValue){
            setBusinessTypeValue(element)
          }
        });
      }
    },[allBusinessType,storeBusinessTypeValue])


  return (
    <Fragment>
      <div className="resource_create">
        <h4>Add Community Business</h4>
        <div className="resource_form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <TextField
                  label="Business Name"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("title", e.target.value)}
                  value={values.title}/>
              </Box>
            </Grid>
            <Grid item sx={6} md={6} xs={12}>
              <Box>
                <TextField
                  label="Business Sub-title"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("subtitle", e.target.value)}
                  value={values.subtitle}
                />
              </Box>
            </Grid>
            <Grid item sx={6} md={6} xs={12}>
              <Box>
                <TextField
                  type="text"
                  label="Business Address"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("address", e.target.value)}
                  value={values.address}
                />
              </Box>
            </Grid>
            <Grid item sx={6} md={6} xs={12}>
              <Box>
                <TextField
                  type="number"
                  label="Business Phone"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("phone", e.target.value)}
                  value={values.phone}
                />
              </Box>
            </Grid>
            <Grid item sx={6} md={6} xs={12}>
              <Box>
                <TextField
                  type="email"
                  label="Business Email"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("email", e.target.value)}
                  value={values.email}
                />
              </Box>
            </Grid>
            {/* <Grid item sx={4} md={4} xs={12}>
              <Box>
                <TextField
                  type="text"
                  label="Business Location"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("location", e.target.value)}
                  value={values.location}
                />
              </Box>
            </Grid> */}
            <Grid item sx={4} md={4} xs={12}>
            <Box>
                <Autocomplete
                  focused
                  options={allBusinessType}
                  defaultValue={businessTypeValue?businessTypeValue:''}
                  value={businessTypeValue}
                  getOptionLabel={(option) => (option ? option?.type: "")}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setBusinessTypeValue(newValue)
                      setTypingBusinessType(newValue.type);
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    setTypingBusinessType(newInputValue);
                  }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      label="Select Business Type"
                      variant="filled"
                      focused
                      {...params}
                    />
                  )}
                />
              </Box>
            </Grid>
            {/* <Grid item sx={1} md={1} xs={12}>
              <Box sx={{ mt: 1 }}>
                <Button variant="contained" onClick={(e) => handleCreateType()}>
                  {" "}
                  +
                </Button>
              </Box>
            </Grid> */}
            <Grid item sx={5} md={5} xs={12}>
              <Box>
                <Autocomplete
                  focused
                  options={userMicrosites}
                  defaultValue={newMicrosite?newMicrosite:''}
                  value={newMicrosite}
                  getOptionLabel={(option) => (option ? option?.microsites?.name: "")}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setNewMicrosite(newValue)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="Select Microsite"
                      variant="filled"
                      focused
                      {...params}
                     
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item sx={3} md={3} xs={12}>
              <Box sx={{ mt: 1 }}>
                <a href="https://icircles.app/app/home/cmicrosites/my-microsite-categories" target="_blank">
                <Button sx={{color:'#fff !important'}} variant="contained">
                  Create New Microsite
                </Button></a>
              </Box>
            </Grid>
            <Grid item sx={4} md={4} xs={12}>
              <Box>
                <TextField
                  label="Website URL"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("websiteUrl", e.target.value)}
                  value={values.websiteUrl}
                />
              </Box>
            </Grid>
            <Grid item sx={4} md={4} xs={12}>
              <Box>
                <TextField
                  label="Google URL"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("googleUrl", e.target.value)}
                  value={values.googleUrl}
                />
              </Box>
            </Grid>
            <Grid item sx={4} md={4} xs={12}>
              <Box>
                <TextField
                  label="Facebook URL"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("facebookUrl", e.target.value)}
                  value={values.facebookUrl}
                />
              </Box>
            </Grid>
            <Grid item sx={4} md={4} xs={12}>
              <Box>
                <TextField
                  label="Instagram URL"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("instagramUrl", e.target.value)}
                  value={values.instagramUrl}
                />
              </Box>
            </Grid>
            <Grid item sx={4} md={4} xs={12}>
              <Box>
                <TextField
                  label="YouTube URL"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("youtubeUrl", e.target.value)}
                  value={values.youtubeUrl}
                />
              </Box>
            </Grid>
            <Grid item sx={4} md={4} xs={12}>
              <Box>
                <TextField
                  label="Twitter URL"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("twitterUrl", e.target.value)}
                  value={values.twitterUrl}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <SunEditor
                  name="details"
                  setContents={values.details}
                  placeholder="Details Here..."
                  showToolbar={true}
                  setDefaultStyle="height: 200px"
                  onChange={(e) => handleEditorChange(e, "details")}
                  setOptions={{
                    buttonList: [
                      [
                        "fontSize",
                        "formatBlock",
                        "paragraphStyle",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "hiliteColor",
                        "align",
                        "list",
                        "link",
                        "codeView",
                      ],
                    ],
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <div className="field_heading">Upload Post Images :</div>
              <div className="resource_files_container">
                <Upload
                  action="https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                >
                  {fileList.length < 5 && "+ Upload"}
                </Upload>
              </div>
            </Grid>
            {getFileList !== null && (
              <Grid item xs={12}>
                <div className="image_list_container">
                  {getFileList.length > 0 &&
                    getFileList.map((data) => {
                      return (
                        <div className="image_place">
                          {data.url && <img src={`${data.url}`} />}
                          <div className="img_place_overly">
                            <div className="img_cross_btn">
                              <CloseIcon
                                onClick={(e) =>
                                  handleDeleteResourceMedia(data.uuid)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Grid>
            )}

            {loaderEffectValue === true && <>{<UploadLoader />}</>}
            {loaderEffectValue === false && (
              <Grid item xs={12}>
                {values.title && values.details ? (
                  <Button
                    onClick={(e) => handleLocalBusiness()}
                    variant="contained"
                    fullWidth
                  >
                    {" "}
                    Submit{" "}
                  </Button>
                ) : (
                  <Button variant="contained" fullWidth disabled>
                    {" "}
                    Submit{" "}
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </div>
      </div>


      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add new microsite
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <div className="creatCommuinity_form_body">
             <div className="creatCommuinity_wrapper">
               <div className="creatCommuinity_wrap">
                  <form>
                        <Grid container spacing={1} sx={{mt:-10}}>
                          <Grid item xs={12} sm={12} md={12}>
                              <input type="text" className="form_control"  placeholder="Microsite Name" onChange={(e)=>setCommunityName(e.target.value)} />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                              <input type="text" className="form_control"  placeholder="Website (If any)" onChange={(e)=>setWebsite(e.target.value)} />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <input type="email" className="form_control"  placeholder="Email (For your community use)" onChange={(e)=>setEmail(e.target.value)} />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                              <input type="phone" className="form_control"  placeholder="Phone" onChange={(e)=>setPhone(e.target.value)} required />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                              <input type="tagline" className="form_control"  placeholder="Tagline (a short and striking or memorable phrase)" onChange={(e)=>setTagLine(e.target.value)} />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                              <input type="text" className="form_control"  placeholder="Address" onChange={(e)=>setAddress(e.target.value)} />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                              <textarea  type="text" className="form_control" onChange={(e)=>setDetails(e.target.value)}  placeholder="Type description"></textarea>
                          </Grid>
                          {!location && <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <Button onClick={getLocation} variant="outlined" startIcon={<LocationOnIcon/>}>
                                  Set location
                                </Button>
                              </Stack>
                            
                            </Grid>}
                         
                            <Grid item xs={12}>
                              <input type="text" value={location}  className="form_control"  placeholder="Location" onChange={(e)=>setLocation(e.target.value)} />
                            </Grid>
                         
                          {!logoPreview &&
                          <Grid item xs={12}> 
                            <form className="uploadBox" onChange={handleLogo}>
                              <h1>Upload  image</h1>
                              <input type="file" name="avatar" />
                            </form>                         
                           </Grid>}
                            {logoPreview &&  <Grid item xs={12}>
                             <div className="image_place_container">
                               <img src={logoPreview} width='50%' alt="" /> 
                               <div className="img_place_overly">
                               <div className="img_cross_btn" onClick={(e)=>{setLogoPreview('');setLogo('')}}>
                                  <CloseIcon/>
                               </div>
                               </div>
                             </div>
                            </Grid> }

                          {/* <FormControlLabel required control={<Checkbox checked={checked} /> } label="Agree with our T&C" /> */}

                          <div className="pass_left">
                              <input type="checkbox" className="chequeB" onChange={handleChange}/>
                              Agree with our Term & Conditions.
                          </div>
                          <Grid item xs={12} sx={{ mt:2 }}> 
                              <Link>{(actionEffectValue !==true)?(communityName && email && phone && checked===true)?<>
                              <Button variant="contained" fullWidth onClick={(e)=> handleCreate(e)}> Create Now</Button>
                              </>: <Button variant="contained" fullWidth disabled> Create Now</Button>: MainLoader(actionEffectValue)}  </Link>
                          </Grid>
                      </Grid>
                  </form>
                </div>
            </div>
        </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>


      <ToastContainer />
      <div className="update_btn">
             {values.title && values.details 
             ?<Button  onClick={(e) => handleLocalBusiness()} variant="contained" ><SyncIcon /></Button>
            :<Button disabled variant="contained" ><SyncIcon /></Button>}
        </div>
    </Fragment>
  );
};

export default LocalBusinessCreateBody;
