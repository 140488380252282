import PropTypes from 'prop-types';
// @mui
import { Tooltip, IconButton, Stack, InputAdornment, TextField, Box, Button } from '@mui/material';
import Iconify from '../Iconify/Iconify';
import { Navigate, useNavigate } from 'react-router-dom';


// ----------------------------------------------------------------------

TableToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function TableToolbar({ filterName, onFilterName,placeholder, addButtonFor,path, bulk, bulkPath }) {
  const navigate = useNavigate()
  return (
    <Stack  direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2.5, px: 3 }}>
      <TextField
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
      />

      <Box display={'flex'}>
        <Box>
          {bulk ===true && <>
            <Box>
                <Button variant='contained' onClick={(e)=>navigate(bulkPath)}>Bulk Upload</Button>
            </Box>
          </>}
        </Box>
        <Box sx={{ml:2}}>
        {addButtonFor ==='admin' && 
          <Tooltip title="Add new">
            <IconButton sx={{color:'#7d6be0'}} onClick={(e)=>navigate(path)}>
              <Iconify icon={'ic:round-add'} />
            </IconButton>
          </Tooltip>
          }
        </Box>
      </Box>
      
    </Stack>
  );
}
