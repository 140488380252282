import React from 'react'
import NoThemeFound from '../NoThemeFound'
import DynamicBody from './DynamicBody'

const bodyArray =[1,2,3,4,5,6,7,8,9,10]
const DynamicThemeBody = ({params}) => {
    const findTheme = bodyArray.find(e => e === parseInt(params.themenumber))

    if(findTheme && parseInt(params.themenumber) <=3){
        return(
          <DynamicBody params={params}/>
        )
    }
    
    if(findTheme ===undefined){
      return(
        <NoThemeFound/>
      )
    }
}

export default DynamicThemeBody