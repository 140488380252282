import React, { useEffect } from "react";
import { Grid, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChooseCommuinity from "../../components/postBusiness/ChooseCommuinity";


function ChooseCommuinityPage() {
  const navigate = useNavigate(); 
    useEffect(() => {
      // Get the query parameters from the URL
      const params = new URLSearchParams(window.location.search);
      const packageId = params.get('package_id');
  
      if (packageId) {
        // Store the package_id in local storage
        localStorage.setItem('package_id', packageId);
      }
    }, []); // Empty dependency array to run once when the component mounts
  
  
  return (
    <>
       <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="content_body">
            <Tooltip title="Back">
              <div
                className="backArrow"
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
              </div>
            </Tooltip>
            <div className="post-business-container">
                <ChooseCommuinity/>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ChooseCommuinityPage