import { Box, Button, FormControl, FormControlLabel, FormGroup, Grid, Switch, TextField } from "@mui/material";
import { Upload } from "antd";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { notifyError } from "../../utils/Toast";
import { baseUrl, groupUrl, resourceMediaUrl } from "../../api/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../utils/UserContext";
import { ToastContainer } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
import SunEditor from "suneditor-react"; 
import UploadLoader from "../PageLoadEffects/UploadLoader";
import MultipleGlobalUploader from "../Uploader/MultipleGlobalUploader";
import { useGetSingleGroupDataQuery, useStoreGroupMutation } from "../../api/slices/groupsApiSlice";
import SyncIcon from '@mui/icons-material/Sync';

const GroupCreateBody = () => { 
  const navigate = useNavigate();
  const location = useLocation();
  const {msDetails} = useContext(UserContext)
  const [fileList, setFileList] = useState([]);
  const [getFileList, setGetFileList] = useState(null)
  const [thumbUrl, setThumbUrl] = useState(null)
  const [loaderEffectValue, setLoaderEffectValue] = useState(false)

  const token = localStorage.getItem('token');
  const [uuid, setUuid] = useState(location?.state?.uuid)
  const {data: getSingleGroupData, isFetching: getDataFetching, isError:getDataError} = useGetSingleGroupDataQuery({token,uuid});
  const [storeGroup] = useStoreGroupMutation()


  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  // input default state value
const defaultValues = {
  name: '',
  details: '',
  media:[],
  status:true
};
const methods = useForm({defaultValues});
const {watch,setValue} = methods;
const values = watch();

const handleEditorChange = (content,type) => {
  setValue('details', content)
};


useEffect(()=>{
  if(getSingleGroupData){
    setValue('name', getSingleGroupData?.name)
    setValue('details', getSingleGroupData?.details)

    if(getSingleGroupData?.files && getSingleGroupData?.files.length>0){
      setGetFileList(getSingleGroupData?.files)
    }
    if(getSingleGroupData?.thumb){
      setThumbUrl(getSingleGroupData?.thumb)
    }

    if(getSingleGroupData?.status ===1){
      setValue('status', true)
    }else{
      setValue('status', false)
    }
    
  }
},[getSingleGroupData, setValue])


// handle create new resource
const handleGroup = ()=>{
  setLoaderEffectValue(true)
    let formData = new FormData();
    formData.append('microsite_id', msDetails.id);
    formData.append('name', values.name);
    formData.append('details', values.details);

    if(values.status ===true){
      formData.append('status', 1);
    }else{
      formData.append('status', 0);
    }

    if(fileList && fileList.length>0){
      formData.append('thumb', fileList[0].originFileObj);
    }
    if(values.media.length>0){
      values.media.forEach(file=>{
        formData.append("files[]", file.originFileObj);
      });
    }


    var url; 
    if(location?.state !==null){
      url = `${groupUrl}/${location?.state?.uuid}`
    }else{
      url = groupUrl
    }

    storeGroup({token,formData,url})
    .unwrap()
    .then((response) => {
      setLoaderEffectValue(false)
      navigate("/groups");
    })
}


 //handle delete a resouce media
 const handleDeleteMedia=(uuid)=>{
  Swal.fire({
      heightAuto: false,
      backdrop: false,
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoaderEffectValue(true)
        let config = {
          method: "delete",
          url: `${resourceMediaUrl}/${uuid}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        axios 
          .request(config)
          .then((response) => {
            setLoaderEffectValue(false)
            //notifySuccess();
            window.location.reload()
          })
          .catch((error) => {
              setLoaderEffectValue(false)
              notifyError('Something went wrong')
          });
      }
    });  
}


const handleChangeStatus = (event,field) => {
  setValue(`${field}`, event.target.checked);
};





  return (
    <Fragment>
         <div className="resource_create">
             <h4>{location?.state !==null?'Update Group':'Create Group'}</h4>
             <div className="resource_form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box><TextField label="Group Name"  variant="filled" fullWidth  focused onChange={(e)=>setValue('name', e.target.value)} value={values.name}/></Box>
                    </Grid>
                    <Grid item xs={12}>
                      <label>Group Details</label>
                      <Box> 
                        <SunEditor
                                     name="details"
                                     setContents={values.details}
                                     placeholder="Details Here..."
                                     showToolbar={true}
                                     setDefaultStyle="height: 200px"
                                     onChange={(e)=>handleEditorChange(e,'details')}
                                     setOptions={{
                                          buttonList: [
                                            [
                                              "fontSize",
                                              "formatBlock",
                                              "paragraphStyle",
                                              "blockquote",
                                              "bold",
                                              "underline",
                                              "italic",
                                              "hiliteColor",
                                              "align",
                                              "list",
                                              "link",
                                              "codeView",
                                            ],
                                          ],
                                     }}
                                   />
                      </Box>
                    </Grid>
                  
                    <Grid item xs={12}>
                        <div className="field_heading">
                            Upload Thumb :
                        </div>
                       <div className="resource_files_container">
                       <Upload
                           action='https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png'
                           listType="picture-card"
                           fileList={fileList}
                           onChange={onChange}
                           onPreview={onPreview}
                           maxCount={1}>
                           {'Upload'}
                         </Upload>
                       </div>
                    </Grid>

                    {thumbUrl !==null && <Grid sx={{mt:2}} item xs={12}>
                         <div className="image_list_container">
                              <div className="image_place">
                              <img src={`${baseUrl}/${thumbUrl}`} alt=""/> 
                            </div>
                         </div>
                      </Grid>}
                  

                      <Grid item xs={12}>
                        <div className="field_heading">
                            Media Files :
                        </div>
                       <div className="resource_files_container">
                          <MultipleGlobalUploader setValue={setValue} name='media' />
                       </div>
                       {getFileList !==null && <Grid sx={{mt:2}} item xs={12}>
                         <div className="image_list_container">
                          {getFileList.length>0 && getFileList.map((data)=>{
                            return(
                              <div className="image_place">
                                {data.url && <img src={`${data.url}`} alt="" />  }
                              <div className="img_place_overly">
                              <div className="img_cross_btn">
                                 <CloseIcon onClick={(e)=>handleDeleteMedia(data.uuid)}/>
                              </div>
                              </div>
                            </div>
                            )
                          })}
                            
                         </div>
                      </Grid> }
                    </Grid>

                    <Grid item xs={12}>
                      <Box >
                        <FormControl component="fieldset" variant="standard">
                          <FormGroup>
                            <FormControlLabel
                              label="Public"
                              value='Public'
                              labelPlacement="start"
                              control={
                                <Switch
                                  id="myToggleStatus"
                                  labelPlacement="start"
                                  checked={JSON.parse(values.status)}
                                  onChange={(e) => handleChangeStatus(e, "status")}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </Box>

                    </Grid>


                     {loaderEffectValue===true &&<>{<UploadLoader/>}</> }
                     {loaderEffectValue ===false && <Grid item xs={12}>
                      {(values.name)?<Button onClick={(e)=>handleGroup()} variant="contained" fullWidth> Submit </Button>
                      :
                      <Button variant="contained" fullWidth disabled> Submit </Button>
                      }
                    </Grid>}
                    
                </Grid>
             </div>
         </div>
         <ToastContainer />
         <div className="update_btn">
                  {(values.name)
                    ?<Button  onClick={(e)=>handleGroup()} variant="contained" ><SyncIcon /></Button>
                    :<Button disabled variant="contained" ><SyncIcon /></Button>}
                </div>
    </Fragment>
  );
};

export default GroupCreateBody;
