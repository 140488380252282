import { Grid } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { ShimmerContentBlock } from "react-shimmer-effects";


const ItemListEffect = () => {
  return (
    <Fragment>
        <ShimmerContentBlock title  text  thumbnailWidth={370} thumbnailHeight={70}/>
        <ShimmerContentBlock title  text  thumbnailWidth={370} thumbnailHeight={70}/>
        <ShimmerContentBlock title  text  thumbnailWidth={370} thumbnailHeight={70}/>
        <ShimmerContentBlock title  text  thumbnailWidth={370} thumbnailHeight={70}/>
        <ShimmerContentBlock title  text  thumbnailWidth={370} thumbnailHeight={70}/>
        <ShimmerContentBlock title  text  thumbnailWidth={370} thumbnailHeight={70}/>
        <ShimmerContentBlock title  text  thumbnailWidth={370} thumbnailHeight={70}/>
    </Fragment>
  );
};

export default ItemListEffect;
