import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userListSlice } from "../api/slices/userSlice";
import { matrimonyApiSlice } from "../api/slices/matrimonyApiSlice";
import { countryApiSlice } from "../api/slices/countryApiSlice";
import { payStationSlice } from "../api/slices/payStattionApiSlice";
import { loungeApiSlice } from "../api/slices/loungeApiSlice";
import { groupsApiSlice } from "../api/slices/groupsApiSlice";
import { notificationApiSlice } from "../api/slices/notificationApiSlice";
import { braodcastApiSlice } from "../api/slices/braodcastApiSlice";
import { pollApiSlice } from "../api/slices/pollApiSlice";

export const store = configureStore({
  reducer: {
    [userListSlice.reducerPath]: userListSlice.reducer,
    [matrimonyApiSlice.reducerPath]: matrimonyApiSlice.reducer,
    [countryApiSlice.reducerPath]: countryApiSlice.reducer,
    [payStationSlice.reducerPath]: payStationSlice.reducer,
    [loungeApiSlice.reducerPath]: loungeApiSlice.reducer,
    [groupsApiSlice.reducerPath]: groupsApiSlice.reducer,
    [notificationApiSlice.reducerPath]: notificationApiSlice.reducer,
    [braodcastApiSlice.reducerPath]: braodcastApiSlice.reducer,
    [pollApiSlice.reducerPath]: pollApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userListSlice.middleware,
      matrimonyApiSlice.middleware,
      countryApiSlice.middleware,
      payStationSlice.middleware,
      loungeApiSlice.middleware,
      groupsApiSlice.middleware,
      notificationApiSlice.middleware,
      braodcastApiSlice.middleware,
      pollApiSlice.middleware,
    ),
});

setupListeners(store.dispatch);
