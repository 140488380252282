import React, { Fragment, useContext, useEffect, useState } from "react";
import { Avatar} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import parser from 'html-react-parser'
import { baseUrl } from "../../api/Api";
import { UserContext } from "../../utils/UserContext";
import HttpsIcon from '@mui/icons-material/Https';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useGetGroupMemberDataQuery } from "../../api/slices/groupsApiSlice";

const GroupMainBody = ({ group, handleDeleteGroup }) => {
    const navigate = useNavigate();
    const { userDetails} = useContext(UserContext);
    
    const [lockOpen, setLoackOpen] = useState(false)


    useEffect(()=>{
        if((group && group?.user?.id === userDetails.id) || (group?.status ===1)){
            setLoackOpen(true)
        }
    },[group, userDetails?.id])


    return (
        <Fragment>

            <div className="resource_item_2 representative_item_2 cursorPointer" >
                <Avatar
                    alt={group?.name}
                    src={`${baseUrl}/${group?.thumb}`}
                    sx={{ width: 100, height: 100 }}
                    onClick={(e) => navigate('/group-lounge', { state: {group:group } })}
                    />

                {group?.name && <h4  onClick={(e) => navigate('/group-lounge', { state: {group:group } })}>{group?.name} {lockOpen===true ? <span><LockOpenIcon /></span>:<span><HttpsIcon /></span>} </h4>}
                {group && group?.details && <div className="card_details"  onClick={(e) => navigate('/group-lounge', { state: {group:group } })}>
                    {group?.details.length>250 ?<>{parser(group.details.slice(0, 250))}</>:<>{parser(group.details)}</>}
                </div>}

                <div className="item_Bottom">
                    {group?.created_at && <div className="create_date">
                        Created <span>{new Date(group?.created_at).toDateString().split(' ').slice(1).join(' ')} </span>
                    </div>}
                    <div className="action_list">
                        {(userDetails.id === group?.user?.id) && (
                                <i
                                    className="cursorPointer"
                                    onClick={(e) =>
                                        navigate("/group-store", {
                                            state: { uuid: group.uuid },
                                        })
                                    }
                                >
                                    <EditIcon />
                                </i>
                            )}
                           
                        {(userDetails.id === group?.user?.id) && (
                                <i
                                    className="cursorPointer"
                                    onClick={(e) => handleDeleteGroup(group.uuid)}
                                >
                                    <DeleteIcon />
                                </i>
                            )}
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default GroupMainBody