import React, { Fragment, useContext, useEffect, useState } from "react";
import ProfileBody from "../../components/ProfileBody/ProfileBody";
import { Grid, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserContext } from "../../utils/UserContext";
import { useGetFeedDataQuery } from "../../api/slices/loungeApiSlice";

const ProfilePage = () => {
  const navigate = useNavigate();

  const {msDetails} = useContext(UserContext)

  const token = localStorage.getItem("token");
  const microsite_id = msDetails?.id
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [groupId, setGrouppId] = useState('')



  const {data: getPostsData, isFetching: getDataFetching, isError:getDataError} = useGetFeedDataQuery({token,microsite_id,currentPage,searchValue, groupId});

  return (
    <Fragment>
      <Grid container spacing={2}>
         <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
         <Grid item lg={8} md={8} sm={12} xs={12}>
            <div className="content_body">
                <Tooltip title="Back">
                 <div className="backArrow" onClick={(e)=>{navigate(-1)}}>
                    <ArrowBackIcon/>
                 </div> 
               </Tooltip>
              <ProfileBody allFeeds={getPostsData?.data} getDataFetching={getDataFetching} />
            </div>
         </Grid>
      </Grid>
    </Fragment>
  );
};

export default ProfilePage;
