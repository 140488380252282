import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../Api";
import axios from "axios";

export const loungeApiSlice = createApi({
  reducerPath: "lounge",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["lounge"],
  endpoints: (builder) => ({
    getFeedData: builder.query({
      query: ({token,microsite_id,currentPage,searchValue,groupId}) => {
        return {
          url: `/api/community/feeds?microsite_id=${microsite_id}&current_page=${currentPage}&keyword=${searchValue}&group_id=${groupId}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["lounge"],
    }),
    getPinnedFeedData: builder.query({
      query: ({token,microsite_id,currentPage,searchValue,groupId,pinnedPost}) => {
        return {
          url: `/api/community/feeds?microsite_id=${microsite_id}&current_page=${currentPage}&keyword=${searchValue}&group_id=${groupId}&pinned=${pinnedPost}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["lounge"],
    }),
    getNotificationFeedData: builder.query({
      query: ({token,microsite_id,currentPage,searchValue,groupId,notificationPost}) => {
        return {
          url: `/api/community/feeds?microsite_id=${microsite_id}&current_page=${currentPage}&keyword=${searchValue}&group_id=${groupId}&notification=${notificationPost}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["lounge"],
    }),
    getSingleFeedData: builder.query({
      query: ({token,uuid,microsite_id}) => {
        return {
          url: `/api/community/feeds/${uuid}?microsite_id=${microsite_id}`,
          method: "GET"
        };
      },
      providesTags: ["lounge"],
    }),
    // storeFeed: builder.mutation({
    //   query: ({token,formData,url}) => ({
    //     url: url,
    //     method: 'POST',
    //     body: formData,
    //     headers:{
    //       Authorization: `Bearer ${token}`
    //     },
       
    //   }),
    //   invalidatesTags: ['lounge'],
    // }),
    storeFeed: builder.mutation({
      queryFn: async ({ token, formData, url, setProgressValue }) => {
        try {
            const  response = await axios.post(url, formData, {
              url: url,
              method: 'POST',
              body: formData,
              headers:{
                Authorization: `Bearer ${token}`
              },
            onUploadProgress: (uploadEvent) => {
              // Set the progress value to show the progress bar
              const  uploadProgress = Math.round((100 * uploadEvent.loaded) / uploadEvent.total);
               setProgressValue(uploadProgress)
            },
          });
          // Handle the response if needed
          return response
        } catch (error) {
          // Handle errors
          // throw new Error(error.message);
        }
      },
      invalidatesTags: ['lounge'],
    }),
    storeReaction: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        },
       
      }),
      invalidatesTags: ['lounge'],
    }),
    removeReaction: builder.mutation({
      query: ({token,url}) => ({
        url: url,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['lounge'],
    }),
    deleteFeed: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/community/feeds/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['lounge'],
    }),



    getCommentData: builder.query({
      query: ({token,url}) => {
        return {
          url: url,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["lounge"],
    }),
    storeComment: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        },
      }),
      invalidatesTags: ['lounge'],
    }),
    storeCommentReaction: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        },
       
      }),
      invalidatesTags: ['lounge'],
    }),
   
    removeCommentReaction: builder.mutation({
      query: ({token,url}) => ({
        url: url,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['lounge'],
    }),


    deleteComment: builder.mutation({
      query: ({token,url}) => ({
        url: url,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['lounge'],
    }),
    
  }),
});

export const {
   useGetFeedDataQuery,
   useGetPinnedFeedDataQuery,
   useGetNotificationFeedDataQuery,
   useStoreFeedMutation,
   useDeleteFeedMutation,
   useRemoveReactionMutation,
   useGetSingleFeedDataQuery,

   useStoreReactionMutation,

   useGetCommentDataQuery,
   useStoreCommentMutation,
   useStoreCommentReactionMutation,
   useRemoveCommentReactionMutation,
   useDeleteCommentMutation
  } = loungeApiSlice;