import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../Api";
import axios from "axios";

export const pollApiSlice = createApi({
  reducerPath: "poll",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["poll"],
  endpoints: (builder) => ({

    getPollData: builder.query({
      query: ({token,microsite_id,user_id,active_pools,status,currentPage,searchValue}) => {
        return {
          url: `/api/community/pool?microsite_id=${microsite_id}&user_id=${user_id}&active_pools=${active_pools}&status=${status}&current_page=${currentPage}&keyword=${searchValue}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["poll"],
    }),  
    getSinglePollData: builder.query({
      query: ({token,uuid}) => {
        return {
          url: `/api/community/pool/${uuid}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["poll"],
    }),  
    storePoll: builder.mutation({
      queryFn: async ({ token, formData, url, setProgressValue }) => {
        try {
            const  response = await axios.post(url, formData, {
              url: url,
              method: 'POST',
              body: formData,
              headers:{
                Authorization: `Bearer ${token}`
              },
            onUploadProgress: (uploadEvent) => {
              // Set the progress value to show the progress bar
              const  uploadProgress = Math.round((100 * uploadEvent.loaded) / uploadEvent.total);
               setProgressValue(uploadProgress)
            },
          });
          // Handle the response if needed
          return response
        } catch (error) {
          // Handle errors
          // throw new Error(error.message);
        }
      },
      invalidatesTags: ['poll'],
    }),
    deletePoll: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/community/pool/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['poll'],
    }),


    getPollQuestionsData: builder.query({
      query: ({token,poll_id,searchValue}) => {
        return {
          url: `/api/community/questions?poll_id=${poll_id}&keyword=${searchValue}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["poll"],
    }),  
    storePollQuestion: builder.mutation({
      queryFn: async ({ token, formData, url, setProgressValue }) => {
        try {
            const  response = await axios.post(url, formData, {
              url: url,
              method: 'POST',
              body: formData,
              headers:{
                Authorization: `Bearer ${token}`
              },
            onUploadProgress: (uploadEvent) => {
              // Set the progress value to show the progress bar
              const  uploadProgress = Math.round((100 * uploadEvent.loaded) / uploadEvent.total);
               setProgressValue(uploadProgress)
            },
          });
          // Handle the response if needed
          return response
        } catch (error) {
          // Handle errors
          // throw new Error(error.message);
        }
      },
      invalidatesTags: ['poll'],
    }),
    deletePollQuestion: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/community/questions/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['poll'],
    }),


    getPollAnswerData: builder.query({
      query: ({token,microsite_id,poll_id,status,currentPage,searchValue}) => {
        return {
          url: `/api/community/questions?microsite_id=${microsite_id}&poll_id=${poll_id}&status=${status}&current_page=${currentPage}&keyword=${searchValue}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["poll"],
    }),  
    storePollAnswer: builder.mutation({
      queryFn: async ({ token, formData, url, setProgressValue }) => {
        try {
            const  response = await axios.post(url, formData, {
              url: url,
              method: 'POST',
              body: formData,
              headers:{
                Authorization: `Bearer ${token}`
              },
            onUploadProgress: (uploadEvent) => {
              // Set the progress value to show the progress bar
              const  uploadProgress = Math.round((100 * uploadEvent.loaded) / uploadEvent.total);
               setProgressValue(uploadProgress)
            },
          });
          // Handle the response if needed
          return response
        } catch (error) {
          // Handle errors
          // throw new Error(error.message);
        }
      },
      invalidatesTags: ['poll'],
    }),
    deletePollAnswer: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/community/poolanswer/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['poll'],
    }),

    storePollParticipants: builder.mutation({
      queryFn: async ({ token, formData, url, setProgressValue }) => {
        try {
            const  response = await axios.post(url, formData, {
              url: url,
              method: 'POST',
              body: formData,
              headers:{
                Authorization: `Bearer ${token}`
              },
            onUploadProgress: (uploadEvent) => {
              // Set the progress value to show the progress bar
              const  uploadProgress = Math.round((100 * uploadEvent.loaded) / uploadEvent.total);
               setProgressValue(uploadProgress)
            },
          });
          // Handle the response if needed
          return response
        } catch (error) {
          // Handle errors
          // throw new Error(error.message);
        }
      },
      invalidatesTags: ['poll'],
    }),


    deletePollMedia: builder.mutation({
      query: ({token,url}) => ({
        url: url,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['poll'],
    }),

  }),
});

export const {
  useGetPollDataQuery,
  useGetSinglePollDataQuery,
  useStorePollMutation,
  useDeletePollMutation,

  useGetPollQuestionsDataQuery,
  useStorePollQuestionMutation,
  useDeletePollQuestionMutation,

  useGetPollAnswerDataQuery,
  useStorePollAnswerMutation,
  useDeletePollAnswerMutation,

  useStorePollParticipantsMutation,

  useDeletePollMediaMutation

  } = pollApiSlice;