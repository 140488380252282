import React from 'react';
import user from '../../../../asset/image/user.jpg';
import g1 from '../../../../asset/image/g1.jpg';
import g2 from '../../../../asset/image/g2.jpg';
import g3 from '../../../../asset/image/g3.jpg';
import g4 from '../../../../asset/image/g4.jpg';
import shape1 from '../../../../asset/image/shape-1.webp';
import shape2 from '../../../../asset/image/shape-2.webp';
import midImg from '../../../../asset/image/mid.png';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';


const Body = ({params}) => {

  return (
     <>      
      {/* bridal-1  bridal-2 bridal-3 groom-1 groom-2 */}

      

      <div className={`metromonial_theme ${params.type}-${params.themenumber}`} > 
        <div className="theme_heading">
            {params.type ==='bridal' && <h4> Jeny's Metromial Profile</h4>}
            {params.type ==='groom' && <h4> Shahed's Metromial Profile </h4>}
        </div>

        <div class="shape_one">
            <img src={shape1} alt=""/>
        </div>
        <div class="shape_two">
            <img src={shape2} alt=""/>
        </div>
        <div class="shape_three">
            <img src={midImg} alt=""/>
        </div>

       <div className="part_one">
                <div className="feature_img">
                    {params.type ==='bridal' &&  <img src={user} alt=""/>}
                    {params.type ==='groom' &&  <img src={g2} alt=""/>}
                </div>
                <div className="user_personal_data">
                   <Grid container spacing={1.5}>
                      <Grid item lg={12}>
                      <div className="data_item">
                                <span> Name :</span>
                                {params.type ==='bridal' && 'Jeny' }
                                {params.type ==='groom' && 'Shahed'}
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Gender :</span>
                                {params.type ==='bridal' && 'Female'}
                                {params.type ==='groom' && 'Male'}
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Religion :</span>
                                 Islam
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Marital Status :</span>
                                 Unmarried
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Date Of Birth :</span>
                                 7 July 2023
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Height :</span>
                                 5 . 10'
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Complexion :</span>
                                 White
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Blood Group :</span>
                                 O+
                            </div>
                      </Grid>
                      <Grid item lg={6}>
                      <div className="data_item">
                                <span> Location :</span>
                                 Uttara - Dhaka
                            </div>
                      </Grid>
                      <Grid item lg={12}>
                      <div className="data_item">
                                <span> Address :</span>
                                House-39. Road- 7/D, Sector-10, Uttara, Dhaka.
                            </div>
                      </Grid>
                      <Grid item lg={12}>
                      <div className="data_item">
                                <span> About Me :</span>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae veritatis laudantium, corporis iure adipisci, et incidunt unde perspiciatis molestiae doloremque,Recusandae veritatis laudantium, corporis iure adipisci,non sunt suscipit illo debitis.
                            </div>
                      </Grid>
                   </Grid>
                   
                </div>
       </div>
       
       <div className="others_data">
        <div className="data_section">
            Gallery
         </div>
          <Grid container spacing={1}>
            <Grid item lg={3}>
                <div className="met_gallery_wrap">
                     <img src={g1} alt=""/>
                </div>
            </Grid>
            <Grid item lg={3}>
                <div className="met_gallery_wrap">
                     <img src={g2} alt=""/>
                </div>
            </Grid>
            <Grid item lg={3}>
                <div className="met_gallery_wrap">
                     <img src={g3} alt=""/>
                </div>
            </Grid>
            <Grid item lg={3}>
                <div className="met_gallery_wrap">
                     <img src={g4} alt=""/>
                </div>
            </Grid>
          </Grid>
       </div>
        {/* Grid item lg={} */}
       <div className="others_data">
        <div className="data_section">
            Family Information
         </div>
         <div className="m_data_wrapper">
             <div className="m_data_item">
               <Grid container spacing={2}>
                   <Grid item lg={3}>
                       <div className="data_title">
                           Father's Details 
                        </div>
                   </Grid>
                   <Grid item lg={9}>
                       <div className="data_text">
                           Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus suscipit tenetur sunt molestiae molestias omnis aliquam vel cum non saepe. Porro, dolorem distinctio?
                        </div>
                   </Grid>
                </Grid>
             </div>
             <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Mother's Details 
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus suscipit tenetur sunt molestiae molestias omnis aliquam vel cum non saepe. Porro, dolorem distinctio?
                         </div>
                    </Grid>
                 </Grid>
              </div>
              <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Sibling's Details 
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus suscipit tenetur sunt molestiae molestias omnis aliquam vel cum non saepe. 
                         </div>
                    </Grid>
                 </Grid>
              </div>
              <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Paternal Family Details 
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus suscipit tenetur sunt molestiae molestias omnis aliquam vel cum non saepe aliquam vel cum non saepe. Porro, dolorem distinctio?
                         </div>
                    </Grid>
                 </Grid>
              </div>
         </div>
       </div>

       <div className="others_data">
        <div className="data_section">
            Carrer Information
         </div>
         <div className="m_data_wrapper">
             <div className="m_data_item">
               <Grid container spacing={2}>
                   <Grid item lg={3}>
                       <div className="data_title">
                           Educational Details 
                        </div>
                   </Grid>
                   <Grid item lg={9}>
                       <div className="data_text">
                           Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus suscipit tenetur sunt molestiae molestias omnis aliquam vel cum non saepe. Porro, dolorem distinctio?
                        </div>
                   </Grid>
                </Grid>
             </div>
             <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Work Details 
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus suscipit tenetur sunt molestiae molestias omnis aliquam vel cum non saepe. Porro, dolorem distinctio?
                         </div>
                    </Grid>
                 </Grid>
              </div>
         </div>
       </div>

       <div className="others_data">
        <div className="data_section">
            Note
         </div>
         <div className="m_data_wrapper">
             <div className="m_data_item">
               <Grid container spacing={2}>
                   <Grid item lg={3}>
                       <div className="data_title">
                        Dislikes From Partner
                        </div>
                   </Grid>
                   <Grid item lg={9}>
                       <div className="data_text">
                           Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus suscipit tenetur sunt molestiae molestias omnis aliquam vel cum non saepe. Porro, dolorem distinctio?
                        </div>
                   </Grid>
                </Grid>
             </div>
             <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Dont Knock If
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus suscipit tenetur sunt molestiae molestias omnis aliquam vel cum non saepe. Porro, dolorem distinctio?
                         </div>
                    </Grid>
                 </Grid>
              </div>
              <div className="m_data_item">
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <div className="data_title">
                            Special Note
                         </div>
                    </Grid>
                    <Grid item lg={9}>
                        <div className="data_text">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus suscipit tenetur sunt molestiae molestias omnis aliquam vel cum non saepe. 
                         </div>
                    </Grid>
                 </Grid>
              </div>
         </div>
       </div>
       <div className="others_data">
        <div className="data_section">
            Others
         </div>
         <div className="m_data_wrapper">
             <div className="m_data_item">
               <Grid container spacing={2}>
                   <Grid item lg={3}>
                       <div className="data_title">
                           Others Details 
                        </div>
                   </Grid>
                   <Grid item lg={9}>
                       <div className="data_text">
                           Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus suscipit tenetur sunt molestiae molestias omnis aliquam vel cum non saepe. Porro, dolorem distinctio?
                        </div>
                   </Grid>
                </Grid>
             </div>
         </div>
       </div>
    </div>
     
     </>
  )
}

export default Body