import React from "react";
import CategoryItem from "./CategoryItem";
import { Box, Button } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";
import { baseUrl } from "../../../api/Api";
import CategoryEffect from "../../Skeleton/VideoBroadcast/CategoryEffect";
import { useNavigate, useParams } from "react-router-dom";

const BroadcastCategory = ({broadcastCategroy, loading, subcategory, details}) => {
  const params = useParams()
  const navigate = useNavigate()
  const storeActiveCategory = [];
  const storeSubActiveCategory = [];
  if(broadcastCategroy && broadcastCategroy?.length>0){
    broadcastCategroy.forEach(category => {
      if(category?.status ===1 && category?.parent_id===null){
        if(params?.uuid){
          if(params?.uuid !==category?.uuid){
            storeActiveCategory?.push(category)
          }
        }else{
          storeActiveCategory?.push(category)
        }
      }
      if(category?.parent_id !==null && details?.id ===category?.parent_id && category?.status ===1  && subcategory===true){
        storeSubActiveCategory?.push(category)
      }
    });
  }
  // handle details single actegory
  const handleSingleCategory = (uuid)=>{
    navigate(`/broadcast-category/${uuid}`)
  }

  
  return (
    <>
      <div className="broadcast-category">
        {/* <h4>{(params?.uuid && subcategory !==true )? 'Top Categories': subcategory===true?'Sub Categories': 'Categories'}</h4> */}
        <h4>{(params?.uuid && subcategory !==true )? 'Top Categories': subcategory===true?'Sub Categories': ''}</h4>
         {loading && <CategoryEffect />} 
        
         {!loading && subcategory !==true && (storeActiveCategory?.length ===0) &&
          <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}>
            <Button disabled>{'No Data Found...'}</Button>
            {!params?.uuid && 
              <Button variant="outlined" onClick={(e)=> navigate('/broadcast-category-create')}>Create Now</Button>
            }
          </Box>
         }
         {!loading && subcategory ===true && (storeSubActiveCategory?.length ===0) &&
          <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}>
            <Button disabled>{'No Data Found...'}</Button>
            {/* <Button variant="outlined" onClick={(e)=> navigate('/broadcast-category-create')}>Create Now</Button> */}
          </Box>
         }
      {subcategory !==true &&
        <Swiper 
           slidesPerView={7}
           spaceBetween={10}
           navigation={true} 
           modules={[Navigation]} 
           breakpoints={{
            280: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            575: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 7,
              spaceBetween: 10,
            },
          }}
           className="mySwiper fahim">
         {storeActiveCategory && storeActiveCategory?.length>0 && storeActiveCategory.map((category, key)=>{
            return(
              <SwiperSlide key={category?.uuid}>
                  <CategoryItem
                    title={category?.title}
                    img={category?.thumb !==null?`${baseUrl}/${category?.thumb}`:null}
                    handleSingleCategory={(e)=> handleSingleCategory(category?.uuid)}
                  />
             </SwiperSlide>
            )
         })}  
        </Swiper>}

      {subcategory ===true &&
        <Swiper 
           slidesPerView={7}
           spaceBetween={10}
           navigation={true} 
           breakpoints={{
            280: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            575: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 7,
              spaceBetween: 10,
            },
          }}
           modules={[Navigation]} 
           className="mySwiper">
         {storeSubActiveCategory && storeSubActiveCategory?.length>0 && storeSubActiveCategory.map((category, key)=>{
            return(
              <SwiperSlide key={category?.uuid}>
                  <CategoryItem
                    title={category?.title}
                    img={category?.thumb !==null?`${baseUrl}/${category?.thumb}`:null}
                    handleSingleCategory={(e)=> handleSingleCategory(category?.uuid)}
                  />
             </SwiperSlide>
            )
         })}  
        </Swiper>}
      </div>
    </>
  );
};

export default BroadcastCategory;
