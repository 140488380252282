import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GoogleApiKey } from "../../utils/GoogleApiKey";
import Geocode from 'react-geocode';
import useGeolocation from "react-hook-geolocation";
import MainLoader from "../../components/PageLoadEffects/MainLoader";

const LocalBusinessSubscriptionPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [currentLocation, setCurrentLocation] = useState("");
  const [loading, setLoading] = useState(true)
  // const [longitude, setLongitude] = useState(null);
  const params = useParams();
  Geocode.setApiKey(GoogleApiKey);
  const geolocation = useGeolocation();
  useEffect(()=>{
    setLoading(true)
    setCurrentLocation('loading')
    if (geolocation.error) {
      alert(geolocation.error.message);
      setLoading(false)
    } else {
      Geocode.fromLatLng(geolocation.latitude, geolocation.longitude).then(
        (response) => {
          response?.results[0]?.address_components.forEach(element => {
            if(element?.long_name ==="Bangladesh" || element?.short_name==="BD"){
              setCurrentLocation('BD')
              setLoading(false)
            }
          });

        },
        (error) => {
          // console.error(error);
          setCurrentLocation('')
          // setLoading(false)
        }
      );
    }
  },[geolocation.latitude,geolocation.longitude,geolocation.error])

  // handle select
  const handleSelect =(businessPackage, amount)=>{
    navigate('/local-business-payment',{state:{businessPackage:businessPackage,amount:amount,business:location?.state?.business}})
  }  
  return (
    <Fragment>
      <Grid container spacing={2}>
        {loading && <MainLoader/> } 
        <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="content_body">
            <div className="purchase_body">
            <Box display='flex' justifyContent='center' justifyItems='center'> 
                <h4>Subscription Packages</h4>
            </Box>
              <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                      <Box  item xs={6}>
                        <div className="purchase_card">
                          <div>
                            <h4 style={{ marginTop: "10px" }}>Monthly</h4>
                            <Box display='flex' justifyContent='center' justifyItems='center'> 
                              {currentLocation ==='BD' &&<p>500tk</p>}
                              {currentLocation !=='BD' &&<p>50$</p>}
                            </Box>
                          </div>
                          <Box sx={{mt:5}}>
                            <Button variant="contained" onClick={(e)=> handleSelect('monthly',currentLocation ==='BD'?'500':'50')}>Select</Button>
                          </Box>
                        </div>
                      </Box>
                  </Grid>
                  <Grid item xs={6} md={6}>
                      <Box item xs={6}>
                        <div className="purchase_card">
                          <div>
                            <h4 style={{ marginTop: "10px" }}>Yearly (20% offer)</h4>
                            <Box display='flex' justifyContent='center' justifyItems='center'> 
                              {currentLocation ==='BD' &&<p>4800tk</p>}
                              {currentLocation !=='BD' &&<p>480$</p>}
                            </Box>
                          </div>
                          <Box sx={{mt:5}}>
                            <Button variant="contained" onClick={(e)=> handleSelect('yearly',currentLocation ==='BD'?'4800':'480')}>Select</Button>
                          </Box>                       
                        </div>
                      </Box>
                  </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default LocalBusinessSubscriptionPage;
