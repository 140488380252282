import { Grid } from "@mui/material";
import BroadcastTopNavigate from "../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate";
import BroadcastVideo from "../../components/Broadcast/BroadcastDetails/BroadcastVideo";
import BroadcastSubItemList from "../../components/Broadcast/BroadcastDetails/BroadcastSubItemList";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../utils/UserContext";
import { useGetBroadcastItemDataQuery, useGetSingleBroadcastItemDataQuery } from "../../api/slices/braodcastApiSlice";
import { commentsUrl } from "../../api/Api";
import BroadcastComment from "../../components/Broadcast/BroadcastDetails/BroadcastComment";

const BroadcastDetails = () => {
  const params = useParams()
  const {msDetails} = useContext(UserContext)
  const microsite_id = msDetails?.id;
  const singleUuid = params?.uuid

  const token = localStorage.getItem('token');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const [content, setContent] = useState('')
  const [emojiShow, setEmojiShow] = useState(0)
  const [commentImage, setCommentImage] = useState(null)

  const [autoFocus, setAutoFocus] = useState(false)

  const {data: getSingleBroadcastItemData, isFetching:getSingleBroadcastItemDataFeting, isError:getSingleDataError} = useGetSingleBroadcastItemDataQuery({token,singleUuid,microsite_id})
  const {data: getBroadcastItemData, isFetching: getItemDataFetching, isError:getDataItemError} = useGetBroadcastItemDataQuery({token,microsite_id,currentPage,searchValue});

  const [scrollTop, setScrollTop] = useState(true)
  useEffect(() => {
    if(scrollTop ===true){
      window.scrollTo(0, 0)
      setScrollTop(false)
    }
  }, [scrollTop])





  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <div className="broadcast-body">
              <BroadcastTopNavigate />
              {/* <Divider sx={{ mt:3 , mb:1}}/> */}
              <Grid container spacing={2}>
                 <Grid item lg={8} xs={12}>
                    <BroadcastVideo data ={getSingleBroadcastItemData} loading={getSingleBroadcastItemDataFeting} setAutoFocus={setAutoFocus} />
                    <BroadcastComment data ={getSingleBroadcastItemData} autoFocus={autoFocus}/>
                 </Grid>
                 <Grid item lg={4} xs={12}>
                    <BroadcastSubItemList data ={getSingleBroadcastItemData} broadcastItem ={getBroadcastItemData} loading={getItemDataFetching} singleUuid={singleUuid} setScrollTop={setScrollTop} />
                 </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default BroadcastDetails;