import React, { Fragment, useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Container, Grid } from '@mui/material'
import axios from 'axios';
import { baseUrl, commonUserUrl, micrositeDetailsUrl, userMicrositesUrl } from '../../api/Api';
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GeneralTopNavigation from '../AuthenticationPages/GeneralTopNavigation';
import MainLoader from '../../components/PageLoadEffects/MainLoader';
import { UserContext } from '../../utils/UserContext';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const UserCommunitiesSitesPage = () => {
  const token = localStorage.getItem('token')
  const [userMicrosites, setUserMicrosites] = useState(null)
  const [loaderVisible, setLoaderVisible] = useState(false)
  const {msDetails, userDetails} = useContext(UserContext)
  const navigate = useNavigate();
  //get all user's microsite by given token
  const getAllUserMicrosites = () => {
    setLoaderVisible(true)
    let config = {
      method: 'get',
      url: `${userMicrositesUrl}?enable_community=1`,
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };
    axios.request(config)
      .then((response) => {
        setUserMicrosites(response.data.data)
        var ids = [];
        if(response?.data?.data.length>0){
          response?.data?.data.forEach(element => {
            if(element.status ===1){
              ids.push(element.microsites.id)
            }
          });
        }
        localStorage.setItem('user-ms-ids', JSON.stringify(ids))
        setLoaderVisible(false)
      }).catch((e)=>{
        setLoaderVisible(false)
      }
      )
  }

  useEffect(() => {
    getAllUserMicrosites()
  }, [])

  // handle visit microsite
  const handleVisitNow = (uuid) => {
    let config = {
      method: 'get',
      url: `${micrositeDetailsUrl}/${uuid}`,
    };

    axios.request(config)
      .then((response) => {
        localStorage.setItem('msDetails', JSON.stringify(response.data))


        let config = {
          method: 'get',
          url: `${commonUserUrl}/${userDetails?.id}/${response.data?.id}`,
        };
        axios.request(config)
        .then((res) => {
         localStorage.setItem('loggedInUserInfo', JSON.stringify(res?.data))
          window.location.href='/';  
          setLoaderVisible(false)
        }).catch((e)=>  {   setLoaderVisible(false)})

        
      })
  }





  return (
    <Fragment>
  <div className="entry_header">
    <GeneralTopNavigation back={false} />
  </div>
  <div className="community_list_item">
    {userMicrosites !== null && userMicrosites.length > 0 && (
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {userMicrosites.map((data, key) => {
          if (data?.status === 1) {
            return (
              <Grid item lg={3} md={6} sm={12} xs={12} key={key}>
                <div
                  className="commuinity_item cursorPointer"
                  onClick={() => handleVisitNow(data.microsites.uuid)}
                >
                  <div className="commuinty_img">
                    <img
                      src={`${baseUrl}/${data?.microsites?.entity_logo}`}
                      alt={data?.microsites?.name || "Community Logo"}
                    />
                    {/* Verified Badge */}
                    {data?.microsites?.verification_status ===1 && (
                      <div className="verified_badge">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="verified_icon"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                      </svg>
                      Verified
                    </div>
                    )}
                    {/* Premium Badge */}
                    {data?.microsites?.premium ===1 && (
                      <div className="premium_badge">
                        <span className="icon">★</span> Premium
                      </div>
                    )}
                  </div>
                  <div className="commuinity_name">
                    {data?.microsites?.name}
                  </div>
                  {data?.microsites?.location &&
                    data?.microsites?.location !== "null" && (
                      <div className="commuinity_location">
                        <span>Location: </span>
                        {data?.microsites?.location?.length > 100 ? (
                          <>
                            {data?.microsites?.location.slice(0, 100)}...
                          </>
                        ) : (
                          <>{data?.microsites?.location}</>
                        )}
                      </div>
                    )}
                  <div className="card_footer">
                    <div
                      className="viewBtn"
                      onClick={() => handleVisitNow(data.microsites.uuid)}
                    >
                      View Community
                    </div>
                  </div>
                </div>
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    )}
  </div>

  {loaderVisible === true && <MainLoader />}
</Fragment>

  )
}

export default UserCommunitiesSitesPage