import React, { useEffect } from "react";
import { Fragment,useState } from "react";
import Button from '@mui/material/Button';
import { Box } from "@mui/material";
import axios from "axios";
import { commentsUrl } from "../../api/Api";
import { CommentsLoadEffect } from "../PageLoadEffects";
import SingleComment from "./SingleComment";

const LoungeCommentTwo = ({data,itemModalClick,comments,getAllFeeds})=>{
    const token = localStorage.getItem('token');
    //load from api
    // const [comments, setComments] = useState(null);
    //    //get all comments by post id
    //    const getAllComments = () =>{
    //     let config = {
    //       method: 'get',
    //       url : `${commentsUrl}?post_id=${data.id}`,
    //       headers:{
    //         'Authorization': `Bearer ${token}`
    //       }
    //     }
    //     axios.request(config)
    //     .then((res)=>{
    //       setComments(res.data.data)
    //     })
    //    }

    //    useEffect(()=>{
    //       if(data){
    //         getAllComments()
    //       }
      //  },[])




    return(
        <Fragment>
             <div className="comment_input_main">
             </div>
             {comments !==null && comments.length<1 && <Box sx={{display:'flex', justifyContent:'center', justifyItems:'center'}}><Button disabled>No Comments Yet</Button></Box>}
             
             {comments ===null  && CommentsLoadEffect()}

             {comments !==null && comments.length>0 && 
              <div className="comment_container">
                <ul className="comment_ul">
                  {comments?.slice(0).reverse().map((mainComment,mainKey)=>{
                    return(
                      <li key={mainComment.uuid}>   
                        <SingleComment 
                          data={mainComment} 
                          getAllComments={''} 
                          replayShow='0' 
                          findLast={true}
                          createBy={data?.user_id}
                           />
                      </li>
                    )
                  })}
                 
              </ul>
              {comments !==null && comments.length===2 && <Box sx={{display:'flex', justifyContent:'center', justifyItems:'center'}}><Button onClick={(e)=>itemModalClick()} >More Comments</Button></Box>}
              </div>
             }
            {/* <ToastContainer /> */}
        </Fragment>
    )
}

export default LoungeCommentTwo