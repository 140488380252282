import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../Api";
export const notificationApiSlice = createApi({
  reducerPath: "notification",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}`,
  }),
  tagTypes: ["notification"],
  endpoints: (builder) => ({

    getNotification: builder.query({
      query: ({token,msID,userID,currentPage,searchValue}) => {
        return {
          url: `/api/common/micrositecontact?microsite_id=${msID}&user_id=${userID}&current_page=${currentPage}&keyword=${searchValue}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["notification"],
    }),

    storeNotification: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['notification'],
    }),

    deleteNotification: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/common/micrositecontact/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['notification'],
    }),
    
  }),
});

export const {
    useGetNotificationQuery,
    useStoreNotificationMutation,
    useDeleteNotificationMutation
  } = notificationApiSlice;