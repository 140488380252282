import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  TextField,
} from "@mui/material";
import { Image, Upload } from "antd";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../utils/UserContext";
import { useStoreBroadcastItemMutation } from "../../../api/slices/braodcastApiSlice";
import LoungeUploadProgress from "../../LoungeUploadProgress/LoungeUploadProgress";
import { baseUrl } from "../../../api/Api";
import UploadLoader from "../../PageLoadEffects/UploadLoader";
import { BigPlayButton, ControlBar, ForwardControl, Player } from "video-react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function UploadEditForm({singleData, allCategory}) {
  const defaultValues = {
    title: singleData?.title?singleData.title:"",
    subTitle: "",
    details: singleData?.details?singleData.details:"",

    allCategory: allCategory?.data?allCategory.data:[],
    selectedCategory: null,

    tags: singleData?.meta?.tags? singleData?.meta?.tags.split(','):'',
    keywords: singleData?.meta?.keywords? singleData?.meta?.keywords.split(','):'',

    media: null,
    mediaUrl:singleData?.video?singleData.video :null,
    mediaUploadShow: singleData?.video? false:true,

    thumb: null,
    thumbUrl: singleData?.thumb? singleData?.thumb : null,
    thumbUploadShow:singleData?.thumb?false:true,

    status: "false",  
    visible:false,

    uploaderOptionShow:true,
    uloaderDetailsShow:false,
    videoRecordOptions:false
  };
  const methods = useForm({ defaultValues });
  const { watch, setValue } = methods;
  const values = watch();

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const [progressValue,setProgressValue] = useState(0)
  const [storeBroadcastItem] = useStoreBroadcastItemMutation()

  useEffect(()=>{
    if(allCategory?.data && allCategory?.data?.length>0){
      allCategory.data.forEach(element => {
        if(element?.id ===singleData?.category_id){
          setValue('selectedCategory', element)
        }
      });
    }
  },[])




  const { Dragger } = Upload;

  const generateVideoThumbnail = async(file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");
  
      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      // console.log('video src', video.src)
      // console.log('video url file', URL.createObjectURL(file))
  
      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");
  
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
  
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        // console.log('canvas', canvas.toDataURL("image/png"))
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  function base64ImageToBlob(str) {
    // extract content type and base64 payload from original string
    var pos = str.indexOf(';base64,');
    var type = str.substring(5, pos);
    var b64 = str.substr(pos + 8);
  
    // decode base64
    var imageContent = atob(b64);
  
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);
  
    // fill the view, using the decoded base64
    for(var n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
  
    // convert ArrayBuffer to Blob
    var blob = new Blob([buffer], { type: type });
    const url = URL.createObjectURL(blob);

    const file = new File(
      [blob],
      `generated-${Date.now()}.${blob.type.split("/")[1]}`,
      
    );
    const newFile = {
      uid:`rc-upload-${Date.now()}-12`,
      name: file.name,
      status: "done",
      url: url,
      type: blob?.type,
      originFileObj: file,
    };
    // setFileList((prevFileList) => [...prevFileList, newFile]);
  
    // return blob;
    return newFile;
  }

  const propsVideo = {
    name: "file",
    multiple: false,
    maxCount:1,
    listType:"picture-card",
    accept:"video/mp4,video/x-m4v,video/*",
    action:
      "https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png",
    onChange(info) {
      if (info?.file?.status === "removed") {
        setValue("media", null);
      } else {
        setValue("media", info?.file?.originFileObj);
        generateVideoThumbnail(info?.file?.originFileObj)
        .then((result)=>{
          const thumbnail =  base64ImageToBlob(result)
          setValue("thumb", thumbnail);
        })
      }
    },
    onDrop(e) {
      setValue("media", e.dataTransfer.files);
    },
  };
  const propsThumb = {
    name: "file",
    multiple: false,
    maxCount:1,
    listType:"picture-card",
    accept:"image/png, image/gif, image/jpeg",
    action:"https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png",
    onChange(info) {
      if (info?.file?.status === "removed") {
        setValue("thumb", null);
      } else {
        setValue("thumb", info?.file);
      }
    },
    onDrop(e) {
      setValue("thumb", e.dataTransfer.files);
    },
  };

  // create broad cast item
  const createBroadcastItem = async(e)=>{
    e.preventDefault()
    setValue('visible', true)
    const formData = new FormData();

      if(values?.selectedCategory !==null){
        formData.append("category_id", values?.selectedCategory?.id);
      }
      if(values?.title !==''){
        formData.append("title", values?.title);
      }
      // formData.append("subtitle", values?.subTitle);
      if(values?.details !==''){
        formData.append("details", values?.details);
      }
      if(values?.tags !==''){
        formData.append("tags", values?.tags.join(','));
      }
      if(values?.keywords !==''){
        formData.append("keywords", values?.keywords.join(','));
      }
      if(values?.thumb !==null){
        formData.append("thumb", values?.thumb?.originFileObj);
      }

      if(values?.media !==null){
        formData.append("video", values?.media);
      }
      
      formData.append("status", singleData?.status);

      var url= `${baseUrl}/api/community/broadcastitem/${singleData?.uuid}`
     
      const response = await storeBroadcastItem({token,formData,url,setProgressValue})
      if(response?.data){
       navigate(-1)
      }
  } 


  return (
    <>
      <Grid container spacing={4} sx={{ mt: 3 }}>
        {values?.mediaUploadShow ===true && 
        <Grid item xs={5}>
          <div className="dragger-item">
            <Dragger {...propsVideo} defaultFileList={values?.media !==null?[values?.media]:[]}>
              <h4>
                <strong> SELECT VIDEO TO UPLOAD </strong>
              </h4>
              <h6 className="ant-upload-hint">
                or drag and drop the file here
              </h6>
              <CloudUploadIcon sx={{ fontSize: "60px" }} />
            </Dragger>
            <Box sx={{mt:1}} display={'flex'} justifyContent={'center'}>
                <Button onClick={(e)=>{
                      setValue('media', null)
                      setValue('mediaUploadShow', false)
                    }}>Cancel</Button>
              </Box>
          </div>
        </Grid>}
        {values?.mediaUploadShow === false &&  
        <Grid item xs={5}>
            <div className="broadcast-video">
              <Player
                src={`${baseUrl}/${values?.mediaUrl}`}
              >
                <BigPlayButton position="center" />
                <ControlBar autoHide={false} className="my-class">
                  <ForwardControl seconds={10} order={3.2} />
                </ControlBar>
              </Player>
            </div>
            <Box sx={{mt:1}} display={'flex'} justifyContent={'center'}>
                    <Button onClick={(e)=> setValue('mediaUploadShow',true)}>Change Video</Button>
              </Box>
        </Grid>}
        <Grid item xs={7}>
          <h5> Video Details </h5>
          <div className="upload-content">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Video Title (*)"
                  variant="filled"
                  fullWidth
                  focused
                  value={values?.title}
                  onChange={(e) => setValue("title", e.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  label="Video Sub Title"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("subTitle", e.target.value)}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Autocomplete
                  options={values?.allCategory}
                  getOptionLabel={(option) => option.title}
                  id="categoryCategory"
                  value={values?.selectedCategory}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue("selectedCategory", newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Select Category (*)"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  getOptionLabel={(option) => ""}
                  value={values?.tags}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue("tags", newValue);
                    }
                  }}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Tags"
                      placeholder="tags..."
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  getOptionLabel={(option) => ""}
                  value={values?.keywords}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue("keywords", newValue);
                    }
                  }}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Keywords"
                      placeholder="keywords..."
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values?.details}
                  onChange={(e) => setValue("details", e.target.value)}
                  label="Description"
                  variant="filled"
                  multiline
                  rows={4}
                  fullWidth
                  focused
                />
              </Grid>

              {values?.thumbUploadShow === true && 
              <Grid item xs={7}>
                <div className="dragger-item">
                  <Dragger {...propsThumb} defaultFileList={values?.thumb !==null?[values?.thumb]:[]} fileList={values?.thumb !==null?[values?.thumb]:[]} >
                    <h6 className="ant-upload-hint">Upload Thumb</h6>
                    <CloudUploadIcon sx={{ fontSize: "60px" }} />
                  </Dragger>
                  <Box sx={{mt:1}} display={'flex'} justifyContent={'center'}>
                    <Button onClick={(e)=>{
                      setValue('thumb', null)
                      setValue('thumbUploadShow', false)
                    }}>Cancel</Button>
                  </Box>
                </div>
              </Grid>}

              {values?.thumbUploadShow === false && 
              <Grid item xs={7}>
                <div className="dragger-item">
                  <Image src={`${baseUrl}/${values?.thumbUrl}`} />

                  <Box sx={{mt:1}} display={'flex'} justifyContent={'center'}>
                    <Button onClick={(e)=> setValue('thumbUploadShow',true)}>Change Thumb</Button>
                  </Box>
                </div>
              </Grid>
              }
              {/* <Grid xs={12}>
                <FormControl sx={{ m: 2 }}>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Visibility
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={values?.status}
                    value={values?.status}
                    onChange={(e) => setValue("status", e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Public"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Private"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
              <Grid xs={12} sx={{ ml: 2 }}>
                <Box sx={{mt:5}} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                  <Button sx={{color:'#fff !important'}} variant="contained" size="small" onClick={(e)=> createBroadcastItem(e)}>
                    Update
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
          {values?.visible ===true &&  <UploadLoader />}
          {(progressValue>0 && (values?.media !==null || values?.thumb !==null)) && <LoungeUploadProgress value = {progressValue} progressTotalLenght={0} />}
        </Grid>
      </Grid>
    </>
  );
}

export default UploadEditForm;
