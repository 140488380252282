import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ThemePurchaseBody from "../../../components/Matrimony/Theme/ThemePurchaseBody";
import PaymentCheckOutForm from "../../../components/Matrimony/Theme/PaymentCheckOutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../utils/UserContext";

const stripePromise = loadStripe(
  "pk_test_51OE1gQSFKNm0CZMZHxi93TV4jmFc0TJIfKORRkPVYPqPJU5ObJeRmfEu9f80u5kwmguYfrfseBeWZyLCPbEsDVDZ00CJ9E6cwx"
);
const PaymentPage = () => {
  const { userDetails, msDetails } = useContext(UserContext);
  const params = useParams();
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: "sk_test_51MN1WQKl4xo5wQaCkbD4nvBf2tcsjqjw8fcmbHMZHCpss12J6gicMz8ErRJLIk04XDR8PNm1TvDREK7dy89WyOHa00EPDRbShZ",
  // };
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="content_body">
            <Elements stripe={stripePromise}>
              <PaymentCheckOutForm userDetails={userDetails} msDetails={msDetails} params={params} />
            </Elements>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PaymentPage;
