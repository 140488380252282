import React, { Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PreviewThemeBody from "../../../../components/Matrimony/Theme/Preview/PreviewThemeBody";
import { Grid, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContext } from "react";
import { UserContext } from "../../../../utils/UserContext";
import axios from "axios";
import { paystationUrl } from "../../../../api/Api";
import ThemePurchaseBody from "../../../../components/Matrimony/Theme/ThemePurchaseBody";
import PurchaseThemePage from "../PurchaseThemePage";

const PreviewThemePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { userDetails, msDetails } = useContext(UserContext);

  const callbackUrl = "http://localhost:3000/matrimony-theme";

  // console.log("userDetails", userDetails);

  const handlePurchase = () => {
    // let data = new FormData();
    // data.append("payment_amount", 10);
    // data.append("invoice_number", `IC-${params.type}-${params.themenumber}`);
    // data.append("cust_name", userDetails?.profile?.name);
    // data.append("cust_phone", userDetails?.meta?.mobile);
    // data.append("cust_email", userDetails?.email);
    // data.append("cust_address", userDetails?.meta?.present_address);
    // data.append("currency", msDetails?.currency);
    // data.append("callback_url", callbackUrl);
    // data.append("checkout_items", params.themenumber);

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     "content-type": "multipart/form-data",
    //   },
    // };
    // axios
    //   .post(paystationUrl, data, config)
    //   .then((response) => {
    //     window.location.href = response?.payment_url;
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });

    // console.log(params);
    // <PurchaseThemePage params={params} />

    // console.log(params?.themenumber);

    // navigate("/matrimony-theme-purchase");
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={3} md={12} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={12} sm={12} xs={12}>
          <div className="content_body">
            <div className="section_headear">
              <Tooltip title="Back">
                <div
                  className="backArrow"
                  onClick={(e) => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIcon />
                </div>
              </Tooltip>
              <div className="btns_row">
                <Link to={`/matrimony-theme-purchase/${params?.type}/${params?.themenumber}`}>
                  <div
                    className="Btn_one"
                  >
                    Purchase Now
                  </div>
                </Link>
              </div>
            </div>

            <PreviewThemeBody params={params} />
            {/*  */}
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PreviewThemePage;
