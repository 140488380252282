import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../Api";
export const matrimonyApiSlice = createApi({
  reducerPath: "matrimony",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}`,
  }),
  tagTypes: ["matrimony"],
  endpoints: (builder) => ({

    getMatrimony: builder.query({
      query: ({token,msID,searchQuery}) => {
        return {
          url: `/api/matrimony?microsite_id=${msID}&keyword=${searchQuery}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["matrimony"],
    }),
    getSingleMatrimony: builder.query({
      query: ({token,uuid}) => {
        return {
          url: `/api/matrimony/${uuid}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["matrimony"],
    }),

    deleteProfileMatrimony: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/matrimony/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['matrimony'],
    }),
    
  }),
});

export const {
    useGetMatrimonyQuery,
    useGetSingleMatrimonyQuery,
    useDeleteProfileMatrimonyMutation
  } = matrimonyApiSlice;