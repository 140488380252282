import React from "react";
import { Grid, Tooltip } from "@mui/material";
import { useNavigate,useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BusinessPayment from "../../components/postBusiness/BusinessPayment";

function BusinessPaymentPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const responseData = location.state?.data; // Access the passed data

  console.log("Received data:", responseData);
  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="content_body">
            <Tooltip title="Back">
              <div
                className="backArrow"
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
              </div>
            </Tooltip>
            <div className="post-business-container">
              <BusinessPayment/>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default BusinessPaymentPage;
