import { Box, Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import BroadcastTopNavigate from '../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate'
import LookBackList from '../../components/Broadcast/LookBack/LookBackList'

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useGetBroadcastCategoryDataQuery, useGetBroadcastItemDataQuery } from '../../api/slices/braodcastApiSlice';
import { UserContext } from '../../utils/UserContext';
import MainLoader from '../../components/PageLoadEffects/MainLoader';
import ItemListEffect from '../../components/Skeleton/VideoBroadcast/ItemListEffect';

export default function BroadcastAdmin() {
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {msDetails} = useContext(UserContext)
  const microsite_id = msDetails?.id;

  const token = localStorage.getItem('token');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const {data: getBroadcastCategoryData, isFetching: getCategoryDataFetching, isError:getDataCategoryError} = useGetBroadcastCategoryDataQuery({token,microsite_id,currentPage,searchValue});
  const {data: getBroadcastItemData, isFetching: getItemDataFetching, isError:getDataItemError} = useGetBroadcastItemDataQuery({token,microsite_id,currentPage,searchValue});

  const [scrollTop, setScrollTop] = useState(true)
  useEffect(() => {
    if(scrollTop ===true){
      window.scrollTo(0, 0)
      setScrollTop(false)
    }
  }, [scrollTop])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <div className="broadcast-body">
              <BroadcastTopNavigate />

              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:5 }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="All" value="1" />
                      <Tab label="Approved" value="2" />
                      <Tab label="Pending" value="3" />
                      <Tab label="Rejected" value="4" />
                    </TabList>
                  </Box>
                  {!getItemDataFetching && 
                    <>
                    <TabPanel value="1"><LookBackList item = {getBroadcastItemData} loading={getItemDataFetching} tab={'all'} /></TabPanel>
                    <TabPanel value="2"><LookBackList item = {getBroadcastItemData} loading={getItemDataFetching} tab={'active'} /></TabPanel>
                    <TabPanel value="3"><LookBackList item = {getBroadcastItemData} loading={getItemDataFetching} tab={'pending'} /></TabPanel>
                    <TabPanel value="4"><LookBackList item = {getBroadcastItemData} loading={getItemDataFetching} tab={'reject'} /></TabPanel>
                    </>
                  }
                  {getItemDataFetching && <ItemListEffect />}

                </TabContext>
              </Box>
              {/* <LikeList/>
              <SaveList/> 
              <LookBackList />*/}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}
