import { Avatar } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import LazyImage from "../../LazyImage";
import { baseUrl } from "../../../api/Api";
import { UserContext } from "../../../utils/UserContext";
import playButton from '../../../asset/image/playButton.png'

const BroadcastItem = ({data,img,title,userAvatar,userName, handleSinglItem}) => {
  const navigate = useNavigate()
  const {userDetails} = useContext(UserContext)
  var username = data?.microsite_userdetails?.user_details? JSON.parse(data?.microsite_userdetails?.user_details)?.name?JSON.parse(data?.microsite_userdetails?.user_details)?.name:userName:userName

  return (
    <>
      <div className="broadcast-item">
        <a onClick={(e)=>handleSinglItem(data?.uuid)}>
          <div className="broadcast-img">
            
            <i><img src={playButton} /></i>
            <LazyImage  src={`${baseUrl}/${img}`} alt={title}  />
            {/* <img src={img} alt={title} /> */}
          </div>
        </a>
        <div className="item-content">
        <a onClick={(e)=>handleSinglItem(data?.uuid)}>
          <h4> {title} </h4>
        </a>
          <div className="broadcast-item-bottom">
            <div className="bottom-left-content d-flex cursorPointer"
              onClick={(e)=> userDetails?.id===data?.user?.id
                ?navigate('/community-profile',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user,showInfo:true}})
                :navigate('/community-profile/other',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user}})
              }
            >
              <Avatar 
                alt={username}
                src={`${baseUrl}/${userAvatar}`}
              />
              <div className="left-content">
                <div className="userName">{username}</div>
                <div className="overView">
                  <span> {data?.meta?.hits?`${data?.meta?.hits} views`:'No View'}  </span>
                  {/* <span> 6 days ago </span> */}
                </div>
              </div>
            </div>
            {/* <Avatar
              sx={{ height: 28, width: 28 }}
              className="cursor-pointer"
              id="basic-button"
              aria-controls={menuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={menuHandleClick}
            >
              <MoreVertIcon sx={{ fontSize: "18px" }} />
            </Avatar>
            <Menu
              id="basic-menu"
              anchorEl={menu}
              open={menuOpen}
              onClose={manuHandleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={manuHandleClose} sx={{ fontSize: "14px" }}>
                <AccessAlarmIcon sx={{ mr: 1 }} /> Save to Watch Later
              </MenuItem>
              <MenuItem onClick={manuHandleClose} sx={{ fontSize: "14px" }}>
                <DeleteOutlineOutlinedIcon sx={{ mr: 1 }} /> Remove from Look
                Back
              </MenuItem>
              <MenuItem onClick={manuHandleClose} sx={{ fontSize: "14px" }}>
                <ShareIcon sx={{ mr: 1 }} /> Share
              </MenuItem>
            </Menu> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BroadcastItem;
