import React from "react";
import LazyImage from "../../LazyImage";
import { baseUrl } from "../../../api/Api";

import playButton from '../../../asset/image/playButton.png'

const BroadcasSubItem = ({data,singleUuid,video,img,title,userAvatar,userName, handleSinglItem}) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const date = data?.created_at?new Date(data?.created_at).toLocaleDateString("en-US", options):''
  
  var username = data?.microsite_userdetails?.user_details? JSON.parse(data?.microsite_userdetails?.user_details)?.name?JSON.parse(data?.microsite_userdetails?.user_details)?.name:userName:userName


  return (
    <>
      <div className={singleUuid ===data?.uuid?`broadcast-sub-item cursorPointer active`:`broadcast-sub-item cursorPointer`} onClick={(e)=> handleSinglItem(data?.uuid)}>
        <div className="item-img">
          <i><img src={playButton} width={'20%'} /></i>
          <LazyImage  src={`${baseUrl}/${img}`} alt={title}  />
        </div>
        <div className="item-content">
          <h4> {title} </h4>
          <div className="userName"> {username} </div>
          <div className="overView">
            <span> {data?.meta?.hits?`${data?.meta?.hits} views`:'No View'}  </span>
            <span> {date} </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BroadcasSubItem;
