import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect } from "react";
import { useState } from "react";
import LoungeProfileBio from "../LoungeProfileBio/LoungeProfileBio";
import LoungeItemContent from "../LoungeItemContent/LoungeItemContent";
import PhotoIcon from "@mui/icons-material/Photo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { BigPlayButton, ControlBar, ForwardControl, Player } from "video-react";

import {
  allMembersUrl,
  baseUrl,
  createFeedsUrl,
} from "../../api/Api";
import axios from "axios";
import useGeolocation from "react-hook-geolocation";
import Dragger from "antd/es/upload/Dragger";
import { notifyError } from "../../utils/Toast";
import { UserContext } from "../../utils/UserContext";
import { useNavigate } from "react-router-dom";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import Geocode from "react-geocode";
import { GoogleApiKey } from "../../utils/GoogleApiKey";
import { Box, Grid, TextareaAutosize } from "@mui/material";

// Uploader Modal Attached
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LoungeComment from "../LoungeItemContent/LoungeComment";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import UploadLoader from "../PageLoadEffects/UploadLoader";
import LoungeUploadProgress from "../LoungeUploadProgress/LoungeUploadProgress";
import LoungeLatestJob from "../LoungeBody/LoungeLatestJob";
import LoungeLatestClassified from "../LoungeBody/LoungeLatestClassified";
import { useDeleteFeedMutation, useGetSingleFeedDataQuery, useStoreFeedMutation } from "../../api/slices/loungeApiSlice";
import MainLoader from "../PageLoadEffects/MainLoader";

const LoungeItemDetail = () => {
  const navigate = useNavigate();
  const getLocation = useLocation();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose2 = () => {
    setOpen(false);
  };
  let post_id = getLocation?.state?.data?.uuid
  if(!post_id){
    const params = new URLSearchParams(window.location.search);
     post_id = params.get("post_id");
  } 
  // Sticky right side
  Geocode.setApiKey(GoogleApiKey);
  const geolocation = useGeolocation();
  const [stickyNav, setStickyNav] = useState(false);
  const [visible, setVisible] = useState(false);
  const [postBtnVisible, setPostBtnVisible] = useState("btn btn-primary");
  const { userDetails, msDetails } = useContext(UserContext);
  const [gender, setGender] = useState();
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [cover, setCover] = useState();
  const [members, setAllMembers] = useState([]);
  const [lastPage, setLastPage] = useState("");
  const [tagValue, settagValue] = React.useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowTag, setModalShowTag] = React.useState(false);
  const [modalShowExtraImage, setModalShowExtraImage] = React.useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [toggle, setToggle] = React.useState(false);
  const [sIndex, setSIndex] = React.useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [totalMedia, setTotalMedia] = useState(null);
  const [allFeeds, setAllFeeds] = useState(null);
  const [modalData, setModalData] = useState();
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [emojiShow, setEmojiShow] = useState(0);
  const maxNumber = 90;
  const [content, setContent] = useState("");
  const [location,setLocation] = useState("");
  const [professional, setProfessional] = useState(0);
  const [status, setStatus] = useState("1");
  const [storePositionY, setStorePositionY] = useState(0);

  const [singleFeed, setSingleFeed] = useState(null);
  const [bottom, setBottom] = useState(false);
  const [loadCount, setLoadCount] = useState(2);
  const token = localStorage.getItem("token");
  const [loaderVisible, setLoaderVisible] = useState(false);

  const [progressValue, setProgressValue] = useState(0);
  const [progressTotalLenght, setProgressTotalLength] = useState(0);

  const [autoFocus, setAutoFocus] = useState(false)

  const microsite_id = msDetails?.id

  const [uuid, setUuid] = useState(post_id)
  const {data: getSingleFeedData, isFetching: getDataFetching, isError:getDataError} = useGetSingleFeedDataQuery({token,uuid,microsite_id});
  const [storeFeed] = useStoreFeedMutation()
  const [deleteFeed] = useDeleteFeedMutation()


  //
  const [storeUuid, setStoreUuid] = useState(null);
  const [bgShow, setBgShow] = useState(0);
  const toggleBgShow = () => {
    if (bgShow === 0) {
      setBgShow(1);
    } else {
      setBgShow(0);
    }
  };
  const [bgShow2, setBgShow2] = useState(0);
  const [showMediaModal, setShowMediaModal] = useState("dialogContent-1");

  
  useEffect(()=>{
    if(getSingleFeedData){
      setSingleFeed(getSingleFeedData);
      setStoreUuid(getSingleFeedData.uuid);
    }

  },[getSingleFeedData?.uuid,getSingleFeedData])

  useEffect(() => {
    if(getLocation?.state?.commentClick ===true){
      setAutoFocus(true)
    }
  }, [getLocation?.state]);

  

  const handleLocation = () => {
    if (geolocation.error) {
      alert(geolocation.error.message);
    } else {
      Geocode.fromLatLng(geolocation.latitude, geolocation.longitude).then(
        (response) => {
          const address = response.results[9].formatted_address;
          setLocation(address);
        },
        (error) => {
          // console.error(error);
        }
      );
    }
  };

  //  multiple files upload
  const handleChange = ({ fileList: newFileList }) => {
    var files = newFileList;
    var fileCount = 0;
    var videoCount = 0;
    for (var i = 0; i < files.length; i++) {
      if (
        files[i].type === "video/mp4" ||
        files[i].type === "video/mov" ||
        files[i].type === "video/avi" ||
        files[i].type === "video/mkv"
      ) {
        videoCount = videoCount + 1;
      }
      if (
        files[i].type === "application/pdf" ||
        files[i].type === "application/msword" ||
        files[i].type === "application/zip"
      ) {
        fileCount = fileCount + 1;
      }
      if (
        files[i].type === "image/png" ||
        files[i].type === "image/jpg" ||
        files[i].type === "image/jpeg" ||
        files[i].type === "image/gif"
      ) {
      }
    }

    if (videoCount > 1 || fileCount > 1) {
      notifyError("Limit exit (max: 1 video , max: 1 File)");
    } else {
      setFileList(newFileList);
    }
  };
  const uploadButton = (
    <div>
      <div
        style={{
          marginTop: 0,
          padding: "40px",
        }}
      >
        Upload Media (Photo,Video,Files)
      </div>
    </div>
  );


  const createPost = async (event) => {
    setEmojiShow(0)
    setPostBtnVisible("btn btn-primary d-none");
    setVisible(true);
    event.preventDefault();
    var files = fileList;
  
    const formData = new FormData();
    if(content !==''){
      formData.append("content", content);
    }
    formData.append("microsite_id", msDetails?.id);
    formData.append("location", location);
    formData.append("professional", professional);
    formData.append("status", 1);
    // formData.append("group_id", groupInfo.id);
  
    let allow = "1";
    for (var i = 0; i < files.length; i++) {
      if (
        files[i].type === "application/pdf" ||
        files[i].type === "application/msword" ||
        files[i].type === "image/png" ||
        files[i].type === "image/jpg" ||
        files[i].type === "image/jpeg" ||
        files[i].type === "image/gif"
      ) {
        formData.append("files[]", files[i].originFileObj);
      }
      if (
        files[i].type === "video/mp4" ||
        files[i].type === "video/mov" ||
        files[i].type === "video/avi" ||
        files[i].type === "video/mkv"
      ) {
        formData.append("video", files[i].originFileObj);
      }
    }
  
    var url;
    if(storeUuid !==null){
      url = `${createFeedsUrl}/${storeUuid}`
    }else{
      url = createFeedsUrl
    }
  
    const response = await storeFeed({token,formData,url,setProgressValue})
    if(response?.data){
      setProgressValue(0)
      setProgressTotalLength(0)
      setContent('')
      setTotalMedia(null);
      setFileList([]);
  
      setPostBtnVisible("btn btn-primary");
      setOpen(false);
      setVisible(false);
      if(storeUuid !==null){
        setStoreUuid(null)
      }
    }
    
  };
  //get all members
  
  // handle scroll pagination
  const handlePagination = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    if (bottom) {
      setBottom(true);
    }
  };

  //set User information
  useEffect(() => {
    if(token){
      const membersUrl = `${allMembersUrl}/${msDetails.id}?current_page=1`;
      let config = {
        method: "get",
        url: membersUrl,
      };
  
      axios
        .request(config)
        .then((response) => {
          setAllMembers(response.data.data);
        })
        .catch((error) => {})
    }
      window.onscroll = () => {
        handlePagination();
        setStorePositionY(window.scrollY);
        setStickyNav(window.pageYOffset >= 350 ? true : false);
        return () => (window.onscroll = null);
      };
    if (userDetails != null) {
      setName(userDetails.profile.name);
      setUsername(userDetails.username);
      setEmail(userDetails.email);
      setGender(userDetails.profile.gender.name);
      setAvatar(userDetails.profile.avatar);
      setCover(userDetails.profile.cover);
    }
  }, []);

  
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };



  return (
    <Fragment>
      <div className="lounge">
       {/* {getDataFetching &&  <MainLoader />} */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <div className="postView_container">
              <div className="postView_top">
                {singleFeed !== null && (
                  <LoungeProfileBio
                    getAllFeeds=''
                    data={getSingleFeedData}
                    getSingleFeedDetails={''}
                    modalOpen={handleClickOpen}
                    modalClose={handleClose2}
                    createPost={createPost}
                    setContent={setContent}
                    setStoreUuid={setStoreUuid}
                  />
                )} 
              </div>
              <div className="postView_container_Top">
                {singleFeed && singleFeed.video && (
                  <Player src={`${baseUrl}/${singleFeed.video}`}>
                    <BigPlayButton position="center" />
                    <ControlBar autoHide={false} className="my-class">
                      <ForwardControl seconds={10} order={3.2} />
                    </ControlBar>
                  </Player>
                )}

                <Swiper
                  pagination={{
                    type: "fraction",
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  {singleFeed && singleFeed.media.length > 0 ? (
                    singleFeed.media.map((data, key) => {
                      return (
                        <>
                          <SwiperSlide key={data.uuid}>
                            <div className="postViewModal_container_Image">
                              <img src={data.url} alt={data.name} />
                            </div>
                          </SwiperSlide>
                        </>
                      );
                    })
                  ) : (
                    <>
                      {/* <SwiperSlide key={data.uuid}>
                                    <div className="postViewModal_container_Image">
                                      <img src={L1} alt=''  />
                                    </div>
                                  </SwiperSlide> */}
                    </>
                  )}
                </Swiper>
              </div>
              <div className="postView_container_Bottom">
                {singleFeed && (
                  <LoungeItemContent
                    data={singleFeed}
                    itemModalClick={(e) => {}}
                    getAllFeeds=''
                    getSingleFeedDetails=''
                    notification={singleFeed?.notification ===1? true:false}
                  />
                )}
                {token && singleFeed?.notification !==1 &&
                  <>
                     <Grid container spacing={1}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="modal_comment_area">
                          {singleFeed && (
                            <LoungeComment
                              data={singleFeed}
                              getAllFeeds=''
                              getSingleFeedDetails=''
                              autoFocus={autoFocus}
                            />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </>
                }
              </div>
            </div>
          </Grid>
          {/* Another Grid */}
          {token && (
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <div className="lounge_right_fixed">
                <LoungeLatestJob />
                <div
                  className={` lounge_right ${stickyNav ? "lounge_right_sticky" : ""}`}
                >
                  <LoungeLatestClassified />
                </div>
              </div>
            </Grid>
          )}

        </Grid>
      </div>
      {/* Post View Modal */}

      {/* Uploader Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose2}
        aria-labelledby="responsive-dialog-title"
      >
        {/* Modal First View */}
        <div className={showMediaModal}>
          {storeUuid !== null ? (
            <Box sx={{m:1}} className="dialog_header">Update Your Post</Box>
          ) : (
            <div className="dialog_header">
              Create Your Post{" "}
              <div className="closeBtn" onClick={handleClose2}>
                {" "}
                <CloseIcon />{" "}
              </div>{" "}
            </div>
          )}

          <DialogContent>
            <div
              className="upload_input_area"
              onClick={(e) => {
                emojiShow === 1 && setEmojiShow(0);
              }}
            >
              <TextareaAutosize
                autoFocus
                aria-label="minimum height"
                minRows={7}
                placeholder="Post your text, video or photo"
                className="Upload_textarea"
                onChange={(e) => setContent(e.target.value)}
                value={content}
              />
              {location && (
                <p>
                  <FontAwesomeIcon icon={faLocationDot} /> {location}
                </p>
              )}
            </div>
          </DialogContent>
          <div className="dialog_footer">
            <div className="emoji_picker">
              {emojiShow === 1 && (
                <Picker
                  style={{ height: "300px" }}
                  data={data}
                  previewPosition={"none"}
                  onEmojiSelect={(e) => {
                    setContent(content + " " + e.native);
                    setSelectedEmoji(e.native);
                  }}
                />
              )}
            </div>
            <div className="upload_attached">
              <ul>
                <li
                  onClick={() => {
                    toggleBgShow();
                    setShowMediaModal("dialogContent-1 d-none");
                  }}
                >
                  <PhotoIcon />{" "}
                  <span className="small-text">
                    {" "}
                    Photo / Video{" "}
                    {fileList && fileList.length > 0 && (
                      <small>({fileList.length})</small>
                    )}
                  </span>
                </li>
                <li onClick={handleLocation}>
                  <LocationOnIcon />
                  <span className="small-text">Location</span>
                </li>
                {/* <li onClick={() => {toggleBgShow2() ; setShowMediaModal('dialogContent-1 d-none')}}>
                      <LocalOfferIcon/> 
                     {tagValue.length > 0 ? (
                       <span className="small-text">
                         Taged ({tagValue.length} persons)
                       </span>
                     ) : (
                       <span className="small-text"> Tag</span>
                     )}
                   </li> */}
                <li
                  onClick={(e) => {
                    emojiShow === 0 ? setEmojiShow(1) : setEmojiShow(0);
                  }}
                >
                  <span>🙂</span>
                </li>
              </ul>
              {visible === true && <UploadLoader />}
            </div>

            <div className="uploader_btn_area">
              {content.length > 0 || fileList.length > 0 ? (
                <Button
                  variant="contained"
                  onClick={(e) => createPost(e)}
                  className={postBtnVisible}
                >
                  {storeUuid !== null ? <>Update Now</> : <>Post now</>}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={(e) => createPost(e)}
                  className={`${postBtnVisible} disabled`}
                >
                  Post now
                </Button>
              )}
            </div>
          </div>
        </div>

        {/* Media Uploader Part  */}
        {bgShow === 1 && (
          <div className="dialogContent-2">
            <div className="dialog_header">
              <div
                className="mediaBack_btn"
                onClick={() => {
                  toggleBgShow();
                  setShowMediaModal("dialogContent-1");
                }}
              >
                <ArrowBackIcon />
              </div>
              Add Media
            </div>
            <div className="mediaAdd_Body">
              <div className="mediaAdder">
                <Dragger
                  action="https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png"
                  name="file"
                  multiple={true}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                >
                  {fileList.length >= 90 ? null : uploadButton}
                </Dragger>
              </div>
              {fileList && fileList.length>0 && 
              <Box sx={{mb:2}} display='flex' justifyContent='center' justifyItems='center'>
                  <Button variant="outlined" onClick={() =>{toggleBgShow(); setShowMediaModal('dialogContent-1')}}>Next</Button>
              </Box>}
            </div>
          </div>
        )}

        {/* Tag Uploader Part */}
        {bgShow2 === 1 && (
          <div className="dialogContent-2">
            {/* <div className="dialog_header">
         <div className="mediaBack_btn"  onClick={() =>{toggleBgShow2(); setShowMediaModal('dialogContent-1')}}>
           <ArrowBackIcon/> 
         </div>
           Tag your friend
        </div> */}
            <DialogContent>
              <div className="TagBody">
                <Stack spacing={3}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={members}
                    getOptionLabel={(option) => option.user.name}
                    defaultValue={tagValue}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        settagValue(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Add friends"
                        placeholder="Add..."
                      />
                    )}
                  />
                </Stack>
              </div>
            </DialogContent>
          </div>
        )}
      </Dialog>
      {(progressValue>0 && fileList.length>0) && <LoungeUploadProgress value = {progressValue} progressTotalLenght={fileList.length} />}

      {/* <ToastContainer /> */}
    </Fragment>
  );
};

export default LoungeItemDetail;
