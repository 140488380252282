import React, { Fragment } from "react";
// import { Col, Nav, NavLink, Row } from "react-bootstrap";


const NotificationBody = () => {
  return (
    <Fragment>
          {/* <div className="notification">
              <Row>
                <Col lg={2}></Col>
                 <Col lg={8}>
                    <div className="notification_list">
                        <div className="notification_top">
                           <div className="notification_top_left"> Notification</div>
                           <div className="notification_top_right"> 
                              <Nav  className="flex-row">
                                    <Nav.Link> <NavLink  to="/">   All   </NavLink></Nav.Link>
                                    <Nav.Link> <NavLink  to="/">  Unread </NavLink></Nav.Link>
                              </Nav>
                          </div>
                        </div>
                        <div className="notification_body">
                           <Row>
                              <Col lg={12}>
                                  <div className="notification_item">
                                  <div className="profile_bio_2">
                                                  <div className="profile_img">
                                                     <img src={L1}  alt='' />
                                                  </div>
                                                  <div className="profile_info">
                                                       <ul>
                                                          <li><a href="/" className="profile_user_name "> Fahim Ahmed </a></li>
                                                          <li className="post_time"> <i><FontAwesomeIcon icon={faClock} /></i> 11  March 2021</li>
                                                      </ul>
                                                       <div className="profile_title">Front-End Devoloper</div>
                                                  </div>
                                      </div>

                                      <div className="notify_text mt-1">
                                        Lorem ipsum dolor sit amet consectetur elit. Illum, Lorem ipsum dolor sit amet Lorem, adipisicing elit. Eveniet et provident consectetur est iste ipsum?
                                      </div>
                                  </div>
                              </Col>
                              <Col lg={12}>
                                  <div className="notification_item">
                                  <div className="profile_bio_2">
                                                  <div className="profile_img">
                                                     <img src={L1}  alt='' />
                                                  </div>
                                                  <div className="profile_info">
                                                       <ul>
                                                          <li><a href="/" className="profile_user_name "> Fahim Ahmed </a></li>
                                                          <li className="post_time"> <i><FontAwesomeIcon icon={faClock} /></i> 11  March 2021</li>
                                                      </ul>
                                                       <div className="profile_title">Front-End Devoloper</div>
                                                  </div>
                                      </div>

                                      <div className="notify_text mt-1">
                                        Lorem ipsum dolor sit amet consectetur elit. Illum.
                                      </div>
                                  </div>
                              </Col>
                           </Row>
                        </div>
                    </div>
                 </Col>
                 <Col lg={2}></Col>
              </Row>
          </div> */}
    </Fragment>
  );
};

export default NotificationBody;