import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  TextField,
} from "@mui/material";
import { Upload } from "antd";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../utils/UserContext";
import { useGetBroadcastCategoryDataQuery, useStoreBroadcastItemMutation } from "../../../api/slices/braodcastApiSlice";
import LoungeUploadProgress from "../../LoungeUploadProgress/LoungeUploadProgress";
import { baseUrl } from "../../../api/Api";
import MainLoader from "../../PageLoadEffects/MainLoader";
import UploadLoader from "../../PageLoadEffects/UploadLoader";

import VideocamIcon from '@mui/icons-material/Videocam';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CategoryCreateModal from "../../../pages/VideoBroadcast/CategoryCreateModal";
import Webcam from "react-webcam";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function UploadForm() {
  const defaultValues = {
    title: "",
    subTitle: "",
    details: "",
    allCategory: [],
    selectedCategory: null,
    tags: "",
    keywords: "",
    media: null,
    thumb: null,
    status: "false",  
    visible:false,

    uploaderOptionShow:true,
    uloaderDetailsShow:false,
    videoRecordOptions:false
  };
  const methods = useForm({ defaultValues });
  const { watch, setValue } = methods;
  const values = watch();

  const navigate = useNavigate();
  const { msDetails, userDetails } = useContext(UserContext);
  const microsite_id = msDetails?.id;

  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [storeUuid, setStoreUuid] = useState(null);
  const [progressValue,setProgressValue] = useState(0)


  const [storeBroadcastItem] = useStoreBroadcastItemMutation()


  const {data: getBroadcastCategoryData,isFetching: getDataFetching,isError: getDataError} = useGetBroadcastCategoryDataQuery({ token,microsite_id,currentPage,searchValue});

  useEffect(() => {
    if (getBroadcastCategoryData?.data) {
      setValue("allCategory", getBroadcastCategoryData?.data);
    }
  }, [getBroadcastCategoryData]);


  const { Dragger } = Upload;


  const generateVideoThumbnail = async(file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");
  
      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      // console.log('video src', video.src)
      // console.log('video url file', URL.createObjectURL(file))
  
      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");
  
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
  
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        // console.log('canvas', canvas.toDataURL("image/png"))
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  function base64ImageToBlob(str) {
    // extract content type and base64 payload from original string
    var pos = str.indexOf(';base64,');
    var type = str.substring(5, pos);
    var b64 = str.substr(pos + 8);
  
    // decode base64
    var imageContent = atob(b64);
  
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);
  
    // fill the view, using the decoded base64
    for(var n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
  
    // convert ArrayBuffer to Blob
    var blob = new Blob([buffer], { type: type });
    const url = URL.createObjectURL(blob);

    const file = new File(
      [blob],
      `generated-${Date.now()}.${blob.type.split("/")[1]}`,
      
    );
    const newFile = {
      uid:`rc-upload-${Date.now()}-12`,
      name: file.name,
      status: "done",
      url: url,
      type: blob?.type,
      originFileObj: file,
    };

    // setFileList((prevFileList) => [...prevFileList, newFile]);
  
    // return blob;
    return newFile;


  }

  const propsVideo = {
    name: "file",
    multiple: false,
    maxCount:1,
    listType:"picture-circle",
    accept:"video/mp4,video/x-m4v,video/*",
    action:
      "https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png",
    onChange(info) {
      if (info?.file?.status === "removed") {
        setValue("media", null);
      } else {
        setValue("media", info?.file?.originFileObj);
        generateVideoThumbnail(info?.file?.originFileObj)
        .then((result)=>{
          const thumbnail =  base64ImageToBlob(result)
          setValue("thumb", thumbnail);
        })
      }
    },
    onDrop(e) {
      setValue("media", e.dataTransfer.files);
    },
  };
  const propsThumb = {
    name: "file",
    multiple: false,
    maxCount:1,
    listType:"picture-card",
    accept:"image/png, image/gif, image/jpeg",
    action:"https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png",
    onChange(info) {
      if (info?.file?.status === "removed") {
        setValue("thumb", null);
      } else {
        setValue("thumb", info?.file);
      }
    },
    onDrop(e) {
      setValue("thumb", e.dataTransfer.files);
    },
  };

  // handle record media
  const handleRecordMedia = (e)=>{
    setValue('media', e.target.files[0])
    generateVideoThumbnail(e?.target?.files[0])
    .then((result)=>{
      const thumbnail =  base64ImageToBlob(result)
      setValue("thumb", thumbnail);
    })
  }

  // create broad cast item
  const createBroadcastItem = async(e)=>{
    e.preventDefault()
    setValue('visible', true)
    const formData = new FormData();
      formData.append("microsite_id", msDetails?.id);
      formData.append("colab_id", userDetails?.id);
      formData.append("category_id", values?.selectedCategory?.id);
      formData.append("title", values?.title);
      // formData.append("subtitle", values?.subTitle);
      formData.append("details", values?.details);

      if(values?.tags !==''){
        formData.append("tags", values?.tags.join(','));
      }
      if(values?.keywords !==''){
        formData.append("keywords", values?.keywords.join(','));
      }

      formData.append("thumb", values?.thumb?.originFileObj);
      formData.append("video", values?.media);
      
      // if(values?.status ==='true'){
      //   formData.append("status", 1);
      // }else{
      //   formData.append("status", 0);
      // }
      formData.append("status", 0);


      var url;
      if(storeUuid !==null){
        url = `${baseUrl}/api/community/broadcastitem/${storeUuid}`
      }else{
        url = `${baseUrl}/api/community/broadcastitem`
      }
    
      const response = await storeBroadcastItem({token,formData,url,setProgressValue})
      if(response?.data){
        if(storeUuid !==null){
          setStoreUuid(null)
        }
        setValue('visible', false)
        navigate('/broadcast-library')
      }
  } 



// media modal
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    return formattedTime;
  };


  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (reset) => {
    setOpen(false);
    if(reset ===true){
      setValue('media', null)
    }
  };


// cat modal
const [openCat, setOpenCat] = useState(false);
  const handleClickOpenCat = () => {
    setOpenCat(true);
  };
  const handleCloseCat = () => {
    setOpenCat(false);
  };



  // video recored desktop and laptop
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [seconds, setSeconds] = useState(null); // Initial countdown time in seconds

  const startRecording = async () => {
    setSeconds(null)
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setIsRunning(true);
        videoRef.current.srcObject = stream;
  
        mediaRecorderRef.current = new MediaRecorder(stream);
        const chunks = [];
  
        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        };
  
        mediaRecorderRef.current.onstop = () => {
          const recordedBlob = new Blob(chunks, { type: "video/webm" });
          // You can now do something with the recorded video blob
          // setValue('media', recordedBlob)
  
          const url = URL.createObjectURL(recordedBlob);
          const file = new File(
            [recordedBlob],
            `rec-video-${Date.now()}.${recordedBlob.type.split("/")[1]}`,
          );
          setValue('media', file)
          generateVideoThumbnail(file)
          .then((result)=>{
            const thumbnail =  base64ImageToBlob(result)
            setValue("thumb", thumbnail);
          })
          
        };
  
        mediaRecorderRef.current.start();
        setIsRecording(true);
    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  };
  const startCountdown = () => {
    let countdown = 5; // Countdown timer value in seconds
    const countdownInterval =  setInterval(() => {
      if (countdown === 0) {
        setSeconds(null)
        clearInterval(countdownInterval); 
        startRecording()
      }else{
        setSeconds(countdown)
      }
      // console.log('Countdown:', countdown); // Log the countdown timer value
      countdown--;
    }, 1000); // Interval of s1 second for countdown
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
    setIsRecording(false);
    setIsRunning(false);
    // setTimer(0);
    // setValue('videoRecordOptions', false)
  };

  const handleStartStopClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      // startRecording();
      startCountdown()
    }
  };

  // camera handleInputTrigger 
    const handleInputTrigger = (e)=>{
      if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/)) {
        document.getElementById("button-Camera").click();
      } else {
          setValue('videoRecordOptions', true)
      }
    } 

// handle cancle
const handleCancel = ()=>{
  setValue('media',null);
  setValue('videoRecordOptions', false)
  setTimer(0)
  stopRecording();
  handleClose(false)
}

// handle re-take
const handleRetake = () =>{
  setValue('media', null)
  setTimer(0)
  startCountdown()
}

// handle Next
const handleNext = () =>{
  setValue('uploaderOptionShow',false);
  setValue('videoRecordOptions', false)
  setValue('uloaderDetailsShow',true); 
  setTimer(0)
  handleClose(false)
}


  return (
    <>

  {values?.videoRecordOptions &&  <Box className="deviceRecorder">
      {/* <video ref={videoRef} autoPlay muted playsInline /> */}
      <Box className="deviceRecorderBody">
        <Box className="video">
          <Box className='videoElements'>
            {(videoRef?.current ===null || seconds!==null ) &&
             <Box className ={seconds===1?'videoWebCam fade-out':''}>
                <Webcam />
             </Box>
             }
            <Box className ={seconds===0?'fade-in':''}>
              <video style={{display:videoRef?.current ===null || seconds!==null ?'none':'block'}} ref={videoRef} autoPlay muted  playsInline />
            </Box>
          </Box>
        </Box>
        <Box className="recorder-btn">
          <p>{formatTime(timer)}</p>
          {seconds !==null && <h1>{seconds}</h1>}
          <button  onClick={handleStartStopClick}>
            {/* {isRecording ? 'Stop ⏺' : 'Start ⏩'} */}
            {isRecording ? 'Stop' : 'Start'}
          </button>
        </Box>

        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
          <Button variant="outlined" sx={{color:'#A0153E !important',m:0.5}}  onClick={(e)=> handleCancel() }>
            Cancel
          </Button>
          <Button variant="outlined" sx={{color:'#337357 !important',m:0.5}} disabled={values?.media !==null?false:true}  onClick={(e)=>handleRetake()}>
            Retake
          </Button>
          <Button variant="outlined" sx={{color:'#7149C6 !important',m:0.5}} disabled={values?.media !==null?false:true}  onClick={(e)=> handleNext()}>
            Next
          </Button>
        </Box>
       
      </Box>
    </Box>
    }

    {(values?.uploaderOptionShow && !values?.videoRecordOptions)&&
      <Grid container spacing={4} sx={{ mt: 3 }}>
        <Grid item lg={2}></Grid>
        <Grid item lg={8} xs={12}>
          <Box className={values?.videoRecordOptions?'media-form divDisabled':'media-form'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Box  className='camcoder-icon' onClick={(e)=> handleInputTrigger()} >
                  <VideocamIcon className="videoIcon" />
                    <input type="file" id="button-Camera" className="inputFile"  capture accept={"video/mp4,video/x-m4v,video/*;capture=camera"} onChange={(e)=> handleRecordMedia(e)} />
                  {/* <VisuallyHiddenInput type="file" capture accept={"video/mp4,video/x-m4v,video/*"} onChange={(e)=> setValue('media', e.target.files[0])} /> */}
              </Box>
              <Box className='video-icon' onClick={handleClickOpen}><CloudUploadIcon /></Box>
             
          </Box>
        </Grid>
        <Grid item lg={2}></Grid>
      </Grid>
    }

     {values?.uloaderDetailsShow &&  
      <Grid container spacing={4}>
        <Grid item lg={5} xs={12}>
          <div className="dragger-item">
            <Dragger {...propsVideo} defaultFileList={values?.media !==null?[values?.media]:[]}>
              <h4>
                <strong> SELECT VIDEO TO UPLOAD </strong>
              </h4>
              <h6 className="ant-upload-hint">
                or drag and drop the file here
              </h6>
              <CloudUploadIcon sx={{ fontSize: "60px" }} />
            </Dragger>
          </div>
        </Grid>
        <Grid item lg={7} xs={12}>
          <h5> Video Details </h5>
          <div className="upload-content">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Video Title (*)"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("title", e.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  label="Video Sub Title"
                  variant="filled"
                  fullWidth
                  focused
                  onChange={(e) => setValue("subTitle", e.target.value)}
                />
              </Grid> */}
              <Grid item xs={10}>
                <Autocomplete
                  options={values?.allCategory}
                  getOptionLabel={(option) => option.title}
                  id="categoryCategory"
                  value={values?.selectedCategory}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue("selectedCategory", newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Select Category (*)"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Button variant="outlined" sx={{mt:3}} onClick={(e)=>setOpenCat(true)}>+</Button>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  getOptionLabel={(option) => ""}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue("tags", newValue);
                    }
                  }}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Tags"
                      placeholder="tags..."
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  getOptionLabel={(option) => ""}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue("keywords", newValue);
                    }
                  }}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Keywords"
                      placeholder="keywords..."
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values?.details}
                  onChange={(e) => setValue("details", e.target.value)}
                  label="Description"
                  variant="filled"
                  multiline
                  rows={4}
                  fullWidth
                  focused
                />
              </Grid>
              {/* <Grid item xs={7}>
                <FormControl fullWidth variant="filled" focused>
                  <InputLabel id="demo-simple-select-label">
                    Select Categories
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}>
                    <MenuItem value={10} sx={{ fontSize: "14px" }}>
                      Ten
                    </MenuItem>
                    <MenuItem value={20} sx={{ fontSize: "14px" }}>
                      Twenty
                    </MenuItem>
                    <MenuItem value={30} sx={{ fontSize: "14px" }}>
                      Thirty
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item lg={7} xs={12}>
                <div className="dragger-item">
                  <Dragger {...propsThumb} defaultFileList={values?.thumb !==null?[values?.thumb]:[]} fileList={values?.thumb !==null?[values?.thumb]:[]} >
                    <h6 className="ant-upload-hint">Change Thumbnail</h6>
                    <CloudUploadIcon sx={{ fontSize: "60px" }} />
                  </Dragger>
                </div>
              </Grid>
              {/* <Grid xs={12}>
                <FormControl sx={{ m: 2 }}>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Visibility
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={values?.status}
                    value={values?.status}
                    onChange={(e) => setValue("status", e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Public"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Private"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
              <Grid xs={12} sx={{ ml: 2 }}>
                <Box sx={{mt:5}} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                  <Button sx={{color:'#fff !important'}} variant="contained" size="small" onClick={(e)=> createBroadcastItem(e)}
                    disabled={(values?.title && values?.media && values?.selectedCategory)?false:true}
                  >
                    Upload{" "}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
          {values?.visible ===true &&  <UploadLoader />}
          {(progressValue>0 && (values?.media !==null || values?.thumb !==null)) && <LoungeUploadProgress value = {progressValue} progressTotalLenght={0} />}
          {getDataFetching && <MainLoader/>} 


        </Grid>
      </Grid>
      }

      {/* media uploader modal */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth='xs'
      >
        <IconButton
          aria-label="close"
          onClick={(e)=>handleClose(true)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent  sx={{mt:4}}>
          <div className="dragger-item">
            <Dragger {...propsVideo}  >
              <h4>
                <strong> SELECT VIDEO TO UPLOAD </strong>
              </h4>
              <h6 className="ant-upload-hint">
                or drag and drop the file here
              </h6>
              <CloudUploadIcon sx={{ fontSize: "60px" }} />
            </Dragger>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" sx={{color:'#fff !important'}} disabled={values?.media !==null?false:true}  onClick={(e)=>{setValue('uploaderOptionShow',false);setValue('uloaderDetailsShow',true);handleClose(false)}}>
            Next
          </Button>
        </DialogActions>
      </BootstrapDialog>

    {/* category modal */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={openCat}
        fullWidth={true}
        maxWidth='sm'
      >
        <IconButton
          aria-label="close"
          onClick={(e)=>handleCloseCat(true)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent  sx={{mt:4}}>
          <CategoryCreateModal setValueForm={setValue} handleCloseCat={handleCloseCat} />
        </DialogContent>
      </BootstrapDialog>
       
    </>
  );
}

export default UploadForm;
