import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import { Button } from "@mui/material";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const LoungeUploadProgress = ({ value,progressTotalLenght }) => {
  const handleCancelUpload = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  };

  return (
    <>
      <div className="upload_progress_container">
        <div className="upload_progress">
          {progressTotalLenght>0 ?<h6> Uploading...({`${progressTotalLenght}`}) </h6>:(value<100)? <h6> Uploading...</h6>:''}
          {value>0 && value<100 && 
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={value} />
            </Box>}
          {value===100 && 
            <Box sx={{ width: "100%" }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Button disabled>Please Wait...(Finishing)</Button>
            </Box>}
          {/* <div className="upload_cancle">
            <CancelIcon onClick={handleCancelUpload} />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LoungeUploadProgress;
