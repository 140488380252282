import { Upload } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField
} from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";

import SyncIcon from '@mui/icons-material/Sync';
import { UploadOutlined } from '@ant-design/icons';
import { UserContext } from "../../../utils/UserContext";
import { addMediaUrl, baseUrl, eventMediaUrl, eventsUrl } from "../../../api/Api";
import { notifyError } from "../../../utils/Toast";
import MainLoader from "../../PageLoadEffects/MainLoader";
import { useStoreFeedMutation } from "../../../api/slices/loungeApiSlice";

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDeletePollAnswerMutation, useDeletePollMediaMutation, useDeletePollQuestionMutation, useGetPollQuestionsDataQuery, useStorePollAnswerMutation, useStorePollQuestionMutation } from "../../../api/slices/pollApiSlice";
import { TroubleshootSharp } from "@mui/icons-material";
import { set } from "date-fns";
import { Switch } from 'antd';
import QuestionForm from "./QuestionForm";
import { ToastContainer } from "react-toastify";
import Card from '@mui/material/Card';
import PopupBanner from "../../mediaUploder/Banner/PopupBanner";
import bannerDefault from '../../../asset/image/pollUploadDefault.png'
import UploadBanner from "../../mediaUploder/Banner/UploadBanner";
import QuestionAnswerForm from "./QuestionAnswerForm";


const PollEditForm = () => {
  const {msDetails, userDetails} = useContext(UserContext)
  const token = localStorage.getItem('token');
  const [searchValue, setSearchValue] = useState('')
  const [companyBanner,setCompanyBanner] = useState(null)
  const [open2, setOpen2] = useState(false);
  const [image2, setImage2] = useState('');
  const handleClose2 = () => {
    setOpen2(false);
    setImage2('')
  };
  const defaultValues = {

    pollShow:true,
    title: "",
    symtoms:"",
    details: "",
    banner:null,
    recommendUrl:"",
    pollStatus:true,
    storePollResponse:null,


    questionShow:false,
    questionTitle: "Question",
    questionStatus:true,
    storeQuestionResponse:{},
    updateQuestion:null,
    questionMedia:[],


    answerShow:false,
    answerTitle: "Answer",
    answerStatus:true,
    storeAnswerResponse:null,
    answerMedia:[],
    updateAnswer:null,

  };
  const methods = useForm({ defaultValues });
  const { watch, setValue } = methods;
  const values = watch();

  const poll_id = values?.storePollResponse?.id
  const microsite_id = msDetails.id
  const {data: getPollQuestionData, isFetching: getQusestionDataFetching, isError:getDataQuestionError} = useGetPollQuestionsDataQuery({token,poll_id,searchValue});
  const [storePoll] = useStoreFeedMutation();
  const [storePollQuestion] = useStorePollQuestionMutation();
  const [storePollAnswer] = useStorePollAnswerMutation();

  const [deletePollQuestion] = useDeletePollQuestionMutation();
  const [deletePollAnswer] = useDeletePollAnswerMutation();
  const [deletePollMedia] = useDeletePollMediaMutation();
  


  const navigate = useNavigate();
  const location = useLocation();
  // Date Time Star
  const [getFileList, setGetFileList] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [fileList, setFileList] = useState([]);
  const [questionFileList, setQuestionFileList] = useState([]);
  const [answerFileList, setAnswerFileList] = useState([]);
  const [loaderEffectValue, setLoaderEffectValue] = useState(false);
  const [loadershow, setLoaderShow] = useState("none");

  const [progressValue, setProgressValue] = useState(0);
  const [questionList, setQuestionList] = useState([])

  useEffect(()=>{
    if(getPollQuestionData?.data){
      setQuestionList(getPollQuestionData?.data)
    }
  },[getPollQuestionData])



  // store answer 
  useEffect(()=>{
    const storeAnswer = []
    if(values?.storeQuestionResponse && (getPollQuestionData?.data && getPollQuestionData?.data?.length>0)){
      getPollQuestionData?.data.forEach(question => {
        if(question.id === values?.storeQuestionResponse?.id){
          if(question?.answers && question?.answers?.length>0){
            storeAnswer.push(question?.answers)
          }
        }
      });
    }
    setValue('storeAnswerResponse', storeAnswer)
  },[getPollQuestionData])
  // update default date and time
  useEffect(()=>{
    var date = new Date()
    var dateString = new Date()
      .toISOString()
      .split("T")[0];
    var getHour = date.getHours();
    var getMinutes = date.getMinutes();
    var timeString = `${getHour}:${getMinutes}:00`;
    var joinDate = dateString.concat(" ", timeString);
    setSelectedDate(joinDate);
    setEndDate(joinDate)
  },[])

  //  start date and time handle
  const handleDateChange = (date) => {
    var dateString = new Date(date - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    var getHour = date.getHours();
    var getMinutes = date.getMinutes();
    var timeString = `${getHour}:${getMinutes}:00`;
    var joinDate = dateString.concat(" ", timeString);
    setSelectedDate(joinDate);
  };

  //  start date and time handle
  const handleEndDateChange = (date) => {
    var dateString = new Date(date - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    var getHour = date.getHours();
    var getMinutes = date.getMinutes();
    var timeString = `${getHour}:${getMinutes}:00`;
    var joinDate = dateString.concat(" ", timeString);
    setEndDate(joinDate);
  };

// const handle create event
  const handleCreatePoll = async() => {
    setLoaderShow("");
    let formData = new FormData();
    formData.append("microsite_id", msDetails.id);
    formData.append("name", values.title);
    formData.append("description", values.details);
    formData.append("start_date", selectedDate);
    formData.append("end_date", endDate);

    if(values?.symtoms !==''){
      formData.append("keywords", values?.symtoms.join(','));
    }

    if(values?.pollStatus ===true){
      formData.append("status", 1);
    }else{
      formData.append("status", 0);
    }

    // if (fileList.length > 0) {
    //   fileList.forEach((file) => {
    //     formData.append("files[]", file.originFileObj);
    //   });
    // }
    if (values?.banner !==null) {
      formData.append("files[]", values?.banner);
    }


    var url = `${baseUrl}/api/community/pool`
    if(values?.storePollResponse !==null){
      url = `${baseUrl}/api/community/pool/${values?.storePollResponse.uuid}`
    }

    const response = await storePoll({
      token,
      formData,
      url,
      setProgressValue,
    });
    if (response?.data) {
      setValue('storePollResponse', response?.data?.pool)
      if(values?.storePollResponse !==null){
        setLoaderShow("none");
      }else{
        handleQuestion(response?.data?.pool?.id,null)
      }
    }else{
      notifyError('Something went wrong', 5000)
      setLoaderShow("none");
    }
}
var random = Math.floor(1000 + Math.random() * 9000);
// handleQuestion
 const handleQuestion = async (pollId,uuid,text) =>{
  setLoaderShow("");
  let formData = new FormData();
  formData.append("poll_id", pollId);
  if(uuid !==null){
    formData.append("question_text", text);
  }else{
    formData.append("question_text", `${values.questionTitle} ${random}`);
  }

  if(values?.questionStatus === true){
    formData.append("status", 1);
  }else{
    formData.append("status", 0);
  }

  if (questionFileList.length > 0) {
    questionFileList.forEach((file) => {
      formData.append("files[]", file.originFileObj);
    });
  }

  var url = `${baseUrl}/api/community/questions`
  if(uuid !==null){
    url = `${baseUrl}/api/community/questions/${uuid}`
  }

  const response = await storePollQuestion({
    token,
    formData,
    url,
    setProgressValue,
  });
  if (response?.data) {
    if(uuid !==null){
      setLoaderShow("none");
    }else{
      for (let i = 1; i < 4; i++) {
        await handleAnswer(response?.data?.questions.id,i,null)
      }
    }
  }
  
}

// handle Answer
const handleAnswer = async(questionId,index,uuid,updatedText) =>{
  setLoaderShow("");
  var  url = `${baseUrl}/api/community/poolanswer`
  if(uuid !==null){
    url = `${baseUrl}/api/community/poolanswer/${uuid}`
  }

  var answerText = values?.answerTitle
  if(index !==null){
    if(index ===1){answerText = 'Yes'}
    if(index ===2){answerText = 'No'}
    if(index ===3){answerText = 'No Comment'}
  }

  let formData = new FormData();
  formData.append("question_id", questionId);
  if(uuid !==null){
    formData.append("answer_text", updatedText);
  }else{
    formData.append("answer_text", answerText);
  }
  if(values?.answerStatus === true){
    formData.append("status", 1);
  }else{
    formData.append("status", 0);
  }

  if (answerFileList.length > 0) {
    answerFileList.forEach((file) => {
      formData.append("files[]", file.originFileObj);
    });
  }

  const response = await storePollAnswer({
    token,
    formData,
    url,
    setProgressValue,
  });
  if (response?.data) {
    if(index && index>2){
      setLoaderShow("none");
    }else{
      setLoaderShow("none");
    }
  }
} 
// handle delete question
  const handleDeleteQuestion = (uuid) => {
    Swal.fire({
      heightAuto: false,
      backdrop: false,
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePollQuestion({token,uuid})
        .unwrap()
        .then((response) => {
          
        })
        .catch((error) => {});
      }
    });
  };

  return (
    <Fragment>
      <Box display={loadershow}>
        <MainLoader />
      </Box>
    
      <div className="event_create">
        <Box>
            <h4>Create Poll</h4>
            <div className="event_form">
              <Grid container spacing={2}>
                
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <div className="input_field">
                          <input
                            type="text"
                            className="form_cotrol"
                            placeholder="Title(*)"
                            onChange={(e) => setValue("title", e.target.value)}
                            value={values.title}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          options={[]}
                          getOptionLabel={(option) => ""}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setValue("symtoms", newValue);
                            }
                          }}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              label="Sign/Symptom/Keyword/Search word"
                              placeholder="keywords..."
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div className="input_field">
                          <input
                            type="text"
                            className="form_cotrol"
                            placeholder="Specialty"
                            onChange={(e) => setValue("details", e.target.value)}
                            value={values.details}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="input_field">
                          <input
                            type="text"
                            className="form_cotrol"
                            placeholder="Recommended Reading Url"
                            onChange={(e) => setValue("recommendUrl", e.target.value)}
                            value={values.recommendUrl}
                          />
                        </div>
                      </Grid>
                  </Grid>
                </Grid>

              {/* upload image */}
                <Grid item xs={6}>
                  <Box className="Event_files_container">
                    <Card sx={{ minHeight: 225 }}>
                          <Box className="bannerImg_complete" >
                          {values.banner !==null ? <img src={URL.createObjectURL(values.banner)} alt="" />: companyBanner? <img src={`${baseUrl}/${companyBanner}`} width='100px' alt="" />:<img src={bannerDefault} width='100px' alt="" /> }
                              <div>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center"
                                    }}
                                  >
                                    <UploadBanner
                                      key={'banner'}
                                      getUploadedFile={(image) => {
                                        setOpen2(true);
                                        setImage2(image);
                                      }}
                                    />
                                    <PopupBanner
                                      open={open2}
                                      handleClose={handleClose2}
                                      image={image2}
                                      setValue={setValue}
                                      getCroppedFile={(image) => {
                                        setImage2(image);
                                        handleClose2();
                                      }}
                                    />
                                    
                                  </Box>
                                </div>
                          </Box>
                        </Card>
                  </Box>
                </Grid>
                

              {/* Start date and time  */}
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      InputProps={{ readOnly: true }}
                      focused
                      margin="normal"
                      id="date-picker-dialog"
                      label="Start Date"
                      format="y-MM-dd"
                      is24Hour
                      //  format="yyyy/MM/dd"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      InputProps={{ readOnly: true }}
                      focused
                      margin="normal"
                      id="time-picker"
                      label="Start Time"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              {/* End date and time  */}
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      InputProps={{ readOnly: true }}
                      focused
                      margin="normal"
                      id="date-picker-dialog"
                      label="End Date"
                      format="y-MM-dd"
                      is24Hour
                      //  format="yyyy/MM/dd"
                      value={endDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      InputProps={{ readOnly: true }}
                      focused
                      margin="normal"
                      id="time-picker"
                      label="End Time"
                      value={endDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>

              {/* Question and answer part */}
              <QuestionAnswerForm 
                questionList={questionList}
                setQuestionList={setQuestionList} 
                pollId={values?.storePollResponse?.id}
                handleQuestion={handleQuestion}
                handleDeleteQuestion={handleDeleteQuestion}
                handleAnswer={handleAnswer}
               />

              <Box sx={{mt:4}} display={'flex'} justifyContent={'center'}>
                <Button className={values?.title !==''?'MuiBtn_create':'MuiBtn_create_disabled' } sx={{p:1,color:'primary',textTransform:'capitalize'}} onClick={(e) => handleCreatePoll()} variant='contained' disabled={values?.title !==''?false:true} >{values?.storePollResponse !==null?'Update':'Create'}</Button>
                 {values?.storePollResponse !==null && <Button onClick={(e)=> navigate('/poll')} className="MuiBtn_create" sx={{ml:2,p:1,color:'primary',textTransform:'capitalize'}}>Finish</Button>}
              </Box>
              
            </div>
        </Box>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default PollEditForm;
