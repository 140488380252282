import { Avatar, Button, Menu, alpha, styled } from "@mui/material";
import React, { useContext, useState } from "react";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { baseUrl } from "../../../api/Api";
import LazyImage from "../../LazyImage";
import ReadMoreComponent from "../../ReadMoreComponent/ReadMoreComponent";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../utils/UserContext";
import { useDeleteBroadcastItemMutation, useStoreBroadcastCategoryMutation, useStoreBroadcastItemMutation } from "../../../api/slices/braodcastApiSlice";
import Swal from "sweetalert2";
import noImage from '../../../asset/image/noImage.jpg'
import { useDeletePollMutation } from "../../../api/slices/pollApiSlice";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-data": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      }
    },
  },
}));

const LookBackItem = ({data,segName,isDateTimeExpired}) => {
  const navigate = useNavigate() 
  const {userDetails,msDetails, loggedInUser} = useContext(UserContext)
  const [progressValue,setProgressValue] = useState(0)
  const [anchorElReport, setAnchorElReport] = useState(null);
  const openReport = Boolean(anchorElReport);
  const handleClickReport = (event) => {
    setAnchorElReport(event.currentTarget);
  };
  const handleCloseReport = () => {
    setAnchorElReport(null);
  };

  const options = { year: "numeric", month: "short", day: "numeric" };
  const date = data?.created_at?new Date(data?.created_at).toLocaleDateString("en-US", options):''

  const bg = (data?.status ===1 && !isDateTimeExpired)?'#124076':'#d50808'

  const token = localStorage.getItem('token');
  const [storeBroadcastItem] = useStoreBroadcastItemMutation()
  const [storeBroadcastCategory] = useStoreBroadcastCategoryMutation()
  const [deletePoll] = useDeletePollMutation()
  const handleDeleteRow = (uuid) => {
   Swal.fire({
     title: "Are you sure?",
     text: "You won't be able to revert this!",
     icon: "warning",
     showCancelButton: true,
     confirmButtonColor: "#3085d6",
     cancelButtonColor: "#d33",
     confirmButtonText: "Yes, delete it!",
   }).then((result) => {
     if (result.isConfirmed) {
      deletePoll({token,uuid})
     }
   });
 };


   // handle details item
   const handleSinglItem = (data)=>{
    navigate(`/poll/${data?.uuid}`, {state:{pollDetails:data}})
  }
// handle edit item
  const handleEditItem = (data)=>{
    navigate(`/poll-update/${data?.uuid}`, {state:{pollDetails:data}})
 }
  
  var username = data?.microsite_userdetails?.user_details? JSON.parse(data?.microsite_userdetails?.user_details)?.name?JSON.parse(data?.microsite_userdetails?.user_details)?.name:data?.user?.name:data?.user?.name


  return (
    <>
      <div className="lookBack-item cursorPointer" >
        <div className="lookBack-item-img" onClick={(e)=>handleSinglItem(data)}>
          {data?.media && data?.media.length>0 &&
            <LazyImage src={`${baseUrl}/storage/media/${data?.media[0].id}/${data?.media[0].file_name}`} alt={data?.name} />
          }
          {data?.media && data?.media.length===0 &&
            <LazyImage src={noImage} alt={data?.name} />
          }
        </div>
        <div className="lookBack-item-content">
          <h4 onClick={(e)=>handleSinglItem(data)}>{data?.name}</h4>
          <div className="user-info">
            <Box className="user-details"
             onClick={(e)=> userDetails?.id===data?.user?.id
               ?navigate('/community-profile',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user,showInfo:true}})
               :navigate('/community-profile/other',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user}})
             }
            >
              <Avatar
                alt={data?.title}
                src={`${baseUrl}/${data?.user?.avatar}`}
                sx={{ maxWidth: "26px", maxHeight: "26px" }}
              />
              <div className="user-name">{username}</div>
            </Box>

            <Box sx={{ mt: 1 }}>
              <Button
                id="demo-customized-button-2"
                aria-controls={openReport ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openReport ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClickReport}
                endIcon={<KeyboardArrowDownIcon />}
                size="small"
                sx={{ color: "#fff !important", backgroundColor:bg}}
              >

                {data?.status ===1 && !isDateTimeExpired && 'Active'}
                {isDateTimeExpired && 'Expired'}

              </Button>
              <StyledMenu
                id="demo-customized-menu-2"
                MenudataProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorElReport}
                open={openReport}
                onClose={handleCloseReport}
              >
                {data?.user_id === userDetails?.id &&  <MenuItem onClick={(e)=>{handleEditItem(data);handleCloseReport(e)}} disableRipple>
                  Edit
                </MenuItem>}
                {data?.user_id === userDetails?.id &&  <MenuItem onClick={(e)=>{handleDeleteRow(data?.uuid);handleCloseReport(e)}} disableRipple>
                  Delete
                </MenuItem>}
               
              </StyledMenu>
            </Box>
          </div>
          {data?.description && <ReadMoreComponent text={data.description} maxLength={150} parse={true} />} 
          <div className="overView">
            {/* <span> 7.2M views </span> */}
            <span> {date} </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LookBackItem;
