import React, { useContext, useState } from "react";
import LookBackItem from "./LookBackItem";
import { Box, Button } from "@mui/material";
import { UserContext } from "../../../utils/UserContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const LookBackList = ({item,tab,loading}) => {
  const {userDetails} = useContext(UserContext)
  const location = useLocation()
  const getUrl = window.location.href;
  const segName = getUrl.split("/").pop();
  var lists = []

  // Function to check if a given date and time have expired
  function isExpired(dateTimeString) {
    // Parse the date string into a Date object
    const dateTime = new Date(dateTimeString);
    
    // Get the current date and time
    const now = new Date();
    
    // Compare the two dates
    return dateTime < now; // Returns true if the given date and time have expired
  }


  if(item?.data && item.data.length>0){
    item?.data.forEach(element => {

      const dateTimeString = element?.end_date.split(' ').join('T');
      const isDateTimeExpired = isExpired(dateTimeString);

      if(tab ==='all'){
        lists?.push(element)
      }
      if(tab === 'active' && element?.status ===1 && !isDateTimeExpired){
        lists?.push(element)
      }
      if(tab === 'expired' && (element?.status ===0 || isDateTimeExpired)){
        lists?.push(element)
      }
    });

  }

  
  return (
    <>
      <div className="lookBack-list">

        {!loading && lists?.length ===0 &&
          <Box sx={{mb:10}} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Button disabled={true}>No items found...</Button>
          </Box>
        }


        {!loading &&  lists?.length>0 && lists.map((list,i)=>{
            const dateTimeString = list?.end_date.split(' ').join('T');
            const isDateTimeExpired = isExpired(dateTimeString);
          return(
            <LookBackItem
              key={list?.uuid}
              data ={list}
              segName={segName}
              isDateTimeExpired={isDateTimeExpired}
            />
          )
        })}
      </div>
    </>
  );
};

export default LookBackList;
