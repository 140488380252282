import { Grid, TextField } from "@mui/material";
import React from "react";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import logo1 from '../../../asset/image/llo.jpg';



function RewardWallet() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="page-container">
        <div className="wallet-card">
          <div className="card-shape-1"></div>
          <div className="card-shape-2"></div>
          <div className="card-shape-3"></div>
          <div className="wallet-card-top">
            <h2>WALLET</h2>

            <div className="main-balance">
              <span>Balance </span> 1098 $
            </div>

            <div className="wallet-number">#44 200 111 3456 7777</div>
          </div>
          <div className="wallet-card-bottom">
            <h5> Fahim Ahmed </h5>
            <h5> ********** </h5>
          </div>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <div className="wallet-history"> 
              <div className="history-top">
                <h5> Wallet Statement </h5>
              </div>

              <div className="transation-container">
                <div className="transation-item">
                  <div className="item-left-content">
                    <h5>Purchase</h5>
                    <h6>10 Aug 2024</h6>
                  </div>
                  <div className="item-right-content">
                    <h5 className="t-out">
                      $ 700 <NorthEastIcon />
                    </h5>
                    <h6>Id: #B8332224 </h6>
                  </div>
                </div>
                <div className="transation-item">
                  <div className="item-left-content">
                    <h5>Poll Reward</h5>
                    <h6>10 Aug 2024</h6>
                  </div>
                  <div className="item-right-content">
                    <h5 className="t-in">
                      $ 500 <SouthWestIcon />
                    </h5>
                    <h6>Id: #B8332224 </h6>
                  </div>
                </div>
                <div className="transation-item">
                  <div className="item-left-content">
                    <h5>Poll Reward</h5>
                    <h6>10 Aug 2024</h6>
                  </div>
                  <div className="item-right-content">
                    <h5 className="t-in">
                      $ 900 <SouthWestIcon />
                    </h5>
                    <h6>Id: #B8332224 </h6>
                  </div>
                </div>
                <div className="transation-item">
                  <div className="item-left-content">
                    <h5>Send Money</h5>
                    <h6>10 Aug 2024</h6>
                  </div>
                  <div className="item-right-content">
                    <h5 className="t-out">
                      $ 1200 <NorthEastIcon />
                    </h5>
                    <h6>Id: #B8332224 </h6>
                  </div>
                </div>
                <div className="transation-item">
                  <div className="item-left-content">
                    <h5>Poll Reward</h5>
                    <h6>10 Aug 2024</h6>
                  </div>
                  <div className="item-right-content">
                    <h5 className="t-in">
                      $ 50 <SouthWestIcon />
                    </h5>
                    <h6>Id: #B8332224 </h6>
                  </div>
                </div>
                <div className="transation-item">
                  <div className="item-left-content">
                    <h5>Poll Reward</h5>
                    <h6>10 Aug 2024</h6>
                  </div>
                  <div className="item-right-content">
                    <h5 className="t-in">
                      $ 355 <SouthWestIcon />
                    </h5>
                    <h6>Id: #B8332224 </h6>
                  </div>
                </div>
                <div className="transation-item">
                  <div className="item-left-content">
                    <h5>Purchase</h5>
                    <h6>10 Aug 2024</h6>
                  </div>
                  <div className="item-right-content">
                    <h5 className="t-out">
                      $ 600 <NorthEastIcon />
                    </h5>
                    <h6>Id: #B8332224 </h6>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <div className="wallet-redeem-card">
              <h4>Wallet Redeem</h4>
              <div className="redeem-btn" onClick={handleClickOpen}>
                Redeem
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* Dialouge Box*/}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Wallet Redeem"}
        </DialogTitle>
        <DialogContent>
          <div className="redeem-dialouge-content">
            <TextField label="Your Amount" type="number" variant="filled" fullWidth  focused />

            <h4>Select Compnay</h4>
            <div className="redeem-card-container">
               <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className="redeem-card active-redeem-card">
                          <div className="card-logo">
                               <img src={logo1} alt="" />
                          </div>
                          <div className="card-content">
                            <h5>Company Name</h5>
                            <p>login to your account and visit Redeem a Steam.</p>
                          </div>
                      </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className="redeem-card">
                          <div className="card-logo">
                               <img src={logo1} alt="" />
                          </div>
                          <div className="card-content">
                            <h5>Company Name</h5>
                            <p>login to your  Redeem a Steam.</p>
                          </div>
                      </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className="redeem-card">
                          <div className="card-logo">
                               <img src={logo1} alt="" />
                          </div>
                          <div className="card-content">
                            <h5>Company Name</h5>
                            <p>To your account a Steam.</p>
                          </div>
                      </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className="redeem-card">
                          <div className="card-logo">
                               <img src={logo1} alt="" />
                          </div>
                          <div className="card-content">
                            <h5>Company Name</h5>
                            <p>Account and visit Redeem a Steam.</p>
                          </div>
                      </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className="redeem-card">
                          <div className="card-logo">
                               <img src={logo1} alt="" />
                          </div>
                          <div className="card-content">
                            <h5>Company Name</h5>
                            <p>Your account and visit Redeem a Steam.</p>
                          </div>
                      </div>
                  </Grid>
               </Grid>

               <div className="redeem-btn">
                Continue
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default RewardWallet;