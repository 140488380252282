import { Box, Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Radio, RadioGroup, FormControlLabel, Button, Typography } from '@mui/material';
import StorageProgress from '../../storageProgress/StorageProgress';
import { baseUrl } from '../../../api/Api';
import { Image } from 'antd';
import { useStorePollParticipantsMutation } from '../../../api/slices/pollApiSlice';
import { UserContext } from '../../../utils/UserContext';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { AddBoxSharp } from '@mui/icons-material';
import { notifyError } from '../../../utils/Toast';
import { ToastContainer } from 'react-toastify';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const PollQnA = ({pollDetails,data,loading}) => {
  const navigate = useNavigate()
  const {userDetails, msDetails } = useContext(UserContext)
  const [selectedOption, setSelectedOption] = useState('');
  const [totalParticipants, setTotalParticipants] = useState(0);
  const[progressValue, setProgressValue] = useState(0)
  const [progress, setProgress] = useState(50);
  const token = localStorage.getItem("token");
  
  const [storePollAnswer, setStorePollAnswer] = useState({})


  const [storeCurrentPoll , setStoreCurrentPoll] = useState()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [storePollParticipants] = useStorePollParticipantsMutation()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (answer) => {
    setOpen(true);
    setStorePollAnswer(answer)
  };
  const handleClose = () => {
    setOpen(false);
    setStorePollAnswer({})
  };

  useEffect(()=>{
    if(data && data?.length>0){
      setStoreCurrentPoll(data[0])
      setCurrentIndex(0)

      if(sessionStorage.getItem('selectedAnswer')){
        // const selectedAnswer = JSON.parse(sessionStorage.getItem('selectedAnswer')) 
        // const poll = JSON.parse(sessionStorage.getItem('poll')) 
        const selectedAnswer = JSON.parse(sessionStorage.getItem('selectedAnswer')) 
        const poll = JSON.parse(sessionStorage.getItem('poll')) 
        if(poll?.answers.length>0){
          poll?.answers.forEach(answer => {
            if(answer?.id === selectedAnswer.id){
              setSelectedOption(answer)
            }
          });
        }
      }
    }
  },[data])

  const handleOptionChange =async (event, option) => {
    setSelectedOption(option)
    var  url = `${baseUrl}/api/community/participants`
    let formData = new FormData();
    formData.append("microsite_id", msDetails.id);
    formData.append("answer_id", option.id);
    formData.append("user_id", userDetails.id);

    var participentFind = false
    if(option?.participants && option?.participants.length>0){
      option?.participants.forEach(participent => {
        if(participent?.user_id === userDetails?.id){
          participentFind = true
        }
      });
    }
    if(participentFind ===true){
      notifyError('Already Selected By You', 3000)
    }else{
      const response = await storePollParticipants({
        token,
        formData,
        url,
        setProgressValue,
      });
      if (response?.data) {
        sessionStorage.setItem('selectedAnswer',JSON.stringify(option))
        sessionStorage.setItem('poll', JSON.stringify(storeCurrentPoll))
        setSelectedOption(option)
      }
    }
  };

  // const handle next previous button
  const  handleQuestionButton = (type)=>{
    if(type ==='next'){
        setStoreCurrentPoll(data[currentIndex+1])
        setCurrentIndex(currentIndex+1)
    }
    if(type ==='pre'){
      setStoreCurrentPoll(data[currentIndex-1])
      setCurrentIndex(currentIndex-1)
    }
  }

// handle poll review result navigate
 const handlePollReview = (data)=>{
  navigate(`/poll-review/${data?.uuid}`, {state:{pollDetails:data}})
  }

  const handleSubmit = () => {
    setTotalParticipants((prevTotal) => prevTotal + 1);
    setProgress((prevProgress) => prevProgress + 1);
  };

  return (
    <Box>
      {storeCurrentPoll?.question_text &&
      <p className='pollQuestionShow'>{storeCurrentPoll?.question_text}</p>}  
      <Box sx={{mt:3}}>
        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
        <div className='poll_options_main'>
         {storeCurrentPoll?.answers && storeCurrentPoll?.answers.length>0 && storeCurrentPoll?.answers.map((answer, key)=>{
          var color = ''
          var classType = ''
          var participents = 0
          if(answer?.participants.length>0){
            participents = answer?.participants.length
          }
          if(answer.answer_text.toLowerCase()==='yes'){
            classType = 'green'
            color = '#0AA936'
          }else if(answer.answer_text.toLowerCase()==='no'){
            classType = 'red'
            color = '#DC0000'
          }else{
            classType = 'grey'
             color = '#989898'
          }
          return(
            <div className={classType} key={answer.uuid}>
              <div className='left'>
                {selectedOption?.id !== answer?.id && <FormControlLabel sx={{ml: '12px !important', mr:'0px !important'}} value={answer} control={<Radio />} />}
                {selectedOption?.id === answer.id && <FormControlLabel sx={{ml: '12px !important', mr:'0px !important'}} className='notAllowed' disabled value={answer} control={<RadioButtonCheckedIcon sx={{ml:2}} />} /> }
              </div>
              <Box className={'right'} >
                <Typography className={selectedOption?.id === answer.id?`notAllowed`:''} onClick={(e)=> handleOptionChange(e,answer)} variant="body1">{answer.answer_text}</Typography>
                {/* <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Box className={selectedOption?.id === answer.id?`notAllowed`:''} onClick={(e)=> handleOptionChange(e,answer)}>
                    {participents>0 
                      ? <Typography variant="body1">{participents} participants</Typography>
                      : <Typography variant="body1">No participant</Typography>
                    }
                  </Box>
                  {participents >0 &&
                     <Box>
                      <AvatarGroup max={participents} onClick={(e)=>handleClickOpen(answer)}>
                        {answer?.participants && answer?.participants.map((participent, key)=>{
                          const userDetails = JSON.parse(participent?.microsite_userdetails?.user_details)
                          return(
                            <Avatar key={participent.id} sx={{ width: 24, height: 24 }}  alt={userDetails?.name} src={`${baseUrl}/${userDetails?.avater}`} />
                          )
                        })}
                      </AvatarGroup>
                   </Box>
                  }
                </Box> */}
                
              </Box>
            </div>
          )
         })}
        </div>
        </RadioGroup>
        {storeCurrentPoll?.answers.length>0 && 
          <Box sx={{mt:2, mb:2}} display={'flex'} justifyContent={'center'}  >
              {currentIndex ===0 && currentIndex+1<data?.length && <Button sx={{minWidth:'360px', borderRadius:'20px'}} style={{ border: '2px solid #000' }}  variant="outlined" color="primary" onClick={(e)=> handleQuestionButton('next')} >Next Question</Button>}
              {(currentIndex !==0 && currentIndex>0) && 
              <>
                <Button sx={{borderRadius:'20px'}} style={{ border: '2px solid #000' }}   variant="outlined" color="primary" onClick={(e)=> handleQuestionButton('pre')}>Previous Question</Button>
                {currentIndex+1<data?.length && <Button  sx={{ml:1, borderRadius:'20px'}} style={{ border: '2px solid #000' }} variant="outlined" color="primary" onClick={(e)=> handleQuestionButton('next')}>Next Question</Button>}
              </>
              }
              {(data?.length === currentIndex+1 )&& <Button sx={{borderRadius:'20px', ml:2}} style={{ border: '2px solid #000' }}   variant="outlined" color="primary" onClick={(e)=> handlePollReview(pollDetails)}>Submit</Button>}
              
          </Box>
        }
      </Box>


        {/* particepants dialog modal */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth={true}
          maxWidth={'md'}
        >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
         <Box sx={{textTransform:'capitalize'}}>Participants for  {storePollAnswer?.answer_text} ({storePollAnswer?.participants?.length})</Box> 
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {storePollAnswer?.participants && storePollAnswer?.participants?.length>0 && storePollAnswer?.participants.map((participent)=>{
             const userDetails = JSON.parse(participent?.microsite_userdetails?.user_details)
            return(
              <Box key={participent?.id} sx={{mb:2}} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Avatar sx={{ width: 34, height: 34 }} alt={userDetails?.name} src={`${baseUrl}/${userDetails?.avater}`} />
                  <Box sx={{ml:1}}>{userDetails?.name}</Box>
                </Box>
                <Box>
                  Participated At: {new Date(participent?.created_at).toLocaleDateString()}
                </Box>
              </Box>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='contained'>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>



      <ToastContainer />
    </Box>
  )
}

export default PollQnA