import React, { Fragment, useEffect, useState } from "react";
import ChatBody from "../components/Chat/ChatBody";
import { Grid } from "@mui/material";
import axios from "axios";
import { chatRoomUrl } from "../api/Api";
import { io } from "socket.io-client";
import Pusher from "pusher-js";
import { useGetPostsQuery } from "../api/slices/userSlice";
import MobileChatBody from "../components/Chat/MobileChatBody";

const socket = io("https://signal.icircles.app:9001");
// const socket = io('https://icircles.app');
// const socket = io('wss://signal.icircles.app:9001/socket.io');

const ChatPage = () => {
  const [chatRooms, setChatRooms] = useState([]);
  const [ismobile, setIsMobile] = useState(false)
  const token = localStorage.getItem("token");



  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // get all chat rooms
  const getAllChatRooms = () => {
    let config = {
      method: "get",
      url: chatRoomUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setChatRooms(response.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getAllChatRooms();
  }, []);

  // socket
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [userStatus, setuserStatus] = useState();

  // useEffect(() => {
  //   // Listen for online/offline status updates
  //   socket.on((data) => {
  //     setOnlineUsers(data.onlineUsers);
  //     // console.log('socket data', data)
  //   });

  //   // client-side
  // socket.on("connect", (data) => {
  //   // console.log(socket.id); // x8WIv7-mJelg7on_ALbx
  //   setuserStatus(socket.id);
  //   // console.log('user-status', data)
  // });

  //   // Listen for incoming notifications
  //   socket.on('notification', (data) => {
  //     setNotifications((prevNotifications) => [...prevNotifications, data]);
  //   });

  //   // Clean up the socket connection on unmount
  //   // return () => {
  //   //   socket.disconnect();
  //   // };
  // }, []);

  // console.log('onlineUsers', onlineUsers)
  // console.log('notifications', notifications)

  // console.log('socket info', socket)

  //   useEffect(() => {
  //     var pusher = new Pusher("69ef518953032858d64d", {
  //       cluster: "ap1",
  //       encrypted: true,
  //     });
  //     var channel = pusher.subscribe("notifyChannel");
  //      channel.bind("notifyChannel", async function (response) {
  //          alert('some notification');
  //         //  setContent(JSON.stringify(response));
  //      })
  // });


  useEffect(()=>{
   // Get the viewport width and height
      var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    // Check if the viewport width is below a certain threshold to identify mobile devices
    if (viewportWidth < 768) {
        // console.log("Mobile device detected!");
        setIsMobile(true)
    } else {
        // console.log("Not a mobile device.");
        setIsMobile(false)
    }
  },[ismobile])

  // console.log('isMobile', ismobile)



  

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={3} md={12} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={12} sm={12} xs={12}>
          <div className="content_body chat_contentBody">
            {ismobile ?
              <MobileChatBody 
                userStatus={userStatus}
                chatRooms={chatRooms}
                setChatRooms={setChatRooms}
                getAllChatRooms={getAllChatRooms}
              />
              :
                <ChatBody
                userStatus={userStatus}
                chatRooms={chatRooms}
                setChatRooms={setChatRooms}
                getAllChatRooms={getAllChatRooms}
              />
            }
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ChatPage;
