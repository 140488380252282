import { Box, Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Radio, RadioGroup, FormControlLabel, Button, Typography } from '@mui/material';
import StorageProgress from '../../storageProgress/StorageProgress';
import { baseUrl } from '../../../api/Api';
import { Image } from 'antd';
import { useStorePollParticipantsMutation } from '../../../api/slices/pollApiSlice';
import { UserContext } from '../../../utils/UserContext';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { AddBoxSharp } from '@mui/icons-material';
import { notifyError } from '../../../utils/Toast';
import { ToastContainer } from 'react-toastify';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const PollReviewQnA = ({pollDetails,data,loading}) => {
  const {userDetails, msDetails } = useContext(UserContext)
  const [selectedOption, setSelectedOption] = useState('');
  const [totalParticipants, setTotalParticipants] = useState(0);
  const[progressValue, setProgressValue] = useState(0)
  const [progress, setProgress] = useState(50);
  const token = localStorage.getItem("token");
  
  const [storePollAnswer, setStorePollAnswer] = useState({})


  const [storeCurrentPoll , setStoreCurrentPoll] = useState()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [storePollParticipants] = useStorePollParticipantsMutation()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (answer) => {
    setOpen(true);
    setStorePollAnswer(answer)
  };
  const handleClose = () => {
    setOpen(false);
    setStorePollAnswer({})
  };

  useEffect(()=>{
    if(data && data?.length>0){
      setStoreCurrentPoll(data[0])
      setCurrentIndex(0)

      if(sessionStorage.getItem('selectedAnswer')){
        // const selectedAnswer = JSON.parse(sessionStorage.getItem('selectedAnswer')) 
        // const poll = JSON.parse(sessionStorage.getItem('poll')) 
        const selectedAnswer = JSON.parse(sessionStorage.getItem('selectedAnswer')) 
        const poll = JSON.parse(sessionStorage.getItem('poll')) 
        if(poll?.answers.length>0){
          poll?.answers.forEach(answer => {
            if(answer?.id === selectedAnswer.id){
              setSelectedOption(answer)
            }
          });
        }
      }
    }
  },[data])

  const handleOptionChange =async (event, option) => {
    setSelectedOption(option)
    var  url = `${baseUrl}/api/community/participants`
    let formData = new FormData();
    formData.append("microsite_id", msDetails.id);
    formData.append("answer_id", option.id);
    formData.append("user_id", userDetails.id);

    var participentFind = false
    if(option?.participants && option?.participants.length>0){
      option?.participants.forEach(participent => {
        if(participent?.user_id === userDetails?.id){
          participentFind = true
        }
      });
    }
    if(participentFind ===true){
      notifyError('Already Selected By You', 3000)
    }else{
      const response = await storePollParticipants({
        token,
        formData,
        url,
        setProgressValue,
      });
      if (response?.data) {
        sessionStorage.setItem('selectedAnswer',JSON.stringify(option))
        sessionStorage.setItem('poll', JSON.stringify(storeCurrentPoll))
        setSelectedOption(option)
      }
    }
  };

  // const handle next previous button
  const  handleQuestionButton = (type)=>{
    if(type ==='next'){
        setStoreCurrentPoll(data[currentIndex+1])
        setCurrentIndex(currentIndex+1)
    }
    if(type ==='pre'){
      setStoreCurrentPoll(data[currentIndex-1])
      setCurrentIndex(currentIndex-1)
    }
  }

  const handleSubmit = () => {
    setTotalParticipants((prevTotal) => prevTotal + 1);
    setProgress((prevProgress) => prevProgress + 1);
  };

  return (
    <Box>
      {storeCurrentPoll?.question_text &&
      <p><span style={{padding: '20px'}}>{currentIndex+1} . </span>
        {storeCurrentPoll?.question_text} </p>}

      <Box>
        {storeCurrentPoll?.answers && storeCurrentPoll?.answers.length>0 && storeCurrentPoll?.answers.map((answer, key)=>{
          return(
            <Box key={key} sx={{maxWidth:'430px',mt:3,ml:5, backgroundColor: '#007DF2',color:'#fff', padding:'10px', borderRadius:'5px'}} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Box>{answer?.answer_text}</Box>
              <Box>20%</Box>
            </Box>
          )
        })}
      </Box>


      <Box >
        {storeCurrentPoll?.answers.length>0 && 
          <Box sx={{mt:2, mb:2 , mr:3}} display={'flex'} alignItems={'center'} justifyContent={'center'}  >
              {currentIndex ===0 && currentIndex+1<data?.length && <Button sx={{minWidth:'120px'}}   variant="contained" color="primary" onClick={(e)=> handleQuestionButton('next')} >Next</Button>}
              {(currentIndex !==0 && currentIndex>0) && 
                <>
                  <Button sx={{minWidth:'120px'}} variant="contained" color="primary" onClick={(e)=> handleQuestionButton('pre')}>Previous</Button>
                  {currentIndex+1<data?.length && <Button sx={{ml:1,minWidth:'120px'}} variant="contained" color="primary" onClick={(e)=> handleQuestionButton('next')}>Next</Button>}
                </>
              }
          </Box>
        }
      </Box>
      <ToastContainer />
    </Box>
  )
}

export default PollReviewQnA