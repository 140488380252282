import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";  
import {  addressUrl, baseUrl, categoryUrl, magazineMainUrl, reportUrl } from "../../api/Api";
import { notifyError, notifySuccess } from "../../utils/Toast";
import { useContext } from "react";
import { UserContext } from "../../utils/UserContext";
import { ThreeDotLoaderEffect } from "../PageLoadEffects";
import { Autocomplete, Box, Button, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useForm } from 'react-hook-form';
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import MainLoader from "../PageLoadEffects/MainLoader";
import SyncIcon from '@mui/icons-material/Sync';

const AddressCreate = ()=>{
  const navigate = useNavigate();
  const location = useLocation();
  const getUrl = window.location.href;
  const segName = getUrl.split("/").pop();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [userInfo, setUserInfo] = useState(null);

  const [storeImage, setStoreImage] = useState(null)

  //get user info
  useEffect(()=>{
    const getLoggedInuser = localStorage.getItem('loggedInUserInfo')
    const parseData = JSON.parse(getLoggedInuser)
    setUserInfo(parseData)
  },[])
  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });



  const {msDetails,userDetails} = useContext(UserContext);
  const [loaderVisible, setLoaderVisible] = useState(null);

  const [loaderEffect, setLoaderEffect] = useState(false)
  const token = localStorage.getItem('token');
  const defaultValues = {
    name:'',
    type:'',
    position:'',
    status:true,
    message:'',
  };

  const [parentValue, setParentValue] = useState(null);
  const [parentId, setParentId] = useState();
  const [parentTitle, setParentTitle] = useState(null);

  const methods = useForm({defaultValues});
  const {watch,setValue} = methods;
  const values = watch();

  const handleChangeStatus = (event) => {
    setValue('status', event.target.checked);
  };

  // get all parent categories
  const [allParentCategories, setAllParentCategories] = useState([]);
  const getAllCategories = () => {
    let config = {
      method: 'get',
      url: `${categoryUrl}/${msDetails.id}/all`,
    };

    const storeCat =[];
    axios.request(config)
    .then((response) => {
      if(response?.data?.data && response?.data?.data.length>0){
        response?.data?.data.forEach(element => {
          if(element?.parent_id ===null){
            storeCat.push(element)
          }
        });
      }
     setAllParentCategories(storeCat)
    })
    .catch((error) => {
    });
  };

  useEffect(() => {
    getAllCategories();
  }, []);



//Create new job
const handleMenuAdd = async () => {
  setLoaderVisible(true);
  let formData = new FormData(); //formdata object
  formData.append("microsite_id", msDetails.id);
  formData.append("address", values.name);
  if(values.status ===true){
    formData.append("status", 1);
  }else{
    formData.append("status", 0);
  }
  

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/form-data",
    },
  };

  var url;
  if(location?.state !==null){
    url = `${addressUrl}/${location?.state?.row?.uuid}`
  }else{
    url = `${addressUrl}`
  }

  axios
    .post(url, formData, config)
    .then((response) => {
      setLoaderVisible(false);
      navigate(-1)
      //notifySuccess();
    })
    .catch((err) => {
      notifyError();
    });
};


// get single category details
const getSingleCategoryDetails = (row)=>{
      setValue('name',row?.address)
      if(row.status ==='0'){
        setValue('status', false)
      }else{
        setValue('status', true)
      }
}

useEffect(()=>{
  if(location?.state !==null){
    getSingleCategoryDetails(location?.state?.row)
  }
},[])



 return(
    <Fragment>
         <div className="jobHome">
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box ><TextField label="Address" variant="filled" fullWidth  focused onChange={(e)=>setValue("name",e.target.value)} value={values.name} /></Box>
                    </Grid>
                  </Grid>

                  <Box sx={{ minWidth: 120, mt:2 }}>
                        <FormControl component="fieldset" variant="standard">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    id="myToggleStatus"
                                    label="Publish"
                                    labelPlacement="start"
                                    checked={values.status}
                                    onChange={handleChangeStatus}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                }
                                label="Publish"
                              />
                              </FormGroup>
                          </FormControl>
                      </Box>
                  

                      <Box sx={{  display: 'flex', justifyContent: 'center', alignItems: 'center',  flexDirection: 'column',    mt:5 }}>
                          {(values.name !=='')
                           ? <Button fullWidth variant="contained" onClick={(e)=>handleMenuAdd()}>{location?.state !==null?'Update':'Create'}</Button>
                           : <Button fullWidth variant="contained" disabled>{location?.state !==null?'Update':'Create'}</Button>
                          }
                      </Box>

                      

                    {loaderVisible === true && <MainLoader/>}
                    <div className="btn_loader">
                       {ThreeDotLoaderEffect(loaderEffect)}
                    </div>
               </div>
       <ToastContainer />
       <div className="update_btn">
             {(values.name !=='') 
             ?<Button  onClick={(e)=>handleMenuAdd()} variant="contained" ><SyncIcon /></Button>
            :<Button disabled variant="contained" ><SyncIcon /></Button>}
        </div>
</Fragment>
)
}

export default AddressCreate

