import React, { useContext, useState } from 'react'
import UploadEditForm from '../../components/Broadcast/BroadcastUpload/UploadEditForm'
import BroadcastTopNavigate from '../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate'
import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useGetBroadcastCategoryDataQuery, useGetSingleBroadcastItemDataQuery } from '../../api/slices/braodcastApiSlice'
import { UserContext } from '../../utils/UserContext'
import MainLoader from '../../components/PageLoadEffects/MainLoader'
import PollTopNavigate from '../../components/Poll/PollTopNavigate/PollTopNavigate'
import PollEditForm from '../../components/Poll/PollCreateAndUpdate/PollEditForm'
import { useGetSinglePollDataQuery } from '../../api/slices/pollApiSlice'

const PollEditFormPage =()=> {
  const params = useParams()
  const uuid = params.uuid

  const token = localStorage.getItem('token');
  const {data: getSinglePollData, isFetching: isFetching, isError:getDataItemError} = useGetSinglePollDataQuery({token,uuid});

  return (
    <>
     <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <div className="broadcast-body">
              <PollTopNavigate  />
              <PollEditForm pollDetails = {getSinglePollData} isFetching={isFetching} />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default PollEditFormPage