import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { TableRow, TableCell, Typography, MenuItem, Button, Box, Avatar } from "@mui/material";

import Iconify from "../../Iconify/Iconify";
import TableMoreMenu from "../TableMoreMenu";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { UserContext } from "../../../utils/UserContext";
import { baseUrl, reportUrl } from "../../../api/Api";
import { notifyError } from "../../../utils/Toast";
import axios from "axios";
// ----------------------------------------------------------------------

BroadcastCategoryTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function BroadcastCategoryTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  index
}) {
  const naviagte = useNavigate()

  const {userDetails, msDetails} = useContext(UserContext)
  const [loaderEffect, setLoaderEffect] = useState(false)
  const token = localStorage.getItem('token');


  return (
    <TableRow hover key={row?.uuid}>
       <TableCell sx={{ display: "flex", alignItems: "center" }}>
        {row?.thumb !==null ? (
          <Avatar alt={row?.title} src={`${baseUrl}/${row?.thumb}`} sx={{ mr: 2 }} />
        ) : (
          <Avatar alt={row?.title} src={''} sx={{ mr: 2 }} />
        )}
        {row?.title && 
          <Typography variant="subtitle2" noWrap>
            {row?.title}
          </Typography>
        }
      </TableCell>
      <TableCell>
        {row?.parent_id ===null 
          ?<Typography style={{ color: "#7d6be0" }}>Primary</Typography>
          :<Typography style={{ color: "#ffc612" }}>Secondary</Typography>
        }
      </TableCell>
      <TableCell>
        {row?.status ===1 && <Typography style={{ color: "#7d6be0" }}>Active</Typography>}
        {row?.status ===0 && <Typography style={{ color: "#f59432" }}>Deactive</Typography>}
      </TableCell>
      <TableCell>
        <Button sx={{color:'#7d6be0',outlineColor:'#7d6be0 !important'}} variant="outlined" onClick={(e)=> onEditRow()}>Edit</Button>
        <DeleteForeverIcon onClick={(e)=> onDeleteRow()} className="cursorPointer" sx={{ml:5}} style={{ color: "red" }} />

      </TableCell>
    </TableRow>
  );
}
