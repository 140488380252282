import React, { Fragment, useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { UserContext } from "../../utils/UserContext";
import { Box } from "@mui/material";


const EventFlipBook = () => { 
   const location = useLocation();
   const navigate = useNavigate();
   const token = localStorage.getItem('token');
   const {userDetails, msDetails} = useContext(UserContext)
  

  return (
    <Fragment>
        <Box className="event_details_body" sx={{p:3}}>
            flip book
        </Box>
    </Fragment>
  );
};

export default EventFlipBook;
