import React from "react";
import {
  Typography,
  Container,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Link,
  AppBar,
  Toolbar,
  Box,
} from "@mui/material";

const TermsAndServicePage = () => {
  return (
    <div>
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography variant="h2" gutterBottom>
            Welcome to Community Microsite!
          </Typography>
        </Box>

        <Typography variant="h4" gutterBottom>
          Community Microsite Terms of Service
        </Typography>

        <Typography paragraph>
          These Terms of Service ("Terms") govern your access and use of the
          Community Microsite mobile application ("App"), website (if
          applicable), and related services (collectively, the "Service"). By
          accessing or using the Service, you agree to be bound by these Terms.
          If you disagree with any part of the Terms, you may not access or use
          the Service.
        </Typography>

        {/* ... Add other components as needed ... */}

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          1. Eligibility
        </Typography>
        <Typography paragraph>
          You must be at least 13 years old to use the Service. By using the
          Service, you represent and warrant that you are at least 13 years old
          and of legal capacity to enter into a binding agreement.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          2. Your Account
        </Typography>
        <Typography paragraph>
          You may need to create an account to access certain features of the
          Service. You are responsible for maintaining the confidentiality of
          your account information, including your username and password. You
          are also responsible for all activity that occurs under your account.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          3. User Content
        </Typography>
        <Typography paragraph>
          The Service allows you to create, upload, and share content, including
          text, photos, videos, and links ("User Content"). You retain all
          ownership rights to your User Content. However, by submitting User
          Content to the Service, you grant Community Microsite a non-exclusive,
          royalty-free, worldwide license to use, reproduce, modify, publish,
          distribute, and translate your User Content for the purpose of
          operating and providing the Service.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          4. Prohibited Content and Conduct
        </Typography>
        <Typography paragraph>
          You agree not to use the Service to: Upload or share content that is
          illegal, harmful, threatening, abusive, harassing, defamatory,
          obscene, hateful, or racially or ethnically offensive. Violate the
          privacy or intellectual property rights of others. Engage in spam or
          commercial activities. Impersonate another person or entity. Interfere
          with or disrupt the Service. Use the Service for any unlawful purpose.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          5. Moderation
        </Typography>
        <Typography paragraph>
          Community Microsite reserves the right to remove or moderate any User
          Content that violates these Terms or that we deem inappropriate for
          any reason.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          6. Termination
        </Typography>
        <Typography paragraph>
          Community Microsite may terminate your access to the Service for any
          reason, at any time, without notice.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          7. Intellectual Property
        </Typography>
        <Typography paragraph>
          The Service and all content and materials included in the Service are
          protected by intellectual property rights. You agree not to remove,
          alter, or obscure any copyright, trademark, or other proprietary
          notices contained in the Service.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          8. Disclaimer
        </Typography>
        <Typography paragraph>
          The Service is provided "as is" and without warranty of any kind.
          Community Microsite disclaims all warranties, express or implied,
          including but not limited to, the warranties of merchantability,
          fitness for a particular purpose, and non-infringement.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          9. Limitation of Liability
        </Typography>
        <Typography paragraph>
          Community Microsite will not be liable for any damages arising out of
          or related to your use of the Service.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          10. Indemnification
        </Typography>
        <Typography paragraph>
          You agree to indemnify and hold harmless Community Microsite, its
          officers, directors, employees, agents, and licensors from and against
          any and all claims, losses, expenses, damages, and costs, including
          reasonable attorneys' fees, arising out of or relating to your use of
          the Service or your violation of these Terms.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          11. Governing Law
        </Typography>
        <Typography paragraph>
          These Terms will be governed by and construed in accordance with the
          laws of Bangladesh, without regard to its conflict of law provisions.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          12. Dispute Resolution
        </Typography>
        <Typography paragraph>
          Any dispute arising out of or relating to these Terms will be resolved
          by binding arbitration in accordance with the rules of the American
          Arbitration Association. The arbitration will be held in Dhaka,
          Bangladesh.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          13. Entire Agreement
        </Typography>
        <Typography paragraph>
          These Terms constitute the entire agreement between you and Community
          Microsite regarding your use of the Service.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          14. Severability
        </Typography>
        <Typography paragraph>
          If any provision of these Terms is held to be invalid or
          unenforceable, such provision shall be struck and the remaining
          provisions shall remain in full force and effect.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          15. Changes to the Terms
        </Typography>
        <Typography paragraph>
          Community Microsite may revise these Terms at any time by posting the
          revised Terms on the Service. You are expected to check this page
          frequently so you are aware of any changes, as they are binding on
          you. By continuing to use the Service after the revised Terms are
          posted, you agree to be bound by the revised Terms.
        </Typography>
        {/* ... Repeat the above structure for other sections ... */}

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          16. Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions about these Terms, please contact us at{" "}
          <Link href="mailto:info@icircles.app">info@icircles.app</Link>.
        </Typography>
        <Typography paragraph>
          Thank you for using Community Microsite!
        </Typography>
      </Container>
    </div>
  );
};

export default TermsAndServicePage;
