import { Autocomplete, Box, Button, Card, FormControlLabel, Grid, Stack, Switch, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import BroadcastTopNavigate from '../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate'
import { useForm } from 'react-hook-form';
import { Label } from '@mui/icons-material';
import SunEditor from 'suneditor-react';
import MultipleGlobalUploader from '../../components/Uploader/MultipleGlobalUploader';
import { Upload } from 'antd';
import { useDeleteBroadcastCategoryMediaMutation, useGetBroadcastCategoryDataQuery, useGetSingleBroadcastCategoryDataQuery, useStoreBroadcastCategoryMutation } from '../../api/slices/braodcastApiSlice';
import { UserContext } from '../../utils/UserContext';
import LoungeUploadProgress from '../../components/LoungeUploadProgress/LoungeUploadProgress';
import { baseUrl } from '../../api/Api';
import UploadLoader from '../../components/PageLoadEffects/UploadLoader';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import MainLoader from '../../components/PageLoadEffects/MainLoader';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
const CategoryCreatePage = () => {
    const defaultValues = {
        title: '',
        details:'',
        allCategory:[],
        useableCategory:[],
        parentCategory:null,
        featureImage:[],
        uploadedFeatureImage:null,
        featureImageShow:false,
        media:[],
        uploadedMedia:[],
        status:true,
        visible:false
      };
      const methods = useForm({defaultValues});
      const {watch,setValue} = methods;
      const values = watch();
      const navigate = useNavigate();
      const location = useLocation();
      const singleUuid = location?.state?.uuid

      const {msDetails,userDetails} = useContext(UserContext)
      const microsite_id = msDetails?.id;

      const token = localStorage.getItem('token');
      const [currentPage, setCurrentPage] = useState(1);
      const [lastPage, setLastPage] = useState(null);
      const [totalLength, setTotalLength] = useState(0)
      const [searchValue, setSearchValue] = useState('')
      const [storeUuid, setStoreUuid] = useState(null);

    
      const {data: getBroadcastCategoryData, isFetching: getDataFetching, isError:getDataError} = useGetBroadcastCategoryDataQuery({token,microsite_id,currentPage,searchValue});
      const {data: getSingleBroadcastCategoryData, isFetching: getSingleDataFetching, isError:getSingleDataError} = useGetSingleBroadcastCategoryDataQuery({token,singleUuid,microsite_id});
      const [progressValue,setProgressValue] = useState(0)

      const [deleteBroadcastCategoryMedia, response] = useDeleteBroadcastCategoryMediaMutation()
      const [storeBroadcastCategory] = useStoreBroadcastCategoryMutation()

      useEffect(()=>{
        if(getBroadcastCategoryData?.data){
          setValue('allCategory',getBroadcastCategoryData?.data)
        }
      },[getBroadcastCategoryData])

      useEffect(()=>{
        if(getSingleBroadcastCategoryData){
          setStoreUuid(getSingleBroadcastCategoryData?.uuid)
          setValue('title',getSingleBroadcastCategoryData?.title)
          setValue('details',getSingleBroadcastCategoryData?.details)
          if(getSingleBroadcastCategoryData?.status ===1){
            setValue('status',true)
          }else{
            setValue('status',false)
          }
          const storeUseableCategory = [];
          if(getSingleBroadcastCategoryData?.parent_id !==null){
            values?.allCategory.forEach(category => {
              if(category?.id === getSingleBroadcastCategoryData?.parent_id){
                setValue('parentCategory', category)
              }
              if(getSingleBroadcastCategoryData?.id !==category?.id){
                storeUseableCategory.push(category)
              }
            });
          }
          setValue('useableCategory',storeUseableCategory)
          setValue('uploadedMedia',getSingleBroadcastCategoryData?.files )

          if(getSingleBroadcastCategoryData?.thumb){
            setValue('uploadedFeatureImage',getSingleBroadcastCategoryData?.thumb )
            setValue('featureImageShow', true)
          }
        }
      },[getSingleBroadcastCategoryData, values?.allCategory])

      const handleEditorChange = (content,type) => {
        setValue('details', content)
      };

// create broad cast category
    const createBroadcastCategory = async(e)=>{
      e.preventDefault()
      setValue('visible', true)
      const formData = new FormData();
        formData.append("microsite_id", msDetails?.id);
        if(values?.parentCategory !==null){
          formData.append("parent_id", values?.parentCategory?.id);
        }
        formData.append("title", values?.title);
        formData.append("details", values?.details);

        if(values?.featureImage[0]){
          formData.append("thumb", values?.featureImage[0]?.originFileObj);
        }
        if(values?.media.length>0){
          values?.media.forEach(element => {
            formData.append("files[]", element?.originFileObj);
          });
        }
        
        if(values?.status ===true){
          formData.append("status", 1);
        }else{
          formData.append("status", 0);
        }


        var url;
        if(storeUuid !==null){
          url = `${baseUrl}/api/community/broadcastcategory/${storeUuid}`
        }else{
          url = `${baseUrl}/api/community/broadcastcategory`
        }
      
        const response = await storeBroadcastCategory({token,formData,url,setProgressValue})
        if(response?.data){
          setValue('title','') 
          setValue('details','') 
          setValue('allCategory',[])
          setValue('parentCategory',null)
          setValue('featureImage',[])
          setValue('media',[])
          setValue('status', true)
          setValue('visible', false)
       
          if(storeUuid !==null){
            setStoreUuid(null)
          }
          navigate('/broadcast-category')
        }
    }  

// handle delete media
const handleDeletMedia = (uuid) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteBroadcastCategoryMedia({token,uuid})
    }
  });
};


      
  return (
    <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
    <Grid item xs={12} sm={12} md={8} lg={9}>
      <div className="content_body">
        <div className="broadcast-body">
          <BroadcastTopNavigate />
            
        <Grid container spacing={3} sx={{mt:3}}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <TextField name="title" label="Category Title" onChange={(e)=>setValue('title', e.target.value)} value={values.title} />
                {/* <RHFTextField name="description" label="Description" multiline rows={3} /> */}

                {/* <div>
                  <label>Details</label>
                  <SunEditor
                    name="details"
                    setContents={values.details}
                    defaultValue={values.details}
                    showToolbar={true}
                    setDefaultStyle="height: 200px"
                    onChange={handleEditorChange}
                    setOptions={{
                      buttonList: [
                        [
                          "undo",
                          "redo",
                          "font",
                          "fontSize",
                          "formatBlock",
                          "paragraphStyle",
                          "blockquote",
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "subscript",
                          "superscript",
                          "fontColor",
                          "hiliteColor",
                          "textStyle",
                          "removeFormat",
                          "outdent",
                          "indent",
                          "align",
                          "horizontalRule",
                          "list",
                          "lineHeight",
                          "table",
                          "link",
                          "image",
                          "video",
                          "audio",
                          "imageGallery",
                          "fullScreen",
                          "showBlocks",
                          "codeView",
                          "preview",
                          "print",
                          "save",
                          "template",
                        ],
                      ],
                    }}
                  />
                </div> */}
                {/* <div>
                  <Box>(Upload Media)</Box>       
                  <Box sx={{ width: "100%", typography: "body1" }}>
                      <Box sx={{mb:2, mt:2}}>
                          <MultipleGlobalUploader setValue={setValue} name='media' />
                        </Box>
                    </Box>

                  {values?.uploadedMedia.length>0 && 
                  <>
                    <Box>(Uploaded Media)</Box> 
                    <Grid container>
                      {values?.uploadedMedia.map((media, key)=>{
                        return(
                          <Grid key={media?.uuid} sx={{mt:1}} item xs={12} md={3}>
                            <Box className ='uploaded-media'>
                              <Box className='uploaded-media-image'><img src={`${baseUrl}/storage/media/${media?.id}/${media?.filename}`}/> </Box>
                              <Box className='uploaded-media-ion' onClick={(e)=> handleDeletMedia(media?.uuid)}><CancelIcon /></Box>
                            </Box>
                          </Grid>
                        )
                      })}
                    </Grid> 
                  </>
                  }    
                </div> */}

              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                
                {/* <Autocomplete
                  sx={{ mb: -2 }}
                  options={singleUuid? values?.useableCategory:values.allCategory}
                  getOptionLabel={option => option.title}
                  id="categoryCategory"
                  value={values?.parentCategory}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValue('parentCategory',newValue);
                    }
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Select Parent Category"  margin="normal" />
                  )}
                /> */}
               


                    <Grid item xs={12}>
                      <div className="field_heading">
                          Feature Image 
                      </div>
                      {values?.featureImageShow === false &&
                        <Box>
                            <MultipleGlobalUploader setValue={setValue} name='featureImage' />
                            {values?.uploadedFeatureImage !==null &&
                              <Box sx={{mt:0.5}} display={'flex'} justifyContent={'center'}>
                                  <Button variant='outlined' onClick={(e)=> setValue('featureImageShow', true)}>Cancel</Button>
                              </Box>
                            }
                        </Box>
                        }
                        {values?.featureImageShow === true && 
                          <Box>
                            <Box>
                              <img src={`${baseUrl}/${values?.uploadedFeatureImage}`} alt='' width={'100%'} height={'100%'} />
                            </Box>
                            <Box sx={{mt:0.5}} display={'flex'} justifyContent={'center'}>
                                <Button variant='outlined' onClick={(e)=> setValue('featureImageShow', false)}>Upload New</Button>
                            </Box>
                          </Box>
                        }
                    </Grid>

                    <div>
                      <FormControlLabel
                        control={<Switch color="primary" checked={values?.status} onChange={(e)=> setValue('status', e.target.checked)} />}
                        label="Publish"
                        labelPlacement="start"
                      />
                    </div>
              </Stack>
            </Card>

            <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                color="primary"
                size="large"
                onClick={createBroadcastCategory}
                disabled={values?.title?false:true}
              >
                Save
              </Button>

            </Stack>
          </Grid>
        </Grid>
         
        </div>
      </div>
      {values?.visible ===true &&  <UploadLoader />}
      {(progressValue>0 && (values?.media.length>0 || values?.featureImage.length>0)) && <LoungeUploadProgress value = {progressValue} progressTotalLenght={values?.media?.length+values?.featureImage?.length} />}
      {getSingleDataFetching && <MainLoader/>} 
      {/* {getDataFetching && <MainLoader/>}  */}
    </Grid>
  </Grid>
  )
}

export default CategoryCreatePage