import { Grid } from "@mui/material";
import { useParams } from "react-router";
import useGeolocation from "react-hook-geolocation";
import Geocode from 'react-geocode';
import BusinessThemePurchaseBody from "../../components/localBusiness/BusinessThemePurchaseBody";
import { GoogleApiKey } from "../../utils/GoogleApiKey";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51OE1gQSFKNm0CZMZHxi93TV4jmFc0TJIfKORRkPVYPqPJU5ObJeRmfEu9f80u5kwmguYfrfseBeWZyLCPbEsDVDZ00CJ9E6cwx"
);


const LocalBusinessPaymentPage = () => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState("");
  // const [longitude, setLongitude] = useState(null);
  const params = useParams();
  Geocode.setApiKey(GoogleApiKey);
  const geolocation = useGeolocation();
  useEffect(()=>{
    setCurrentLocation('loading')
    if (geolocation.error) {
      alert(geolocation.error.message);
    } else {
      Geocode.fromLatLng(geolocation.latitude, geolocation.longitude).then(
        (response) => {
          response?.results[0]?.address_components.forEach(element => {
            if(element?.long_name ==="Bangladesh" || element?.short_name==="BD"){
              setCurrentLocation('BD')
            }
          });

        },
        (error) => {
          // console.error(error);
        }
      );
    }
  },[geolocation.latitude,geolocation.longitude,geolocation.error])


  // console.log('location', location)
  
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="content_body">
          <Elements stripe={stripePromise}>
            <BusinessThemePurchaseBody
              params={params}
              currentLocation={currentLocation}
              // currentLocation={'sds'}
            />
            </Elements>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default LocalBusinessPaymentPage;
