import { Button, Grid } from "@mui/material";
import React from "react";
import showcase1 from "../../asset/image/showcase-1.jpeg";
import showcase2 from "../../asset/image/showcase-2.jpeg";
import showcase3 from "../../asset/image/showcase-3.jpeg";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function BusinessThemeSelect() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="business-setup">
        <h2>Theme Selection</h2>

        <div className="business-setup-content">
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="business-theme">
                <img src={showcase1} alt="" />
                <div className="overly-content">
                  <div className="top-content">
                    <span>Free</span>
                  </div>
                  <div className="middle-content">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleClickOpen}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
              <h5> Alex Theme </h5>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="business-theme">
                <img src={showcase2} alt="" />
                <div className="overly-content">
                  <div className="top-content">
                    <span>10 $</span>
                    <span>-20%</span>
                  </div>
                  <div className="middle-content">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleClickOpen}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
              <h5> Megenda Tech Theme </h5>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="business-theme">
                <img src={showcase3} alt="" />
                <div className="overly-content">
                  <div className="top-content">
                    <span>10 $</span>
                    <span>-20%</span>
                  </div>
                  <div className="middle-content">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleClickOpen}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
              <h5> Barlin Exprem Theme </h5>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="business-theme">
                <img src={showcase3} alt="" />
                <div className="overly-content">
                  <div className="top-content">
                    <span>10 $</span>
                    <span>-20%</span>
                  </div>
                  <div className="middle-content">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleClickOpen}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
              <h5> Dug Out Next Theme </h5>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="business-theme">
                <img src={showcase2} alt="" />
                <div className="overly-content">
                  <div className="top-content">
                    <span>Free</span>
                  </div>
                  <div className="middle-content">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleClickOpen}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
              <h5> Alex Apex Theme </h5>
            </Grid>
          </Grid>

          {/* <div className="text-end">
            <Link to="/business-theme">
              <Button variant="contained" sx={{ mt: 2 }}>
                Next
              </Button>
            </Link>
          </div> */}
        </div>
      </div>

      {/* Dialouge Box*/}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Subscription Plan"}
        </DialogTitle>
        <DialogContent>
          <div className="them-subscription-container">
            <div className="theme-img">
              <img src={showcase2} alt="" />
            </div>
            <div className="theme-info">
              <h4>Barlin Exprem Theme</h4>
              <h4> $5 </h4>
            </div>
            <div className="type-content">
              <h5>Premium Subscription Plan</h5>
              <form>
                <label>
                  <input
                    type="radio"
                    name="business-type"
                    className="card-input-element"
                  />
                  <div className="panel panel-default card-input">
                    <h3>$10/ 1 month</h3>
                    <p>
                      Don't miss out—subscribe today for only $10 per month and
                      enjoy the full experience!
                    </p>
                  </div>
                </label>
                <label>
                  <input
                    type="radio"
                    name="business-type"
                    className="card-input-element"
                  />
                  <div className="panel panel-default card-input">
                    <h3>$50/ 6 month</h3>
                    <p>
                      Don't miss out—subscribe today for only $50 per month and
                      enjoy the full experience!
                    </p>
                  </div>
                </label>
                <label>
                  <input
                    type="radio"
                    name="business-type"
                    className="card-input-element"
                  />
                  <div className="panel panel-default card-input">
                    <h3>$90/ 12 month</h3>
                    <p>
                      Don't miss out—subscribe today for only $90 per month and
                      enjoy the full experience!
                    </p>
                  </div>
                </label>
              </form>
              <Link to="/business-payment">
                <Button
                  variant="contained"
                  fullWidth
                  size="small"
                  sx={{ mt: 2 }}
                >
                  Pay Now
                </Button>
              </Link>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default BusinessThemeSelect;
