import React from 'react'
import { Box, Button, Card, Grid } from '@mui/material'
import pollRewardImge from '../../../asset/image/rewardImge.png'
import icirclesLogo from '../../../asset/image/iCirclesLogo.png'
import qrImage from '../../../asset/image/download.png'

const PollRewardCard = () => {
  return (
    <Card className='reward-card-single'>
        <Box className='reward-card-elements'>
            <Box className='card-image'><img src={pollRewardImge} alt=''  /></Box>
            <Box className='compnay-logo'><img src={icirclesLogo} alt=''  /></Box>
            <Box className='price'>Price: $45</Box>
            <Box className='qr-code'><img src={qrImage} alt='' /></Box>
            <Box className='climBtn'><Button size='small' variant='contained' sx={{textTransform:'capitalize'}}>Claim</Button></Box>
        </Box>
    </Card>
  )
}

export default PollRewardCard