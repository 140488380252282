import { Button, Chip, Stack, TextField } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

function BusinessType() {
  return (
    <>
      <div className="business-type">
        <h2>Business Type</h2>

        <div className="business-type-content">
          <p>
            <strong>What's your site about?</strong> This will help us find you some starting
            ideas and examples.
          </p>

          <div className="type-content">
            <form>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Food</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Music</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Photography</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Gaming</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Art</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Marketing</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Fashion</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Education</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Restaurants</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Real Estate</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Jewelry</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Consulting</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Technology</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Travel</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="business-type"
                  className="card-input-element"
                />
                <div className="panel panel-default card-input">Fitness</div>
              </label>
            </form>
          </div>

          <div className="type-input">
        
          <TextField
                id="filled-basic"
                type="text"
                focused
                label="Type what you’re looking for?"
                fullWidth
                variant="filled"
              />
          </div>

          <Link to="/business-name">
            <Button variant="contained" fullWidth sx={{ mt: 2 }}>
              Next
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default BusinessType;
