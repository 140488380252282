import {
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import { useContext } from "react";
import Swal from "sweetalert2";
import { baseUrl, createFeedsUrl } from "../../api/Api";
import { UserContext } from "../../utils/UserContext";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/EditNote';
import { Box, IconButton, Tooltip } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';

import PeopleIcon from '@mui/icons-material/People';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from "../../asset/image/avatar.jpg";
import { useDeleteFeedMutation, useStoreFeedMutation } from "../../api/slices/loungeApiSlice";

import PushPinIcon from '@mui/icons-material/PushPin';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';

import CampaignIcon from '@mui/icons-material/Campaign';


const LoungeProfileBio = ({ data, getAllFeeds,getSingleFeedDetails,modalOpen,modalClose,createPost,setContent,setStoreUuid }) => {
 
  const getUrl = window.location.href;
  const segName = getUrl.split("/").pop();
  const navigate = useNavigate();
  const { userDetails, msDetails,loggedInUser } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState('')
  const token = localStorage.getItem("token");

  const location  = useLocation()
  const [storeFeed] = useStoreFeedMutation()
  const [deleteFeed] = useDeleteFeedMutation()
  const [progressValue, setProgressValue] = useState(0); 
  const [postId, setpostId] = useState(null); 
  const [uuid, setUuid] = useState(
    data?.user !== undefined ? data?.user?.uuid : ""
  );
  // const [name, setName] = useState(
  //   ( data.user !== undefined && data.user !==null) ? data.user.name : ""
  // );
  const [name, setName] = useState(
    (data?.microsite_userdetails !==null && data?.microsite_userdetails?.name) ? data?.microsite_userdetails?.name :( data.user !== undefined && data.user !==null) ? data.user.name : ""
  );
  const [avater, setAvater] = useState(
   ( data.user !== undefined && data.user !==null) ? data.user.avatar : ""
  );
  const options = { year: "numeric", month: "short", day: "numeric" };
  const [date, setDate] = useState(
    data !== undefined
      ? new Date(data.created_at).toLocaleDateString("en-US", options)
      : ""
  );


  // Delete  single feed
  const handleDeleteFeed = (uuid) => {
    Swal.fire({
      heightAuto: false,
      backdrop: false,
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFeed({token,uuid})
        .unwrap()
        .then((response) => {
          if(getSingleFeedDetails !==undefined){
            getSingleFeedDetails()
          }
        })
        .catch((error) => {});
      }
    });
  };

  // Hide single Feed
  const handleHideFeed = (uuid, getStatus) => {
    Swal.fire({
      heightAuto: false,
      backdrop: false,
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {

        const url =`${createFeedsUrl}/${uuid}`
        const formData = new FormData();
        formData.append("status", getStatus);

        storeFeed({token,formData,url,setProgressValue})
        .unwrap()
        .then((response) => {
          if(getSingleFeedDetails !==undefined){
            getSingleFeedDetails()
          }
        })
          .catch((error) => {});
      }
    });
  };

  // hanlde pinned /unpinned
  const handlePinnedUnpinnedFeed = (uuid, getStatus) => {
    Swal.fire({
      heightAuto: false,
      backdrop: false,
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {

        const url =`${createFeedsUrl}/${uuid}`
        const formData = new FormData();
        formData.append("pinned", getStatus);

        storeFeed({token,formData,url,setProgressValue})
        .unwrap()
        .then((response) => {
          if(getSingleFeedDetails !==undefined){
            getSingleFeedDetails()
          }
        })
          .catch((error) => {});
      }
    });
  };


  // Edit Post
  const handleEditFeed = (uuid, content) =>{
    setContent(content)
    setStoreUuid(uuid)
    modalOpen()
  }


  // MEnubar
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
     let post_id = params.get("post_id");
     if(post_id){
      setpostId(post_id)
     }
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Fragment>
      <div className="profile_bio">

        {/* left side */}
        {data?.notification !==1 
          ?
            <>
            <div className="profile_img">
              {data?.microsite_userdetails?.avater?<img src={`${baseUrl}/${data?.microsite_userdetails?.avater}`} alt={name} />: <img src={Avatar} alt={name} />}
            </div>
              
            <div className="profile_info">
              <div className="userName">
                    {(data?.user_id === userDetails?.id)
                    ?  <a className="profile_title cursorPointer" onClick={(e)=>navigate('/community-profile',{state:{uuid:uuid,userId:data.user_id,userData:data?.user}})}> {name}</a>
                    :  <a className="profile_title cursorPointer" onClick={(e)=>navigate('/community-profile/other',{state:{uuid:uuid,userId:data.user_id,userData:data?.user}})}> {name}</a> }

                    {userInfo !=='' && userInfo?.apartment_number 
                      && <a className="profile_title">{`(Apartment/Holding - ${userInfo?.apartment_number })`}</a>
                    }
                    
                    {data && data.location && 
                    <span>
                        <FontAwesomeIcon icon={faLocationDot} /> {data.location}
                    </span>}
              </div>
              <div className="post_timing">
                {date && date}{' '}
                {(data.status ===1)? <PeopleIcon/>: <PersonOffIcon/>}      
            </div>
            </div>
            </>
          :
            <>
              <div className="profile_info">Announcement</div>
            </>
          }

      {/* right side */}
          <div className="postInfo">
              {data?.pinned === 1 && <PushPinIcon />}
              {data?.notification === 1 && <CampaignIcon />}
              {userDetails?.id === data?.user?.id ?
                  <>
                    <IconButton
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                      >
                        
                        <MoreHorizIcon/>
                      </IconButton>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                      className="dropmenus"
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom-start' ? 'left top' : 'left bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                              >
                              { data?.notification !==1 && 
                                <>
                                  {data?.pinned ===1 && (loggedInUser?.user_type ==='admin' || userDetails?.id === msDetails?.user_id) ?
                                    <>
                                      <MenuItem 
                                          onClick={(e) =>{ handleClose(e);  handlePinnedUnpinnedFeed(data.uuid, 0)}}>
                                          <ModeOfTravelIcon/> Remove pin 
                                      </MenuItem>
                                    </>
                                    :
                                    <>
                                    <MenuItem 
                                          onClick={(e) =>{ handleClose(e);  handlePinnedUnpinnedFeed(data.uuid, 1)}}>
                                          <PushPinIcon/> Pin this post
                                      </MenuItem>
                                    </>
                                    }
                                </>
                              }
                              
                              
                              { data?.notification !==1 && 
                              <>
                                  {(data.status ===0)? <MenuItem 
                                      onClick={(e) =>{ handleClose(e); handleHideFeed(data.uuid,1)}}>
                                      <VisibilityIcon/>Make Public 
                                  </MenuItem>
                                  :
                                  <MenuItem 
                                    className="dropdown_menu_item"
                                      onClick={(e) =>{ handleClose(e); handleHideFeed(data.uuid,0)}}>
                                      <VisibilityOffIcon/> Make Private
                                  </MenuItem>
                                }
                              </>
                            }
                                
                                <MenuItem 
                                    onClick={(e) =>{ handleClose(e);  handleEditFeed(data.uuid, data.content)}}>
                                    <EditIcon/> Edit Post 
                                </MenuItem>
                                {location?.pathname !=='/loungeitemDetail' &&
                                <MenuItem 
                                    onClick={(e) =>{ handleClose(e);  handleDeleteFeed(data.uuid)}}>
                                    <DeleteIcon/> Delete Post 
                                </MenuItem>}
                              </MenuList>


                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                  :
                  (loggedInUser?.user_type ==='admin' || userDetails?.id === msDetails?.user_id) &&
                  <>
                    <IconButton
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                      >
                        
                        <MoreHorizIcon/>
                      </IconButton>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                      className="dropmenus"
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom-start' ? 'left top' : 'left bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                              >
                            {token && data?.notification !=1 && 
                            <>    
                                {(data?.pinned ===1 )&& (loggedInUser?.user_type ==='admin' || userDetails?.id === msDetails?.user_id) ?
                                <>
                                  <MenuItem 
                                      onClick={(e) =>{ handleClose(e);  handleEditFeed(data.uuid, data.content)}}>
                                      <ModeOfTravelIcon/> Remove pin 
                                  </MenuItem>
                                </>
                                :
                                <>
                                <MenuItem 
                                      onClick={(e) =>{ handleClose(e);  handleEditFeed(data.uuid, data.content)}}>
                                      <PushPinIcon/> Pin this post 
                                  </MenuItem>
                                </>
                                }
                              </>
                            }
                              </MenuList>


                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                }

                {postId &&  <div className="post_back_btn">
                  <CloseIcon onClick={(e)=>{navigate(-1)}}/>
                </div>}
              
          </div>
       
      </div>
      {/* <ToastContainer/> */}
    </Fragment>
  );
};

export default LoungeProfileBio;
