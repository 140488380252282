import React from 'react'
import UploadForm from '../../components/Broadcast/BroadcastUpload/UploadForm'
import BroadcastTopNavigate from '../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate'
import { Grid } from '@mui/material'

const BroadcastUploadForm =()=> {
  return (
    <>
     <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <div className="broadcast-body">
              <BroadcastTopNavigate />
              <UploadForm/>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default BroadcastUploadForm