import React, { Fragment } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { produce } from 'immer';

const QuestionAnswerForm = ({ questionList, setQuestionList, pollId, handleQuestion, handleDeleteQuestion,handleAnswer }) => {
  const handleQuestionChange = (index) => (event) => {
    const { value } = event.target;
    setQuestionList(produce(draft => {
      draft[index].question_text = value;
    }));
  };

  const handleAnswerChange = (questionIndex, answerIndex) => (event) => {
    const { value } = event.target;
    setQuestionList(produce(draft => {
      draft[questionIndex].answers[answerIndex].answer_text = value;
    }));
  };

  return (
    <Fragment>
      {questionList && questionList.length > 0 && (
        <Box sx={{ mt: 5 }}>
          {questionList.map((question, index) => (
            <Grid container spacing={2} key={index}>
              <Grid sx={{ mb: 1 }} item xs={6}>
                <TextField
                  fullWidth
                  focused
                  className="form_control"
                  label={`Question`}
                  variant="filled"
                  value={question.question_text}
                  onChange={handleQuestionChange(index)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={(e)=>handleQuestion(pollId,question.uuid,question.question_text)}>
                          <SyncIcon />
                        </IconButton>
                        <IconButton onClick={(e)=>handleDeleteQuestion(question.uuid)}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {question.answers.map((answer, answerIndex) => (
                <Grid sx={{ mb: 1 }} item xs={2} key={answerIndex}>
                  <TextField
                    fullWidth
                    focused
                    className="form_control"
                    disabled={!question.question_text}
                    label={`Answer ${answerIndex + 1}`}
                    variant="filled"
                    value={answer.answer_text}
                    onChange={handleAnswerChange(index, answerIndex)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton disabled={!question.question_text || !answer.answer_text} onClick={(e)=> handleAnswer(question?.id,null,answer.uuid,answer.answer_text)}>
                            <SyncIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
          <Button
            className="MuiBtn_create"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={(e)=> handleQuestion(pollId,null)}
          >
            Add More
          </Button>
        </Box>
      )}
    </Fragment>
  );
};

export default QuestionAnswerForm;
