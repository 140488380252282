import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import groomTheme1 from '../../asset/image/groom-1-prev.webp';
import groomTheme2 from '../../asset/image/groom-2-prev.webp';

import bridalTheme1 from '../../asset/image/bridal-1-prev.webp';
import bridalTheme2 from '../../asset/image/bridal-2-prev.webp';
import bridalTheme3 from '../../asset/image/bridal-3-prev.webp';

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UserContext } from "../../utils/UserContext";
import axios from "axios";
import { notifyError, notifySuccess } from "../../utils/Toast";
import { matrimonyUrl } from "../../api/Api";
import { theme } from "antd";
const MatrimonialMyThemePage = () => {
  const navigate = useNavigate();
  const {userDetails, msDetails} = useContext(UserContext)

  const [themeType, setThemeType] = useState()
  const [themeId, setThemeId] = useState()

  const token = localStorage.getItem("token");
  
  const matrimonyProfile = localStorage.getItem('matrimony_profile')
  const parseProfile = matrimonyProfile?JSON.parse(matrimonyProfile):''

  const [loaderVisible, setLoaderVisible] = useState(false)

  const search = useLocation().search;
  const status=new URLSearchParams(search).get("status");
  const invoice_number=new URLSearchParams(search).get("invoice_number");
  const trx_id=new URLSearchParams(search).get("trx_id");

  const handleMatrimony = async (invoice_number) => {
    setLoaderVisible(true);
    let data = new FormData();
    data.append('microsite_id', msDetails.id);
    data.append('user_id', userDetails?.id);
    data.append('theme_id', invoice_number);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };

      var  url = `${matrimonyUrl}/${parseProfile?.uuid}`
  
      axios
        .post(url, data, config)
        .then((response) => {
          // handleGetMainMagazine();
          setLoaderVisible(false);
          //notifySuccess();
          localStorage.setItem('matrimony_profile',JSON.stringify(response?.data?.matrimony_profile))
          // navigate('/user-matrimony-profile',{state:{data:response?.data?.matrimony_profile}})
        })
        .catch((err) => {
          notifyError();
        });
};

  useEffect(()=>{
    if(trx_id !=='' && trx_id !==null && trx_id !=='null'){
      handleMatrimony(invoice_number)
    }
  },[])

  useEffect(()=>{
    if(parseProfile?.meta?.theme_id !=='null'){
      const storeId= parseProfile?.meta?.theme_id;
      if(storeId){
        const splitedId = storeId.split('-')
        setThemeType(splitedId[1])
        setThemeId(splitedId[2])
      }
    }
    if(invoice_number !=='null' && invoice_number !==null ){
        const splitedId = invoice_number.split('-')
        setThemeType(splitedId[1])
        setThemeId(splitedId[2])
    }


  },[parseProfile?.meta?.theme_id,invoice_number])

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={3} md={12} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={12} sm={12} xs={12}>
          <div className="content_body">
          <Tooltip title="Back">
              <div
                className="backArrow"
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
              </div>
            </Tooltip>
            <div className="theme_select_wrapper">
              <div className="page_head">
                 <h4>My Themes</h4>
              </div>

              <div className="theme_selet_wrap">
              <Grid container spacing={2}>
                {(themeId ==='1' && themeType ==='groom') &&
                     <Grid item lg={4}>
                     <div className="theme_item" >
                        <div className="theem_img" style={{ backgroundImage: `url(${groomTheme1})`}}></div>
                        <div className="theme_content">
                           <div className="themName">
                               Groom  Theme 1
                               <Button sx={{ml:2}} variant="outlined" onClick={(e)=> navigate('/matrimonial-user-theme/groom/1',{state:{data:parseProfile}})}>Visit</Button>
                           </div>
                        </div>
                     </div>
                   </Grid>
                }
                {(themeId ==='2' && themeType ==='groom') &&
                  <Grid item lg={4}>
                  <div className="theme_item" >
                     <div className="theem_img" style={{ backgroundImage: `url(${groomTheme2})`}}></div>
                     <div className="theme_content">
                        <div className="themName">
                            Groom Theme 2
                           <Button sx={{ml:2}} variant="outlined" onClick={(e)=> navigate('/matrimonial-user-theme/groom/2',{state:{data:parseProfile}})} >Visit</Button>
                        </div>
                     </div>
                  </div>
                </Grid>
                }

              {(themeId ==='1' && themeType ==='bridal') &&
                 <Grid item lg={4}>
                 <div className="theme_item">
                    <div className="theem_img" style={{ backgroundImage: `url(${bridalTheme1})`}}></div>
                    <div className="theme_content">
                       <div className="themName">
                           Bridal Theme 1
                           <Button sx={{ml:2}} variant="outlined" onClick={(e)=> navigate('/matrimonial-user-theme/bridal/1',{state:{data:parseProfile}})}>Visit</Button>
                       </div>
                    </div>
                 </div>
               </Grid>
              }
              {(themeId ==='2' && themeType ==='bridal') &&
               <Grid item lg={4}>
                  <div className="theme_item" >
                      <div className="theem_img" style={{ backgroundImage: `url(${bridalTheme2})`}}></div>
                      <div className="theme_content">
                        <div className="themName">
                            Bridal Theme 2
                            <Button sx={{ml:2}} variant="outlined" onClick={(e)=> navigate('/matrimonial-user-theme/bridal/2',{state:{data:parseProfile}})}>Visit</Button>
                        </div>
                      </div>
                  </div>
                </Grid>
                }
                 {(themeId ==='3' && themeType ==='bridal') &&
                  <Grid item lg={4}>
                    <div className="theme_item">
                      <div className="theem_img" style={{ backgroundImage: `url(${bridalTheme3})`}}></div>
                      <div className="theme_content">
                        <div className="themName">
                            Bridal Theme 3
                            <Button sx={{ml:2}} variant="outlined" onClick={(e)=> navigate('/matrimonial-user-theme/bridal/3',{state:{data:parseProfile}})}>Visit</Button>
                        </div>
                      </div>
                  </div>
                  </Grid>
                }
               
              </Grid>
            </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default MatrimonialMyThemePage;
