import React, { Fragment, useContext } from "react";
import { ShimmerPostDetails } from "react-shimmer-effects";


const MatrimonyDetailsEffect = () => {
    return (
        <Fragment>
            <div>
                  
            <ShimmerPostDetails card cta variant="SIMPLE" />
             {/* <ShimmerPostDetails card cta variant="EDITOR" /> */}
                {/* <ShimmerPostItem card title text cta imageType="thumbnail"
                    imageWidth={40}
                    imageHeight={40} /> */}
            </div>

           
        </Fragment>
    );
};

export default MatrimonyDetailsEffect;
