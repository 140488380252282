import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const countryApiSlice = createApi({
  reducerPath: "Country",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://nominatim.openstreetmap.org/reverse?`,
  }),
  tagTypes: ["country"],
  endpoints: (builder) => ({
    getCountry: builder.query({
      query: ({latitude, longitude}) => `format=json&lat=${latitude}&lon=${longitude}`,
      providesTags: ["Posts"],
    }),
  }),
});

export const { useGetCountryQuery } = countryApiSlice;
