import React from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
const StyledDiv = styled("div")`
  background-color: #bcbcbc;
  border-radius: 35px;
  height: 10px;
  overflow: hidden;
`;

const StyledBar = styled("div")`
  background-color: ${({color})=>color};
  height: 10px;
  width: ${({ completed }) => completed}%;
`;

const StorageProgress = (props) => {
  return (
    <>
      {/* <div className="card_progress">
        <div className="progress_wrap"></div>
      </div> */}
      {/* <StyledDiv completed={props.completed}>
        <StyledBar completed={props.completed} />
      </StyledDiv> */}
       <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1}}>
              <StyledDiv completed={props.completed}>
            <StyledBar completed={props.completed} color={props.color} />
          </StyledDiv>     
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
              props.completed,
            )}%`}</Typography>
          </Box>
        </Box>
    </>
  );
};

export default StorageProgress;
