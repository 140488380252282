import React, { Fragment, useContext } from "react";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { ShimmerCircularImage } from "react-shimmer-effects";
import { ShimmerPostItem } from "react-shimmer-effects";


const AllUserEffect = () => {
    return (
        <Fragment>
            <div>

                <ShimmerPostItem card title text cta imageType="thumbnail"
                    imageWidth={80}
                    imageHeight={80} />
            </div>

            {/* <ShimmerSimpleGallery imageType="circular" imageHeight={200} caption /> */}
            {/* <ShimmerSimpleGallery card imageHeight={300} />
        <ShimmerSimpleGallery card imageHeight={300} caption /> */}
        </Fragment>
    );
};

export default AllUserEffect;
