import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../Api";
import axios from "axios";

export const braodcastApiSlice = createApi({
  reducerPath: "broadcast",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["broadcast"],
  endpoints: (builder) => ({
    getBroadcastCategoryData: builder.query({
      query: ({token,microsite_id,currentPage,searchValue}) => {
        return {
          url: `/api/community/broadcastcategory?microsite_id=${microsite_id}&current_page=${currentPage}&keyword=${searchValue}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["broadcast"],
    }),
    getSingleBroadcastCategoryData: builder.query({
      query: ({token,singleUuid,microsite_id}) => {
        return {
          url: `/api/community/broadcastcategory/${singleUuid}?microsite_id=${microsite_id}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["broadcast"],
    }),    
    storeBroadcastCategory: builder.mutation({
      queryFn: async ({ token, formData, url, setProgressValue }) => {
        try {
            const  response = await axios.post(url, formData, {
              url: url,
              method: 'POST',
              body: formData,
              headers:{
                Authorization: `Bearer ${token}`
              },
            onUploadProgress: (uploadEvent) => {
              // Set the progress value to show the progress bar
              const  uploadProgress = Math.round((100 * uploadEvent.loaded) / uploadEvent.total);
               setProgressValue(uploadProgress)
            },
          });
          // Handle the response if needed
          return response
        } catch (error) {
          // Handle errors
          // throw new Error(error.message);
        }
      },
      invalidatesTags: ['broadcast'],
    }),
    deleteBroadcastCategory: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/community/broadcastcategory/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['broadcast'],
    }),
    deleteBroadcastCategoryMedia: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/cms/pages/${uuid}/media`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['broadcast'],
    }),



    getBroadcastItemData: builder.query({
      query: ({token,microsite_id,currentPage,searchValue,user_id}) => {
        return {
          url: `/api/community/broadcastitem?microsite_id=${microsite_id}&current_page=${currentPage}&keyword=${searchValue}&user_id=${user_id}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["broadcast"],
    }),
    getSingleBroadcastItemData: builder.query({
      query: ({token,singleUuid,microsite_id}) => {
        return {
          url: `/api/community/broadcastitem/${singleUuid}?microsite_id=${microsite_id}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["broadcast"],
    }),
    storeBroadcastItem: builder.mutation({
      queryFn: async ({ token, formData, url, setProgressValue }) => {
        try {
            const  response = await axios.post(url, formData, {
              url: url,
              method: 'POST',
              body: formData,
              headers:{
                Authorization: `Bearer ${token}`
              },
            onUploadProgress: (uploadEvent) => {
              // Set the progress value to show the progress bar
              const  uploadProgress = Math.round((100 * uploadEvent.loaded) / uploadEvent.total);
               setProgressValue(uploadProgress)
            },
          });
          // Handle the response if needed
          return response
        } catch (error) {
          // Handle errors
          // throw new Error(error.message);
        }
      },
      invalidatesTags: ['broadcast'],
    }),
    deleteBroadcastItem: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/community/broadcastitem/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['broadcast'],
    }),
    storeItemReaction: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        },
       
      }),
      invalidatesTags: ['broadcast'],
    }),
    removeItemReaction: builder.mutation({
      query: ({token,url}) => ({
        url: url,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['broadcast'],
    }),


    getVideoCommentData: builder.query({
      query: ({token,url}) => {
        return {
          url: url,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["lounge"],
    }),
    storeVideoComment: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        },
      }),
      invalidatesTags: ['lounge'],
    }),
    storeVideoCommentReaction: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        },
       
      }),
      invalidatesTags: ['lounge'],
    }),
   
    removeVideoCommentReaction: builder.mutation({
      query: ({token,url}) => ({
        url: url,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['lounge'],
    }),
    deleteVideoComment: builder.mutation({
      query: ({token,url}) => ({
        url: url,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['lounge'],
    }),

  }),
});

export const {
   useGetBroadcastCategoryDataQuery,
   useGetSingleBroadcastCategoryDataQuery,
   useStoreBroadcastCategoryMutation,

   useDeleteBroadcastCategoryMutation,
   useDeleteBroadcastCategoryMediaMutation,

   useGetBroadcastItemDataQuery,
   useGetSingleBroadcastItemDataQuery,
   useStoreBroadcastItemMutation,
   useDeleteBroadcastItemMutation,
   useStoreItemReactionMutation,
   useRemoveItemReactionMutation,

   useGetVideoCommentDataQuery,
   useStoreVideoCommentMutation,
   useStoreVideoCommentReactionMutation,
   useRemoveVideoCommentReactionMutation,
   useDeleteVideoCommentMutation

  } = braodcastApiSlice;