
import React, { Fragment, useContext } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../utils/UserContext";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import parser from 'html-react-parser'
import { Grid } from "antd";
import { baseUrl } from "../../api/Api";

const EventItem = ({event, handleDeleteEvent}) => { 
  const navigate = useNavigate();
  const {userDetails, msDetails} = useContext(UserContext)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const token = localStorage.getItem('token');
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCard = ()=>{
    if(userDetails.id !== event?.meta?.create_by){
      navigate('/event-detail', {state:{uuid:event.uuid,event:event}})
    }
  }

  return (
    <Fragment>
          <div className="event_item cursorPointer" onClick={(e)=> handleCard()}>
            <a onClick={(e)=>navigate('/event-detail', {state:{uuid:event.uuid,event:event}})}>
              {/* {event.title && <div className="event_title">
                      {event.title}
                </div>}  */}

                <div className="event_title">
                  {event?.title && event?.title}

                  {event?.meta?.banner && 
                    <div className="avatar_tittle">
                        <Avatar alt={event?.title} src={`${baseUrl}/${event?.meta?.banner}`} />
                  </div>}
                </div>
             </a>

             
            {userDetails.id === event?.meta?.create_by && <div className="dropDown"
             id="basic-button"
             aria-controls={open ? 'basic-menu' : undefined}
             aria-haspopup="true"
             aria-expanded={open ? 'true' : undefined}
             onClick={handleClick}
            >
              <MoreVertIcon/>
            </div>}
            
            <Menu
               id="basic-menu"
               anchorEl={anchorEl}
               open={open}
               onClose={handleClose}
               MenuListProps={{
                 'aria-labelledby': 'basic-button',
               }}
             >
               <MenuItem onClick={(e)=>navigate('/event-create', {state:{uuid:event.uuid,event:event,bannerUpdate:false}})}>Edit</MenuItem>
               <MenuItem onClick={(e)=>{handleDeleteEvent(event.uuid);handleClose()}}>Delete</MenuItem>
             </Menu>
             <div className="eventTime" >
                {event.date && <><AccessTimeIcon/> {event.date}</>}
             </div>
             {event.details && <div className="event_details">{parser(event.details.slice(0,300))}</div>}
         </div>
      
    </Fragment>
  );
};

export default EventItem;
