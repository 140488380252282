import React from "react";
import { Button, Grid, TextField, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function BussPhoneSetupPage() {
  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="content_body">
            <Tooltip title="Back">
              <div
                className="backArrow"
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
              </div>
            </Tooltip>
            <div className="post-business-container">
              <div className="choose-commuinity">
                <h2>Business Phone Number</h2>

                <div className="choose-commuinity-content">
                  <p> Please enter phone number for your business. </p>
                  <Grid container spacing={2} sx={{mt:4}}>
                    <Grid item xs={12}>
                      <TextField
                        id="filled-basic"
                        type="number"
                        focused
                        label="Phone"
                        fullWidth
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                  <Link to="/business-tagline">
                    <Button variant="contained" fullWidth sx={{ mt: 2 }}>
                      Next
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default BussPhoneSetupPage;
