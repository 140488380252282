import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { baseUrl } from "../../../api/Api";
import { UserContext } from "../../../utils/UserContext";
import { useNavigate } from "react-router-dom";
import { useStoreBroadcastItemMutation } from "../../../api/slices/braodcastApiSlice";
import PollQnA from "./PollQnA";
import parser from 'html-react-parser'
import { Image } from "antd";
import Chip from '@mui/material/Chip';


const PollDetails = ({pollDetails,data,loading}) => {
  const {userDetails} = useContext(UserContext)
  const navigate = useNavigate();
  const storeData =  data?.data
  var reverseArr ;
  if(storeData && storeData.length>0){
    reverseArr = [...storeData].reverse()
  }

  var username = pollDetails?.microsite_userdetails?.user_details? JSON.parse(pollDetails?.microsite_userdetails?.user_details)?.name?JSON.parse(pollDetails?.microsite_userdetails?.user_details)?.name:data?.user?.name:data?.user?.name

// handle poll review result navigate
 const handlePollReview = (data)=>{
  navigate(`/poll-review/${data?.uuid}`, {state:{pollDetails:data}})
  }

  return (
    <>
      <div className="broadcast-video-container">
        <div className="broadcast-video-content">
          <div className="broadcast-video-details">
            <Box>
              <Box sx={{mb:2}}>
                <Typography variant="subtitle2" gutterBottom>
                  Speciality:{pollDetails?.description}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Title:{pollDetails?.name}
                </Typography>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2" gutterBottom>
                        Sign/Symptom/Keyword/Search word
                    </Typography>
                    {pollDetails?.meta?.keywords?.split(',').map((keyword,key)=>{
                      return(
                        <Chip label={keyword} size="small" />
                      )
                    })}
                  </Stack>
                  <Box>
                    <Button variant="contained" sx={{textTransform:'capitalize'}} onClick={(e)=> handlePollReview(pollDetails)}>Result Review</Button>
                  </Box>
                </Box>
              </Box>

              {/* <div className="user-info">
                <Box className="user-details"
                  onClick={(e)=> userDetails?.id===data?.user?.id
                    ?navigate('/community-profile',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user,showInfo:true}})
                    :navigate('/community-profile/other',{state:{uuid:data?.user?.uuid,userId:data?.user?.id,userData:data?.user}})
                  }
                  >
                  <Box display={'flex'} alignItems={'center'}>
                    <Avatar
                      alt={data?.title}
                      src={`${baseUrl}/${pollDetails?.user?.avatar}`}
                      sx={{ maxWidth: "32px", maxHeight: "32px" }}
                    />
                    <Box sx={{ml:1}} className="user-name">{username}</Box>
                    </Box>  
                </Box>
              </div> */}

              {/* <Box sx={{mt:2}}>
                <Grid container spacing={2}>
                  {pollDetails?.media && pollDetails?.media.length>0 && pollDetails.media.map((media, key)=>{
                    return(
                      <Grid lg={4} key={media.uuid}>
                        <Box sx={{m:0.5}}>
                          <Image src={`${baseUrl}/storage/media/${media?.id}/${media?.file_name}`} alt={media?.file_name} />
                        </Box>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box> */}

            </Box>
              <Divider sx={{mt:2 , mb:2}}/>
             <PollQnA pollDetails={pollDetails} data={reverseArr} loading={loading}  />
          </div>
        </div>
      </div>
    </>
  );
};

export default PollDetails;
