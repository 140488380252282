import React, { Fragment, useContext, useEffect, useState } from "react";
import { Autocomplete, Avatar, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Grid, Switch, TextField } from "@mui/material";
import LoungeBody from "../../components/LoungeBody/LoungeBody";
import { UserContext } from "../../utils/UserContext";
import { ToastContainer } from "react-toastify";
import { allMembersUrl, baseUrl } from "../../api/Api";
import EditIcon from "@mui/icons-material/Edit";
import parse from "html-react-parser";
import { useLocation, useNavigate } from "react-router-dom";

import bannerUploadImage from "../../asset/image/bannerUpload.jpeg";
import uploadLogo from "../../asset/image/uploadIcon.png";
// import uploadLogo from '../../asset/image/upload-logo.jpeg'
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GroupLoungeBody from "../../components/Groups/GroupLoungeBody";
import { useGetFeedDataQuery } from "../../api/slices/loungeApiSlice";
import GroupPrivateLoungeBody from "../../components/Groups/GroupPrivateLoungeBody";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DialogActions from "@mui/material/DialogActions";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useDeleteGroupMemberMutation, useGetGroupMemberDataQuery, useStoreGroupMemberMutation } from "../../api/slices/groupsApiSlice";
import Swal from "sweetalert2";
import axios from "axios";
import MainLoader from "../../components/PageLoadEffects/MainLoader";
import { ThreeDots } from "react-loader-spinner";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;








const GroupLoungePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const groupInfo = location?.state?.group
  const [uuid, setUuid] = useState(groupInfo?.uuid)
  const { msDetails, userDetails } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState("");

  // const userInfo = JSON.parse(localStorage?.getItem('loggedInUserInfo'))
  const info = JSON.parse(localStorage?.getItem("loggedInUserInfo"));
  useEffect(() => {
    if (info) {
      setUserInfo(info);
    }
  }, []);



  const token = localStorage.getItem("token");
  const microsite_id = msDetails?.id
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [groupId, setGrouppId] = useState(groupInfo?.id)



  const {data: getPostsData, isFetching: getDataFetching, isError:getDataError} = useGetFeedDataQuery({token,microsite_id,currentPage,searchValue, groupId});

// lounge member 
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

      const [chatGroup, setchatGroup] = useState(false);
      const [storeGroupMembers, setStoreGroupMembers] = useState([]);
      const [storeExistedMembers, setStoreExistedMembers] = useState([]);
      const [memberTobeAdd, setStoreMemberToBeAdd] = useState([]);
      const [ownerFind, setOwnerFind] = useState(false);
      const [currentMember, setCurrentMember] = useState(null)


  // privatge group access start
    const [userId, setUserId] = useState('')
    const [type, setType] = useState('')

    const {data: getGroupMemberData, isFetching: getMemberDataFetching, isError:getMemberDataError} = useGetGroupMemberDataQuery({token,groupId,userId,type});
    const [storeGroupMember] = useStoreGroupMemberMutation()
    const [deleteGroupMember] = useDeleteGroupMemberMutation()

//get all members
  const membersUrl = `${allMembersUrl}/${msDetails.id}`;
  const getAllMembers = ()=>{
    let config = {
      method: "get",
      url: membersUrl,
    };

    axios
      .request(config)
      .then((response) => {
        const storeUsers = [];
        if(response?.data?.data && response?.data?.data.length>0){
          response?.data?.data.forEach(element => {
            if(element?.status ===1 && element?.user_id !==userDetails?.id){
              storeUsers.push(element?.user)
            } 
          });
        }
        setStoreMemberToBeAdd(storeUsers)
      })
      .catch((error) => {});
  }
  useEffect(() => {
    getAllMembers()
  }, []);



  useEffect(()=>{
    const storeUser = []
    if(groupInfo?.user_id === userDetails?.id){
      storeUser.push(groupInfo?.user)
      setOwnerFind(true)
    }

    const storeExistMembers = [];
    if(getGroupMemberData){
      if(getGroupMemberData?.data?.length>0){
        getGroupMemberData?.data.forEach(element => {
          storeExistMembers.push(element)
        });
      }
    }
    setStoreExistedMembers([...storeUser,...storeExistMembers])

  },[getGroupMemberData,groupInfo?.user,groupInfo?.user_id,userDetails?.id])




    // handle join request
    const  handleJoinRequest =()=>{
      let formData = new FormData();
      formData.append('group_id', groupInfo?.id);
      formData.append('user_id', storeGroupMembers?.id);
      formData.append('status', 2);

      var url =`${baseUrl}/api/community/groupmembers`
      
      storeGroupMember({token,formData,url})
      .unwrap()
      .then((response) => {
       setStoreGroupMembers([])
      })
    }

   

      const handleClickOpenChatGroup = () => {
        setchatGroup(true);
      };
    
      const handleCloseChatGroup = () => {
        setchatGroup(false);
      };

      const [checked, setChecked] = React.useState(true);

      const handleChange = (event) => {
        setChecked(event.target.checked);
      };
      const [switchesState, setSwitchesState] = useState({});

      const handleSwitchChange = (switchId) => {
        setSwitchesState((prevState) => ({
          ...prevState,
          [switchId]: !prevState[switchId],
        }));
      };

  // handle join request
    const  handleRequest =(status,uuid)=>{
      let formData = new FormData();
      formData.append('group_id', groupInfo?.id);
      formData.append('user_id', userDetails?.id);
      formData.append('status', status);

      var url ;
      if(uuid){
        url =`${baseUrl}/api/community/groupmembers/${uuid}`
      }else{
        url =`${baseUrl}/api/community/groupmembers`
      }
      
      storeGroupMember({token,formData,url})
      .unwrap()
      .then((response) => {
      //  console.log('respoonse', response)
      })
    }

     // handle cancel joining request
     const handleCancelJoining = (uuid)=>{
      Swal.fire({
        heightAuto: false,
        backdrop: false,
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteGroupMember({token,uuid})
        }
      });  
    }
    

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <Box sx={{ mb: 2 }}>
              {groupInfo && 
                 <div className="communityInfo_wrapper">
                 <div className="commuinity_banner">
                   {groupInfo?.thumb ? (
                     <img
                       src={`${baseUrl}/${groupInfo?.thumb}`}
                       alt={groupInfo?.name}
                     />
                   ) : (
                     <img src={bannerUploadImage} alt="" />
                   )}
                 </div>
 
                 <div className="community_top_info">
                   <div className="com_one">
                     <div className="com_logo">
                       {groupInfo?.thumb ? (
                         <img
                           src={`${baseUrl}/${groupInfo?.thumb}`}
                           alt={groupInfo?.name}
                         />
                       ) : (
                         <img src={uploadLogo} alt="" />
                       )}
                     </div>
                     <div className="com_top_left">
                       <div className="com_name"> {groupInfo?.name}</div>
                       <div className="tagline">
                         {groupInfo?.details ? parse(groupInfo?.details) : ""}
                       </div>
                     </div>
                   </div>
                   {/* <Button variant="outlined" startIcon={<DeleteIcon />}><img src={'https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png'} width={'10%'}/></Button> */}
 
                   <div className="com_botttom">
                     {(ownerFind === true) && (
                      <>
                       <div className="edit_button cursorPointer"
                            onClick={(e) =>navigate("/group-store", {state: { uuid: groupInfo.uuid }})}>
                         <EditIcon /> Edit
                       </div>

                        <Box sx={{m:1}} className="cursorPointer" onClick={handleClickOpenChatGroup}>
                          <GroupAddIcon />
                        </Box>
                       </>
                     )}

                      {ownerFind !==true && 
                        currentMember !==null && 
                        currentMember?.status ===1 &&
                        <Box sx={{m:1}} className="cursorPointer">
                          <Button sx={{color:'#fff !important'}} variant="contained" onClick={(e)=> handleCancelJoining(currentMember.uuid)}>Leave Now</Button> 
                        </Box>
                       }
                   </div>
                 </div>
               </div>
              }
            </Box>

            {groupInfo?.status ===1 && getPostsData !==undefined && <GroupLoungeBody groupInfo={groupInfo} getPostsData={getPostsData} allFeeds={getPostsData?.data} />}
            {groupInfo?.status ===0 && getPostsData !==undefined && <GroupPrivateLoungeBody  groupInfo={groupInfo} getPostsData={getPostsData} allFeeds={getPostsData?.data} handleRequest={handleRequest} handleCancelJoining={handleCancelJoining} setCurrentMember={setCurrentMember} ownerFind={ownerFind}  />}
            
          </div>
        </Grid>
      </Grid>


    {/* lounge member Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={chatGroup}
        onClose={handleCloseChatGroup}
        aria-labelledby="responsive-dialog-title"
        className="chat_group_modal"
      >
        <DialogTitle sx={{ mt: 4 }} id="responsive-dialog-title">
          {`Member ${groupInfo?.name}`}
        </DialogTitle>
        <DialogContent>
          <div className="chat_group_body">
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="search_user">
                  <Autocomplete
                    sx={{ mt: 2 }}
                    // multiple
                    id="checkboxes-tags-demo"
                    // value={memberTobeAdd}
                    onChange={(event, newValue) => {
                      setStoreGroupMembers(newValue);
                    }}
                    options={memberTobeAdd}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add Member"
                        placeholder="Search"
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12}>
                <div className="list_title">Member List </div>
                {storeExistedMembers &&
                  storeExistedMembers.length > 0 &&
                  storeExistedMembers.map((member, i) => {
                    return (
                      <div className="Aded_list" key={i}>
                        <div className="list_item">
                          <div className="item_left">
                            <Avatar
                              alt={member?.name}
                              src={
                                member?.user?.avatar
                                  ? `${baseUrl}/${member?.user?.avatar}`
                                  :  member?.avatar? `${baseUrl}/${member?.avatar}`:''
                              }
                            />
                            <div className="u_name">{member?.name || member?.user?.name}</div>
                          </div>
                          {ownerFind === true &&
                            userDetails.uuid !== member?.uuid && (
                              <div className="item_right d-flex">
                                <Box>
                                  <i>
                                      <ThreeDots
                                          visible={getMemberDataFetching?true:false}
                                          height="30"
                                          width="30"
                                          color="#4fa94d"
                                          radius="9"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClass=""
                                          />
                                    </i>
                                    </Box>
                                  {(member?.status ===1 || member?.status ===3) &&
                                    <i>
                                      <Switch
                                        // checked={checked}
                                        checked={ member?.status ===1?true:false}
                                        onChange={(e)=>{handleSwitchChange(member?.uuid);handleRequest(member?.status ===1?3:1,member.uuid)}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                    </i>}
                                   
                                    {member?.status ===0 &&
                                      <i>
                                        <Box sx={{mr:5}}><Button variant="contained" onClick={(e)=>handleRequest(1,member.uuid)}>Accept</Button></Box>
                                      </i>
                                     }
                                    {member?.status ===2 && <Button disabled>Request Sent</Button>} 
                                    <i onClick={(e) => handleCancelJoining(member.uuid)}>
                                      <DeleteIcon />
                                    </i>
                              </div>
                            )}
                        </div>
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseChatGroup}>
            Cancel
          </Button>
          {storeGroupMembers?.id ?
           <Button  variant="contained" onClick={(e) => handleJoinRequest()}>
            Add Now
          </Button>
          :
          <Button disabled variant="contained">
            Add Now
          </Button>
          }
        </DialogActions>
      </Dialog>



      <ToastContainer />
    </Fragment>
  );
};

export default GroupLoungePage;
