import { Grid, CircularProgress } from "@mui/material"; 
import React, { useContext, useEffect, useState } from "react"; 
import axios from "axios"; 
import { Link, useNavigate } from "react-router-dom";
import { localBusinessPackages } from "../../api/Api";
import { UserContext } from "../../utils/UserContext";

function ChoosePlan() {
  const [commuinity, setCommuinity] = useState("");
  const navigate = useNavigate();
  const [packages, setPackages] = useState(null);
  const [businessFound, setbusinessFound] = useState(false);
  const { msDetails, userDetails } = useContext(UserContext);
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event) => {
    setCommuinity(event.target.value);
  };

  const getAllPackages = () => {
    setIsLoading(true);
    let config = {
      method: 'get',
      url: `${localBusinessPackages}?sort_by=id&order=asc`,
      headers: { 
        'Authorization': `Bearer ${token}`,
      },
    };
    
    axios.request(config)
    .then((response) => { 
      setPackages(response.data.data);
      if (response.data.data.length > 0) {
        response.data.data.forEach(element => {
          if (element.meta.create_by === userDetails.id) {
            setbusinessFound(true);
          }
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching packages:", error);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };
  
  useEffect(() => { 
    getAllPackages(); 
  }, []); 

  return (
    <>
      <div className="busines-plan-content">
        <h2>Choose Your Plan</h2>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {packages !== null && packages.length > 0 && packages.map((data, key) => ( 
              <Grid item lg={4} md={4} sm={12} xs={12} key={key}>
                <div className={`plan-card plan-card-${key + 1}`}> 
                  <div className="plan-card-top">
                    <div className="card-left"> 
                      <span>{data.package_name}</span>
                    </div>
                    <div className="card-right">
                      <h3>${data.package_value}</h3>
                      <p>Per Month</p>
                    </div>
                  </div>
                  
                  <div className="packages_details">
                    <h4>{data.special_note}</h4>
                    <div dangerouslySetInnerHTML={{ __html: data.package_details }} /> {/* Safely rendering HTML */}
                  </div> 
                  
                  <Link to={`/choose-commuinity?package_id=${data.id}`}>
                    <div className="plan-select-btn">Select Plan</div>
                  </Link>
                </div>
              </Grid>
            ))}
            {packages !== null && packages.length === 0 && (
              <div className="placeholder_text">
                No packages are available 
              </div>
            )} 
          </Grid>
        )}
      </div>
    </>
  );
}

export default ChoosePlan;
