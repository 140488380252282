import React, { useState } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import SyncIcon from '@mui/icons-material/Sync';
import EditIcon from '@mui/icons-material/Edit';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const QuestionForm = ({setValue,values,questions,handleAddQuestion, handleQuestionChange, handleAnswerChange, handleRemoveQuestion}) => {
  
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const index = 1

    const handleToggle = (id, type)=>{
        if(type === 'title'){
            document.getElementById(`${id}+update`).style.display = 'block !important'
            document.getElementById(`${id}+title`).style.display = 'none !important'
        }else{
            document.getElementById(`${id}+update`).style.display = 'none !important'
            document.getElementById(`${id}+title`).style.display = 'block !important'
        }
    }

  return (
    <Box sx={{mt:4, mb:2}}>
      
      {/* {questions.map((question, index) => (
        <Box sx={{mt:2, mb:3}} key={index}>
            <Grid container >
                <Grid lg={8}>
                    <TextField
                        label={`Question ${index + 1}`}
                        variant="outlined"
                        fullWidth
                        value={question.question}
                        onChange={(e) => handleQuestionChange(index, e)}
                    />
                </Grid>
                <Grid lg={2}>
                    <FormControl sx={{ml:1}} variant="outlined" fullWidth >
                    <InputLabel id={`answer-label-${index}`}>Answer</InputLabel>
                    <Select
                        labelId={`answer-label-${index}`}
                        value={question.answer}
                        onChange={(e) => handleAnswerChange(index, e)}
                        label="Answer"
                        >
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                        <MenuItem value="no comments">No Comments</MenuItem>
                    </Select>
                    </FormControl>
                </Grid>
                <Grid lg={2}>
                    <Box display={'flex'}>
                        <Box>
                            <Button
                                variant='outlined'
                                color="info"
                                sx={{ml:2, mt:1}}
                                onClick={() => handleRemoveQuestion(index)}
                                >
                                Add
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                variant='outlined'
                                color="error"
                                sx={{ml:1, mt:1}}
                                onClick={() => handleRemoveQuestion(index)}
                                >
                                <DeleteForeverIcon />
                            </Button>
                        </Box>
                    </Box>
                
                </Grid>
            </Grid>
        </Box>
      ))} */}

<div>
    {questions && questions.map((question, key)=>{
        return(
            <Accordion key={key} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    {/* title */}
                    <Box  id={`${question?.id+'title'}`} display={'flex'} alignItems={'center'} justifyContent={'space-between'}> 
                        <Box><Typography>Collapsible Group Item #1</Typography></Box>
                        <Box onClick={(e)=> handleToggle(`${question?.id+'title'}`, 'title')} sx={{ml:3}}><EditIcon /></Box>
                    </Box>
                    {/* update */}
                    <Box sx={{visibility:'hidden'}} id={`${question?.id+'update'}`} display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <TextField
                            label={`Question ${index + 1}`}
                            variant="filled"
                            fullWidth  // Ensures TextField takes full width available
                            value={'s'}  // Replace with your dynamic value from state or props
                            onChange={(e) => handleQuestionChange(index, e)}
                        />
                        <Box display="flex">
                            <Button
                                variant='outlined'
                                color="info"
                                sx={{ ml: 2, mt: 1 }}
                                onClick={() => handleAddQuestion()}  // Ensure this adds a new question
                            >
                            <SyncIcon />
                        </Button>
                        <Button
                            variant='outlined'
                            color="error"
                            sx={{ ml: 1, mt: 1 }}
                            onClick={() => handleRemoveQuestion(index)}  // Ensure this removes the correct question
                        >
                            <DeleteForeverIcon />
                        </Button>
                            <Button onClick={(e)=> handleToggle(question?.id, 'update')}>Cancel</Button>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                    sit amet blandit leo lobortis eget.
                </Typography>
                </AccordionDetails>
            </Accordion>
  
        )
    })}
     
    </div>
      
      <Box display={'flex'} justifyContent={'space-between'}>
            <Box></Box>
            <Box sx={{mt:1, mb:2}}>
                <Button className={values?.storePollResponse !==null?'MuiBtn_create':'MuiBtn_create_disabled' } variant="contained" onClick={(e)=> handleAddQuestion()}>
                    Add Question
                </Button>
            </Box>
        </Box>

    </Box>
  );
};

export default QuestionForm;
