import React from 'react'
import eroorImg from "../../asset/image/payError.png";
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';


function PaymentFailedMsg() {
  return (
    <>
     <div className="payment-msg">
          <h2>Payment Confirmation</h2>

          <div className="msg-content">
              <img src={eroorImg} alt="" />
              <h3> Payment has been failed </h3>
              <div>
               <Button variant="text"> Try Again </Button>
              </div>



              <Link to='/localBusiness'>
                  <Button variant="outlined" size="small" sx={{mt:5}}> <ArrowBackIcon/> Return to community business </Button>
              </Link>
          </div>
      </div>
    </>
  )
}

export default PaymentFailedMsg