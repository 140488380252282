import React from 'react'
import { baseUrl } from '../../../api/Api'
import { ProfileCoverLoadEffect } from '../../PageLoadEffects'
import LazyImage from '../../LazyImage'
import noImage from '../../../asset/image/noImageVerticle.png'

const BroadcastSingleCategoryDetails = ({details, loading}) => {
  return (
    <div className="broadcast-category">
         <h4>{details?.title}</h4>
        {loading && <ProfileCoverLoadEffect/>}

        {!loading && 
            <div className="broadcast-single-category">
                <div className="item-img">
                    <LazyImage src={details?.thumb?`${baseUrl}/${details?.thumb}`:noImage} alt={details?.title} width={'100%'} height={'100%'} />
                </div>
                {/* <div className="item-details">
                    <h4>{details?.title}</h4>
                    {details?.details && <p>{parser(details?.details)}</p>}
                </div> */}
            </div>
        }
        
    </div>
  )
}

export default BroadcastSingleCategoryDetails