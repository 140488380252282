import { Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { usePayStationPaymentMutation } from "../../../api/slices/payStattionApiSlice";
import { useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import Swal from "sweetalert2";

const PayStationPaymentCheckOut = ({ params }) => {
  // const navigate = useNavigate()
  var token = localStorage.getItem("token");
  const [payStationPayment, { isLoading }] = usePayStationPaymentMutation();
  const [paymentData, setPaymentData] = useState({
    invoice_number: `${params?.type + "_"}${params?.themeId}`,
    // invoice_number: "werwerfwe3231dea&1199",
    currency: "BDT",
    payment_amount: 0,
    reference: "",
    cust_name: "",
    cust_phone: "",
    cust_email: "",
    cust_address: "",
    callback_url: "https://community.icircles.app/matrimony-theme",
    checkout_items: "",
  });
  const handlePayStationPaymentSubmit = async () => {
    // console.log("Payment button clicked", paymentData);
    try {
      const result = await payStationPayment({ paymentData, token });
      if (result?.data?.status === "success") {
        // console.log("payment url", result?.data?.payment_url);
        window.open(result?.data?.payment_url, "_blank");
        // navigate(result?.data?.payment_url)
      }

      // console.log(result)

      if (result?.data?.status === "failed") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result?.data?.message,
          // footer: '<a href="#">Why do I have this issue?</a>',
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const handlePaymentInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setPaymentData({
      ...paymentData,
      [name]: value,
    });
  };
  return (
    <div>
      <h4>Pay Bill By Pay Station</h4>
      <div className="paybill_form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handlePayStationPaymentSubmit();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                label="Invoice Number"
                name="invoice_number"
                // defaultValue={`${params?.type + "_"}${params?.themeId}`}
                defaultValue="werwerfwe3231dea&1199"
                onChange={handlePaymentInputChange}
                required
                id="fullWidth"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Payment Amount"
                type="number"
                name="payment_amount"
                onChange={handlePaymentInputChange}
                defaultValue={paymentData?.payment_amount}
                required
                id="fullWidth"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Customer Name"
                type="text"
                name="cust_name"
                onChange={handlePaymentInputChange}
                defaultValue={paymentData?.cust_name}
                required
                id="fullWidth"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Reference"
                type="text"
                name="reference"
                onChange={handlePaymentInputChange}
                defaultValue={paymentData?.reference}
                required
                id="fullWidth"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Customer Phone"
                type="text"
                name="cust_phone"
                onChange={handlePaymentInputChange}
                defaultValue={paymentData?.cust_phone}
                required
                id="fullWidth"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Customer Email"
                type="email"
                name="cust_email"
                onChange={handlePaymentInputChange}
                defaultValue={paymentData?.cust_email}
                required
                id="fullWidth"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Customer Address"
                type="text"
                name="cust_address"
                onChange={handlePaymentInputChange}
                defaultValue={paymentData?.cust_address}
                required
                id="fullWidth"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="currency"
                type="text"
                name="currency"
                onChange={handlePaymentInputChange}
                required
                defaultValue="BDT"
                id="fullWidth"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="CheckOut Items"
                type="text"
                name="checkout_items"
                onChange={handlePaymentInputChange}
                defaultValue={paymentData?.checkout_items}
                required
                id="fullWidth"
              />
            </Grid>
          </Grid>
          {isLoading ? (
            <>
              <button className="pay_button" disabled>
                <FadeLoader height={15} color="#ffffff" />
                Processing
              </button>
            </>
          ) : (
            <>
              <button className="pay_button" type="submit">
                Pay
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default PayStationPaymentCheckOut;
