import React from "react";
import { Grid, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChoosePlan from "../../components/postBusiness/ChoosePlan";

function ChoosePlanPage() {
  const navigate = useNavigate();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="content_body">
            <Tooltip title="Back">
              <div
                className="backArrow"
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
              </div>
            </Tooltip>
            <div className="post-business-container">
              <ChoosePlan/>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default ChoosePlanPage;
