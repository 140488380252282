import React, { Fragment, useContext, useState } from "react";
import {Box, Button, Grid, Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import CommunitySetUp from "../../components/CommunitySetUp/CommunitySetUp";
import AdminBugSetUp from "../../components/BugReport/AdminBugSetUp";
import MyAdminBugList from "../../components/BugReport/MyAdminBugList";
import MagazineMenuList from "../../components/Magazine/MagazineMenuList";
import AddressList from "../../components/Address/AddressList";
import MultipleGlobalUploader from "../../components/Uploader/MultipleGlobalUploader";
import { useForm } from "react-hook-form";
import DownloadIcon from '@mui/icons-material/Download';
import { addressBulkUrl } from "../../api/Api";
import { notifyError } from "../../utils/Toast";
import { UserContext } from "../../utils/UserContext";
import axios from "axios";
import MainLoader from "../../components/PageLoadEffects/MainLoader";

const AddressBulkUploadPage = () => {
  const navigate = useNavigate();

  const {msDetails,userDetails} = useContext(UserContext);
  const [loaderVisible, setLoaderVisible] = useState(null);

  const token = localStorage.getItem('token');

  const defaultValues = {
    file:null
  };

  const methods = useForm({ defaultValues });
  const { watch, setValue } = methods;
  const values = watch();

  const handleAdd = async () => {
    setLoaderVisible(true);
    let formData = new FormData(); //formdata object
    formData.append("microsite_id", msDetails.id);
    formData.append("csv_file", values.file[0].originFileObj);
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };
  
    var url = addressBulkUrl;
    axios
      .post(url, formData, config)
      .then((response) => {
        setLoaderVisible(false);
        navigate(-1)
        //notifySuccess();
      })
      .catch((err) => {
        notifyError();
      });
  };

  return ( 
    <Fragment>
      <Grid container spacing={2}>
         <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
         <Grid item xs={12} sm={12} md={8} lg={9}>
            <div className="content_body">
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box>
                  <Tooltip title="Back">
                      <div className="backArrow" onClick={(e)=>navigate(-1)}>
                          <ArrowBackIcon/>
                      </div> 
                  </Tooltip>
                </Box>
                <Box>
                  <a href="../../asset/file/housing_address_lists.csv" download="housing_address_lists.csv">
                    <Button variant="outlined" startIcon={<DownloadIcon />}>
                      Sample CSV
                    </Button>
                  </a>
                </Box>
              </Box>
              {/* <AdminBugSetUp /> */}
              <Box sx={{mt:2}}>
                <MultipleGlobalUploader setValue={setValue} name={'file'} />

                <Box sx={{mt:2}} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Button variant='contained' onClick={(e)=> handleAdd()}>Upload</Button>
                </Box>
              </Box>
            </div>
            {loaderVisible === true && <MainLoader/>}

         </Grid>
      </Grid>
    </Fragment>
  );
};

export default AddressBulkUploadPage;