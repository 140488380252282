import React from "react";
import LookBackList from "../../components/Broadcast/LookBack/LookBackList";
import { Grid } from "@mui/material";
import BroadcastTopNavigate from "../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate";

const LookBack = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <div className="broadcast-body">
              <BroadcastTopNavigate />
              <Grid container spacing={2}>
                <Grid item xs={10}>
                   <LookBackList />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default LookBack;
