import React, { Fragment } from "react";
import RegisterBody from "../../components/RegisterBody/RegisterBody";

const RegisterPage = () => {
  return (
    <Fragment>
      <RegisterBody/>
    </Fragment>
  );
};

export default RegisterPage;
