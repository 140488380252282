import React from "react";
import {
  Typography,
  Container,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Link,
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  Divider,
} from "@mui/material";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography variant="h4" gutterBottom>
            Welcome to Community Microsite
            <small>("Community Microsite," "we," "us," or "our")</small>
          </Typography>
        </Box>

        <Typography variant="h5" gutterBottom>
          Community Microsite Privacy Policy
        </Typography>

        <Typography paragraph>
          This Privacy Policy describes how we collect, use, and disclose your
          information when you use our mobile application (the "App").
        </Typography>
        <Typography paragraph>Information We Collect</Typography>
        <Typography paragraph>
          We collect the following information about you:
        </Typography>

        {/* ... Add other components as needed ... */}

        <Typography paragraph>
          <strong>Account Information:</strong> When you create an account with
          Community Microsite, we collect your username, email address, and any
          other information you choose to provide.
        </Typography>

        <Typography paragraph>
          <strong>Profile Information:</strong> You can choose to provide
          additional information to your profile, such as your name, biography,
          interests, and photo. Activity Information: We collect information
          about your activity on the App, such as the content you view and
          interact with, the groups you join, and the people you follow.
        </Typography>
        <Typography paragraph>
          <strong>Device Information:</strong> We collect information about the
          device you use to access the App, such as the device type, operating
          system, IP address, and unique device identifier.
        </Typography>

        <Typography variant="h4" style={{ marginTop: "20px" }} gutterBottom>
          How We Use Your Information
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          We use your information to:
        </Typography>
        <List>
          <ListItem>
            <Typography>
              <strong> ⚫ Provide and operate the App</strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Create and maintain your account</strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Send you notifications </strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Send you marketing communications </strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Analyze how you use the App </strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Personalize your experience </strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Find and connect you with people </strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Comply with legal requirements </strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Sharing Your Information </strong>
            </Typography>
          </ListItem>
        </List>
        <Typography paragraph>
          We may share your information with third-party service providers who
          help us operate the App. These service providers are contractually
          obligated to keep your information confidential and secure.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          We may also share your information with third parties if we are
          required to do so by law or in the good faith belief that such action
          is necessary to:
        </Typography>
        <List>
          <ListItem>
            <Typography>
              <strong> ⚫ Comply with a legal obligation</strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Protect the rights or safety of you or others</strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong>
                {" "}
                ⚫ Prevent or investigate fraud or other wrongdoing{" "}
              </strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Your Choices </strong>
            </Typography>
          </ListItem>
          <Divider />
        </List>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          You can control your privacy settings on the App. You can choose to:
        </Typography>
        <List>
          <ListItem>
            <Typography>
              <strong> ⚫ Make your profile private</strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Limit who can see your posts</strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong>⚫ Opt out of receiving marketing communications</strong>
            </Typography>
          </ListItem>
          <Divider />

          <ListItem>
            <Typography>
              <strong> ⚫ Data Retention </strong>
            </Typography>
          </ListItem>
          <Divider />
        </List>
        <Typography paragraph>
          We will retain your information for as long as your account is active
          or as needed to provide you with services. We will also retain your
          information as necessary to comply with legal obligations, resolve
          disputes, and enforce our agreements.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          Children's Privacy
        </Typography>
        <Typography paragraph>
          The App is not intended for children under the age of 13. We do not
          knowingly collect information from children under 13. If you are a
          parent or guardian and you believe that your child has provided us
          with information, please contact us. We will take steps to delete the
          information from our servers.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          Security
        </Typography>
        <Typography paragraph>
          We take reasonable steps to protect your information from unauthorized
          access, disclosure, alteration, or destruction. However, no internet
          or electronic storage system is 100% secure.
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography paragraph>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on the App.
        </Typography>

        {/* ... Repeat the above structure for other sections ... */}

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          16. Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions about these Terms, please contact us at{" "}
          <Link href="mailto:info@icircles.app">info@icircles.app</Link>.
        </Typography>
        <Typography paragraph>
          <strong>Effective Date: </strong> This Privacy Policy is effective as
          of March 8, 2024.
        </Typography>
      </Container>
    </div>
  );
};

export default PrivacyPolicyPage;
