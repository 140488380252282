import React from "react";
import BroadcastItem from "./BroadcastItem";
import { Box, Button, Grid } from "@mui/material";
import ItemEffect from "../../Skeleton/VideoBroadcast/ItemEffect";
import { useNavigate } from "react-router-dom";

const BroadcastRelatedItemSection = ({broadcastItem,details, loading}) => {
  const navigate = useNavigate();
  const storeActiveItem = [];
  if(broadcastItem && broadcastItem?.data && broadcastItem?.data?.length>0){
    broadcastItem?.data.forEach(item => {
      if(details?.id ===item?.category_id && item?.status ===1){
        storeActiveItem.push(item)
      }
    });
  }

 // handle details item
  const handleSinglItem = (uuid)=>{
      navigate(`/broadcast-details/${uuid}`)
  }

  return (
    <>
      <div className="broadcast-item-section">
        <h4> Videos </h4>

        {loading && <ItemEffect />} 
        {!loading && (storeActiveItem?.length ===0) &&
          <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}>
            <Button disabled>{'No Media Data Found...'}</Button>
          </Box>
         }
        <Grid container spacing={2}>
          {storeActiveItem && storeActiveItem?.length>0 && storeActiveItem.map((item, i)=>{
            return(
              <Grid item lg={4} md={6} sm={12} xs={12} key={item?.uuid}>
                <BroadcastItem
                  data={item}
                  video={item?.video}
                  img={item?.thumb}
                  title={item?.title}
                  userAvatar={item?.user?.avatar}
                  userName={item?.user?.name}
                  handleSinglItem={handleSinglItem}
                />
              </Grid>
            )
          })}
        </Grid>
      </div>
    </>
  );
};

export default BroadcastRelatedItemSection;
