import React, { useContext, useState } from 'react'
import UploadEditForm from '../../components/Broadcast/BroadcastUpload/UploadEditForm'
import BroadcastTopNavigate from '../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate'
import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useGetBroadcastCategoryDataQuery, useGetSingleBroadcastItemDataQuery } from '../../api/slices/braodcastApiSlice'
import { UserContext } from '../../utils/UserContext'
import MainLoader from '../../components/PageLoadEffects/MainLoader'

const BroadcastUploadEditForm =()=> {
  const params = useParams()
  const {msDetails} = useContext(UserContext)
  const microsite_id = msDetails?.id;
  const singleUuid = params?.uuid

  const token = localStorage.getItem('token');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const {data: getSingleBroadcastItemData, isFetching:getSingleBroadcastItemDataFeting, isError:getSingleDataError} = useGetSingleBroadcastItemDataQuery({token,singleUuid,microsite_id})
  const {data: getBroadcastCategoryData,isFetching: getDataCategoryFetching,isError: getDataError} = useGetBroadcastCategoryDataQuery({ token,microsite_id,currentPage,searchValue});

  return (
    <>
     <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <div className="broadcast-body">
              <BroadcastTopNavigate  />

              {(!getSingleBroadcastItemDataFeting && !getDataCategoryFetching) && <UploadEditForm singleData ={getSingleBroadcastItemData} allCategory={getBroadcastCategoryData} /> }
              {(getSingleBroadcastItemDataFeting || getDataCategoryFetching) && <MainLoader/>} 

            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default BroadcastUploadEditForm