import React from 'react'
const CategoryItem =(props)=> {
  const { title, handleSingleCategory } = props

  return (
    <>
      <div className="broadcast-category-item" onClick={handleSingleCategory}>
          <div className="item-img">
            {/* <LazyImage src={img?img:noImageVerticle} alt="" />   */}
             {/* <img src={img} alt="" /> */}
          </div>
          <div className="item-title">
             {title}
          </div>
      </div>
    </>
  )
}

export default CategoryItem