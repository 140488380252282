import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import SubItemEffect from "../../Skeleton/VideoBroadcast/SubItemEffect";
import PollIItem from "./PollIItem";
import PollCard from "./PollCard";

const PollsReviewItemList = ({pollDetails,pollItem,singleUuid,setScrollTop,loading }) => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(true)
  
  useEffect(()=>{
    setTimeout(()=>{
      setTimer(false)
    },1500)
  },[timer])

 // handle details item
 const handleSinglItem = (data)=>{
  navigate(`/poll/${data?.uuid}`, {state:{pollDetails:data}})
  }
  return (
    <>
    {loading && <SubItemEffect />} 
        {!loading && (pollItem?.length ===0) &&
          <>
          {!timer
            ? <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}>
                <Button disabled>{'No Poll Data Found...'}</Button>
              </Box>
            : <SubItemEffect />
          }
          </>
      }
      <div className="broadcast-sub-item-list">
      <div className="broadcast-poll-item">
        <div className="polls_right">
          <Box sx={{m:3}}>
             <Typography variant="h6" gutterBottom> 
                Leaderboard
             </Typography>
          </Box>

          <Box className='poll_leader_board'>
            <Box className='firstPrize'>
              <Box>
                <Box className='prizeTitle'>Naruto</Box>
                <Box className='prizePosition'>1</Box>
              </Box>
            </Box>
            <Box className='secondPrize'>
              <Box>
                <Box className='prizeTitle'>One Piece</Box>
                <Box className='prizePosition'>2</Box>
              </Box>
            </Box>
            <Box className='thirdPrize'>
              <Box>
                <Box className='prizeTitle'>Dragon Ball</Box>
                <Box className='prizePosition'>3</Box>
              </Box>
            </Box>
          </Box>
          <Box className='rewardBtn'>
            <Button
              onClick={(e)=> navigate('/poll-reward')}
              className="btn" 
              variant="contained" 
              sx={{backgroundColor:'#7D6FDD !important'}}>
              Go to reward center
            </Button>
          </Box>
          
        </div>
      </div>
      </div>
    </>
  );
};

export default PollsReviewItemList;
