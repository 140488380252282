import { Backdrop, Box, Card, Grid, IconButton, Table, TableBody, TableContainer, TablePagination, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import BroadcastTopNavigate from '../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate'
import { ReactSession } from "react-client-session";
import useTable from '../../hooks/useTable';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import TableToolbar from '../../components/table/TableToolbar';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions, TableSkeleton } from '../../components/table';
import { UserContext } from '../../utils/UserContext';
import { useDeleteBroadcastCategoryMutation, useGetBroadcastCategoryDataQuery } from '../../api/slices/braodcastApiSlice';
import BroadcastCategoryTableRow from '../../components/table/Broadcast/BroadcastCategoryTableRow';
import MainLoader from '../../components/PageLoadEffects/MainLoader';


const CategoryPage = () => {
  ReactSession.setStoreType("sessionStorage");
 
  const TABLE_HEAD = [
    { id: "title", label: "Title", align: "left" },
    { id: "type", label: "Type", align: "left" },
    { id: "status", label: "Status", align: "left" },
    { id: "action", label: "Actions", align: "left" },
    { id: "" },
  ];
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangeRowsPerPage,
  } = useTable();


  const {msDetails,userDetails} = useContext(UserContext)


  const navigate = useNavigate();
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [tableData, setTableData] = useState([]);

  const getMicrositeInfo = ReactSession.get("MicrositeInfo");
  const microsite_id = msDetails?.id;

  const [filterName, setFilterName] = useState("");


  const token = localStorage.getItem('token');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')



  const {data: getBroadcastCategoryData, isFetching: getDataFetching, isError:getDataError} = useGetBroadcastCategoryDataQuery({token,microsite_id,currentPage,searchValue});
  const [deleteBroadcastCategory, response] = useDeleteBroadcastCategoryMutation()
  

  useEffect(()=>{
    if(getBroadcastCategoryData?.data){
      setTableData(getBroadcastCategoryData?.data);
      setLastPage(getBroadcastCategoryData?.last_page);
      setTotalLength(getBroadcastCategoryData?.total)
    }
  },[getBroadcastCategoryData])


  const handleDeleteRow = (uuid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBroadcastCategory({token,uuid})
      }
    });
  };

  const handleEditRow = (row) => {
    navigate('/broadcast-category-create', {
      state: { uuid:row.uuid },
    });
  };



  const denseHeight = dense ? 52 : 72;
  
    const handleSearch = (e) => {
      e.preventDefault();
      const searchValue = e.target.value;
      setSearchValue(searchValue)
    };
  
    const handleChangePage = (event, newPage) => {
      setLoaderVisible(true)
      setPage(newPage);
      var updatePage;
      if(newPage === currentPage){
        updatePage = newPage+1;
      }else{
        updatePage = currentPage-1;
      }
      if(updatePage <=lastPage){
          setCurrentPage(updatePage)
      }
    };

    const handleFilterName = (filterName) => {
      setFilterName(filterName);
      setSearchValue(filterName)
      setPage(0);
    };


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <div className="broadcast-body">
              <BroadcastTopNavigate />
                
                <Card sx={{mt:4}} className="overflow_card">
                  <TableToolbar
                    filterName={filterName}
                    onFilterName={handleFilterName}
                    placeholder={'Search Category...'}
                    addButtonFor={'admin'}
                    path={'/broadcast-category-create'}
                    // path={'/magazine-menu'}
                    />
                    <TableContainer sx={{ minWidth: 800 }} >
                        <Table size={dense ? "small" : "medium"}>
                            <TableHeadCustom
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={tableData.length}
                                onSort={onSort}
                                />
                        <TableBody>
                        {tableData.length>0 && tableData.map((row, index) => (
                            <BroadcastCategoryTableRow
                                key={row.id}
                                row={row}
                                index={index}
                                selected={selected.includes(row.id)}
                                onSelectRow={() => onSelectRow(row.id)}
                                onDeleteRow={() => handleDeleteRow(row.uuid)}
                                onEditRow={() => handleEditRow(row)}
                              />
                            ))} 
                        </TableBody>
                    </Table>
                    </TableContainer>
                <Box sx={{ position: "relative" }}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={totalLength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    />
                    {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={onChangeDense} />}
                    label="Dense"
                    sx={{ px: 3, py: 1.5, top: 0, position: { md: "absolute" } }}
                    /> */}
                </Box>
                </Card>
             
            </div>
          </div>

          {/* {getDataFetching && <MainLoader/>}  */}
        </Grid>
      </Grid>
    </>
  )
}

export default CategoryPage