import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import SubItemEffect from "../../Skeleton/VideoBroadcast/SubItemEffect";
import PollIItem from "./PollIItem";
import PollCard from "./PollCard";

const PollsItemList = ({pollDetails,pollItem,singleUuid,setScrollTop,loading }) => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(true)
  
  useEffect(()=>{
    setTimeout(()=>{
      setTimer(false)
    },1500)
  },[timer])

 // handle details item
 const handleSinglItem = (data)=>{
  navigate(`/poll/${data?.uuid}`, {state:{pollDetails:data}})
  }
  return (
    <>
    {loading && <SubItemEffect />} 
        {!loading && (pollItem?.length ===0) &&
          <>
          {!timer
            ? <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}>
                <Button disabled>{'No Poll Data Found...'}</Button>
              </Box>
            : <SubItemEffect />
          }
          </>
      }
      <div className="broadcast-sub-item-list">
      <div className="broadcast-poll-item">
        <div className="polls_right">
          <Box sx={{m:3}}>
              Previous Polls
          </Box>
          <hr />
          {pollItem && pollItem?.length>0 && pollItem.map((item, i)=>{
            var active = false
              if(item?.id === pollDetails.id){
                active = true
              }
              return(
                  <PollCard data={item} key={i} active={active} handleSinglItem={handleSinglItem} />
                )
          })}
        </div>
      </div>
      </div>
    </>
  );
};

export default PollsItemList;
