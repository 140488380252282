import React, { Fragment, useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import mProfile from "../../asset/image/test4.jpg";
import { baseUrl, matrimonyUrl } from "../../api/Api";
import { UserContext } from "../../utils/UserContext";
import axios from "axios";
import MainLoader from "../../components/PageLoadEffects/MainLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useGetMatrimonyQuery } from "../../api/slices/matrimonyApiSlice";
import AllUserEffect from "../../components/Skeleton/Matrimony/AllUserEffect";
import Swal from "sweetalert2";
import { notifyError } from "../../utils/Toast";
import { ToastContainer } from "react-toastify";
import LockPersonIcon from '@mui/icons-material/LockPerson';

const MatrimonialPage = () => {
  const navigate = useNavigate();
  const { msDetails, userDetails } = useContext(UserContext);
  const msID = msDetails?.id;
  const token = localStorage.getItem("token");

  const [storeProfile, setStoreProfile] = useState(null)
  const [searchQuery, setSearchQuery] = useState('');

  const {data: getMatrimonyData,  isFetching: getDataFetching, isError:getDataError} = useGetMatrimonyQuery({token,msID,searchQuery});
  const [users, setUsers] = useState([]);


  useEffect(()=>{
    if(getMatrimonyData && getMatrimonyData?.data.length>0){
      setUsers(getMatrimonyData?.data)
    }
      if(getMatrimonyData?.data && getMatrimonyData?.data.length>0){
          getMatrimonyData?.data.forEach(element => {
            if(element?.user_id  === userDetails.id){
              setStoreProfile(element)
              localStorage.setItem('matrimony_profile',JSON.stringify(element))
            }
          });
      }
  },[getMatrimonyData?.data, userDetails?.id, getMatrimonyData])
  const handleMyProfile = ()=>{
    if(storeProfile?.meta?.theme_id && storeProfile?.meta?.theme_id !==null && storeProfile?.meta?.theme_id !=='null'){
      const splitedTheme = storeProfile?.meta?.theme_id.split('-')
      navigate(`/matrimonial-user-theme/${splitedTheme[1]}/${splitedTheme[2]}`,{state:{data:storeProfile}})
    }else{
      navigate('/user-matrimony-profile',{state:{data:storeProfile}})
    }
  }

  // handle view details
  const handleViewDetails = async(e,data)=>{
    e.preventDefault();
    var splitedTheme;
    if(data?.meta?.theme_id && data?.meta?.theme_id !==null && data?.meta?.theme_id !=='null'){
       splitedTheme = data?.meta?.theme_id.split('-')
    }
    if(data?.meta?.pass_code && data?.meta?.pass_code !=='no' && data?.meta?.pass_code !=='No'){
      const { value: passcode } = await Swal.fire({
        inputLabel: "Need Passcode To See This Profile",
        input: "password",
        inputPlaceholder: "Enter Passcode",
        showCancelButton: true
      });
      if (passcode === data?.meta?.pass_code) {
        if(splitedTheme !==undefined){
          const splitedTheme = data?.meta?.theme_id.split('-')
          navigate(`/matrimonial-user-theme/${splitedTheme[1]}/${splitedTheme[2]}`,{state:{data:data}})
        }else{
          navigate('/user-matrimony-profile',{state:{data:data}})
        }
      }else{
        if(passcode !== undefined){
          Swal.fire('Invalid Passcode. Try Again!.')
        }
      }
    }else{
      if(splitedTheme !==undefined){
        const splitedTheme = data?.meta?.theme_id.split('-')
        navigate(`/matrimonial-user-theme/${splitedTheme[1]}/${splitedTheme[2]}`,{state:{data:data}})
      }else{
        navigate('/user-matrimony-profile',{state:{data:data}})
      }
    }
  }

  
  return (
    <Fragment>
      {/* {(getDataFetching) === true && <MainLoader />} */}
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="content_body">
            <Tooltip title="Back">
              <div
                className="backArrow"
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
              </div>
            </Tooltip>

            <div className="section_headear section_header_2">
              <h4>Matrimonial Center</h4>
              <div className="btns_row">
                {(getDataFetching === false) && (users && users.length === 0) ?
                 <a onClick={(e)=> navigate('/user-matrimony-profile-create')}>
                  <div className="Btn_one">
                      Create
                  </div>
                  </a>
                  :
                  <a>
                  {!getDataFetching &&
                      <Box className="Btn_one" onClick={(e)=> handleMyProfile()}>
                          My Matrimonial Profile
                      </Box>}
                    </a>
                 }
              </div>
            </div>

          
          <div className="matromonial_search_form">  
            <div className="searchBar">
              <input type="text" placeholder="Search Here"
                autocomplete="off"
                className="form_control"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                />
                <i><Button type="submit">
                  <FontAwesomeIcon icon={faSearch}/></Button>
                </i>
            </div>
          </div>

          {getDataFetching === false && users && users.length === 0 && (
              <Box display="flex" justifyContent="center" justifyItems="center">
                <Button disabled>No post found...</Button>
              </Box>
            )}

            <div className="matrimonial_wrapper">
              <Grid container spacing={2}>
                {getDataFetching === false &&
                  users &&
                  users?.length > 0 &&
                  users?.map((data, key) => {
                    return (
                      <Grid item lg={4} md={6} sm={12} xs={12} key={data.uuid}>
                        <div className="matrimonial_card">
                          <div className="card_top">
                            <div className="card_profil">
                              {data?.featured_image !==null && <img src={`${baseUrl}/${data.featured_image}`} alt={data?.name} />}
                            </div>
                            <div className="person_name">{data?.name}</div>
                            <div className="DOB">DOB : {data?.meta?.dob}</div>
                          </div>
                          <div className="card_content">
                            <div className="content_box">
                              <div className="box_content_item">
                                <div className="box_item">Marital Status</div>
                                <div className="box_item bold_text">
                                  {data?.meta?.marital_status}
                                </div>
                              </div>

                              <div className="box_content_item">
                                <div className="box_item">Location</div>
                                <div className="box_item bold_text">{data?.location}</div>
                              </div>

                              <div className="box_content_item">
                                <div className="box_item">Religion</div>
                                <div className="box_item bold_text">{data?.meta?.religion}</div>
                              </div>
                              <div className="box_content_item">
                                <div className="box_item">Gender</div>
                                <div className="box_item bold_text">{data?.meta?.gender}</div>
                              </div>
                              <div className="box_content_item">
                                <div className="box_item">Height</div>
                                <div className="box_item bold_text">{data?.meta?.height}</div>
                              </div>
                              <div className="box_content_item">
                                <div className="box_item">Weight</div>
                                <div className="box_item bold_text">{data?.meta?.weight}</div>
                              </div>
                              <div className="box_content_item">
                                <div className="box_item">Blood Group</div>
                                <div className="box_item bold_text">{data?.meta?.blood_group}</div>
                              </div>
                              {data?.meta?.complexion !=='undefined' && 
                                <div className="box_content_item">
                                  <div className="box_item">Complexion</div>
                                  <div className="box_item bold_text">{data?.meta?.complexion}</div>
                                </div>}
                              
                            </div>

                            {/* <div className="view_details" onClick={(e)=> navigate('/matrimonial-details',{state:{data}})}>View Details</div> */}

                            {data?.meta?.pass_code && data?.meta?.pass_code !=='no' && data?.meta?.pass_code !=='No' 
                            ? <div className="view_details" onClick={(e)=> handleViewDetails(e,data)}><LockPersonIcon /> View Details</div>
                            : <div className="view_details" onClick={(e)=> handleViewDetails(e,data)}>View Details</div>
                            }
                           
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          </div>

          {getDataFetching ===true && <Box>
            <Grid container spacing={3}>
              <Grid item sm={4}><AllUserEffect/></Grid>
              <Grid item sm={4}><AllUserEffect/></Grid>
              <Grid item sm={4}><AllUserEffect/></Grid>
            </Grid>
          </Box>}

          
        </Grid>
      </Grid>
      <ToastContainer />
    </Fragment>
  );
};

export default MatrimonialPage;
