import React, { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "../utils/UserContext";
import LoungePage from "./LoungePages/LoungePage";
import { useNavigate } from "react-router-dom";
import { notifyError } from "../utils/Toast";
import { Link } from "react-router-dom"; // Import Link for navigation

const HomePage = () => {
  const navigate = useNavigate();
  const { msDetails } = useContext(UserContext);
  const getUserInfo = localStorage?.getItem('loggedInUserInfo');
  const storeUserDetailsParse = JSON?.parse(getUserInfo);
  const [state, setState] = useState(true);
  const getUrl = window.location.href;
  const segNamae = getUrl.split("/").pop();

  // New state to handle notification visibility
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (storeUserDetailsParse?.status === 2 && segNamae !== 'newCommuinity' && segNamae !== 'community-packeges') {
      notifyError('Your membership confirmation is waiting for pending approval!.Please accept the invitation!');
      setTimeout(() => {
        navigate('/community-info-public', { state: { uuid: msDetails.uuid, memberStatus: 2, reload: true } });
      }, 100);
    }
    if (storeUserDetailsParse?.status === 3 && segNamae !== 'newCommuinity' && segNamae !== 'community-packeges') {
      notifyError('You are blocked from this community!');
      setTimeout(() => {
        navigate('/commuinityList');
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (storeUserDetailsParse?.status === 1 && storeUserDetailsParse?.user_details) {
      const parseData = JSON.parse(storeUserDetailsParse?.user_details);
      if (!parseData?.name) {
        // Show notification if the name is missing
        setShowNotification(true);
      }
    }
  }, [getUserInfo, storeUserDetailsParse]);

  return (
    <Fragment>
      <LoungePage />

      {/* Conditionally render notification sticker */}
      {showNotification && (
        <div style={notificationStyle}>
          <p>
            Please complete your profile to enjoy all features!{" "}
            <Link to="/complete-profile" style={linkStyle}>
              Complete Profile
            </Link>
          </p>
        </div>
      )}
    </Fragment>
  );
};

// Basic styling for the notification
const notificationStyle = {
  position: "fixed",
  bottom: "20px",
  right: "20px",
  backgroundColor: "#f8d7da",
  color: "#721c24",
  padding: "15px",
  borderRadius: "8px",
  boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
  zIndex: 1000,
};

const linkStyle = {
  color: "#0056b3",
  textDecoration: "underline",
};

export default HomePage;
