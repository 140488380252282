import { Grid } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { ShimmerCircularImage } from "react-shimmer-effects";
import { ShimmerPostItem } from "react-shimmer-effects";

const ItemEffect = () => {
  return (
    <Fragment>
    <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
            <ShimmerPostItem card  text  imageType="thumbnail" imageWidth={100} imageHeight={40}/>
        </Grid>
        <Grid item xs={12} md={4}>
            <ShimmerPostItem card  text  imageType="thumbnail" imageWidth={100} imageHeight={40}/>
        </Grid>
        <Grid item xs={12} md={4}>
            <ShimmerPostItem card  text  imageType="thumbnail" imageWidth={100} imageHeight={40}/>
        </Grid>
        <Grid item xs={12} md={4}>
            <ShimmerPostItem card  text  imageType="thumbnail" imageWidth={100} imageHeight={40}/>
        </Grid>
        <Grid item xs={12} md={4}>
            <ShimmerPostItem card  text  imageType="thumbnail" imageWidth={100} imageHeight={40}/>
        </Grid>
        <Grid item xs={12} md={4}>
            <ShimmerPostItem card  text  imageType="thumbnail" imageWidth={100} imageHeight={40}/>
        </Grid>
        
    </Grid>
    </Fragment>
  );
};

export default ItemEffect;
