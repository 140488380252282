import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  CircularProgress,
  Card,
  Typography,
  Avatar,
  TextField,
  Box,Autocomplete
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../utils/UserContext";
import { ToastContainer } from "react-toastify";
import CallIcon from "@mui/icons-material/Call";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import FacebookIcon from '@mui/icons-material/Facebook';
import CategoryIcon from '@mui/icons-material/Category';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { notifyError, notifySuccess } from "../../utils/Toast";
import Swal from "sweetalert2";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  baseUrl,
  micrositeDetailsUrl,
  localBusinessUrl,
  localBusinessMediaUrl,
  localBusinessPackages
} from "../../api/Api";
import { Upload } from "antd";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"; // Import Google Maps components

function BusinessSetup() {
  const GOOGLE_MAPS_API_KEY = "AIzaSyAOVYRIgupAurZup5y1PRh8Ismb1A3lLao"; // Replace with your API key
  const [selectedMicrosite, setSelectedMicrosites] = useState(null);
  const [social_page, setSocialPage] = useState(null);
  const [isEditing, setIsEditing] = useState({}); // State to manage which field is being edited
  const [editValues, setEditValues] = useState({
    title: "",
    subtitle: "",
    phone: "",
    email: "",
    address: "",
    location: "",
    details: "",
    websiteUrl: "",
    linkedin: "",
    fb: "",
    instagram: "",
    youtube: "",
    twitter: ""
  }); 
  const [typeSuggestions, setTypeSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
 // State to store edited values
  const [logoFile, setLogoFile] = useState(null); // State for logo file
  const [bannerFile, setBannerFile] = useState(null); // State for banner file
  const logoInputRef = useRef(null); // Ref to the hidden file input for logo
  const bannerInputRef = useRef(null); // Ref to the hidden file input for banner
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation(); // Hook to get route location state
  const [isSubmitting, setIsSubmitting] = useState(false); // Loader state for form submission
  const token = localStorage.getItem("token"); // Assuming the token is stored in local storage
  const { msDetails, userDetails } = useContext(UserContext);
  const [fileList, setFileList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const package_id = localStorage.getItem('package_id');
  const [mapVisible, setMapVisible] = useState(false); // State to control map popup visibility
  const [markerPosition, setMarkerPosition] = useState(null); // State to store marker position

  // Handle map click to set marker position
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    setEditValues((prevValues) => ({
      ...prevValues,
      location: `${lat}, ${lng}` // Store latitude and longitude as a comma-separated string
    }));
  };

  // Function to toggle map popup visibility
  const toggleMapPopup = () => {
    setMapVisible(!mapVisible);
  };
  // Fetch Package details
  const getPackageDetails = (package_id) => {
    let config = {
      method: "get",
      url: `${localBusinessPackages}/${package_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    axios
      .request(config)
      .then((response) => {
        console.log(response.data); 
      })
      .catch((e) => {
        console.error(e); 
      });
  };


  // Fetch business details
  const getMicrositeDetails = (business_uuid) => {
    let config = {
      method: "get",
      url: `${micrositeDetailsUrl}/${business_uuid}`,
    };
    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setSelectedMicrosites(response.data);
        setEditValues(response.data); // Initialize edit values with existing data
        setSocialPage(response.data?.social_page ? JSON.parse(response.data.social_page) : null);
        
        // Check if there's an existing video and set it in the videoList
        const existingVideoUrl = response.data?.meta?.video;
        if (existingVideoUrl) {
          setVideoList([
            {
              uid: "-1", // Unique identifier for the file
              name: "Microsite Video", // Display name for the file
              status: "done", // Status of the file upload (done means the file is already uploaded)
              url: `${existingVideoUrl}`, // URL to the existing video
            },
          ]);
        }
  
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const business_uuid = params.get("business_uuid");
    if (business_uuid) {
      getMicrositeDetails(business_uuid);
      getPackageDetails(package_id);
    } else {
      alert("Business or community not found!");
      navigate(-1);
    }
  }, []);

  // Toggle Edit Mode
  const handleEditClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: !prev[field] }));
  };

    // Function to update the form fields
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setEditValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }; 

  // Handle File Selection for Logo
  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoFile(file);
    }
  };

  // Handle File Selection for Banner
  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBannerFile(file); // Store the selected file
      // Optionally, upload the file immediately here or display a preview
      console.log("Selected banner file:", file);
    }
  };

  // Trigger File Input Click for Logo
  const handleLogoUploadClick = () => {
    if (logoInputRef.current) {
      logoInputRef.current.click(); // Trigger click on hidden file input
    }
  };

  // Trigger File Input Click for Banner
  const handleBannerUploadClick = () => {
    if (bannerInputRef.current) {
      bannerInputRef.current.click(); // Trigger click on hidden file input
    }
  };
   
  
  // Save Changes
  const handleSaveClick = (field) => {  
    if (field === "type") {
      setSelectedMicrosites((prevMicrosite) => ({
        ...prevMicrosite,
        subtype: {
          ...prevMicrosite.subtype,
          name: editValues.subtype?.name, // Update the subtype name
        },
      }));
    }
    setSelectedMicrosites((prevMicrosite) => ({
      ...prevMicrosite,
      [field]: editValues[field], // Save the updated value
    }));
    setIsEditing((prev) => ({ ...prev, [field]: false }));  
  };
  const handleCancelEdit = (field) => {
    setIsEditing(prevState => ({ ...prevState, [field]: false }));
    setEditValues(prevValues => ({ ...prevValues, [field]: selectedMicrosite[field] })); // Reset the edited value
  };
  
  // handle create new resource
  const handleSubmit = async () => {
    setIsSubmitting(true); // Start the loader effect   
    let data = new FormData();
    data.append("microsite_id", msDetails.id);
    data.append("user_id", userDetails.id);
    data.append("business_microsite_id", selectedMicrosite.id);
    data.append("title", editValues.name);
    data.append("subtitle", editValues.tagline);
    data.append("phone", editValues.entity_phone);
    data.append("email", editValues.entity_email);
    data.append("address", editValues.address);
    data.append("location", editValues.location);
    data.append("type", editValues.type); 
    data.append("details", editValues.entity_details);
    data.append("website_url", editValues.website);
    data.append("linkedin", social_page.linkedin);
    data.append("facebook_url", social_page.fb);
    data.append("instagram_url", social_page.instagram);
    data.append("youtube_url", social_page.youtube);
    data.append("twitter_url", social_page.twitter);
    data.append("status", 0);
    data.append("package_id", package_id); 
    if (logoFile){
      data.append("logo", logoFile); // Append logo file if it exists
    } else{
      const imageUrl = `${selectedMicrosite?.entity_logo}`; 
      data.append("logoUrl", imageUrl);
    } 
    if (bannerFile){
      data.append("banner", bannerFile); // Append banner file if it exists
    }else{
      const bannerUrl = `${selectedMicrosite?.meta?.banner}`; 
      data.append("bannerUrl", bannerUrl);
    }

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        data.append("files[]", file.originFileObj);
      });
    } 

    const config = {
      method: "post",
      url: `${localBusinessUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => { 
        setIsSubmitting(false); // Stop the loader effect 
        // Navigate to the business payment page with response data as state
       navigate("/business-payment", { state: { data: response.data } });
      })
      .catch((error) => { 
        setIsSubmitting(false); // Stop the loader effect in case of error
      }); 
  };


  //handle delete a resouce media
  const handleDeleteResourceMedia = (uuid) => {
    Swal.fire({
      heightAuto: false,
      backdrop: false,
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsSubmitting(true);
        let config = {
          method: "delete",
          url: `${localBusinessMediaUrl}/${uuid}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        axios
          .request(config)
          .then((response) => {
            setIsSubmitting(false);
            //notifySuccess();
            window.location.reload();
          })
          .catch((err) => {
            setIsSubmitting(false);
            if (err?.response) {
              notifyError(err?.response?.data?.message);
            } else {
              notifyError("Something went wrong!.");
            }
          });
      }
    });
  };
  const fetchTypeSuggestions = async (inputValue) => {
    setLoading(true);
    try {
      const response = await axios.get("https://icircles.app/api/community/typesuggestion", {
        params: { query: inputValue }, // Assuming you need to send a query parameter
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setTypeSuggestions(response.data); // Ensure response contains an array with valid objects
    } catch (error) {
      console.error("Error fetching type suggestions", error);
    } finally {
      setLoading(false);
    }
  };
  
  // Handle input change in Autocomplete
  const handleInputChange2 = (event, newValue) => {
    setEditValues((prevValues) => ({
      ...prevValues,
      subtype: { ...prevValues.subtype, name: newValue },
    }));
  
    // Fetch suggestions based on input value length
    if (newValue.length >= 3) {
      fetchTypeSuggestions(newValue);
    }
  };
  

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onVideoChange = ({ fileList: newVideoList }) => {
    setVideoList(newVideoList);
  };
  const stripHtmlTags = (input) => {
    return input.replace(/<[^>]*>?/gm, '');  // Regular expression to match and remove HTML tags
  };
  
  const onVideoPreview = async (file) => {
    const url = file.url || (await getBase64(file.originFileObj));
    window.open(url, '_blank');
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  return (
    <>
      <div className="business-section">
        {isLoading ? (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {selectedMicrosite ? (
              <>
                {/* Header Section */}
                <div className="business-section-top">
                  <div className="business-section-banner">
                    <img
                      src={
                        bannerFile
                          ? URL.createObjectURL(bannerFile)
                          : `${baseUrl}/${selectedMicrosite?.meta?.banner}`
                      }
                      alt="Banner"
                      style={{
                        width: "100%",
                        height: "250px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="banner-edit">
                      <button
                        className="avatar-edit-btn"
                        onClick={handleBannerUploadClick}
                      >
                        <EditIcon sx={{ fontSize: "18px" }} />
                      </button>
                      <input
                        type="file"
                        accept="image/*"
                        ref={bannerInputRef}
                        style={{ display: "none" }}
                        onChange={handleBannerChange}
                      />
                    </div>
                  </div>
                  <div className="profile-info">
                    <div className="profile-avatar">
                      <Avatar
                        src={
                          logoFile
                            ? URL.createObjectURL(logoFile)
                            : `${baseUrl}/${selectedMicrosite?.entity_logo}`
                        }
                        alt={selectedMicrosite?.name}
                        sx={{
                          width: 130,
                          height: 130,
                          border: "3px solid white",
                          marginRight: "10px",
                        }}
                      />
                      <div className="avatar-edit">
                        <div
                          className="avatar-edit-btn"
                          onClick={handleLogoUploadClick}
                        >
                          <EditIcon sx={{ fontSize: "18px" }} />
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          ref={logoInputRef}
                          style={{ display: "none" }}
                          onChange={handleLogoChange}
                        />
                      </div>
                    </div>
                    <div className="user-data">
                      <div className="user-name">
                        {selectedMicrosite.name}{" "}
                        {selectedMicrosite.verification_status && selectedMicrosite.verification_status == 1 && (
                          <span>
                            Verified{" "}
                            <VerifiedIcon sx={{ fontSize: "13px", ml: 0.5 }} />
                          </span>
                        )}  
                      </div>
                      <em>
                        {isEditing["tagline"] ? (
                          <>
                            <TextField
                              name="tagline"
                              label="Tagline"
                              value={stripHtmlTags(editValues.tagline)}
                              onChange={handleInputChange}
                              fullWidth
                            />
                            <Button
                              size="small"
                              onClick={() => handleSaveClick("tagline")}
                              sx={{ ml: 1 }}
                            >
                              Save
                            </Button>
                            <Button
                              size="small"
                              onClick={() => handleCancelEdit("tagline")} // Close button to cancel editing
                              sx={{ ml: 1 }}
                            >
                              Close
                            </Button>
                          </>
                        ) : (
                          <>
                           <span className="field_data">{stripHtmlTags(selectedMicrosite.tagline)}</span> 
                            <Button
                              size="small"
                              onClick={() => handleEditClick("tagline")}
                              sx={{ ml: 1 }}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                      </em>

                      <h6 className="microsite-address">
                          {isEditing["address"] ? (
                            <>
                              <TextField
                                name="address"
                                label="Address"
                                value={editValues.address}
                                onChange={handleInputChange}
                                fullWidth
                              />
                              <Button
                                size="small"
                                onClick={() => handleSaveClick("address")}
                                sx={{ ml: 1 }}
                              >
                                Save
                              </Button>
                              <Button
                                size="small"
                                onClick={() => handleCancelEdit("address")} // Close button to cancel editing
                                sx={{ ml: 1 }}
                              >
                                Close
                              </Button>
                            </>
                          ) : (
                            <>
                              {selectedMicrosite.address}
                              <Button
                                size="small"
                                onClick={() => handleEditClick("address")}
                                sx={{ ml: 1 }}
                              >
                                Edit
                              </Button>
                            </>
                          )}
                        </h6>

                    </div>
                  </div>
                </div>

                <div className="microsite-details">
                    {isEditing["entity_details"] ? (
                      <>
                        <TextField
                          name="entity_details"
                          label="Details"
                          value={stripHtmlTags(editValues.entity_details)}
                          onChange={handleInputChange}
                          fullWidth
                          multiline
                          rows={4}
                        />
                        <Button
                          size="small"
                          onClick={() => handleSaveClick("entity_details")}
                          sx={{ ml: 1 }}
                        >
                          Save
                        </Button>
                        <Button
                          size="small"
                          onClick={() => handleCancelEdit("entity_details")} // Close button to cancel editing
                          sx={{ ml: 1 }}
                        >
                          Close
                        </Button>
                      </>
                    ) : (
                      <>
                        {stripHtmlTags(selectedMicrosite.entity_details)}
                        <Button
                          size="small"
                          onClick={() => handleEditClick("entity_details")}
                          sx={{ ml: 1 }}
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </div>


                {/* Info Section */}
                <div className="business-section-content">
                   {/* Type Section */}
                   <Box className="underlined-text" sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                  {isEditing["type"] ? (
                    <>
                      <Autocomplete
                        freeSolo
                        value={editValues.subtype?.name || ""}
                        onInputChange={handleInputChange2}
                        options={typeSuggestions.map((suggestion) => suggestion?.type || "")} // Map only valid strings
                        loading={loading}
                        getOptionLabel={(option) => option} // Ensure correct label handling
                        renderInput={(params) => (
                          <TextField {...params}  label="Type" name="stype" fullWidth />
                        )}
                        sx={{ width: '100%' }}
                      />
                      <Button
                        size="small"
                        onClick={() => handleSaveClick("type")}
                        sx={{ ml: 1 }}
                      >
                        Save
                      </Button>
                      <Button
                        size="small"
                        onClick={() => handleCancelEdit("type")}
                        sx={{ ml: 1 }}
                      >
                        Close
                      </Button>
                    </>
                  ) : (
                    <>
                      <Typography variant="body1">
                        <CategoryIcon sx={{ mr: 1 }} />
                        {selectedMicrosite.subtype?.name}
                      </Typography>
                      <Button
                        size="small"
                        onClick={() => handleEditClick("type")}
                        sx={{ ml: 1 }}
                      >
                        Edit
                      </Button>
                    </>
                  )}
                </Box>

                  {/* Phone Section */}
                  <Box className="underlined-text" sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                      {isEditing["entity_phone"] ? (
                        <>
                          <TextField
                            name="entity_phone"
                            label="Phone"
                            value={editValues.entity_phone}
                            onChange={handleInputChange}
                            fullWidth
                          />
                          <Button
                            size="small"
                            onClick={() => handleSaveClick("entity_phone")}
                            sx={{ ml: 1 }}
                          >
                            Save
                          </Button>
                          <Button
                            size="small"
                            onClick={() => handleCancelEdit("entity_phone")} // Close button to cancel editing
                            sx={{ ml: 1 }}
                          >
                            Close
                          </Button>
                        </>
                      ) : (
                        <>
                          <Typography variant="body1">
                            <CallIcon sx={{ mr: 1 }} />
                            {selectedMicrosite.entity_phone}
                          </Typography>
                          <Button
                            size="small"
                            onClick={() => handleEditClick("entity_phone")}
                            sx={{ ml: 1 }}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </Box>


                  {/* Email Section */}
                  <Box className="underlined-text" sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                    {isEditing["entity_email"] ? (
                      <>
                        <TextField
                          name="entity_email"
                          label="Email"
                          value={editValues.entity_email}
                          onChange={handleInputChange}
                          fullWidth
                        />
                        <Button
                          size="small"
                          onClick={() => handleSaveClick("entity_email")}
                          sx={{ ml: 1 }}
                        >
                          Save
                        </Button>
                        <Button
                          size="small"
                          onClick={() => handleCancelEdit("entity_email")} // Close button to cancel editing
                          sx={{ ml: 1 }}
                        >
                          Close
                        </Button>
                      </>
                    ) : (
                      <>
                        <Typography variant="body1">
                          <MailIcon sx={{ mr: 1 }} />
                          {selectedMicrosite.entity_email}
                        </Typography>
                        <Button
                          size="small"
                          onClick={() => handleEditClick("entity_email")}
                          sx={{ ml: 1 }}
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </Box>


                  {/* Website Section */}
                  <Box className="underlined-text" sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                        {isEditing["website"] ? (
                          <>
                            <TextField
                              name="website"
                              label="Website"
                              value={editValues.website}
                              onChange={handleInputChange}
                              fullWidth
                            />
                            <Button
                              onClick={() => handleSaveClick("website")}
                              size="small"
                              sx={{ ml: 1 }}
                            >
                              Save
                            </Button>
                            <Button
                              onClick={() => handleCancelEdit("website")} // Function to handle cancel/close
                              size="small"
                              sx={{ ml: 1 }}
                            >
                              Close
                            </Button>
                          </>
                        ) : (
                          <>
                            <Typography variant="body1">
                              <WebAssetIcon sx={{ mr: 1 }} />
                              {selectedMicrosite.website}
                            </Typography>
                            <Button
                              onClick={() => handleEditClick("website")}
                              size="small"
                              sx={{ ml: 1 }}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                      </Box>

                  {/* Social Page Section */}  
                    <Box className="underlined-text" sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                      {isEditing["fb"] ? (
                        <>
                          <TextField
                            name="fb"
                            label="Facebook"
                            value={social_page?.fb}
                            onChange={(e) => {
                              setSocialPage((prev) => ({
                                ...prev,
                                fb: e.target.value,
                              }));
                            }}
                            fullWidth
                          />
                          <Button size="small" onClick={() => handleSaveClick("fb")} sx={{ ml: 1 }}>
                            Save
                          </Button>
                          <Button size="small" onClick={() => handleCancelEdit("fb")} sx={{ ml: 1 }}>
                            Close
                          </Button>
                        </>
                      ) : (
                        <>
                          <Typography variant="body1">
                            <FacebookIcon sx={{ mr: 1 }} />
                            {social_page?.fb}
                          </Typography>
                          <Button size="small" onClick={() => handleEditClick("fb")} sx={{ ml: 1 }}>
                            Edit
                          </Button>
                        </>
                      )}
                    </Box>
 
                    <Box className="underlined-text" sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                      {isEditing["youtube"] ? (
                        <>
                          <TextField
                            name="youtube"
                            label="YouTube"
                            value={social_page?.youtube}
                            onChange={(e) => {
                              setSocialPage((prev) => ({
                                ...prev,
                                youtube: e.target.value,
                              }));
                            }}
                            fullWidth
                          />
                          <Button size="small" onClick={() => handleSaveClick("youtube")} sx={{ ml: 1 }}>
                            Save
                          </Button>
                          <Button size="small" onClick={() => handleCancelEdit("youtube")} sx={{ ml: 1 }}>
                            Close
                          </Button>
                        </>
                      ) : (
                        <>
                          <Typography variant="body1">
                            <YouTubeIcon sx={{ mr: 1 }} />
                            {social_page?.youtube}
                          </Typography>
                          <Button size="small" onClick={() => handleEditClick("youtube")} sx={{ ml: 1 }}>
                            Edit
                          </Button>
                        </>
                      )}
                    </Box> 
                    <Box className="underlined-text" sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                      {isEditing["twitter"] ? (
                        <>
                          <TextField
                            name="twitter"
                            label="X"
                            value={social_page?.twitter}
                            onChange={(e) => {
                              setSocialPage((prev) => ({
                                ...prev,
                                twitter: e.target.value,
                              }));
                            }}
                            fullWidth
                          />
                          <Button size="small" onClick={() => handleSaveClick("twitter")} sx={{ ml: 1 }}>
                            Save
                          </Button>
                          <Button size="small" onClick={() => handleCancelEdit("twitter")} sx={{ ml: 1 }}>
                            Close
                          </Button>
                        </>
                      ) : (
                        <>
                          <Typography variant="body1">
                            <XIcon sx={{ mr: 1 }} />
                            {social_page?.twitter}
                          </Typography>
                          <Button size="small" onClick={() => handleEditClick("twitter")} sx={{ ml: 1 }}>
                            Edit
                          </Button>
                        </>
                      )}
                    </Box>
 
                    <Box className="underlined-text" sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                      {isEditing["linkedin"] ? (
                        <>
                          <TextField
                            name="linkedin"
                            label="LinkedIn"
                            value={social_page?.linkedin}
                            onChange={(e) => {
                              setSocialPage((prev) => ({
                                ...prev,
                                linkedin: e.target.value,
                              }));
                            }}
                            fullWidth
                          />
                          <Button size="small" onClick={() => handleSaveClick("linkedin")} sx={{ ml: 1 }}>
                            Save
                          </Button>
                          <Button size="small" onClick={() => handleCancelEdit("linkedin")} sx={{ ml: 1 }}>
                            Close
                          </Button>
                        </>
                      ) : (
                        <>
                          <Typography variant="body1">
                            <LinkedInIcon sx={{ mr: 1 }} />
                            {social_page?.linkedin}
                          </Typography>
                          <Button size="small" onClick={() => handleEditClick("linkedin")} sx={{ ml: 1 }}>
                            Edit
                          </Button>
                        </>
                      )}
                    </Box>
 
                    <Box className="underlined-text" sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                      {isEditing["instagram"] ? (
                        <>
                          <TextField
                            name="instagram"
                            label="Instagram"
                            value={social_page?.instagram}
                            onChange={(e) => {
                              setSocialPage((prev) => ({
                                ...prev,
                                instagram: e.target.value,
                              }));
                            }}
                            fullWidth
                          />
                          <Button size="small" onClick={() => handleSaveClick("instagram")} sx={{ ml: 1 }}>
                            Save
                          </Button>
                          <Button size="small" onClick={() => handleCancelEdit("instagram")} sx={{ ml: 1 }}>
                            Close
                          </Button>
                        </>
                      ) : (
                        <>
                          <Typography variant="body1">
                            <InstagramIcon sx={{ mr: 1 }} />
                            {social_page?.instagram}
                          </Typography>
                          <Button size="small" onClick={() => handleEditClick("instagram")} sx={{ ml: 1 }}>
                            Edit
                          </Button>
                        </>
                      )}
                    </Box>

                      <Grid container spacing={2}>
                      {/* Left Column for Promotional Image Upload */}
                      <Grid item xs={12} md={6}>
                        <Box className="underlined-text map_height" sx={{ mt: 4 }}>
                          <h5>Promotional Videos or Images:</h5>
                          <Upload 
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                          >
                            {fileList.length < 5 && "+ Photo"}
                          </Upload>
                        </Box>
                      </Grid>

                      {/* Right Column for Location Selector */}
                      <Grid item xs={12} md={6}> 
                      <Box className="underlined-text" sx={{ mb: 2, display: "flex", flexDirection: "column" }}>
                        {isEditing["location"] ? (
                          <>
                            {/* Button to toggle map visibility */}
                            <Button size="small" onClick={toggleMapPopup}>
                              {mapVisible ? "Close Map" : "Select Location on Map"}
                            </Button>
                            
                            {/* Google Map for location selection */}
                            {mapVisible && (
                              <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                                <GoogleMap
                                  mapContainerStyle={{ width: "400px", height: "300px" }}
                                  center={markerPosition || { lat: 40.73061, lng: -73.935242 }} // Center based on selected or default position
                                  zoom={12}
                                  onClick={handleMapClick} // Handle map click to select location
                                >
                                  {markerPosition && (
                                    <Marker position={markerPosition} />
                                  )}
                                </GoogleMap>
                              </LoadScript>
                            )}

                            {/* Save Button */}
                            <Button size="small" onClick={() => handleSaveClick("location")}>
                              Save
                            </Button>
                          </>
                        ) : (
                          <Typography variant="body1">
                            {/* Show Google Map with selected location */}
                            {selectedMicrosite.location ? (
                              <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                                <GoogleMap
                                  mapContainerStyle={{ width: "400px", height: "300px" }}
                                  center={markerPosition || selectedMicrosite.location}
                                  zoom={12}
                                >
                                  <Marker position={markerPosition || selectedMicrosite.location} />
                                </GoogleMap>
                              </LoadScript>
                            ) : (
                              "Location not selected"
                            )}
                          </Typography>
                        )}

                        {/* Edit Button */}
                        {!isEditing["location"] && (
                          <Button
                            size="small"
                            onClick={() => handleEditClick("location")}
                          >
                            Edit
                          </Button>
                        )}
                      </Box> 
                      </Grid>
                    </Grid>
                   
                  <div className="text-end">
                    <Button
                      variant="contained"
                      sx={{ mt: 3 }}
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      size="small"
                    >
                      {isSubmitting ? <CircularProgress size={24} /> : "Next"}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <p>Business or community details not found.</p>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default BusinessSetup;
