import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userListSlice = createApi({
  reducerPath: "post",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://jsonplaceholder.typicode.com",
  }),
  tagTypes: ["Posts"],
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: () => "/posts",
      providesTags: ["Posts"],
    }),
  }),
});

export const {
    useGetPostsQuery
  } = userListSlice;