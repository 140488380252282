import React, { memo, useContext, useEffect } from "react";
import { Fragment,useState } from "react";
import Button from '@mui/material/Button';
import { Box, IconButton, TextareaAutosize } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import emojiData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import CloseIcon from '@mui/icons-material/Close';
import {PhotoCamera } from "@mui/icons-material";
import axios from "axios";
import BroadcastSingleComment from "./BroadcastSingleComment";
import { commentsBroadcastsUrl, commentsUrl } from "../../../api/Api";
import { CommentsLoadEffect } from "../../PageLoadEffects";
import { notifyError } from "../../../utils/Toast";
import { useGetCommentDataQuery, useStoreCommentMutation } from "../../../api/slices/loungeApiSlice";
import { UserContext } from "../../../utils/UserContext";
import { useGetVideoCommentDataQuery, useStoreVideoCommentMutation } from "../../../api/slices/braodcastApiSlice";


const BroadcastComment = ({data, autoFocus })=>{
    const token = localStorage.getItem('token');
    const [content, setContent] = useState("");
    const [emojiShow, setEmojiShow] = useState(0);
    const [commentImage,setCommentImage] = useState(null);

    const { userDetails, msDetails } = useContext(UserContext);
    const microsite_id = msDetails?.id


    var url = `${commentsBroadcastsUrl}?item_id=${data?.id}&microsite_id=${microsite_id}`
    const {data:getCommentData, isFetching: getDataFetching, isError:getDataError} = useGetVideoCommentDataQuery({token, url,microsite_id});
    const [storeComment] = useStoreVideoCommentMutation()
    
    //load from api
    const [comments, setComments] = useState(null);
       useEffect(()=>{
          if(data && getCommentData){
            setComments(getCommentData?.data)
          }
       },[data, getCommentData])

       //craete new comment 
       const handleCreateComment = (postId) =>{
        setEmojiShow(0)
        let formData = new FormData();
        formData.append('item_id', postId);
        // formData.append('parent_id', 0);
        formData.append('comments', content);
        if(commentImage){
          formData.append('comment_image', commentImage);
        }
        formData.append('status', 1);

        var url = commentsBroadcastsUrl
        storeComment({token,formData,url})
        .then((response) => {

          setComments(null)
          setContent('');
          // getSingleFeedDetails();
          // getAllFeeds();
          setCommentImage(null);
        })
        .catch((error) => {
          setCommentImage(null);
          setContent('');
          notifyError('Something went wrong !')
        });
       }

    return(
        <Fragment>
             <div className="comment_input_main" >
               <div className="comment_input_container">
                <div className="comment_input_area" onClick={(e) => {(emojiShow === 1) && setEmojiShow(0) }}>
                   <TextareaAutosize
                      autoFocus={autoFocus}
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      aria-label="minimum height"   
                      minRows={1}
                      placeholder="Your comment"
                      className="comment_textarea"/>
                </div>
                 <div className="comment_attachment">
                      <ul>
                        <li><IconButton color="primary" aria-label="upload picture" component="label">
                            <input hidden accept="image/*" type="file" onChange={(e)=> setCommentImage(e.target.files[0])}  />
                            <PhotoCamera />
                          </IconButton></li>
                        <li><span onClick={(e) => {
                            emojiShow === 0 ? setEmojiShow(1) : setEmojiShow(0);
                          }}>🙂</span> 
                           <div className="comment_emoji_picker">
                           {emojiShow === 1 && (
                                  <Picker
                                      style={{ height: '300px' }}
                                      data={emojiData}
                                      previewPosition={"none"}
                                      onEmojiSelect={(e) => {
                                        setContent(content + " " + e.native);
                                      }}
                                  />
                              )}  
                            </div>
                          
                           </li>
                 
                      </ul>
                      <div className="send_btn"><Button disabled={content !=="" || commentImage !=null?false:true} endIcon={<SendIcon />} onClick={(e)=>handleCreateComment(data.id)}></Button></div>
                  </div>
                  {commentImage !=null &&  <div className="comment_media_visiable">
                         <img src={URL.createObjectURL(commentImage)} alt=""  />
                         <div className="comment_media_overly">
                         <CloseIcon onClick={(e)=> setCommentImage(null)} />
                      </div>
                  </div> }
                 
                </div>
             </div>
             {comments !==null && comments.length<1 && <Box sx={{display:'flex', justifyContent:'center', justifyItems:'center'}}><Button disabled>No Comments Yet</Button></Box>}
             {getDataFetching   && CommentsLoadEffect()}

             {comments !==null && comments.length>0 && 
              <div className="comment_container">
                <ul className="comment_ul">
                  {comments.map((mainComment,mainKey)=>{
                    return(
                      <li key={`${mainComment.uuid}-${mainComment.comment}`}>   
                          <BroadcastSingleComment data={mainComment} getAllComments={''} replayShow='1' key={`${mainComment.uuid}-${mainComment.comment}`} findLast={false} createBy={data.user_id} />
                              {mainComment.children.length>0 &&
                               <ul key={`${mainComment.uuid}-${mainComment.comment}`}>
                                  {mainComment.children.map((firstChild, firstKey)=>{
                                      return(
                                        <li key={`${firstChild.uuid}-${firstChild?.comment}`}>
                                          <BroadcastSingleComment data = {firstChild} getAllComments={''} replayShow='1' key={`${firstChild.uuid}-${firstChild?.comment}`} findLast={false} createBy={data.user_id} />
                                            {firstChild.children.length>0 && 
                                               <ul key={`${firstChild.uuid}-${firstChild?.comment}`}>
                                                {firstChild.children.map((secondChild, secondKey)=>{
                                                  return(
                                                      <li key={`${secondChild.uuid}-${secondChild?.comment}`}>
                                                        <BroadcastSingleComment data={secondChild} getAllComments={''} replayShow='1' key={`${secondChild.uuid}-${secondChild?.comment}`} findLast={true} createBy={data.user_id}  />
                                                      </li>
                                                  )                                 
                                                })}
                                              </ul>
                                            }
                                        </li>
                                      )
                                    })}
                                </ul>
                              }
                             
                      </li>
                    )
                  })}
                 
              </ul>
              </div>
             }
            {/* <ToastContainer /> */}
        </Fragment>
    )
}

export default  BroadcastComment