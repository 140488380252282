import { Divider, Grid } from "@mui/material";
import BroadcastTopNavigate from "../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate";
import BroadcastCategory from "../../components/Broadcast/BroadcastCategory/BroadcastCategory";
import { useGetBroadcastCategoryDataQuery, useGetBroadcastItemDataQuery } from "../../api/slices/braodcastApiSlice";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../utils/UserContext";
import PollTopNavigate from "../../components/Poll/PollTopNavigate/PollTopNavigate";
import { useGetPollDataQuery } from "../../api/slices/pollApiSlice";
import PollReward from "../../components/Poll/PollReward/PollReward";

const PollRewardClaimCenterPage = () => {
  const {msDetails, userDetails} = useContext(UserContext)
  const microsite_id = msDetails?.id
  const user_id = ''
  const active_pools ='yes'
  const status ='1'

  const token = localStorage.getItem('token');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const {data: getPollData, isFetching: getItemDataFetching, isError:getDataItemError} = useGetPollDataQuery({token,microsite_id,user_id,active_pools,status,currentPage,searchValue,user_id});


  const [scrollTop, setScrollTop] = useState(true)
  useEffect(() => {
    if(scrollTop ===true){
      window.scrollTo(0, 0)
      setScrollTop(false)
    }
  }, [scrollTop])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            {/* <p>Under development !</p> */}
            <div className="broadcast-body">
              <PollTopNavigate />
              <PollReward />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default PollRewardClaimCenterPage;
