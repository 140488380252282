import { Grid } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../utils/UserContext";
import { useGetBroadcastItemDataQuery, useGetSingleBroadcastItemDataQuery } from "../../api/slices/braodcastApiSlice";
import PollTopNavigate from "../../components/Poll/PollTopNavigate/PollTopNavigate";
import PollDetails from "../../components/Poll/PollDetails/PollDetails";
import PollsItemList from "../../components/Poll/PollDetails/PollsItemList";
import { useGetPollDataQuery, useGetPollQuestionsDataQuery } from "../../api/slices/pollApiSlice";

const PollDetailsPage = () => {
  const params = useParams()
  const {msDetails, userDetails} = useContext(UserContext)
  const microsite_id = msDetails?.id;
  const user_id  = userDetails.id
  const active_pools ='yes'
  const status ='1'

  const token = localStorage.getItem('token');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const location = useLocation()
  const poll_id = location?.state?.pollDetails?.id

  const {data: getSingleData, isFetching:getSingleDataFeting, isError:getSingleDataError} = useGetPollQuestionsDataQuery({token,microsite_id,poll_id, searchValue})
  const {data: getPollData, isFetching: getItemDataFetching, isError:getDataItemError} = useGetPollDataQuery({token,microsite_id,user_id,active_pools,status,currentPage,searchValue,user_id});

  const [scrollTop, setScrollTop] = useState(true)
  useEffect(() => {
    if(scrollTop ===true){
      window.scrollTo(0, 0)
      setScrollTop(false)
    }
  }, [scrollTop])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <div className="broadcast-body">
              <PollTopNavigate/>
              {/* <Divider sx={{ mt:3 , mb:1}}/> */}
              <Grid container spacing={2}>
                 <Grid item lg={8} xs={12}>
                    <PollDetails pollDetails={location?.state?.pollDetails} data={getSingleData} loading={getSingleDataFeting} />
                 </Grid>
                 <Grid item lg={4} xs={12}>
                    <PollsItemList pollDetails ={location?.state?.pollDetails} pollItem ={getPollData?.data} loading={getItemDataFetching} singleUuid={params?.uuid} setScrollTop={setScrollTop} />
                 </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default PollDetailsPage;