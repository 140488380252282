import React from 'react'
import { Grid } from '@mui/material'
import PollTopNavigate from '../../components/Poll/PollTopNavigate/PollTopNavigate'
import PollCreateForm from '../../components/Poll/PollCreateAndUpdate/PollCreateForm'

const PollCreateFormPage =()=> {
  return (
    <>
     <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className="content_body">
            <div className="broadcast-body">
              <PollTopNavigate />
              <PollCreateForm />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default PollCreateFormPage