import { Upload } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import userPic from "../../asset/image/femaleAvatar.png";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Avatar,
  AvatarGroup,
  Backdrop,
  Box,
  Button,
  Card,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { ThreeDotLoaderEffect } from "../PageLoadEffects";
import { baseUrl, eventMediaUrl, eventsUrl } from "../../api/Api";
import { UserContext } from "../../utils/UserContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import { notifyError, notifySuccess } from "../../utils/Toast";

import CancelIcon from "@mui/icons-material/Cancel";

import bannerUpload from "../../asset/image/bannerUpload.jpeg";
import MainLoader from "../PageLoadEffects/MainLoader";

import bannerDefault from "../../asset/image/bannerUpload.jpeg";
import UploadBanner from "../mediaUploder/Banner/UploadBanner";
import PopupBanner from "../mediaUploder/Banner/PopupBanner";

import SyncIcon from '@mui/icons-material/Sync';
import { UploadOutlined } from '@ant-design/icons';


const EventCreateBody = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // Date Time Star
  const [getFileList, setGetFileList] = useState(null);
  const { msDetails, userDetails } = useContext(UserContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [fileList, setFileList] = useState([]);
  const [loaderEffectValue, setLoaderEffectValue] = useState(false);
  const [eventBanner, setEventBanner] = useState(null);
  const [loadershow, setLoaderShow] = useState("none");

  const [flipFileList, setFlipFileList] = useState([])

  // date and time handle
  const handleDateChange = (date) => {
    var dateString = new Date(date - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    var getHour = date.getHours();
    var getMinutes = date.getMinutes();
    var timeString = `${getHour}:${getMinutes}`;
    var joinDate = dateString.concat(" ", timeString);
    setSelectedDate(joinDate);
  };

  // Date Time End
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onChangeFlipFile = ({ fileList: newFileList }) => {
    setFlipFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleEditorChange = (content, type) => {
    setValue("details", content);
  };

  const [loaderEffect, setLoaderEffect] = useState(false);
  const token = localStorage.getItem("token");
  const defaultValues = {
    title: "",
    subtitle: "",
    location: "",
    details: "",
    directionUrl: "",
    banner: null,
  };
  const methods = useForm({ defaultValues });
  const { watch, setValue } = methods;
  const values = watch();

  // const handle create event
  const handleCreateEvent = () => {
    setLoaderEffect(true);
    let data = new FormData();
    data.append("microsite_id", msDetails.id);
    data.append("title", values.title);
    data.append("subtitle", values.subtitle);
    data.append("location", values.location);
    data.append("date", selectedDate);
    data.append("details", values.details);

    data.append("direction_url", values.directionUrl);

    if (values.banner) {
      data.append("banner", values.banner);
    }

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        data.append("files[]", file.originFileObj);
      });
    }
    if (flipFileList.length > 0) {
      flipFileList.forEach((file) => {
        data.append("flip_file", file.originFileObj);
      });
    }

    var config;

    if (location.state !== null) {
      config = {
        method: "post",
        url: `${eventsUrl}/${location.state.uuid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
    } else {
      config = {
        method: "post",
        url: eventsUrl,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
    }

    axios
      .request(config)
      .then((response) => {
        setLoaderEffect(false);
        if (location.state !== null) {
          // window.location.reload();
          navigate("/event-detail", {
            state: { uuid: location.state.uuid, event: location.state.event },
          });
        } else {
          navigate("/event");
        }
      })
      .catch((err) => {
        setLoaderEffect(false);
        if (err?.response) {
          notifyError(err?.response?.data?.message);
        } else {
          notifyError("Something went wrong!.");
        }
      });
  };

  // get details item
  const getSingleEventDetails = () => {
    setLoaderShow("");
    let config = {
      method: "get",
      url: `${eventsUrl}/${location.state.uuid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.request(config).then((response) => {
      setLoaderShow("none");
      if (response.data) {
        setValue("title", response.data.title);
        setValue("subtitle", response.data.subtitle);
        setValue("location", response.data.location);
        setValue("details", response.data.details);

        setValue("directionUrl", response?.data?.meta?.direction_url);
        setSelectedDate(response.data.date);

        setEventBanner(response?.data?.meta?.banner);

        if (response.data.files.length > 0) {
          setGetFileList(response.data.files);
        }
      }
    });
  };

  useEffect(() => {
    if (location.state !== null) {
      getSingleEventDetails();
    }
  }, []);

  //handle delete a resouce media
  const handleDeleteEventMedia = (uuid) => {
    Swal.fire({
      heightAuto: false,
      backdrop: false,
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoaderEffectValue(true);
        let config = {
          method: "delete",
          url: `${eventMediaUrl}/${uuid}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        axios
          .request(config)
          .then((response) => {
            setLoaderEffectValue(false);
            //notifySuccess();
            window.location.reload();
          })
          .catch((err) => {
            setLoaderEffect(false);
            if (err?.response) {
              notifyError(err?.response?.data?.message);
            } else {
              notifyError("Something went wrong!.");
            }
          });
      }
    });
  };

  const [open2, setOpen2] = useState(false);
  const [image2, setImage2] = useState("");

  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <Fragment>
      <Box display={loadershow}>
        {" "}
        <MainLoader />
      </Box>
      

      <div className="event_create">
        <h4>Create Event</h4>
        <div className="event_form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="input_field">
                <input
                  type="text"
                  className="form_cotrol"
                  placeholder="Event Title(*)"
                  onChange={(e) => setValue("title", e.target.value)}
                  value={values.title}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="input_field">
                <input
                  type="text"
                  className="form_cotrol"
                  placeholder="Sub Title"
                  onChange={(e) => setValue("subtitle", e.target.value)}
                  value={values.subtitle}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="input_field">
                <input
                  type="text"
                  className="form_cotrol"
                  placeholder="Location"
                  onChange={(e) => setValue("location", e.target.value)}
                  value={values.location}/>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="input_field">
                <input
                  type="text"
                  className="form_cotrol"
                  placeholder={`Venue${" "}(Google Map Direction URL)`}
                  onChange={(e) => setValue("directionUrl", e.target.value)}
                  value={values.directionUrl}
                />
              </div>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  InputProps={{ readOnly: true }}
                  focused
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date"
                  format="y-MM-dd"
                  is24Hour
                  //  format="yyyy/MM/dd"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  InputProps={{ readOnly: true }}
                  focused
                  margin="normal"
                  id="time-picker"
                  label="Time"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <SunEditor
                  name="details"
                  setContents={values.details}
                  placeholder="Type Event Details Here..."
                  showToolbar={true}
                  setDefaultStyle="height: 200px"
                  onChange={(e) => handleEditorChange(e, "details")}
                  setOptions={{
                    buttonList: [
                      [
                        "fontSize",
                        "formatBlock",
                        "paragraphStyle",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "hiliteColor",
                        "align",
                        "list",
                        "link",
                        "codeView",
                      ],
                    ],
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <div className="field_heading">Upload Event Images :</div>
              <div className="Event_files_container">
                <Upload
                  action="https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                  multiple
                  >
                  {/* {fileList.length < 5 && "+ Upload"} */}
                  <Button icon={<UploadOutlined />}>Upload </Button>

                </Upload>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="field_heading">Upload Event Flipbook </div>
              <div className="Event_files_container">
                <Upload
                    action="https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png"
                    listType="picture-card"
                    fileList={flipFileList}
                    onChange={onChangeFlipFile}
                    onPreview={onPreview}
                    maxCount={1}
                    accept=".pdf"
                  >
                    <Button icon={<UploadOutlined />}>Upload </Button>
                  </Upload>
              </div>
            </Grid>
           
            {getFileList !== null && (
              <Grid item xs={12}>
              <Grid container spacing={2}>
                  {getFileList.length > 0 &&
                    getFileList.map((data) => {
                      return (
                        <Grid item lg={2} md={3} sm={6} xs={6}>
                          <div className="event_image_place">
                            {data.url && <img src={`${data.url}`} />}
                            <div className="event_image_place_overly">
                              <div className="img_cross_btn">
                                <CloseIcon
                                  onClick={(e) =>
                                    handleDeleteEventMedia(data.uuid)}/>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
              </Grid>
            </Grid>
            )}

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card sx={{ minHeight: 225 }}>
                {/* <CloudUploadIcon  className="cursorPointer" sx={{displa:'flex', float:'right', m:1}}  /> */}
                <Box className="bannerImg_complete">
                  {values.banner !== null ? (
                    <img src={URL.createObjectURL(values.banner)} />
                  ) : eventBanner ? (
                    <img src={`${baseUrl}/${eventBanner}`} width="100px" />
                  ) : (
                    <img src={bannerDefault} width="100px" />
                  )}
                  <i>
                    {/* <CloudUploadIcon /> */}
                    <div>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <UploadBanner
                          getUploadedFile={(image) => {
                            setOpen2(true);
                            setImage2(image);
                          }}
                        />
                        <PopupBanner
                          open={open2}
                          handleClose={handleClose2}
                          image={image2}
                          setValue={setValue}
                          getCroppedFile={(image) => {
                            setImage2(image);
                            handleClose2();
                          }}
                        />
                      </Box>
                    </div>
                  </i>
                </Box>
              </Card>
            </Grid>

            {/* ***************Banner End************* */}

            {/* <Grid item xs={12}>
              {loaderEffect === false && (
                <div
                  className="eventSubmit_btn"
                  onClick={(e) => handleCreateEvent()}
                >
                  Submit
                </div>
              )}
              <div className="btn_loader">
                {ThreeDotLoaderEffect(loaderEffect)}
              </div>
            </Grid> */}
           
          </Grid>


          <div className="update_btn">
            {values?.title
              ?<Button  onClick={(e) => handleCreateEvent()} variant="contained" ><SyncIcon /></Button>
              :<Button disabled variant="contained" ><SyncIcon /></Button>}
          </div>
          
        </div>
      </div>
    </Fragment>
  );
};

export default EventCreateBody;
