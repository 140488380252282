import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import groomTheme1 from '../../asset/image/groom-1-prev.webp';
import groomTheme2 from '../../asset/image/groom-2-prev.webp';

import bridalTheme1 from '../../asset/image/bridal-1-prev.webp';
import bridalTheme2 from '../../asset/image/bridal-2-prev.webp';
import bridalTheme3 from '../../asset/image/bridal-3-prev.webp';

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const MatrimonialThemeSelectPage = () => {
  const navigate = useNavigate();
  const url = window.location.href
  let params = new URLSearchParams(url);



  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={3} md={12} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={12} sm={12} xs={12}>
          <div className="content_body">
          <Tooltip title="Back">
              <div
                className="backArrow"
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
              </div>
            </Tooltip>
            <div className="theme_select_wrapper">
              <div className="page_head">
                 <h4> Select Theme </h4>
              </div>
              <div className="theme_selet_wrap">
              <Grid container spacing={2}>
                <Grid item lg={4}>
                  <div className="theme_item" onClick={(e)=> navigate('/matrimonial-preview-theme/groom/1')}>
                     <div className="theem_img" style={{ backgroundImage: `url(${groomTheme1})`}}></div>
                     <div className="theme_content">
                        <div className="themName">
                            Groom  Theme 1
                        </div>
                     </div>
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <div className="theme_item" onClick={(e)=> navigate('/matrimonial-preview-theme/groom/2')}>
                     <div className="theem_img" style={{ backgroundImage: `url(${groomTheme2})`}}></div>
                     <div className="theme_content">
                        <div className="themName">
                            Groom Theme 2
                        </div>
                     </div>
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <div className="theme_item" onClick={(e)=> navigate('/matrimonial-preview-theme/bridal/1')}>
                     <div className="theem_img" style={{ backgroundImage: `url(${bridalTheme1})`}}></div>
                     <div className="theme_content">
                        <div className="themName">
                            Bridal Theme 1
                        </div>
                     </div>
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <div className="theme_item" onClick={(e)=> navigate('/matrimonial-preview-theme/bridal/2')}>
                     <div className="theem_img" style={{ backgroundImage: `url(${bridalTheme2})`}}></div>
                     <div className="theme_content">
                        <div className="themName">
                            Bridal Theme 2
                        </div>
                     </div>
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <div className="theme_item" onClick={(e)=> navigate('/matrimonial-preview-theme/bridal/3')}>
                     <div className="theem_img" style={{ backgroundImage: `url(${bridalTheme3})`}}></div>
                     <div className="theme_content">
                        <div className="themName">
                            Bridal Theme 3
                        </div>
                     </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default MatrimonialThemeSelectPage;
