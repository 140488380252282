import React, { Fragment } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import CommunitySetUp from "../../components/CommunitySetUp/CommunitySetUp";
import CommunityProfileUpdate from "../../components/CommunitySetUp/CommunityProfileUpdate";
import CommunityProfileComplete from "../../components/CommunitySetUp/CommunityProfileComplete";

const CommunityPtofileCompletePage = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="content_body mob_margin_top">
        <Tooltip title="Back">
          <div
            className="backArrow"
            onClick={(e) => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </div>
        </Tooltip>
        <CommunityProfileComplete />
      </div>
    </Fragment>
  );
};

export default CommunityPtofileCompletePage;
