import React, { useState } from "react";
import { Button, Grid, TextField, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Upload } from "antd";



function BussLogoSetupPage() {
  const navigate = useNavigate();


  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <div className="content_body">
            <Tooltip title="Back">
              <div
                className="backArrow"
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
              </div>
            </Tooltip>
            <div className="post-business-container">
            
              <div className="choose-commuinity">
                <h2>Business Logo</h2>

                <div className="choose-commuinity-content">
                  <p> Please enter your business logo here.. </p>
                  <Grid container spacing={2} sx={{ mt: 4 }}>
                    <Grid item lg={2} md={6} xs={12}></Grid>
                    <Grid item lg={8} md={6} xs={12}>
                      <div className="business-banner-upload">
                        <Upload
                          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                          listType="picture-card"
                          fileList={fileList}
                          onChange={onChange}
                          onPreview={onPreview}
                        >
                          {fileList.length < 1 && " Upload Logo Image"}
                        </Upload>
                      </div>
                    </Grid>
                    <Grid item lg={2} md={6} xs={12}></Grid>
                  </Grid>
                  <Link to="/business-banner">
                    <Button variant="contained" fullWidth sx={{ mt: 2 }}>
                      Next
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default BussLogoSetupPage;
