import React, { useState, useEffect } from "react";
import { Upload, Button } from "antd";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box } from "@mui/material";

const { Dragger } = Upload;

export default function MultipleGlobalUploader({setValue, name}) {
  const [fileList, setFileList] = useState([]);

  const handleBeforeUpload = (file) => {
    setFileList([...fileList, file]);
    return false;
  };

  const handleChangeFiles = ({ fileList, file}) => {
    // console.log('ON_CHANGE_FILES:', fileList)
    // console.log("ON_CHANGE_FILES:", file);
    setFileList([...fileList]);
    setValue(`${name}`,[...fileList])
  };

  const handleRemove = (selectedFile) => {
    return fileList.filter((file) => {
      return selectedFile.uid !== file.uid;
    });
  };

  // useEffect(()=>{
  //   if(name ==='media'){
  //     setValue('media',...[fileList])
  //   }
       
  // },[fileList])



  return (
    <div >
      <Dragger
        {...{
          fileList,
          defaultFileList: fileList,
          onRemove: handleRemove,
          beforeUpload: handleBeforeUpload,
          multiple: true,
          onChange: handleChangeFiles,
          listType: "picture",
          progress: { showInfo: true },
        }}
      >
        <Box sx={{p:3}}>
            <Box className="ant-upload-drag-icon">
                <CloudUploadIcon />
            </Box>
            <Box sx={{ml:1}} className="ant-upload-text">
                Click or drag file to this area to upload
            </Box>
            <p className="ant-upload-hint"></p>
        </Box>
            
      </Dragger>
     
    </div>
  );
}
