import React, { Fragment } from "react";
import { useState } from "react";
import {  baseUrl } from "../../../api/Api";
import { useEffect } from "react";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Box} from "@mui/material";

import '@ashwamegh/react-link-preview/dist/index.css'
import { useRemoveItemReactionMutation, useStoreItemReactionMutation } from "../../../api/slices/braodcastApiSlice";


const BroadcastReaction = ({ data }) => {
  const [myReaction,setMyReaction] = useState(null);
  const [meaning, setmeaning] = useState("");
  const [loaderVisible, setLoaderVisible] = useState(false)

  const token = localStorage.getItem("token");

  const [storeItemReaction] = useStoreItemReactionMutation()
  const [removeItemReaction] = useRemoveItemReactionMutation()

  useEffect(() => {
    setMyReaction(null)
    if(data?.my_reactions?.length>0){
      if(data?.my_reactions[0]?.reaction_type ==='like'){
        setMyReaction('👍')
      }
      if(data?.my_reactions[0]?.reaction_type ==='love'){
        setMyReaction('❤️')
      }
      if(data?.my_reactions[0]?.reaction_type ==='happy'){
        setMyReaction('😊')
      }
      if(data?.my_reactions[0]?.reaction_type ==='adore'){
        setMyReaction('🤗')
      }
      if(data?.my_reactions[0]?.reaction_type ==='laugh'){
        setMyReaction('😆')
      }
      if(data?.my_reactions[0]?.reaction_type ==='angry'){
        setMyReaction('👿')
      }
      if(data?.my_reactions[0]?.reaction_type ==='sad'){
        setMyReaction('😥')
      }
    }
  }, [data,myReaction]);

  //emoji
  const reactionEmoji = {
    "👍": "like",
    "❤️": "love",
    "😊": "happy",
    "🤗": "adore",
    "😆": "laugh",
    "👿": "angry",
    "😥": "sad",
};
const [emojiClass, setEmojiClass] = useState(`emojiTab ${data?.id} d-none`)
var feedsEmoji = Object.keys(reactionEmoji);


// emoji click handler
const emojiClickHandler =(emoji,postId,uuid)=> {
    setLoaderVisible(true)
    setEmojiClass(`emojiTab ${postId} d-none`)
    var meaning = reactionEmoji[emoji];
    setmeaning(meaning); 
    setMyReaction(emoji);

    let formData = new FormData();
    formData.append('item_id', postId);
    formData.append('reaction_type', meaning);

    var url;
    if(uuid !==null){
      url=`${baseUrl}/api/community/broadcastreaction/${uuid}`
    }else{
      url=`${baseUrl}/api/community/broadcastreaction`
    }
    storeItemReaction({token,formData,url})
    .unwrap()
    .then((response) => {
      setTimeout(()=>{
        setLoaderVisible(false)
      },2000)
    })
    .catch((error) => {
      setLoaderVisible(false)
    //  notifyError('Something went wrong')
    });

}

// handle remove reaction
const handleRemoveReaction=(uuid)=>{
  if(uuid !==null){
    var url = `${baseUrl}/api/community/broadcastreaction/${uuid}`;
    
    removeItemReaction({token,url})
    .then((response) => {
      setMyReaction(null)
    })
    .catch((error) => {
      // notifyError('Something went wrong !')
    });
  }
}

// handle emoji class
const handleEmoji =(id,emojiClass)=>{
  if(emojiClass ===`emojiTab ${id} d-none`){
    setEmojiClass(`emojiTab ${id}`)
  }else{
    setEmojiClass(`emojiTab ${id} d-none`)
  }
}


  return (
    <Fragment>
      <div className="LoungeItemContent">
        <div className="loungeItem_footer">
         <div className="emojis_container">
            <div className={`${emojiClass} ${data?.id}`}>
              {feedsEmoji.map(function (emoji) {
                    return (
                        <span
                            className="singleEmoji"
                            onClick={() => emojiClickHandler(emoji,data?.id,(data?.my_reactions?.length>0)?data?.my_reactions[0]?.uuid:null)}
                            key={emoji}>
                            {emoji}
                        </span>
                    );
              })}
        </div>
          </div>
        
          <div className="broadcastItem_footer_status">
            <div className="footer_status_one" >
              {(myReaction !==null)?
              <>
                <span className="emoji_icon" onClick={(e)=> handleRemoveReaction((data.my_reactions.length>0)?data.my_reactions[0].uuid:null)}>{myReaction} </span>
                <span>{data?.reactions?.length}</span>
              </>
              : <Box sx={{display:'contents'}}  onClick={(e)=>handleEmoji(data.id,emojiClass)}><ThumbUpIcon/> <span>{data?.reactions?.length}</span></Box>}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BroadcastReaction;