import React, { useState } from 'react';
import parser from 'html-react-parser'
import { Box, Button, Typography } from '@mui/material';

const ReadMoreComponent = ({ text, maxLength, parse }) => {
  const [isFullTextVisible, setIsFullTextVisible] = useState(false);
  const truncatedText = isFullTextVisible ? text : text.slice(0, maxLength);

  const toggleReadMore = () => {
    setIsFullTextVisible(!isFullTextVisible);
  };

  return (
    <div>
      <p>{(parse === true)?parser(truncatedText):truncatedText} 
        <span>
            {text.length > maxLength && (
                <Button onClick={toggleReadMore} className='readmoreless'>
                  {isFullTextVisible ? '▲' : '▼'}
                </Button>
              )}
        </span>
      </p>
    </div>
  );
};

export default ReadMoreComponent;
