import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BroadcastTopNavigate from "../../components/Broadcast/BroadcastTopNavigate/BroadcastTopNavigate";
import { useForm } from "react-hook-form";
import { Label } from "@mui/icons-material";
import SunEditor from "suneditor-react";
import MultipleGlobalUploader from "../../components/Uploader/MultipleGlobalUploader";
import { Upload } from "antd";
import {
  useDeleteBroadcastCategoryMediaMutation,
  useGetBroadcastCategoryDataQuery,
  useGetSingleBroadcastCategoryDataQuery,
  useStoreBroadcastCategoryMutation,
} from "../../api/slices/braodcastApiSlice";
import { UserContext } from "../../utils/UserContext";
import LoungeUploadProgress from "../../components/LoungeUploadProgress/LoungeUploadProgress";
import { baseUrl } from "../../api/Api";
import UploadLoader from "../../components/PageLoadEffects/UploadLoader";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import MainLoader from "../../components/PageLoadEffects/MainLoader";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
const CategoryCreateModal = ({setValueForm, handleCloseCat}) => {
  const defaultValues = {
    title: "",
    details: "",
    allCategory: [],
    useableCategory: [],
    parentCategory: null,
    featureImage: [],
    uploadedFeatureImage: null,
    featureImageShow: false,
    media: [],
    uploadedMedia: [],
    status: false,
    visible: false,
  };
  const methods = useForm({ defaultValues });
  const { watch, setValue } = methods;
  const values = watch();
  const navigate = useNavigate();

  const { msDetails, userDetails } = useContext(UserContext);
  const microsite_id = msDetails?.id;

  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [progressValue, setProgressValue] = useState(0);
  const [loader, setLoader] = useState(false)

  const [deleteBroadcastCategoryMedia, response] =
    useDeleteBroadcastCategoryMediaMutation();
  const [storeBroadcastCategory] = useStoreBroadcastCategoryMutation();

  const handleEditorChange = (content, type) => {
    setValue("details", content);
  };

  // create broad cast category
  const createBroadcastCategory = async (e) => {
    e.preventDefault();
    setLoader(true)
    const formData = new FormData();
    formData.append("microsite_id", msDetails?.id);
    if (values?.parentCategory !== null) {
      formData.append("parent_id", values?.parentCategory?.id);
    }
    formData.append("title", values?.title);
    formData.append("details", values?.details);

    if (values?.featureImage[0]) {
      formData.append("thumb", values?.featureImage[0]?.originFileObj);
    }
    if (values?.media.length > 0) {
      values?.media.forEach((element) => {
        formData.append("files[]", element?.originFileObj);
      });
    }

    formData.append("status", 0);

    var url = `${baseUrl}/api/community/broadcastcategory`;
    const response = await storeBroadcastCategory({
      token,
      formData,
      url,
      setProgressValue
    });
    if (response?.data) {
      setValueForm('selectedCategory',response?.data?.entity)
      handleCloseCat();
      setLoader(false)
    }
  };
  return (
      <Grid container>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <TextField
                      name="title"
                      label="Category Title"
                      onChange={(e) => setValue("title", e.target.value)}
                      value={values.title}
                    />
                  </Stack>
                </Card>

                <Card sx={{ p: 3, mt:1.5 }}>
                  <Stack spacing={3}>
                
                    <Grid item xs={12}>
                      <div className="field_heading">Feature Image</div>
                        <Box>
                          <MultipleGlobalUploader
                            setValue={setValue}
                            name="featureImage"
                          />
                        </Box>
                    </Grid>
                  </Stack>
                </Card>

                <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={createBroadcastCategory}
                    disabled={values?.title ? false : true}
                  >
                    Save
                  </Button>
                </Stack>
              </Grid>

             
            </Grid>
        {/* {values?.visible === true && <UploadLoader />}
        {progressValue > 0 &&
          (values?.media.length > 0 || values?.featureImage.length > 0) && (
            <LoungeUploadProgress
              value={progressValue}
              progressTotalLenght={
                values?.media?.length + values?.featureImage?.length
              }
            />
          )} */}
        {loader && <MainLoader/>} 
      </Grid>
  );
};

export default CategoryCreateModal;
