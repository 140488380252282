import React, { Fragment, useContext } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DynamicThemeBody from "../../../../components/Matrimony/Theme/Dynamic/DynamicThemeBody";
import { UserContext } from "../../../../utils/UserContext";

const DynamicThemePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const{userDetails} = useContext(UserContext)
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={3} md={12} sm={12} xs={12}></Grid>
        <Grid item lg={9} md={12} sm={12} xs={12}>
          <div className="content_body">
            <div className="section_headear">
              <Tooltip title="Back">
                <div
                  className="backArrow"
                  onClick={(e) => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIcon />
                </div>
              </Tooltip>

              {userDetails.id === location?.state?.data?.user_id && 
                <div className="section_headear">
                  <div className="btns_row">
                    <Box sx={{ mr: 2 }}>
                      <Button
                        variant="contained"
                        onClick={(e) => navigate("/matrimony-theme")}
                      >
                        Choose Theme
                      </Button>
                    </Box>
                    {/* <Box>
                      <Button
                        variant="contained"
                        onClick={(e) => navigate("/matrimony-my-theme")}
                      >
                        My Theme
                      </Button>
                    </Box> */}
                    <a
                      onClick={(e) =>
                        navigate("/user-matrimony-profile-create", {
                          state: { uuid: location?.state?.data.uuid },
                        })
                      }
                    >
                      <div className="Btn_one">Update</div>
                    </a>
                  </div>
                </div>
              }
            </div>

            <DynamicThemeBody params={params} />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DynamicThemePage;
