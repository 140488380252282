import { Avatar, Box, Button, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { baseUrl } from "../../../api/Api";
import { UserContext } from "../../../utils/UserContext";
import { useNavigate } from "react-router-dom";
import { useStoreBroadcastItemMutation } from "../../../api/slices/braodcastApiSlice";
import PollQnA from "./PollQnA";
import parser from 'html-react-parser'
import { Image } from "antd";
import Chip from '@mui/material/Chip';
import { useDeletePollMutation } from "../../../api/slices/pollApiSlice";
import Swal from "sweetalert2";
import PollDropDownMenu from "../PollcastItem/PollDropDownMenu";
import ReadMoreComponent from "../../ReadMoreComponent/ReadMoreComponent";
import PollReviewQnA from "./PollReviewQnA";


const PollReviewDetails = ({pollDetails,data,loading}) => {
  const {userDetails} = useContext(UserContext)
  const [pollItem, setPollItem] = useState([pollDetails])
  const navigate = useNavigate();
  const storeData =  data?.data
  var reverseArr ;
  if(storeData && storeData.length>0){
    reverseArr = [...storeData].reverse()
  }

  var username = pollDetails?.microsite_userdetails?.user_details? JSON.parse(pollDetails?.microsite_userdetails?.user_details)?.name?JSON.parse(pollDetails?.microsite_userdetails?.user_details)?.name:data?.user?.name:data?.user?.name

// handle poll review result navigate
 const handlePollReview = (data)=>{
  navigate(`/poll-review/${data?.uuid}`, {state:{pollDetails:data}})
  }

   // Function to check if a given date and time have expired
   function isExpired(dateTimeString) {
    // Parse the date string into a Date object
    const dateTime = new Date(dateTimeString);
    
    // Get the current date and time
    const now = new Date();
    
    // Compare the two dates
    return dateTime < now; // Returns true if the given date and time have expired
  }

  const token = localStorage.getItem('token');
  const [deletePoll] = useDeletePollMutation()


 // handle details item
 const handleSinglItem = (data)=>{
   navigate(`/poll/${data?.uuid}`, {state:{pollDetails:data}})
 }


 const handleDeleteRow = (uuid) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
     deletePoll({token,uuid})
    }
  });
};
// handle edit item
 const handleEditItem = (data)=>{
   navigate(`/poll-update/${data?.uuid}`, {state:{pollDetails:data}})
}

  return (
    <>
      <Box>
              {pollItem && pollItem.length>0 && pollItem.map((data, key)=>{
                  const options = { year: "numeric", month: "short", day: "numeric" };
                  const date = data?.created_at?new Date(data?.end_date).toLocaleDateString("en-US", options):''

                  const dateTimeString = data?.end_date?.split(' ').join('T');
                  const endDate =  data?.end_date
                  const currentDate = new Date()
                  const isDateTimeExpired = isExpired(dateTimeString);

                  var username = data?.microsite_userdetails?.user_details? JSON.parse(data?.microsite_userdetails?.user_details)?.name?JSON.parse(data?.microsite_userdetails?.user_details)?.name:data?.user?.name:data?.user?.name


                  const date1 = new Date(data?.end_date);
                  const date2 = new Date().toLocaleString().split(',').join(' ')
                  function getDifferenceInDays(date1, date2) {
                    const diffInMs = Math.abs(date2 - date1);
                    return diffInMs / (1000 * 60 * 60 * 24);
                  }
                  
                  function getDifferenceInHours(date1, date2) {
                    const diffInMs = Math.abs(date2 - date1);
                    return diffInMs / (1000 * 60 * 60);
                  }
                  
                  function getDifferenceInMinutes(date1, date2) {
                    const diffInMs = Math.abs(date2 - date1);
                    return diffInMs / (1000 * 60);
                  }

                  // console.log('end date', endDate)
                  // console.log('date2', date2.toLocaleString())
                  // console.log('days', getDifferenceInDays(date1,date2))
                  // console.log('hour', getDifferenceInHours(date1, date2))
                  // console.log('min', getDifferenceInMinutes(date1, date2))

                  console.log('keywords', data?.meta?.keywords)

                  var keywords = null;
                  if( data?.meta?.keywords){
                    keywords =  data?.meta?.keywords.split(',')
                  }

                  var avatar = null
                  var parseData
                  if(data?.microsite_userdetails?.user_details){
                    parseData = JSON.parse(data?.microsite_userdetails?.user_details)
                  }
                  if(parseData?.avater){
                    avatar = parseData?.avater
                  }else{
                    avatar = data?.user?.avatar
                  }

                return(
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Card sx={{mt:1}} key={data.uuid} className="pollCards" >
                            <Box sx={{m:2}} className='cursorPointer'> 
                                  {/* image and title part  */}
                                  <Box sx={{mt:2}} className='cardBody'>
                                      <Box display={'flex'} justifyContent={'space-between'}>
                                        <Box><Avatar alt={username} src={`${baseUrl}/${avatar}`} /></Box>
                                        {data?.media && data?.media.length>0  &&
                                        <div className="poll_card_image">
                                            {data?.media && data?.media.length>0 &&
                                              <img src={`${baseUrl}/storage/media/${data?.media[0].id}/${data?.media[0].file_name}`} alt="" width={'100%'} height={'100%'} />
                                            }
                                        </div>}
                                        {userDetails?.id === data?.user_id && 
                                          <Box><PollDropDownMenu data={data}  handleDeleteRow={handleDeleteRow} handleEditItem={handleEditItem} /></Box>
                                        }
                                      </Box>  

                                      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}><Button sx={{ml:1, mb:5, mt:1.5}}>Poll No :{data?.id}</Button></Box>

                                      {keywords !==null &&
                                      <Box sx={{mt:1, mb:1}} display={'flex'} alignItems={'center'} justifyContent={'center'} >
                                        <Stack direction="row" spacing={1}>
                                        {keywords.slice(0,2).map((keyword, key)=>{
                                          return(
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}><Chip label={keyword} size="small" /></Box>
                                          )
                                        })}
                                        {keywords?.length>2 &&
                                          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}><Chip label={`${keywords?.length -2} ...More`} size="small" /></Box>
                                        }
                                        </Stack>
                                      </Box>}

                                        <Box  display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={(e) => handleSinglItem(data)}>
                                          <ReadMoreComponent text={data?.name} maxLength={90} />
                                        </Box>
                                        <Box  display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={(e) => handleSinglItem(data)}>
                                          {/* <Box><i>Signs/Symptoms: </i> Chest pain with EF 15%.</Box> */}
                                        </Box>
                                        <Box className="sticky_bottom" sx={{mt:5}} display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={(e) => handleSinglItem(data)}>
                                          <Box><small className="smaller"> <i>Created By: {username}</i>  ({isDateTimeExpired?'Expired': `Expires on: ${date}`})</small></Box>
                                        </Box>

                                    </Box>
                            </Box>
                    </Card>
                  </Box>
                )
              })}
      </Box>
      <Box sx={{mt:3}}>
        <PollReviewQnA pollDetails={pollDetails} data={reverseArr} loading={loading}  />
      </Box>
    </>
  );
};

export default PollReviewDetails;
